<template>
  <the-custom-table :head="head" :data="data" />
  <v-row v-if="isManager || isMonitor" class="pa-0 ma-0 mt-6">
    <v-btn color="primary" @click="exportPrograms">Выгрузить</v-btn>
  </v-row>
</template>
<script setup>
import { v4 as uuidv4 } from 'uuid'
import { ref, onMounted, computed } from 'vue'

import TheCustomTable from '@/components/TheCustomTable'
import { useStore } from 'vuex'
import { api } from '@/api/Api'
import { downloadFileData } from '@/helpers'
import moment from 'moment'

const store = useStore()
const props = defineProps({
  projects: {
    type: Array,
    default: () => [],
  },
})

const head = ref([
  {
    id: uuidv4(),
    title: 'Номер программы',
  },
  {
    id: uuidv4(),
    title: 'Центр НТИ',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'Дата утверждения',
  },
])
const data = ref([])

const isManager = computed(() => store.getters['user/isManager'])
const isMonitor = computed(() => store.getters['user/isMonitor'])

function setData() {
  props.projects?.forEach((el) => {
    data.value.push({
      programNumber: `<a href="${getLinkProgram(el.id)}">${el?.centerNti?.programNumber}</a>`,
      centerNtiFullName: el.centerNti?.fullTitle,
      confirmProgramDate: el.confirmProgramDate
        ? moment(el.confirmProgramDate).format('DD.MM.YYYY')
        : '',
    })
  })
}

function getLinkProgram(id) {
  if (isManager.value) {
    return `/coordination-documents/request-create-support-recipient-program-card-view-for-manager/${id}#files`
  }

  if (isMonitor.value) {
    return `/coordination-documents/request-create-support-recipient-program-card-view-for-monitor/${id}#files`
  }

  return `/coordination-documents/request-create-support-recipient-program-card-view-for-winner/${id}#files`
}

async function exportPrograms() {
  try {
    await api
      .projects()
      .exportPrograms()
      .then((data) => {
        downloadFileData(data, 'Программы')
      })
  } catch (err) {
    console.log(err?.message)
  }
}

onMounted(() => {
  setData()
})
</script>
