<template>
  <div class="tasks-tabs">
    <the-tabs
      :tabs="tabs"
      :projects="projects"
      :points="points"
      :is-loading="isLoading"
      @tab-click="handleTabClick"
    />
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import TheTabs from '@/components/TheTabs'
import TasksList from '@/components/Tasks/TasksList'
import { api } from '@/api/Api'
import { useToast } from '@/composables/useToast'
import NotesList from '@/components/Notes/NotesList.vue'
import ExpertiseList from '@/components/Expertise/ExpertiseList.vue'
import store from '@/store'

const { toast } = useToast()

const isExpert = computed(() => store.getters['user/isExpert'])
const isLoading = ref(false)
const tabs = ref([
  {
    id: uuidv4(),
    title: 'Все',
    component: TasksList,
  },
  {
    id: uuidv4(),
    title: 'Требуют действий',
    component: TasksList,
  },
  {
    id: uuidv4(),
    title: 'Отработаны',
    component: TasksList,
  },
  {
    id: uuidv4(),
    title: 'Черновики',
    component: NotesList,
  },
])

const projects = ref([])
const points = ref([])
const activeTab = ref(0)

async function getAllPoints() {
  isLoading.value = true

  try {
    await api
      .projects()
      .getAllPoints()
      .then((data) => {
        points.value = data
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function getActivePoints() {
  isLoading.value = true

  try {
    await api
      .projects()
      .getActivePoints()
      .then((data) => {
        points.value = data
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function handleTabClick(index) {
  if (activeTab.value !== index) {
    activeTab.value = index
    switch (index) {
      case 0:
        return getAllPoints()
      case 1:
        return getActivePoints()
      case 3:
        return
      case 4:
        return
      default:
        return getSpentPoints()
    }
  }
}

async function getProjects() {
  isLoading.value = true
  try {
    await api
      .projects()
      .getProjectsList()
      .then((data) => {
        projects.value = data
      })
  } catch (err) {
    toast.error(err.message)
  } finally {
    isLoading.value = false
  }
}

async function getSpentPoints() {
  isLoading.value = true

  try {
    await api
      .projects()
      .getClosedPoints()
      .then((data) => {
        points.value = data
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

const checkIsExpert = () => {
  if (isExpert.value) {
    tabs.value?.splice(3, 0, {
      id: uuidv4(),
      title: 'Экспертиза',
      component: ExpertiseList,
    })
  }
}

watch(isExpert, () => {
  checkIsExpert()
})

onMounted(() => {
  getProjects()
  getAllPoints()
  checkIsExpert()
})
</script>
<style lang="scss">
.tasks-tabs {
  .v-tab.v-tab {
    border-radius: 0;

    .v-btn__content {
      font-size: 14px;
    }
  }

  .v-slide-group__content {
    border-bottom: 2px solid var(--table--light-border);
  }

  .v-expansion-panel-title {
    border: 1px solid var(--border-th-table);
    background: var(--background-thead-table);
    border-radius: 0;
  }
}
</style>
