<template>
  <section v-if="fields.length !== 0" class="form__container">
    <v-row :no-gutters="true">
      <v-col>
        <div v-for="field in fields" :key="field.id">
          <template v-if="!field.group_id">
            <span class="text-caption text-grey">Индекс сортировки {{ field.sort }}</span>
            <p v-if="field.title" class="text-body-1 ma-0">
              {{ field.title }}
              <FormButtonControlsEdit
                v-if="canEdit"
                is-visible
                :field-id="field.id"
                @delete-filed="$emit('delete', field.id)"
                @update-field="$emit('update', field.id, field.type === 'group')"
              />
            </p>
          </template>
          <div v-if="field.type === 'group'" class="group__border mb-5 pa-5">
            <p v-if="field.type !== 'group'" class="mt-0">
              {{ field.title }}
            </p>
            <div v-for="item in groupById(field, fields)" :key="item.id">
              <span class="text-caption text-grey">Индекс сортировки {{ item.sort }}</span>
              <p class="text-body-1 ma-0">
                {{ item.title }}
                <FormButtonControlsEdit
                  v-if="canEdit"
                  :is-visible="!field.group_id"
                  @delete-filed="$emit('delete', item.id)"
                  @update-field="$emit('update', item.id, false)"
                />
              </p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import FormButtonControlsEdit from '@/components/Form/FormButtonControlsEdit'
import cloneDeep from 'lodash/cloneDeep'

const validForm = ref(true)
const form = ref(null)

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  isDialog: {
    type: Boolean,
    default: false,
  },
  canEdit: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update-forms', 'get-forms', 'delete-input', 'delete', 'update'])

const formData = ref(null)
const isFetch = ref(true)

const fields = computed({
  get() {
    const array = formData.value ? formData.value : []
    return array.sort((a, b) => a.sort - b.sort)
  },
  set(value) {
    formData.value = value
  },
})

function groupById(field, fields) {
  const { id, type } = field
  if (type !== 'group') {
    return
  }
  return fields.filter((item) => item.group_id === id)
}

function focusErrorInput({ id }) {
  const inputId = '#' + id
  document.querySelector(inputId).focus()
}

async function getForm() {
  await form.value.validate()
  if (!validForm.value) {
    const errInput = form.value.errors[0]
    focusErrorInput(errInput)
    return
  }

  emit('get-forms', formData.value, validForm.value)
}

onMounted(() => {
  if (props.isDialog) {
    setTimeout(() => {
      isFetch.value = false
    }, 1000)
  }
  fields.value = cloneDeep(props.data)
})

defineExpose({
  getForm,
})
</script>
<script>
export default {
  name: 'FormsAdminConstructor',
}
</script>

<style lang="scss" scoped>
.group {
  &__border {
    border: 2px solid #aeaeae;
  }
}
</style>
