<template>
  <v-row v-if="isLoading" class="px-0 justify-center align-center" style="min-height: 400px">
    <v-progress-circular color="primary" :indeterminate="true" />
  </v-row>
  <template v-else>
    <v-row class="overflow-x-auto pa-0 ma-0">
      <the-custom-table
        :head="head"
        :data="formData"
        :has-delete="isWinner"
        :has-edit="isWinner"
        width="1400px"
        @delete="deleteConsortia"
        @edit="(id) => $router.push(`/consortia-form/${id}`)"
      />
    </v-row>
    <template v-if="isWinner">
      <FormFile
        id="importFile"
        v-model="importFile"
        accept=".xls, .xlsx"
        reverse
        style="display: none"
        :rules="[['required'], ['file', { extensions: ['.xlsx', '.xls'] }]]"
        clearable
        single-line
        variant="outlined"
      />
      <v-row class="mt-12 mx-0 align-center">
        <v-btn class="mr-4" color="primary" variant="outlined" @click="downloadConsortiumsTemplate">
          Скачать шаблон / инструкцию
        </v-btn>
        <v-btn color="primary" @click="findFile"> Загрузить </v-btn>
        <!--    <v-progress-circular indeterminate color="primary" :size="20" class="ml-4" :width="3" />-->
      </v-row>
      <v-row class="mt-12 mx-0 align-center">
        <v-btn color="primary" @click="() => $router.push('/consortia-form')">
          Добавить участника
        </v-btn>
      </v-row>
    </template>
    <v-row v-else class="mt-12 mx-0 align-center">
      <v-btn class="mr-4" color="primary" @click="exportConsortiums"> Выгрузить </v-btn>
    </v-row>
  </template>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import TheCustomTable from '@/components/TheCustomTable'
import { CONSORTIA_UNIQUE } from '@/constants'
import { api } from '@/api/Api'
import FormFile from '@/components/Form/FormFile'
import { useStore } from 'vuex'
import { downloadFileData } from '@/helpers'
import moment from 'moment'
import { useToast } from '@/composables/useToast'

const store = useStore()
const { toast } = useToast()

const importFile = ref()
const isLoading = ref(false)
const head = ref([
  {
    id: uuidv4(),
    title: 'Центр НТИ',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'Наименование партнера (участника консорциума)',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'Организационно-правовая форма',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'Категория субъекта предпринимательства',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'ИНН (участника консорциума)',
  },
  {
    id: uuidv4(),
    title: 'Тип участника консорциума',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'Номер соглашения и вступлении в консорциум',
  },
  {
    id: uuidv4(),
    title: 'Дата соглашения о вступлении в консорциум',
  },
  {
    id: uuidv4(),
    title: 'Участие в других консорциумах',
  },
])
const formData = ref([])
const isWinner = computed(() => store.getters['user/isWinner'])

function findFile() {
  document.getElementById('inputCustomimportFile').click()
}

async function exportConsortiums() {
  try {
    api
      .information()
      .exportConsortiums()
      .then((response) => {
        downloadFileData(response, 'Консорциумы')
      })
  } catch (err) {
    console.log(err)
  }
}

async function downloadConsortiumsTemplate() {
  try {
    await api
      .information()
      .downloadConsortiumsTemplate()
      .then((response) => {
        downloadFileData(response, 'Шаблон "Консорциумы"')
      })
  } catch (err) {
    console.log(err)
  }
}

async function deleteConsortia(id) {
  try {
    await api
      .information()
      .deleteConsortiums(id)
      .then(() => getConsortiums())
  } catch (err) {
    console.log(err)
  }
}

function getUniqueConsotiaTitle(value) {
  const findEl = CONSORTIA_UNIQUE.find((el) => el.value === value)
  return findEl?.title || ''
}

async function getConsortiums() {
  isLoading.value = true
  try {
    await api
      .information()
      .getConsortiums()
      .then((data) => {
        const newValue = data?.map((el) => {
          el['otherParticipation'] = getUniqueConsotiaTitle(el.otherParticipation)
          el['entryAgreementDate'] = el.entryAgreementDate
            ? moment(el.entryAgreementDate).format('DD.MM.YYYY')
            : ''
          delete el.centerNtiId
          return el
        })
        formData.value = newValue
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function importConsortiums() {
  try {
    const form = new FormData()
    form.append('file', importFile.value[0])
    await api
      .information()
      .importConsortiums(form)
      .then(() => {
        getConsortiums()
      })
  } catch (err) {
    toast.error('Ошибка при чтении файла. Попробуйте загрузить другой файл')
    console.log(err)
  }
}

onMounted(() => {
  getConsortiums()
})

watch(importFile, () => {
  importConsortiums()
})
</script>
