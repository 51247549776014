<template>
  <v-dialog
    v-model="showDialog"
    :max-width="maxWidth"
    class="w-100 pa-0"
    :content-class="['custom-dialog', isDarkTheme && 'dark']"
    persistent
  >
    <v-card class="pa-0 w-100 custom-content-dialog-wrapper">
      <v-card-title class="custom-dialog-head">
        <v-row class="pa-0 justify-space-between align-center px-7 py-2">
          <div :class="['text-subtitle-1 font-weight-bold', isDarkTheme && 'text-white']">
            {{ title }}
          </div>
          <v-btn
            :color="isDarkTheme ? 'white' : 'blue text-decoration-underline'"
            variant="text"
            class="px-0"
            @click="openDialog"
          >
            {{ closeButton.title }}
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <div v-if="data?.length && head?.length" class="ma-2">
          <v-card elevation="0" border="0" style="border-radius: 0">
            <v-col class="overflow-x-auto pa-0 ma-0">
              <the-custom-table :head="head" :data="data" />
              <v-row v-if="hasExport" class="ma-0 px-4 py-4 align-center justify-end">
                <v-btn class="mr-4" color="primary" @click="emit('export')"> Выгрузить </v-btn>
              </v-row>
            </v-col>
          </v-card>
        </div>
        <div v-else class="ma-2">
          <v-card elevation="0">
            <v-col class="overflow-x-auto pa-0 ma-0">
              <slot />
            </v-col>
          </v-card>
        </div>
        <v-row
          v-if="isTableDialog && !data?.length"
          class="justify-center align-center py-12 text-caption text-white"
        >
          Нет данных
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    closeButton: {
      type: Object,
      default: () => {
        return {
          title: 'Закрыть',
        }
      },
    },
    maxWidth: {
      type: Number,
      default: 1000,
    },
    hasExport: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    head: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    isDarkTheme: {
      type: Boolean,
      default: false,
    },
    isTableDialog: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<script setup>
import { ref } from 'vue'
import TheCustomTable from '@/components/TheCustomTable'
import { emit as baseEmit } from '@/composables/form/usePropsForm'

const emit = defineEmits(baseEmit)

const showDialog = ref(false)

function openDialog() {
  showDialog.value = !showDialog.value
  if (showDialog.value) {
    emit('get-data')
  }
}

defineExpose({
  openDialog,
})
</script>
<style lang="scss">
.dark {
  .custom-dialog-head {
    background: var(--dialog-data-background-head);
  }
}
.custom-dialog-head {
  background: white;
}
.dark {
  &.custom-dialog {
    background: var(--dialog-data-background);
  }
}
.custom-dialog {
  background: white;
  border-radius: 4px;
}

.custom-content-dialog-wrapper {
  background: none;
}
</style>
