<template>
  <v-row v-if="!isFetch" justify="center" align="center" class="ma-auto">
    <v-col cols="auto">
      <v-progress-circular color="primary" :indeterminate="true" />
    </v-col>
  </v-row>
  <the-container v-else>
    <div v-if="isNewForm">
      <h3 class="mb-6">{{ title }}</h3>
      <FormsNewForm ref="formHeader" @get-forms="createForm" />
      <v-btn color="primary" @click="getForm"> Создать </v-btn>
    </div>

    <div v-if="!isNewForm">
      <v-row>
        <v-col>
          <h3 class="mb-6">{{ title }}</h3>
          <p>{{ description }}</p>
          <v-row>
            <v-col v-if="canEdit" cols="auto">
              <v-btn color="primary" @click="addInput(forms, 'updateForm', currentForm)">
                Редактировать описание формы
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="primary" @click.prevent="publishForm(!canEdit)">
                {{ canEdit ? 'Опубликовать' : 'Снять с публикации' }}
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="primary" @click="copy"> Получить ссылку </v-btn>
            </v-col>
          </v-row>

          <v-container class="px-0">
            <v-row class="py-2">
              <v-col>
                <v-divider />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <FormsAdminConstructor
                  v-if="currentForm.fields.length !== 0"
                  :key="formKey"
                  :data="currentForm.fields"
                  :can-edit="canEdit"
                  @delete="deleteField"
                  @update="updateField"
                />
                <span v-else>Нет полей формы</span>
              </v-col>
            </v-row>
          </v-container>

          <v-row class="py-2">
            <v-col>
              <v-divider />
            </v-col>
          </v-row>
          <v-row v-if="canEdit">
            <v-col cols="auto">
              <v-btn color="primary" @click="addInput(fieldsHasOptions, 'createField')">
                Добавить новое поле
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="primary" @click="addInput(fieldsAll, 'createGroup')">
                Добавить новую группу
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col>
          <div class="text-h6 mb-3">Предварительный просмотр</div>
          <v-card elevation="0" outlined>
            <v-col class="pa-6">
              <h1 class="mb-6 line-height-1">
                {{ titleForm }}
              </h1>
              <v-card-text :key="currentForm.fields">
                <FormPreview :fields="currentForm.fields" />
              </v-card-text>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
      <FormMainDialog
        ref="formDialog"
        :fields-form="fieldsForm"
        :action="formAction"
        :success-button="successButton"
        @action-form="caseAction"
      />
    </div>
  </the-container>
</template>

<script>
export default {
  name: 'FormEditView',
}
</script>

<script setup>
import TheContainer from '@/components/TheContainer'
import { useRoute, useRouter } from 'vue-router'
import { computed, ref, onMounted } from 'vue'
import { api } from '@/api/Api'
import { v4 as uuidv4 } from 'uuid'
import { prepareFields } from '@/utils/common'
import FormsNewForm from '@/components/Forms/FormsNewForm'
import { useForms } from '@/composables/forms/useForms'
import FormMainDialog from '@/components/Form/FormMainDialog'
import cloneDeep from 'lodash/cloneDeep'
import FormsAdminConstructor from '@/components/Forms/FormsAdminConstructor'
import { copyText } from 'vue3-clipboard'
import { useToast } from '@/composables/useToast'
import FormPreview from '@/components/Forms/FormPreview'

const route = useRoute()
const router = useRouter()
const { getValuesByFields } = useForms()
const { toast } = useToast()

const fieldsForm = ref([])
const isFetch = ref(false)
const currentForm = ref(null)
const formHeader = ref(null)
const forms = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'title',
    title: 'Заголовок формы',
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 20,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'description',
    title: 'Описание формы',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 30,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'email',
    title: 'E-mail для кнопки «Связаться с менеджером»',
    options: [],
    default_value: '',
    descr:
      'Так же используется как отправитель при отправке писем. Позволяет переопределить настройки конкурса.',
    note: '',
    multiple: 0,
    rules: [['email']],
    sort: 1400,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'email_sign',
    title: 'Подпись для уведомлений и писем',
    options: [],
    default_value: '',
    descr: 'Позволяет переопределить настройки конкурса.',
    note: '',
    multiple: 0,
    rules: null,
    sort: 1500,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'success_message',
    title: 'Текст, который отправляется при заполнении формы конкурса',
    options: [],
    default_value: '',
    descr:
      'Выводится при успешной отправке анкеты на экране и отправляется по почте пользователю.  Позволяет переопределить настройки конкурса.',
    note: '',
    multiple: 0,
    rules: null,
    sort: 1600,
  },
  {
    isVisible: true,
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    name: 'data_source_id',
    title: 'Выберите форму, для обогащения анкеты',
    options: [],
    default_value: 0,
    descr: '',
    note: '',
    multiple: 0,
    rules: null,
    sort: 110,
    itemValue: 'id',
    itemTitle: 'title',
  },
])
const formKey = ref(0)
const formDialog = ref(null)
const formAction = ref('')
const selectedFieldId = ref(null)
const groupIds = ref([])
const fieldTypes = [
  {
    name: 'Текстовое поле',
    value: 'text',
  },
  {
    name: 'Много-строковое текстовое поле',
    value: 'textarea',
  },
  {
    name: 'Выпадающий список',
    value: 'select',
  },
  {
    name: 'Выпадающий список с возможностью ввода другого значения',
    value: 'combobox_other',
  },
  {
    name: 'Текстовое поле с выпадающий списком',
    value: 'combobox',
  },
  {
    name: 'Чек-бокс',
    value: 'checkbox',
  },
  {
    name: 'Радио-кнопки',
    value: 'radio',
  },
  {
    name: 'Файл',
    value: 'file',
  },
]

const fieldsAll = [
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'title',
    title: 'Заголовок поля',
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 10,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'name',
    title: 'Имя параметра',
    default_value: '',
    descr: 'Значение должно быть уникальное, короткое(одно или два слова) и на латинице.',
    note: '',
    multiple: 0,
    rules: [['required'], ['latin'], ['string', { max: '255' }], ['cannotStartWithNumber']],
    sort: 20,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'descr',
    title: 'Текст описания поля',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 40,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'note',
    title: 'Текст всплывающей подсказки',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 50,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'radio',
    name: 'multiple',
    title: 'Возможность добавить еще поле',
    options: [
      {
        id: 7,
        name: 'Да',
        value: 1,
        description: '',
        disabled: 'N',
        sort: 10,
      },
      {
        id: 8,
        name: 'Нет',
        value: 0,
        description: '',
        disabled: 'N',
        sort: 10,
      },
    ],
    default_value: 0,
    descr: '',
    note: '',
    multiple: 0,
    rules: null,
    sort: 60,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'rules',
    name: 'rules',
    title: 'Конструктор правил',
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: null,
    sort: 70,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'sort',
    title: 'Индекс сортировки',
    default_value: '',
    descr: 'Используется для выстраивания порядка элементов форм (чем меньше, тем выше).',
    note: '',
    multiple: 0,
    rules: null,
    sort: 80,
  },
]
const fieldsHasOptions = [
  ...fieldsAll,
  {
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    name: 'type',
    title: 'Тип поля',
    options: fieldTypes,
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: ['required'],
    sort: 30,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    name: 'group_id',
    title: 'Поле группы',
    options: groupIds.value,
    default_value: 0,
    descr: '',
    note: '',
    multiple: 0,
    rules: ['required'],
    sort: 40,
  },
  {
    id: 9,
    group_id: 0,
    type: 'group',
    name: 'options',
    title: 'Опция',
    options: [],
    default_value: '',
    descr:
      "Обязательно для заполнения при выборе 'Тип поля' - 'Выпадающий список', 'Текстовое поле с выпадающий списком', 'Чек-бокс', 'Радио-кнопки'",
    note: '',
    multiple: 1,
    rules: null,
    sort: 90,
    buttonAdd: 'Добавить опцию',
  },
  {
    id: uuidv4(),
    group_id: 9,
    type: 'text',
    name: 'option_name[]',
    name_children: 'name',
    title: 'Наименование',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['string', { max: '255' }]],
    sort: 500,
  },
  {
    id: uuidv4(),
    group_id: 9,
    type: 'text',
    name: 'option_value[]',
    name_children: 'value',
    title: 'Уникальное значение',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['string', { max: '255' }]],
    sort: 1000,
  },
  {
    id: uuidv4(),
    group_id: 9,
    type: 'textarea',
    name: 'option_description[]',
    name_children: 'description',
    title: 'Описание',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: null,
    sort: 1500,
  },
  {
    id: uuidv4(),
    group_id: 9,
    type: 'radio',
    name: 'option_disabled[]',
    name_children: 'disabled',
    title: 'Заблокировать для заполнения',
    options: [
      {
        id: 7,
        name: 'Да',
        value: 'Y',
        description: '',
        disabled: 'N',
        sort: 10,
      },
      {
        id: 8,
        name: 'Нет',
        value: 'N',
        description: '',
        disabled: 'N',
        sort: 10,
      },
    ],
    default_value: 'N',
    descr: '',
    note: '',
    multiple: 0,
    rules: null,
    sort: 2000,
  },
  {
    id: uuidv4(),
    group_id: 9,
    type: 'text',
    name: 'option_sort[]',
    name_children: 'sort',
    title: 'Индекс сортировки',
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: null,
    sort: 2500,
  },
]
const fieldsHasOptionsCopy = ref(cloneDeep(fieldsHasOptions))
const fieldsAllCopy = ref(cloneDeep(fieldsAll))
const successButton = ref({
  title: 'Добавить',
})

const canEdit = computed(() => currentForm.value?.can_edit)

const titleForm = computed(() => currentForm.value?.title)

const formId = computed(() => {
  const { id } = route.params
  return id
})

const isNewForm = computed(() => !formId.value)
const title = computed(() => {
  const title = currentForm.value?.title
  return isNewForm.value
    ? 'Новая форма'
    : `${title} ${canEdit.value ? ' - (форма не опубликована)' : ''}`
})

const description = computed(() => {
  return isNewForm.value ? '' : currentForm.value?.description
})

async function nextFormPage(id) {
  await router.push(`/form-edit/${id}`)
}
function setFields(fields) {
  fieldsForm.value = fields
}
function getForm() {
  if (formHeader.value) {
    formHeader.value.getForm()
  }
}
function addInput(basisFields, action, source = null) {
  successButton.value.title = 'Добавить'
  if (source) {
    successButton.value.title = 'Изменить'
    const fields = getValuesByFields(source, basisFields)
    setFields(fields)
  } else {
    setFields(basisFields)
  }
  formAction.value = action
  formDialog.value.openDialog()
}
async function deleteField(fieldId) {
  await api.formFields().deleteFields(fieldId)
  update()
}
function updateField(fieldId, isGroup) {
  selectedFieldId.value = fieldId
  const foundField = currentForm.value.fields.find((field) => field.id === fieldId)
  if (isGroup) {
    addInput(fieldsAll, 'updateFieldGroup', foundField)
    return
  }

  addInput(fieldsHasOptions, 'updateField', foundField)
}

async function sendUpdatedField(inputs, valid) {
  if (!valid) {
    return
  }
  const formData = new FormData()
  const preparedFields = prepareFields(inputs)

  preparedFields.forEach((input) => {
    if (input.type === 'rules') {
      formData.append(input.name, input.default_value || JSON.stringify(input.rules))
    } else {
      formData.append(input.name, input.default_value)
    }
  })

  try {
    const { title } = await api.formFields().updateFields(selectedFieldId.value, formData)
    toast.success(`Поле "${title}" успешно обновлено`)
  } catch (e) {
    toast.error('Ошибка при обновлении поля')
  }
}
async function sendUpdatedForm(formData) {
  return await api
    .form()
    .updateForm(formId.value, formData)
    .finally(() => {
      update()
    })
}
async function updateForm(inputs, valid) {
  if (!valid) {
    return
  }

  const formData = new FormData()
  inputs.forEach((input) => {
    currentForm.value[input.name] = input.default_value
  })

  Object.entries(currentForm.value).forEach(([key, value]) => {
    formData.append(key, value)
  })

  await sendUpdatedForm(formData)
}
async function publishForm(isPublic) {
  const formData = new FormData()
  formData.append('active', isPublic ? 0 : 1)

  await sendUpdatedForm(formData)
  const message = !isPublic ? 'Форма успешно опубликована' : 'Форма снята с публикации'
  toast.success(message)
}
async function createForm(inputs, valid) {
  if (!valid) {
    return
  }

  const formData = new FormData()
  inputs.forEach((input) => {
    if (input.default_value === '') {
      return
    }

    formData.append(input.name, input.default_value)
  })
  if (!isNewForm.value) {
    return await api.form().updateForm(formId.value, formData)
  }

  if (formData.has('donor_id')) {
    const { id } = await api
      .form()
      .duplicateForm(formData.get('donor_id'), formData.get('contest_id'))
    await nextFormPage(id)
    return
  }

  const { id } = await api.form().createForm(formData)
  await nextFormPage(id)
}
function createField(fields, valid, isGroup = false) {
  if (!valid) {
    return
  }

  const formData = new FormData()
  formData.append('form_id', formId.value)
  if (isGroup) {
    formData.append('group_id', 0)
    formData.append('type', 'group')
  }

  const preparedFields = prepareFields(fields)
  preparedFields.forEach((field) => {
    if (field.default_value !== '') {
      formData.append(field.name, field.default_value)
    }
  })

  api.formFields().createFields(formData)
}
function update() {
  setTimeout(async () => {
    await loadItems()
    formKey.value += 1
  }, 1000)
}
async function loadItems() {
  if (isNewForm.value) {
    return
  }

  currentForm.value = await api.form().getFormById(formId.value)

  const {
    contest: { id: contestId },
  } = currentForm.value
  const qwery = {
    contest_id: contestId,
    exclude: 'contest,fields',
  }

  const { items } = await api.form().getForms(1, 1000, qwery)
  const basisForm = [
    {
      title: 'Нет формы',
      id: 0,
    },
  ]
  forms.value.find((field) => field.name === 'data_source_id').options = [...basisForm, ...items]
  const groups = currentForm.value.fields
    .map((field) => {
      if (field.type !== 'group') {
        return
      }

      return {
        value: field.id,
        name: field.title,
      }
    })
    .filter(Boolean)

  const basisGroups = [
    {
      name: 'Нет группы',
      value: 0,
    },
  ]
  groupIds.value = [...basisGroups, ...groups]
  fieldsHasOptions.find((field) => field.name === 'group_id').options = groupIds.value
}

function caseAction(inputs, valid, action) {
  switch (action) {
    case 'updateForm': {
      updateForm(inputs, valid)
      break
    }
    case 'createField': {
      createField(inputs, valid)
      break
    }
    case 'createGroup': {
      createField(inputs, valid, true)
      break
    }
    case 'updateFieldGroup': {
      sendUpdatedField(inputs, valid)
      break
    }
    case 'updateField': {
      sendUpdatedField(inputs, valid)
      break
    }
  }

  update()
  formDialog.value.closeDialog()
  fieldsHasOptionsCopy.value = cloneDeep(fieldsHasOptions)
  fieldsAllCopy.value = cloneDeep(fieldsAll)
}

async function copy() {
  const link = `${window.location.origin}/form/${formId.value}`
  copyText(link)
  toast.info('Ссылка скопирована')
}

onMounted(async () => {
  isFetch.value = false
  await loadItems()
  isFetch.value = true
})
</script>
