<template>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <v-row v-else class="overflow-x-auto pa-0 ma-0">
    <the-custom-table :key="data" :head="head" :data="data" width="2700px" />
  </v-row>

  <v-row v-if="isManager || isMonitor" class="pa-0 ma-0 mt-6">
    <v-btn color="primary" @click="exportPrograms">Выгрузить</v-btn>
  </v-row>
  <v-row v-else class="pa-0 ma-0 mt-6">
    <v-btn color="primary" @click="openFeedbackForm">
      Запрос на изменение сведений о центре НТИ
    </v-btn>
  </v-row>
  <FeedbackDialog ref="feedbackDialog" />
</template>
<script setup>
import { v4 as uuidv4 } from 'uuid'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

import { downloadFileData } from '@/helpers'

import { api } from '@/api/Api'

import TheCustomTable from '@/components/TheCustomTable'
import FeedbackDialog from '@/components/User/FeedbackDialog'
import moment from 'moment'

const store = useStore()

const props = defineProps({
  projects: {
    type: Array,
    default: () => [],
  },
})

const isManager = computed(() => store.getters['user/isManager'])
const isMonitor = computed(() => store.getters['user/isMonitor'])

const data = computed(() => {
  return props.projects?.map((el) => {
    return {
      id: uuidv4(),
      grantRecipientName: el.grantRecipientName,
      granteeINN: el.granteeINN,
      centerNtiFullName: el.centerNti?.fullTitle,
      centerNtiShortName: el.centerNti?.shortTitle,
      technologyName: el.technologyName,
      curatorCutr: el.curatorCutr,
      legalAddress: el?.legalAddress,
      addressCorrespondence: el?.addressCorrespondence,
      fullNameCorrespondence: el?.fullnameCorrespondence,
      phoneNumberCorrespondence: el?.phoneNumberCorrespondence,
      contractDate: getContractDate(el.contracts),
      bankAccountBankName: el.bankAccountBankName,
    }
  })
})
const feedbackDialog = ref(null)
const isLoading = ref(false)
const head = ref([
  {
    id: uuidv4(),
    title: 'Полное наименование получателя Гранта',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'ИНН Получателя гранта',
  },
  {
    id: uuidv4(),
    title: 'Наименование центра НТИ',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'Краткое наименование Центра НТИ',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'Наименование «Сквозной технологии»',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'Куратор ЦУТР (ФИО)',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'Юридический адрес',
  },
  {
    id: uuidv4(),
    title: 'Адрес для отправки корреспонденции',
  },
  {
    id: uuidv4(),
    title: 'ФИО',
  },
  {
    id: uuidv4(),
    title: 'Телефон получателя',
  },
  {
    id: uuidv4(),
    title: 'Номера и дата подписания договоров',
  },
  {
    id: uuidv4(),
    title: 'Банковские реквизиты',
  },
])

function getContractDate(contracts) {
  let data = ''
  contracts?.forEach((el) => {
    data += `<div>${el.number}, ${moment(el.agreementDate).format('DD.MM.YYYY')}</div>`
  })

  return data
}

async function exportPrograms() {
  try {
    await api
      .projects()
      .exportProjects()
      .then((data) => {
        downloadFileData(data, 'Программы')
      })
  } catch (err) {
    console.log(err?.message)
  }
}

function openFeedbackForm() {
  if (feedbackDialog.value) {
    feedbackDialog.value.dialogToggle()
  }
}
</script>
