<template>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <div v-else :key="data.length">
    <v-table v-if="data?.length" class="text-caption">
      <thead>
        <tr>
          <th
            v-for="el in head"
            :key="el"
            class="text-left pl-0"
            :style="`min-width: ${el.width}px`"
          >
            {{ el.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="`tr-${index}`">
          <td
            v-for="(val, i) in Object.values(item)"
            :key="`data-${val}`"
            :style="`min-width: ${head[i]?.width}px`"
            class="pl-0"
          >
            {{ val }}
          </td>
        </tr>
      </tbody>
    </v-table>
    <v-row v-else class="justify-center align-center py-12 text-caption text-grey">
      Нет данных
    </v-row>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'

import { api } from '@/api/Api'
import { useRoute } from 'vue-router/dist/vue-router'

const route = useRoute()

const isLoading = ref(false)
const data = ref([])
const head = ref([
  {
    title: 'Наименование дохода',
    width: 500,
  },
  {
    title: 'Наименование стороны договора / соглашения',
    width: 'auto',
  },
  {
    title: 'ИНН стороны договора / соглашения',
    width: 'auto',
  },
  {
    title: 'Сумма дохода, руб. ',
    width: 'auto',
  },
  {
    title: 'Договор / соглашение',
    width: 'auto',
  },
  {
    title: 'Акты выполненных работ5',
    width: 'auto',
  },
  {
    title: 'Платежные поручения',
    width: 'auto',
  },
  {
    title: 'Перечень сотрудников Центра НТИ',
    width: 500,
  },
  {
    title: 'Расходы на оборудование, руб.',
    width: 'auto',
  },
  {
    title: 'Расходы на программное обеспечение, руб.)',
    width: 'auto',
  },
  {
    title: 'Бухгалтерская справка подтверждающая расходы',
    width: 'auto',
  },
])

async function getAccountRegisterIncome() {
  isLoading.value = true
  try {
    await api
      .reports()
      .getAccountRegisterIncome(route.params.id)
      .then((res) => {
        data.value = res?.map((el) => {
          return {
            name: el.name,
            partyAgreementName: el.partyAgreementName,
            partyAgreementINN: el.partyAgreementINN,
            amount: el.amount,
            agreement: el.agreement,
            acts: el.acts,
            paymentOrders: el.paymentOrders,
            centerEmployeesList: el.centerEmployeesList,
            equipmentExpenses: el.equipmentExpenses,
            softwareExpenses: el.softwareExpenses,
            accountingStatement: el.accountingStatement,
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getAccountRegisterIncome()
})
</script>
