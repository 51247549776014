<template>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <div v-else :key="data.length">
    <v-table v-if="data?.length" class="text-caption">
      <thead>
        <tr>
          <th v-for="el in head" :key="el" class="text-left">{{ el.title }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="`tr-${index}`">
          <td v-for="val in Object.values(item)" :key="`data-${val}`">{{ val }}</td>
        </tr>
      </tbody>
    </v-table>
    <v-row v-else class="justify-center align-center py-12 text-caption text-grey">
      Нет данных
    </v-row>
    <!--    <the-custom-table :head="head" :data="data" text-td-left />-->
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'

import { api } from '@/api/Api'
import { useRoute } from 'vue-router/dist/vue-router'
// import TheCustomTable from '@/components/TheCustomTable'
const route = useRoute()

const isLoading = ref(false)
const data = ref([])
const head = ref([
  {
    title: 'Сотрудник центра программы',
  },
  {
    title: 'Название организации',
  },
  {
    title: 'Название программы',
  },
  {
    title: 'Специалист',
  },
  {
    title: 'Дата получения диплома',
  },
  {
    title: 'Дата получения нового диплома',
  },
])

async function getAccountRegisterEducation() {
  isLoading.value = true
  try {
    await api
      .reports()
      .getAccountRegisterEducation(route.params.id)
      .then((res) => {
        data.value = res.slice(0, 10)?.map((el) => {
          return {
            centerProgramEmployees: el.centerProgramEmployees,
            specialistTrainedOrganizationName: el.specialistTrainedOrganizationName,
            educationalProgramName: el.educationalProgramName,
            specialistName: el.specialistName,
            receivedDiplomaOldDate: el.receivedDiplomaOldDate,
            receivedDiplomaNewDate: el.receivedDiplomaNewDate,
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getAccountRegisterEducation()
})
</script>
