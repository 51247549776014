import { v4 as uuidv4 } from 'uuid'
import { REPORT_TYPES } from '@/constants/index'

export const FORM_DETAILS_STRUCTURE = [
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'grantRecipientName',
    title: 'Полное наименование получателя Гранта',
    options: [],
    disabled: 1,
    default_value: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    disabled: 1,
    name: 'centerNTI',
    title: 'Наименование центра НТИ',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    disabled: 1,
    type: 'text',
    name: 'granteeINN',
    title: 'ИНН Получателя гранта',
    options: [],
    default_value: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    disabled: 1,
    name: 'realizationStartDate',
    title: 'Дата начала реализации Программы Центра НТИ',
    options: [],
    default_value: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    disabled: 1,
    name: 'contractNumber',
    title: 'Номер Договора',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    disabled: 1,
    name: 'contractAgreementDate',
    title: 'Дата заключения Договора',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    disabled: 1,
    type: 'date',
    name: 'reportingPeriodStartDate',
    title: 'Дата начала отчётного периода',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    disabled: 1,
    name: 'reportingPeriodEndDate',
    title: 'Дата окончания отчётного периода',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    disabled: 1,
    name: 'createdAt',
    title: 'Дата предоставления отчета',
    options: [],
    default_value: new Date(),
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    disabled: 1,
    name: 'status',
    title: 'Вид отчета',
    options: REPORT_TYPES,
    default_value: 0,
    descr: '',
    note: '',
    multiple: 0,
    rules: null,
    sort: 110,
    itemValue: 'id',
    itemTitle: 'title',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'number',
    name: 'year',
    title: 'Год',
    disabled: 0,
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '4' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 0,
    type: 'number',
    computedProperty: 'beginningBalance',
    name: 'expenseStaffBeginning',
    title: 'Остаток средств на начало отчетного периода, руб.',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 0,
    type: 'number',
    name: 'expenseStaffActual',
    computedProperty: 'actualExpenses',
    title: 'Фактический размер расходов, руб. *7',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 0,
    type: 'number',
    name: 'expenseStaffEnd',
    computedProperty: 'balanceEndDate',
    title: 'Остаток средств на дату окончания отчетного периода, руб.',
    options: [],
    disabled: 1,
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 1,
    type: 'number',
    name: 'expenseServiceBeginning',
    computedProperty: 'beginningBalance',
    title: 'Остаток средств на начало отчетного периода, руб.',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 1,
    type: 'number',
    name: 'expenseServiceActual',
    computedProperty: 'actualExpenses',
    title: 'Фактический размер расходов, руб.',
    options: [['string', { max: '15' }]],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 1,
    type: 'number',
    name: 'expenseServiceEnd',
    computedProperty: 'balanceEndDate',
    title: 'Остаток средств на дату окончания отчетного периода, руб.',
    options: [['string', { max: '15' }]],
    default_value: '',
    descr: '',
    disabled: 1,
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 2,
    type: 'number',
    name: 'expenseEquipmentBeginning',
    computedProperty: 'beginningBalance',
    title: 'Остаток средств на начало отчетного периода, руб.',
    options: [['string', { max: '15' }]],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 2,
    type: 'number',
    name: 'expenseEquipmentActual',
    computedProperty: 'actualExpenses',
    title: 'Фактический размер расходов, руб.',
    options: [['string', { max: '15' }]],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 2,
    type: 'number',
    name: 'expenseEquipmentEnd',
    computedProperty: 'balanceEndDate',
    title: 'Остаток средств на дату окончания отчетного периода, руб.',
    options: [],
    default_value: '',
    disabled: 1,
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 3,
    type: 'number',
    name: 'expenseSoftwareBeginning',
    computedProperty: 'beginningBalance',
    title: 'Остаток средств на начало отчетного периода, руб.',
    options: [['string', { max: '15' }]],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 3,
    type: 'number',
    name: 'expenseSoftwareActual',
    computedProperty: 'actualExpenses',
    title: 'Фактический размер расходов, руб.',
    options: [['string', { max: '15' }]],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 3,
    type: 'number',
    name: 'expenseSoftwareEnd',
    computedProperty: 'balanceEndDate',
    title: 'Остаток средств на дату окончания отчетного периода, руб.',
    options: [['string', { max: '15' }]],
    default_value: '',
    disabled: 1,
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 4,
    type: 'number',
    name: 'balanceStartPeriod',
    computedProperty: 'beginningBalance',
    title: 'Остаток средств на начало отчетного периода, руб.',
    options: [],
    disabled: 1,
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 4,
    type: 'number',
    name: 'factSizeExpenses',
    computedProperty: 'actualExpenses',
    title: 'Фактический размер расходов, руб.',
    options: [['string', { max: '15' }]],
    disabled: 1,
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 4,
    type: 'number',
    name: 'balanceEnd',
    computedProperty: 'balanceEndDate',
    title: 'Остаток средств на дату окончания отчетного периода, руб.',
    options: [],
    disabled: 1,
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 4,
    type: 'text',
    name: 'description',
    title: 'Примечание',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'dropFile',
    links: [],
    name: 'grantSpendingStructureFiles',
    title: 'Форма для загрузки подтверждающих документов',
    previewTitle: 'Прикрепленные документы:',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required']],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'text',
    name: 'equipmentName',
    title: 'Наименование оборудования *9',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'number',
    name: 'unitPrice',
    title: 'Цена за единицу, руб.',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'number',
    name: 'count',
    title: 'Кол-во ед.',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'number',
    isComputed: true,
    name: 'amount',
    title: 'Стоимость, руб.',
    disabled: 1,
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'text',
    name: 'nameAndDetailsOfDocuments',
    title: 'Наименование и реквизиты документов, подтверждающих расходы *10',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'dropFile',
    links: [],
    name: 'costStructureGrantFundsConfirmDocs',
    title: 'Форма для загрузки подтверждающих документов',
    previewTitle: 'Прикрепленные документы:',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required']],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'text',
    name: 'softwareName',
    title: 'Наименование программного обеспечения *12',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'number',
    name: 'unitPrice',
    title: 'Цена за единицу, руб.',
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'number',
    name: 'count',
    title: 'Кол-во ед.',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'number',
    isComputed: true,
    name: 'amount',
    title: 'Стоимость, руб.',
    options: [],
    disabled: 1,
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'text',
    isComputed: true,
    name: 'nameAndDetailsOfDocuments',
    title: 'Наименование и реквизиты документов, подтверждающих расходы *13',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'dropFile',
    links: [],
    name: 'equipmentAcquisitionCostsConfirmDocs',
    title: 'Форма для загрузки подтверждающих документов',
    previewTitle: 'Прикрепленные документы:',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required']],
    sort: 1,
  },

  {
    id: uuidv4(),
    group_id: 4,
    type: 'text',
    name: 'serviceName',
    title: 'Наименование работ, услуг *15',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 4,
    type: 'number',
    isComputed: true,
    name: 'price',
    title: 'Стоимость, руб.',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 4,
    type: 'text',
    isComputed: true,
    name: 'nameAndDetailsOfDocuments',
    title: 'Наименование и реквизиты документов, подтверждающих расходы *16',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 4,
    type: 'dropFile',
    links: [],
    name: 'grantSpendingConfirmDocs',
    title: 'Форма для загрузки подтверждающих документов',
    previewTitle: 'Прикрепленные документы:',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
]

export const FORM_PROGRAM_EXPENDITURE_REPORT = [
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'grantRecipientName',
    title: 'Полное наименование получателя Гранта',
    options: [],
    disabled: 1,
    default_value: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    disabled: 1,
    name: 'centerNTI',
    title: 'Наименование центра НТИ',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    disabled: 1,
    type: 'text',
    name: 'granteeINN',
    title: 'ИНН Получателя гранта',
    options: [],
    default_value: '',
    note: '',
    multiple: false,
    rules: [['required']],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    disabled: 1,
    name: 'realizationStartDate',
    title: 'Дата начала реализации Программы Центра НТИ',
    options: [],
    default_value: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    disabled: 1,
    name: 'contractNumber',
    title: 'Номер Договора',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    disabled: 1,
    name: 'contractAgreementDate',
    title: 'Дата заключения Договора',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    disabled: 1,
    type: 'date',
    name: 'reportingPeriodStartDate',
    title: 'Дата начала отчётного периода',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    disabled: 1,
    name: 'reportingPeriodEndDate',
    title: 'Дата окончания отчётного периода',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    disabled: 1,
    name: 'createdAt',
    title: 'Дата предоставления отчета',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    disabled: 1,
    name: 'type_report',
    title: 'Вид отчета',
    options: REPORT_TYPES,
    default_value: 0,
    descr: '',
    note: '',
    multiple: 0,
    rules: null,
    sort: 110,
    itemValue: 'id',
    itemTitle: 'title',
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 0,
    type: 'number',
    name: 'plannedExpenditure',
    title: 'Плановый объем расходов, руб.',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 1,
    type: 'number',
    name: 'wageFunds',
    title: 'Расходы из доходных договоров, руб.',
    isExpensesFromIncomeContracts: true,
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 1,
    type: 'number',
    name: 'wageFundOtherSource',
    title: 'Расходы из других источников, руб.',
    isSpendingFromOtherSources: true,
    options: [],
    disabled: 0,
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 1,
    type: 'number',
    name: 'amount',
    disabled: 1,
    title: 'Всего средств:',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },

  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 2,
    type: 'number',
    name: 'equipments',
    title: 'Расходы из доходных договоров, руб.',
    isExpensesFromIncomeContracts: true,
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 2,
    type: 'number',
    name: 'equipmentsOtherSource',
    title: 'Расходы из других источников, руб.',
    isSpendingFromOtherSources: true,
    options: [],
    disabled: 0,
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 2,
    type: 'number',
    name: 'amount',
    disabled: 1,
    title: 'Всего средств:',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },

  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 3,
    type: 'number',
    name: 'software',
    title: 'Расходы из доходных договоров, руб.',
    isExpensesFromIncomeContracts: true,
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 3,
    type: 'number',
    name: 'softwareOtherSource',
    title: 'Расходы из других источников, руб.',
    isSpendingFromOtherSources: true,
    options: [],
    disabled: 0,
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 3,
    type: 'number',
    name: 'amount',
    disabled: 1,
    title: 'Всего средств:',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 4,
    type: 'number',
    name: 'materials',
    title: 'Расходы из доходных договоров, руб.',
    isExpensesFromIncomeContracts: true,
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 4,
    type: 'number',
    name: 'amount',
    disabled: 1,
    title: 'Всего средств:',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 5,
    type: 'number',
    name: 'paymentsThirdPartyServices',
    title: 'Расходы из доходных договоров, руб.',
    isExpensesFromIncomeContracts: true,
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 5,
    type: 'number',
    name: 'amount',
    disabled: 1,
    title: 'Всего средств:',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },

  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 6,
    type: 'number',
    name: 'otherDirectCosts',
    title: 'Расходы из доходных договоров, руб.',
    isExpensesFromIncomeContracts: true,
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 6,
    type: 'number',
    name: 'amount',
    disabled: 1,
    title: 'Всего средств:',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },

  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 7,
    type: 'number',
    name: 'overheads',
    title: 'Расходы из доходных договоров, руб.',
    isExpensesFromIncomeContracts: true,
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 7,
    type: 'number',
    name: 'amount',
    disabled: 1,
    title: 'Всего средств:',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },

  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 8,
    type: 'number',
    disabled: 1,
    name: 'incomeContractsExpenses',
    title: 'Расходы из доходных договоров, руб.',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 8,
    type: 'number',
    name: 'otherExpenses',
    disabled: 1,
    title: 'Расходы из других источников, руб.',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    subcategory: 8,
    type: 'number',
    name: 'totalAmount',
    disabled: 1,
    title: 'Всего средств:',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'text',
    name: 'equipmentName',
    title: 'Наименование оборудования *9',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'number',
    name: 'unitPrice',
    title: 'Цена за единицу, руб.',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'number',
    name: 'count',
    title: 'Кол-во ед.',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'number',
    isComputed: true,
    name: 'amount',
    title: 'Стоимость, руб.',
    disabled: 1,
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'text',
    name: 'nameOrganization',
    title: 'Наименование организации, понесшей расходы',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    title: 'Наименование и реквизиты документов, подтверждающих расходы *19',
    id: uuidv4(),
    group_id: 2,
    type: 'text',
    links: [],
    name: 'nameDocuments',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'dropFile',
    links: [],
    name: 'reportCoFinanceExpenseEquipmentDocs',
    title: 'Форма для загрузки подтверждающих документов',
    previewTitle: 'Прикрепленные документы:',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required']],
    sort: 1,
  },

  {
    id: uuidv4(),
    group_id: 3,
    type: 'text',
    name: 'softwareName',
    title: 'Наименование программного обеспечения *12',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'number',
    name: 'unitPrice',
    title: 'Цена за единицу, руб.',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'number',
    name: 'count',
    title: 'Кол-во ед.',
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'number',
    isComputed: true,
    name: 'amount',
    title: 'Стоимость, руб.',
    options: [],
    disabled: 1,
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'text',
    name: 'nameOrganization',
    title: 'Наименование организации, понесшей расходы',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['string', { max: '255' }]],
    sort: 1,
  },
  {
    title: 'Наименование и реквизиты документов, подтверждающих расходы *19',
    id: uuidv4(),
    group_id: 3,
    type: 'text',
    links: [],
    name: 'nameDocuments',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'dropFile',
    links: [],
    name: 'reportCoFinanceExpenseSoftwareDocs',
    title: 'Форма для загрузки подтверждающих документов',
    previewTitle: 'Прикрепленные документы:',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required']],
    sort: 1,
  },

  {
    id: uuidv4(),
    group_id: 4,
    type: 'text',
    name: 'workName',
    title: 'Наименование работ, услуг *15',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 4,
    type: 'text',
    name: 'nameOrganization',
    title: 'Наименование организации, понесшей расходы',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    title: 'Наименование и реквизиты документов, подтверждающих расходы *19',
    id: uuidv4(),
    group_id: 4,
    type: 'text',
    links: [],
    name: 'nameDocuments',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 4,
    type: 'number',
    isComputed: true,
    name: 'cost',
    title: 'Стоимость, руб.',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 4,
    type: 'dropFile',
    links: [],
    name: 'grantSpendingConfirmDocs',
    title: 'Форма для загрузки подтверждающих документов',
    previewTitle: 'Прикрепленные документы:',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required']],
    sort: 1,
  },
]

export const FORM_PROGRAM_PROGRESS = [
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'grantRecipientName',
    title: 'Полное наименование получателя Гранта',
    options: [],
    disabled: 1,
    default_value: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    disabled: 1,
    name: 'centerNTI',
    title: 'Наименование центра НТИ',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 2,
  },
  {
    id: uuidv4(),
    group_id: 0,
    disabled: 1,
    type: 'text',
    name: 'granteeINN',
    title: 'ИНН Получателя гранта',
    options: [],
    default_value: '',
    note: '',
    multiple: false,
    rules: [['required'], ['granteeINN'], ['string', { max: '10' }]],
    sort: 3,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    disabled: 1,
    name: 'realizationStartDate',
    title: 'Дата начала реализации Программы Центра НТИ',
    options: [],
    default_value: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 4,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    disabled: 1,
    name: 'contractNumber',
    title: 'Номер Договора',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 5,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    disabled: 1,
    name: 'contractAgreementDate',
    title: 'Дата заключения Договора',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 6,
  },
  {
    id: uuidv4(),
    group_id: 0,
    disabled: 1,
    type: 'date',
    name: 'reportingPeriodStartDate',
    title: 'Дата начала отчётного периода',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 7,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    disabled: 1,
    name: 'reportingPeriodEndDate',
    title: 'Дата окончания отчётного периода',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 8,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    disabled: 1,
    name: 'signedAt',
    title: 'Дата предоставления отчета',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [],
    sort: 9,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    disabled: 1,
    name: 'type_report',
    title: 'Вид отчета',
    options: REPORT_TYPES,
    default_value: 0,
    descr: '',
    note: '',
    multiple: 0,
    rules: null,
    sort: 10,
    itemValue: 'id',
    itemTitle: 'title',
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'text',
    name: 'controlPoint',
    title: 'Контрольные точки',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 11,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'date',
    monthPicker: true,
    name: 'plannedExecutionTime',
    title: 'Плановая дата наступления',
    isExpensesFromIncomeContracts: true,
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required']],
    sort: 12,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'date',
    name: 'executionTime',
    title: 'Фактическая дата наступления',
    isSpendingFromOtherSources: true,
    options: [],
    disabled: 0,
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required']],
    sort: 13,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'text',
    name: 'plannedResult',
    disabled: 0,
    title: 'Плановые результаты',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 14,
  },

  {
    id: uuidv4(),
    group_id: 1,
    type: 'textarea',
    name: 'result',
    title: 'Фактические результаты, документы, подтверждающие достижение результата',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '1000' }]],
    sort: 15,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'dropFile',
    name: 'implementationControlPointsDocs',
    title: 'Форма для загрузки подтверждающих документов',
    options: [],
    disabled: 0,
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 16,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 1,
    type: 'text',
    name: 'number',
    title: '№ п/п *25',
    min: 1,
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 17,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 1,
    type: 'text',
    name: 'name',
    title: 'Наименование мероприятия или работы *26',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 18,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 1,
    type: 'date',
    name: 'plannedExecutionTime',
    title: 'Плановый срок исполнения',
    options: [],
    monthPicker: true,
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required']],
    sort: 19,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 1,
    type: 'date',
    name: 'executionTime',
    title: 'Фактический срок исполнения',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required']],
    sort: 20,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 1,
    type: 'textarea',
    name: 'plannedResult',
    title: 'Плановый результат',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '1000' }]],
    sort: 21,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 1,
    type: 'textarea',
    name: 'result',
    title: 'Фактические результаты, документы, подтверждающие достижение результата',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '1000' }]],
    sort: 22,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 1,
    type: 'dropFile',
    name: 'organizationEventsDocs',
    title: 'Форма для загрузки подтверждающих документов',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 23,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 1,
    type: 'text',
    name: 'fundingSource',
    title: 'Источник финансирования работы *27',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 24,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 2,
    type: 'text',
    name: 'number',
    title: '№ п/п *25',
    min: 1,
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 25,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 2,
    type: 'text',
    name: 'name',
    title: 'Наименование мероприятия или работы *26',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 26,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 2,
    type: 'date',
    name: 'plannedExecutionTime',
    title: 'Плановый срок исполнения',
    options: [],
    monthPicker: true,
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required']],
    sort: 27,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 2,
    type: 'date',
    name: 'executionTime',
    title: 'Фактический срок исполнения',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required']],
    sort: 28,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 2,
    type: 'textarea',
    name: 'plannedResult',
    title: 'Плановый результат',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '1000' }]],
    sort: 29,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 2,
    type: 'textarea',
    name: 'result',
    title: 'Фактические результаты, документы, подтверждающие достижение результата',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '1000' }]],
    sort: 30,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 2,
    type: 'dropFile',
    name: 'implementationScientificProjectsDocs',
    title: 'Форма для загрузки подтверждающих документов',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required']],
    sort: 31,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 2,
    type: 'text',
    name: 'fundingSource',
    title: 'Источник финансирования работы *27',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 32,
  },

  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 3,
    type: 'text',
    name: 'number',
    title: '№ п/п *25',
    min: 1,
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 33,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 3,
    type: 'text',
    name: 'name',
    title: 'Наименование мероприятия или работы *26',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 34,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 3,
    type: 'date',
    name: 'plannedExecutionTime',
    title: 'Плановый срок исполнения',
    options: [],
    monthPicker: true,
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required']],
    sort: 35,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 3,
    type: 'date',
    name: 'executionTime',
    title: 'Фактический срок исполнения',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required']],
    sort: 36,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 3,
    type: 'textarea',
    name: 'plannedResult',
    title: 'Плановый результат',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '1000' }]],
    sort: 37,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 3,
    type: 'textarea',
    name: 'result',
    title: 'Фактические результаты, документы, подтверждающие достижение результата',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '1000' }]],
    sort: 38,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 3,
    type: 'dropFile',
    name: 'securityRightsManagementDocs',
    title: 'Форма для загрузки подтверждающих документов',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required']],
    sort: 39,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 3,
    type: 'text',
    name: 'fundingSource',
    title: 'Источник финансирования работы *27',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 40,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 4,
    type: 'text',
    name: 'number',
    title: '№ п/п *25',
    min: 1,
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 41,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 4,
    type: 'text',
    name: 'name',
    title: 'Наименование мероприятия или работы *26',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 42,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 4,
    type: 'date',
    name: 'plannedExecutionTime',
    title: 'Плановый срок исполнения',
    options: [],
    monthPicker: true,
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required']],
    sort: 43,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 4,
    type: 'date',
    name: 'executionTime',
    title: 'Фактический срок исполнения',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required']],
    sort: 44,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 4,
    type: 'textarea',
    name: 'plannedResult',
    title: 'Плановый результат',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '1000' }]],
    sort: 45,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 4,
    type: 'textarea',
    name: 'result',
    title: 'Фактические результаты, документы, подтверждающие достижение результата',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '1000' }]],
    sort: 46,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 4,
    type: 'dropFile',
    name: 'implementationEducationalProgramsDocs',
    title: 'Форма для загрузки подтверждающих документов',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required']],
    sort: 47,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 4,
    type: 'text',
    name: 'fundingSource',
    title: 'Источник финансирования работы *27',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 48,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 5,
    type: 'text',
    name: 'number',
    title: '№ п/п *25',
    min: 1,
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 49,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 5,
    type: 'text',
    name: 'name',
    title: 'Наименование мероприятия или работы *26',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 50,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 5,
    type: 'date',
    name: 'plannedExecutionTime',
    title: 'Плановый срок исполнения',
    options: [],
    monthPicker: true,
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required']],
    sort: 51,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 5,
    type: 'date',
    name: 'executionTime',
    title: 'Фактический срок исполнения',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required']],
    sort: 52,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 5,
    type: 'textarea',
    name: 'plannedResult',
    title: 'Плановый результат',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '1000' }]],
    sort: 53,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 5,
    type: 'textarea',
    name: 'result',
    title: 'Фактические результаты, документы, подтверждающие достижение результата',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '1000' }]],
    sort: 54,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 5,
    type: 'dropFile',
    name: 'developingPartnershipsDocs',
    title: 'Форма для загрузки подтверждающих документов',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required']],
    sort: 55,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 5,
    type: 'text',
    name: 'fundingSource',
    title: 'Источник финансирования работы *27',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 56,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 6,
    type: 'text',
    name: 'number',
    title: '№ п/п *25',
    min: 1,
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 57,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 6,
    type: 'text',
    name: 'name',
    title: 'Наименование мероприятия или работы *26',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 58,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 6,
    type: 'date',
    name: 'plannedExecutionTime',
    title: 'Плановый срок исполнения',
    options: [],
    monthPicker: true,
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required']],
    sort: 59,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 6,
    type: 'date',
    name: 'executionTime',
    title: 'Фактический срок исполнения',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required']],
    sort: 60,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 6,
    type: 'textarea',
    name: 'plannedResult',
    title: 'Плановый результат',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '1000' }]],
    sort: 61,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 6,
    type: 'textarea',
    name: 'result',
    title: 'Фактические результаты, документы, подтверждающие достижение результата',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '1000' }]],
    sort: 62,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 6,
    type: 'dropFile',
    name: 'otherDocs',
    title: 'Форма для загрузки подтверждающих документов',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required']],
    sort: 63,
  },
  {
    id: uuidv4(),
    group_id: 2,
    subcategory: 6,
    type: 'text',
    name: 'fundingSource',
    title: 'Источник финансирования работы *27',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 64,
  },

  {
    id: uuidv4(),
    group_id: 3,
    type: 'text',
    name: 'indicatorName',
    title: 'Наименование индикатора',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 65,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'text',
    name: 'unitName',
    title: 'Единицы измерения',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 66,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'text',
    name: 'plannedResult',
    title: 'Плановое значение показателя',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 67,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'text',
    name: 'result',
    title: 'Фактическое значение показателя',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 68,
  },
]
