// Статусы анкеты

export const REJECT_STATUS = 'reject'
export const REVISION_STATUS = 'revision'
export const NEW_STATUS = 'new'
export const DRAFT_STATUS = 'draft'
export const SENT_STATUS = 'sent'
export const ACCEPT_STATUS = 'accept'

export const SERVISE_TYPES = {
  SERVISE: {
    id: 0,
    name: 'Конкурс',
  },
  SELECTIONS: {
    id: 1,
    name: 'Отбор',
  },
}
