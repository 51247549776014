<template>
  <the-container>
    <h3 class="mb-6">Контакты грантополучателей</h3>
    <v-row
      v-if="isLoading"
      class="w-100 h-100 d-flex align-center justify-center"
      style="min-height: 400px"
    >
      <v-progress-circular indeterminate color="primary" />
    </v-row>
    <v-form v-else ref="form" lazy-validation>
      <v-row>
        <v-col cols="12" md="6">
          <div v-for="field in fieldsForm" :key="field.id">
            <p v-if="field.title" class="text-body-1 ma-0">
              {{ field.title }}
            </p>
            <Component
              :is="inputs[getFormInput(field.type)]"
              v-bind="field"
              @change-value="updateDataInStash"
              @update-value="(data) => updateValue(fieldsForm, data)"
            />
          </div>
          <v-col class="px-0 mt-6">
            <div class="v-messages">
              <span class="text-body-2">* - поля обязательные для заполнения</span>
            </div>
            <div class="v-messages">
              <span class="text-body-2">Введенные данные сохраняются автоматически</span>
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-col>
        <v-row class="px-0 align-center">
          <v-btn depressed color="primary" variant="elevated" @click="onSubmit"> Сохранить </v-btn>
          <v-btn
            class="ml-2 text-decoration-underline"
            color="primary"
            variant="text"
            @click="openGranteeContactsPage"
          >
            Закрыть
          </v-btn>
        </v-row>
      </v-col>
    </v-form>
  </the-container>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useStore } from 'vuex'
import moment from 'moment'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'

import { api } from '@/api/Api'

import { inputs, getFormInput, updateValue, focusErrorInput } from '@/helpers/index'
import { ACTUALITY_OPTION, CATEGORIES_CONTACTS, NTI_INFORMATION_TABS } from '@/constants'

import TheContainer from '@/components/TheContainer'

const router = useRouter()
const route = useRoute()
const store = useStore()

const centerNti = computed(() => store.getters['project/centerNti'])

const fieldFormHash = computed(() => {
  if (route.params?.id) {
    return `grantee-contacts-${route.params?.id}-${store.getters['user/hash']}`
  }

  return `grantee-contacts-${store.getters['user/hash']}`
})

const fieldsForm = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'centerNtiShortTitle',
    title: 'Центр НТИ',
    options: [],
    disabled: true,
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    name: 'actual',
    title: 'Актуальность',
    itemValue: 'value',
    itemTitle: 'title',
    options: ACTUALITY_OPTION,
    default_value: false,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 2,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    name: 'category',
    title: 'Категория контактов',
    options: CATEGORIES_CONTACTS,
    itemValue: 'title',
    itemTitle: 'title',
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 3,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'email',
    title: 'E-mail',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['email']],
    sort: 4,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'fullName',
    title: 'ФИО',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 5,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'jobTitle',
    title: 'Должность',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 5,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'phone',
    title: 'Тел.',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['phone-number']],
    sort: 8,
  },
])
const isLoading = ref(false)
const form = ref(null)

async function clearStash() {
  try {
    await api.stash().clearStash(fieldFormHash.value)
  } catch (err) {
    console.log(err)
  }
}

async function updateDataInStash({ value, id }) {
  const findField = fieldsForm.value?.find((el) => el.id === id)
  if (findField) {
    const params = {
      field: findField.name,
      value: value,
      hash: fieldFormHash.value,
    }

    await api.stash().saveFieldInStash(params)
  }
}

async function getSavedData() {
  isLoading.value = true
  try {
    await api
      .stash()
      .getStashData(fieldFormHash.value)
      .then((data) => {
        fieldsForm?.value?.forEach((el) => {
          if (data[el.name]) {
            el.default_value = data[el.name]
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function getGranteeContacts() {
  isLoading.value = true
  try {
    await api
      .information()
      .getGranteeContact(route.params?.id)
      .then((res) => {
        fieldsForm.value?.forEach((el) => {
          if (res[el.name]) {
            el.default_value = res[el.name]
          }
        })

        getSavedData()
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = true
  }
}

async function updateGranteeContact(data) {
  isLoading.value = true
  try {
    await api
      .information()
      .updateGranteeContact(data, route.params?.id)
      .then(() => {
        clearStash()
        openGranteeContactsPage()
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function createGranteeContact(data) {
  isLoading.value = true
  try {
    await api
      .information()
      .createGranteeContact(data)
      .then(() => {
        clearStash()
        openGranteeContactsPage()
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function onSubmit() {
  await form.value.validate()

  if (form.value?.errors?.length) {
    const errInput = form.value.errors[0]
    if (!errInput) {
      return
    }
    focusErrorInput(errInput)
    return
  }

  isLoading.value = true
  try {
    let params = {}
    fieldsForm.value?.forEach((el) => {
      if (el.type === 'date') {
        return (params[el.name] = el.default_value
          ? moment(el.default_value).format('YYYY-MM-DD')
          : null)
      }

      params[el.name] = el.default_value
    })
    if (route.params?.id) {
      return updateGranteeContact(params)
    }

    return createGranteeContact(params)
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function openGranteeContactsPage() {
  return router.push(`/nti-information?tab=${NTI_INFORMATION_TABS.GRANTEE_CONTACTS}`)
}

onMounted(() => {
  if (route.params?.id) {
    return getGranteeContacts()
  }

  if (centerNti.value) {
    fieldsForm.value?.forEach((el) => {
      if (el.name === 'centerNtiShortTitle') {
        el.default_value = centerNti.value?.shortTitle
      }
    })
  }

  return getSavedData()
})
</script>
