<template>
  <v-col
    v-for="category in formGroups"
    :key="`category-${category.id}-${formFields?.length}`"
    class="px-0"
  >
    <p v-if="category.title" class="text-subtitle-1 mx-0 my-2 mb-4 font-weight-bold">
      {{ category.title }}
    </p>
    <template v-if="category?.subcategories?.length">
      <div v-for="subcategory in category?.subcategories" :key="subcategory.id" class="my-4">
        <div v-if="subcategory.title" class="text-body-1 font-weight-bold mb-2">
          {{ subcategory.title }}
        </div>
        <div v-for="field in getSubcategoryField(category.id, subcategory.id)" :key="field.id">
          <v-row
            v-if="field.type !== 'dropFile'"
            class="pa-0 ma-0 border-bottom-line text-grey-darken-1"
          >
            <v-col cols="6" class="px-0 mx-0">{{ field.title }}</v-col>
            <v-col cols="6" class="px-0 mx-0">{{ getValue(field) }}</v-col>
          </v-row>
          <v-col v-else-if="field.links?.length" class="px-0 mx-0">
            <v-col
              v-if="field.previewTitle"
              class="px-0 mx-0 text-grey-darken-1 font-weight-bold mb-2"
            >
              {{ field.previewTitle }}
            </v-col>
            <v-row v-for="file in field.links" :key="file.id" class="mx-0 px-0">
              <v-col class="ml-0 pl-0">{{ file.originalFileName }}</v-col>
              <v-col
                class="text-decoration-underline text-blue-darken-4 cursor-pointer"
                @click="() => $emit('download-file', file.id, file.originalFileName)"
              >
                Просмотреть
              </v-col>
            </v-row>
          </v-col>
        </div>
      </div>
    </template>

    <div v-if="getCategoryFields(category.id)?.length">
      <div v-for="field in getCategoryFields(category.id)" :key="`field-${field.id}`">
        <v-row
          v-if="field.type !== 'dropFile'"
          class="pa-0 ma-0 border-bottom-line text-grey-darken-1"
        >
          <v-col cols="6" class="px-0 mx-0">{{ field.title }}</v-col>
          <v-col cols="6" class="px-0 mx-0">{{ getValue(field) }}</v-col>
        </v-row>
        <v-col v-else-if="field.links?.length" class="px-0 mx-0">
          <v-col
            v-if="field.previewTitle"
            class="px-0 mx-0 text-grey-darken-1 font-weight-bold mb-2"
          >
            {{ field.previewTitle }}
          </v-col>
          <v-row v-for="file in field.links" :key="file.id" class="mx-0 px-0">
            <v-col class="ml-0 pl-0">{{ file.originalFileName }}</v-col>
            <v-col
              class="text-decoration-underline text-blue-darken-4 cursor-pointer"
              @click="() => $emit('download-file', file.id, file.originalFileName)"
            >
              Просмотреть
            </v-col>
          </v-row>
        </v-col>
      </div>
    </div>
    <v-row v-if="category.total" class="pa-0 ma-0 border-bottom-line text-grey-darken-1">
      <v-col cols="6" class="px-0 mx-0">Итого:</v-col>
      <v-col cols="6" class="px-0 mx-0">{{ formatTotalAmount(category.total) }}</v-col>
    </v-row>
  </v-col>
  <v-row class="px-0 align-center mx-0 mt-6">
    <v-btn
      v-if="isWinner"
      depressed
      color="primary"
      variant="elevated"
      class="mr-4"
      @click="$emit('print-pdf')"
    >
      Печать
    </v-btn>
    <v-btn
      class="mx-0 px-0 text-decoration-underline"
      color="primary"
      variant="text"
      @click="goBack"
    >
      Закрыть
    </v-btn>
  </v-row>
</template>
<script setup>
import moment from 'moment'
import { useRouter } from 'vue-router/dist/vue-router'
import { computed } from 'vue'

import store from '@/store'
import { REPORT_TYPES } from '@/constants'

import { getNumberWithSpaces } from '@/helpers'

const router = useRouter()

const isWinner = computed(() => store.getters['user/isWinner'])

const props = defineProps({
  formGroups: {
    type: Array,
    default: () => [],
  },
  formFields: {
    type: Array,
    default: () => [],
  },
})

function getSubcategoryField(categoryId, subcategoryId) {
  const categoryFields = props.formFields.filter((el) => el.group_id === categoryId)
  const subcategoryFields = categoryFields?.filter((el) => el.subcategory === subcategoryId)
  return subcategoryFields
}

function getCategoryFields(categoryId) {
  const categoryFields = props.formFields?.filter(
    (el) => el.group_id === categoryId && typeof el?.subcategory !== 'number',
  )
  return categoryFields
}

function goBack() {
  return router.go(-1)
}

function formatTotalAmount(amount) {
  return getNumberWithSpaces(`${parseFloat(amount)?.toFixed(2)}`?.replace('.', ','))
}

function getValue(item) {
  if (item.type === 'date') {
    return item.default_value ? moment(item.default_value).format('DD.MM.YYYY') : ''
  }

  if (item.type === 'number' && item.name !== 'year') {
    return item.default_value ? formatTotalAmount(item.default_value) : '0,00'
  }

  if (item.name === 'status' || item.name === 'type_report') {
    return REPORT_TYPES?.find((el) => el.id === item?.default_value)?.title
  }

  return item.default_value
}
</script>
