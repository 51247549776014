<template>
  <the-container>
    <h3 class="mb-6">Карточка Программы получателя поддержки</h3>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <template v-else>
      <v-row class="px-0 ma-a mb-4">
        <v-col class="pa-a ma-0">
          Номер программы:
          {{ programNumber }}
        </v-col>
      </v-row>
      <div class="custom-inputs">
        <div v-for="group in groups" :key="`group-${group.id}`" class="mb-6">
          <div class="text-subtitle-1 font-weight-bold text-grey-darken-1 mb-6">
            {{ group.title }}
          </div>
          <div>
            <div v-for="field in getFiledGroup(group.id)" :key="field.id">
              <v-row class="px-0 mx-0 align-end">
                <v-col cols="8" class="py-0 px-0 mx-0 mb-3">
                  <v-row class="pa-0 ma-0 border-bottom-line text-grey-darken-1">
                    <v-col class="px-0 mx-0 pr-6" cols="6">
                      {{ field.title }}
                    </v-col>
                    <v-col class="px-0 mx-0 word-break-all" cols="6">
                      {{ getValue(field) }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
      <div id="files">
        <div v-if="files?.length" class="mb-4">
          <div class="font-weight-bold text-grey my-6">Прикрепленные документы</div>
          <div v-for="(fileGroup, i) in files" :key="`fileGroup-${i}`">
            <v-row v-for="file in fileGroup" :key="file.id" class="mx-0 px-0">
              <v-col class="ml-0 pl-0 word-break" cols="6">
                {{ file.originalFileName }}
              </v-col>
              <v-col
                class="text-decoration-underline text-blue-darken-4 cursor-pointer"
                cols="2"
                @click="() => downloadFile(file.id, file.originalFileName)"
              >
                Просмотреть
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <v-col
        v-if="
          projectData?.managerComment && projectData?.status !== PROJECT_REQUEST_STATUSES.ACCEPTED
        "
        cols="8"
        class="w-100 px-0 mx-0"
      >
        <div class="text-body-1 mx-0 my-2">Комментарий Менеджера</div>
        <div class="text-body-1 mx-0 my-2 box-comment">
          {{ projectData?.managerComment }}
        </div>
      </v-col>
      <template
        v-if="
          projectData?.status !== PROJECT_REQUEST_STATUSES.ACCEPTED &&
          projectData?.status !== PROJECT_REQUEST_STATUSES.REJECTED &&
          projectData?.status !== PROJECT_REQUEST_STATUSES.SEND_FOR_APPROVE
        "
      >
        <v-col v-if="projectData?.monitorComment" cols="8" class="w-100 px-0 mx-0">
          <div class="text-body-1 mx-0 my-2">Комментарий Монитора</div>
          <div class="text-body-1 mx-0 my-2 box-comment">
            {{ projectData?.monitorComment }}
          </div>
        </v-col>
      </template>
      <v-col cols="8" class="w-100 px-0 mx-0">
        <v-form v-if="isEdit" ref="form" lazy-validation>
          <div v-if="commentMonitor.title" class="text-body-1 mx-0 my-2">
            {{ commentMonitor.title }}
          </div>
          <Component
            :is="inputs[getFormInput(commentMonitor.type)]"
            v-bind="commentMonitor"
            @change-value="updateCommentMonitorInStash"
            @update-value="updateValueCommentMonitor"
            @delete-file="deleteFile"
          />
          <div class="text-body-1 mx-0 my-2">
            {{ fileMonitor.title }}
          </div>
          <Component
            :is="inputs[getFormInput(fileMonitor.type)]"
            v-bind="fileMonitor"
            @update-value="updateValueFileMonitor"
            @delete-file="deleteFile"
            @download-file="downloadFile"
          />
        </v-form>
      </v-col>
      <v-row class="mx-0 mt-6 px-0 align-center">
        <v-btn v-if="isEdit" depressed color="primary" class="mr-4" @click="sendForRevision">
          Отправить
        </v-btn>
        <v-btn
          class="px-0 text-decoration-underline"
          color="primary"
          variant="text"
          @click="onBack"
        >
          Закрыть
        </v-btn>
      </v-row>
    </template>
  </the-container>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'

import { api } from '@/api/Api'

import { useToast } from '@/composables/useToast'

import { downloadFileWithLinkData } from '@/helpers'
import { inputs, getFormInput } from '@/helpers/index'

import { PROJECT_REQUEST_STATUSES, STATUS_CODE, SUPPORT_KIND } from '@/constants'
import { MONITOR_FOLDER, WINNER_FOLDER, MANAGER_FOLDER } from '@/constants/store'

import TheContainer from '@/components/TheContainer'
import { PROGRAM_FILES, TEMP } from '@/constants/buckets'

const route = useRoute()
const router = useRouter()
const store = useStore()
const { toast } = useToast()

const commentHash = computed(() => {
  return `comments-${store.getters['user/hash']}-${route.params?.id}`
})

const bucket = computed(() => (projectId.value ? PROGRAM_FILES : TEMP))
const requestStatus = ref(null)
const form = ref(null)
const isEdit = ref(false)
const centerNti = ref([])
const files = ref([])
const commentMonitor = ref({
  id: uuidv4(),
  group_id: 0,
  type: 'textarea',
  name: 'monitorComment',
  title: 'Комментарий Монитора*',
  options: [],
  default_value: '',
  links: [],
  note: '',
  multiple: true,
  rules: [['required']],
  sort: 1,
})

const fileMonitor = ref({
  id: uuidv4(),
  group_id: 0,
  type: 'dropFile',
  name: 'conclusion',
  title: 'Добавить Заключение Монитора*',
  options: [],
  default_value: [],
  note: '',
  multiple: true,
  links: [],
  fileTypes: '.docs, .pdf, .doc',
  rules: [['required'], ['file', { extensions: ['.pdf', '.doc', '.docs'] }]],
  sort: 1,
})
const programNumber = ref(null)
const groups = [
  {
    id: 0,
    title: 'Общие сведения',
  },
  {
    id: 1,
    title: 'Сведения о гранте',
  },
  {
    id: 2,
    title: 'Реквизиты',
  },
  {
    id: 3,
    title: 'Данные для отправки корреспонденции',
  },
]
const fieldsForm = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'grantRecipientName',
    title: 'Полное наименование получателя Гранта',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'shortTitle',
    title: 'Краткое наименование Центра НТИ',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 1,
    comment: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'fullTitle',
    title: 'Полное наименование Центра НТИ',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 1,
    comment: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeINN',
    title: 'ИНН получателя',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'technologyName',
    title: 'Наименование "Сквозной технологии"',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'textarea',
  //   name: 'curatorCutr',
  //   title: 'Куратор ЦУТР (ФИО)',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: 0,
  //   rules: [['required']],
  //   sort: 1,
  //   comment: '',
  //   isEdit: false,
  // },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'confirmProgramDate',
    title: 'Дата утверждения программы',
    options: [],
    isDate: true,
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'textarea',
  //   yearPicker: true,
  //   name: 'programYear',
  //   title: 'Год программы',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: 0,
  //   rules: [['required']],
  //   sort: 1,
  //   comment: '',
  //   isEdit: false,
  // },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'textarea',
  //   name: 'programNumber',
  //   title: 'Номер программы',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: 0,
  //   rules: [['required']],
  //   sort: 1,
  //   comment: '',
  //   isEdit: false,
  // },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'granteeAddress',
    title: 'Фактический адрес',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'legalAddress',
    title: 'Юридический адрес',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'textarea',
    name: 'addressCorrespondence',
    title: 'Адрес',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'textarea',
    name: 'fullnameCorrespondence',
    title: 'ФИО',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'textarea',
    name: 'phoneNumberCorrespondence',
    title: 'Телефон',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeKPP',
    title: 'КПП получателя',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'programName',
    title: 'Наименование программы',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    yearPicker: true,
    name: 'startFinanceYear',
    title: 'Год начала финансирования',
    options: [],
    required: true,
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'endFinanceYear',
    title: 'Год завершения финансирования',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'endProjectYear',
    title: 'Год завершения постмониторинга',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'supportKind',
    title: 'Форма поддержки',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'textarea',
  //   name: 'statusCode',
  //   title: 'Статус реализации проекта (код)',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: true,
  //   rules: [],
  //   sort: 1,
  //   first_value: '',
  // },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'textarea',
  //   name: 'contractNumber',
  //   title: 'Номер договора',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: true,
  //   rules: [],
  //   sort: 1,
  //   first_value: '',
  // },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'textarea',
  //   name: 'contractDate',
  //   isDate: true,
  //   title: 'Дата договора',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: true,
  //   rules: [],
  //   sort: 1,
  //   first_value: '',
  // },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeRepresentativeName',
    title: 'В лице кого представлен получатель',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeRepresentativeJustification',
    title: 'На основании чего действует представитель получателя',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeEmail',
    title: 'Эл. почта получателя',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeOGRN',
    title: 'ОГРН получателя',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeOKTMO',
    title: 'ОКТМО получателя',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'textarea',
    name: 'grantAmountFull',
    title: 'Размер гранта (цифрами) общий',
    options: [],
    isAmount: true,
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'textarea',
    name: 'grantAmountFullText',
    title: 'Размер гранта (прописью) общий',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountBIK',
    title: 'БИК',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountBankName',
    title: 'Наименование банка',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountSingleTreasuryAccount',
    title: 'Единый казначейский счет',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountTreasuryAccount',
    title: 'Казначейский счет',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountPersonalAccount',
    title: 'л/с',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountTreasuryDepartmentName',
    title: 'Наименование Управления Федерального казначейства',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
])

const projectId = ref(route.params?.id)
const isLoading = ref(false)
const projectData = ref(null)

function getFiledGroup(idGroup) {
  return fieldsForm.value.filter((el) => el.group_id === idGroup)
}

async function updateCommentMonitorInStash({ value }) {
  const params = {
    field: commentMonitor.value.name,
    value: value,
    hash: commentHash.value,
  }

  await api.stash().saveFieldInStash(params)
}

async function updateValueCommentMonitor({ value }) {
  commentMonitor.value.default_value = value
}

function updateValueFileMonitor({ value }) {
  fileMonitor.value.default_value = value

  uploadFile(fileMonitor.value?.name, value[0])
}

async function deleteFile(id) {
  try {
    await api
      .files()
      .deleteFile(id)
      .then(() => {
        getFiles()
      })
  } catch (err) {
    toast.error(err)
  }
}

async function uploadFile(name, data) {
  try {
    const form = new FormData()
    if (route.params?.id) {
      form.append('entityId', route.params?.id)
    }

    form.append('file', data)
    form.append('fileKey', name)
    form.append('folder', MONITOR_FOLDER)
    await api
      .files()
      .uploadFile(bucket.value, form)
      .then(() => {
        getFiles()
      })
  } catch (err) {
    toast.error(err)
  }
}

function onBack() {
  router.back()
}

async function getSavedData() {
  isLoading.value = true
  try {
    await api
      .stash()
      .getStashData(commentHash.value)
      .then((data) => {
        commentMonitor.value.default_value = data?.monitorComment

        getFiles()
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function clearStash(hash) {
  try {
    await api.stash().clearStash(hash)
  } catch (err) {
    console.log(err)
  }
}

async function getProjectData() {
  isLoading.value = true
  try {
    await api
      .projects()
      .getProjectData(projectId.value)
      .then((data) => {
        getSavedData()

        projectData.value = data
        commentMonitor.value.default_value = data?.monitorComment
        requestStatus.value = data.status
        isEdit.value = data?.status === PROJECT_REQUEST_STATUSES.SEND_FOR_APPROVE

        fieldsForm.value?.forEach((el) => {
          const value = data[el.name]

          if (value) {
            el.first_value = value
          }
        })

        if ('annualGrantAmounts' in data) {
          addAnnualGrantAmountsFields(data['annualGrantAmounts'])
        }

        if ('granteeCompanies' in data) {
          addGranteeCompaniesFileds(data['granteeCompanies'])
        }
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function addAnnualGrantAmountsFields(data) {
  data?.forEach((el, index) => {
    const year = el.year
    const firstValueAmount = el.amount
    const firstValueAmountText = el.amountText
    const newValueAmount = {
      first_value: firstValueAmount,
      id: uuidv4(),
      group_id: 1,
      type: 'textarea',
      name: 'amount',
      isAmount: true,
      group_name: 'annualGrantAmounts',
      title: `Размер гранта (цифрами) год ${year || ''}`,
      index: index,
    }

    const newValueTextAmount = {
      first_value: firstValueAmountText,
      id: uuidv4(),
      group_id: 1,
      type: 'textarea',
      name: 'amountText',
      group_name: 'annualGrantAmounts',
      title: `Размер гранта (прописью) год ${year || ''}`,
      index: index,
    }
    fieldsForm.value.push(newValueAmount)
    fieldsForm.value.push(newValueTextAmount)
  })
}

function addGranteeCompaniesFileds(data) {
  data?.forEach((el) => {
    const name = el.name
    const newField = {
      first_value: name,
      id: uuidv4(),
      group_id: 0,
      type: 'textarea',
      name: 'name',
      group_name: 'granteeCompanies',
      title: 'Наименование организации - Участник консорциума - получатель гранта (при наличии)',
    }
    fieldsForm.value.push(newField)
  })
}

async function getFiles() {
  try {
    const entityId = projectId.value ? { entityId: projectId.value } : null
    fileMonitor.value.links = []
    files.value = []
    await api
      .files()
      .getFiles(bucket.value, entityId)
      .then((data) => {
        const { filesInFolders } = data
        if (filesInFolders && WINNER_FOLDER in filesInFolders) {
          files.value = Object.values(filesInFolders[WINNER_FOLDER])
        }

        if (filesInFolders && MONITOR_FOLDER in filesInFolders) {
          fileMonitor.value.links = filesInFolders[MONITOR_FOLDER][fileMonitor.value?.name]

          if (projectData.value?.status !== PROJECT_REQUEST_STATUSES.SEND_FOR_APPROVE) {
            files.value = [...files.value, ...Object.values(filesInFolders[MONITOR_FOLDER])]
          }
        }

        if (filesInFolders && MANAGER_FOLDER in filesInFolders) {
          files.value = [...files.value, ...Object.values(filesInFolders[MANAGER_FOLDER])]
        }
      })
  } catch (err) {
    toast.error(err)
  }
}

async function downloadFile(id, name) {
  try {
    await api
      .files()
      .downloadFile(id)
      .then((data) => {
        downloadFileWithLinkData(data, name)
      })
  } catch (err) {
    toast.error(err)
  }
}

async function sendForRevision() {
  isLoading.value = true

  try {
    const { valid } = await form.value.validate()

    if (!valid || !fileMonitor.value.links?.length) {
      toast.info('Поля комментарий Монитора и заключение Монитора - обязательны')
      return
    }

    const formData = new FormData()

    formData.append('comment', commentMonitor.value?.default_value)

    await api
      .projects()
      .sendForManagerRequestWithConfirm(projectId.value, formData)
      .then(() => {
        getProjectData()
        clearStash()
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function getValue(item) {
  if (item.name === 'fullTitle') {
    const centerNtiId =
      projectData.value && 'centerNtiId' in projectData.value
        ? projectData.value['centerNtiId']
        : null
    const center = centerNti.value?.find((el) => el?.id === centerNtiId)
    return center?.fullTitle
  }

  if (item.isAmount) {
    return item.first_value ? String(item.first_value)?.replace('.', ',') : ''
  }

  if (item.name === 'shortTitle') {
    const centerNtiId =
      projectData.value && 'centerNtiId' in projectData.value
        ? projectData.value['centerNtiId']
        : null
    const center = centerNti.value?.find((el) => el?.id === centerNtiId)

    return center?.shortTitle
  }

  if (item.isDate) {
    return moment(item.first_value).format('DD.MM.YYYY')
  }

  if (item.name === 'supportKind') {
    return SUPPORT_KIND.find((el) => el.id === item.first_value)?.name
  }

  if (item.name === 'statusCode') {
    return STATUS_CODE.find((el) => el.id === item.first_value)?.name
  }

  return item.first_value
}

async function getCenterNtiList() {
  try {
    await api
      .projects()
      .getCenterNtiList()
      .then((data) => {
        if (data) {
          centerNti.value = data
        }
      })
  } catch (err) {
    console.log(err)
  }
}

function setProgramNumber() {
  const center = centerNti.value?.find((el) => el?.id === projectData.value?.centerNtiId)
  if (center) {
    programNumber.value = center.programNumber
  }
}

watch(projectData, () => {
  if ((projectData.value, centerNti.value)) {
    setProgramNumber()
  }
})

watch(centerNti, () => {
  if ((projectData.value, centerNti.value)) {
    setProgramNumber()
  }
})

onMounted(() => {
  getCenterNtiList()
  getProjectData()

  setTimeout(() => {
    const hash = window.location.hash.substr(1)
    if (hash) {
      document.getElementById(hash).scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, 500)
})
</script>
<style lang="scss">
.custom-inputs {
  .v-field__input {
    height: 56px;
  }
}
</style>
