<template>
  <v-row v-if="isLoadingPage" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <v-form v-else ref="form" lazy-validation>
    <v-row :key="programNumber" class="px-0 ma-a mb-4">
      <v-col class="pa-a ma-0">
        Номер программы:
        {{ programNumber }}
      </v-col>
    </v-row>
    <div v-for="group in groups" :key="`group-${group.id}`" class="mb-6">
      <div v-if="group?.title" class="text-subtitle-1 font-weight-bold mb-4">
        {{ group?.title }}
      </div>
      <div
        v-for="field in getFieldsForGroup(group.id)"
        :key="`${field.id}`"
        :class="[
          field.comment && !field.isEdit && 'error',
          field.comment && field.isEdit && 'edited',
        ]"
      >
        <p v-if="field.title" class="text-body-1 mx-0 my-2">
          {{ field.title }}
        </p>
        <v-row class="pa-0 ma-0 flex-nowrap">
          <Component
            :is="inputs[getFormInput(field.type)]"
            :key="isEdit ? field.isEdit : null"
            :class="['w-100', field?.isCopyField ? 'mt-2' : '']"
            v-bind="field"
            @update-value="updateValue"
            @change-value="sendHash"
            @delete-file="deleteFile"
            @download-file="downloadFile"
          />
          <button
            v-if="!route.params?.id && field?.isCopyField"
            class="ml-2"
            type="button"
            @click="() => deleteField(field.id, field.group_name)"
          >
            <v-icon color="red" size="xs"> mdi-delete-outline </v-icon>
          </button>
        </v-row>
        <div v-if="field.comment" class="error text-caption word-break-all">
          {{ field.comment }}
        </div>
        <v-row
          v-if="field.name === 'legalAddress'"
          class="pa-0 ma-0 align-center justify-start"
          style="color: black"
        >
          <v-checkbox
            v-model="matchesActualAddress"
            label="Совпадает с фактическим адресом"
            @change="() => sendHashAdress(matchesActualAddress)"
          />
        </v-row>
        <v-row
          v-if="getIsLastElementInGroup(field.group_name, field.id, field.index, field.isCopyGroup)"
          class="px-0 mx-0 justify-end"
        >
          <button
            class="ml-2"
            type="button"
            @click="() => deleteFieldsGroup(field.group_name, field.index)"
          >
            <v-icon color="red" size="xs"> mdi-delete-outline </v-icon>
            <span class="text-body-2 title text-red">Удалить группу полей</span>
          </button>
        </v-row>
      </div>
    </div>
    <div>
      <div v-for="(fileField, fileIndex) in filesFields" :key="fileIndex" class="mt-8">
        <div class="text-subtitle-1 font-weight-bold mb-2">{{ fileField.sectionName }}*</div>
        <div class="text-blue-darken-4 font-weight-bold text-body-2 my-6 mt-2">
          {{ fileField.description }}
        </div>
        <Component
          :is="inputs[getFormInput(fileField.type)]"
          v-bind="fileField"
          @update-value="updateFileValue"
          @delete-file="deleteFile"
          @download-file="downloadFile"
        />
        <v-row
          v-if="fileField.name !== 'program'"
          class="px-0 mx-0 align-center cursor-pointer"
          @click="() => downloadTemplate(fileField?.templateType, fileField?.sectionName)"
        >
          <v-icon color="blue" size="s"> mdi-download </v-icon>
          <span class="ml-2 text-body-2 title">Скачать шаблон / инструкцию</span>
        </v-row>
      </div>
    </div>
    <div v-if="projectData?.managerComment" class="comment error mt-6">
      <div>Комментарий Менеджера</div>
      <div class="field">{{ projectData?.managerComment }}</div>
    </div>
    <v-col class="px-0 mt-6 mx-0">
      <div class="v-messages">
        <span class="text-body-2">* - поля обязательные для заполнения</span>
      </div>
      <div class="v-messages">
        <span class="text-body-2">Введенные данные сохраняются автоматически</span>
      </div>
    </v-col>
    <v-row class="mx-0 mt-12 px-0 align-center">
      <v-btn
        :disabled="isDisableSubmitButton"
        depressed
        :color="isDisableSubmitButton ? 'grey' : 'primary'"
        variant="elevated"
        @click="onSubmit"
      >
        Отправить
      </v-btn>
      <v-btn
        class="ml-2 text-decoration-underline"
        color="primary"
        variant="text"
        @click="router.push('/coordination-documents')"
      >
        Закрыть
      </v-btn>
      <template v-if="isLoading">
        <v-progress-circular indeterminate color="primary" :size="20" class="ml-4" :width="3" />
        <div class="text-body-2 text-grey ml-2">Данные сохраняются</div>
      </template>
    </v-row>
  </v-form>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useStore } from 'vuex'
import moment from 'moment'
import { useRoute } from 'vue-router/dist/vue-router'

import { api } from '@/api/Api'

import { downloadFileData, downloadFileWithLinkData } from '@/helpers'

import { inputs, getFormInput, focusErrorInput } from '@/helpers/index'
import { useToast } from '@/composables/useToast'
import { SUPPORT_KIND } from '@/constants'
import { PROGRAM_FILES, TEMP } from '@/constants/buckets'
import { useRouter } from 'vue-router'
import { WINNER_FOLDER } from '@/constants/store'

const { toast } = useToast()
const store = useStore()
const route = useRoute()
const router = useRouter()

const matchesActualAddress = ref(false)
const projectId = ref(route.params.id)
const programNumber = ref(null)
const bucket = computed(() => (projectId.value ? PROGRAM_FILES : TEMP))
const isWinner = computed(() => store.getters['user/isWinner'])
const centerNtiList = computed(() => centerNtiFilter)

const uniqueHash = ref()
const form = ref(null)
const isLoadingPage = ref(false)
const isLoading = ref(false)
const isEdit = ref(!!route.meta?.isEdit)
const comments = ref(null)
const centerNti = ref([])

const centerNtiFilter = computed(() =>
  centerNti.value?.filter((el) => !!el?.removeable || el.id === projectData.value?.centerNtiId),
)
const projectData = ref(null)
const groups = ref([
  {
    id: 0,
    title: 'Общие сведения',
  },
  {
    id: 1,
    title: 'Сведения о гранте',
  },
  {
    id: 2,
    title: 'Реквизиты',
  },
  {
    id: 3,
    title: 'Данные для отправки корреспонденции',
  },
])
const fields = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    hideDetails: true,
    name: 'grantRecipientName',
    title: 'Полное наименование получателя Гранта*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    isRequired: true,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    name: 'shortTitle',
    title: 'Краткое наименование Центра НТИ*',
    options: centerNtiList.value,
    default_value: '',
    hideDetails: true,
    descr: '',
    note: '',
    itemValue: 'id',
    itemTitle: 'shortTitle',
    multiple: 0,
    isRequired: true,
    rules: [['required']],
    sort: 1,
    comment: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    name: 'fullTitle',
    hideDetails: true,
    title: 'Полное наименование Центра НТИ*',
    options: centerNtiList.value,
    default_value: '',
    descr: '',
    itemValue: 'id',
    itemTitle: 'fullTitle',
    note: '',
    multiple: 0,
    isRequired: true,
    rules: [['required']],
    sort: 1,
    comment: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'technologyName',
    hideDetails: true,
    title: 'Наименование "Сквозной технологии"*',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    comment: '',
    isEdit: false,
    isRequired: true,
  },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'text',
  //   name: 'curatorCutr',
  //   hideDetails: true,
  //   title: 'Куратор ЦУТР (ФИО)*',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: 0,
  //   rules: [['required'], ['string', { max: '255' }]],
  //   sort: 1,
  //   comment: '',
  //   isEdit: false,
  // },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    name: 'confirmProgramDate',
    hideDetails: true,
    title: 'Дата утверждения программы*',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    comment: '',
    isEdit: false,
    isRequired: true,
  },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'date',
  //   yearPicker: true,
  //   name: 'programYear',
  //   hideDetails: true,
  //   title: 'Год программы*',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: 0,
  //   rules: [['required']],
  //   sort: 1,
  //   comment: '',
  //   isEdit: false,
  // },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'granteeAddress',
    hideDetails: true,
    title: 'Фактический адрес*',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    comment: '',
    isRequired: true,
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'legalAddress',
    hideDetails: true,
    title: 'Юридический адрес*',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    comment: '',
    isRequired: true,
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'text',
    name: 'addressCorrespondence',
    hideDetails: true,
    title: 'Адрес*',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    isRequired: true,
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'text',
    name: 'fullnameCorrespondence',
    title: 'ФИО*',
    hideDetails: true,
    options: [],
    default_value: null,
    descr: '',
    note: '',
    isRequired: true,
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'text',
    name: 'phoneNumberCorrespondence',
    title: 'Телефон*',
    options: [],
    hideDetails: true,
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '14' }]],
    sort: 1,
    comment: '',
    isRequired: true,
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'number',
    name: 'granteeINN',
    hideDetails: true,
    title: 'ИНН получателя*',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['granteeINN'], ['string', { max: '10' }]],
    sort: 1,
    isRequired: true,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    hideDetails: true,
    type: 'number',
    name: 'granteeKPP',
    title: 'КПП получателя*',
    options: [],
    default_value: null,
    descr: '',
    isRequired: true,
    note: '',
    multiple: 0,
    rules: [['required'], ['granteeKPP'], ['string', { max: '9' }]],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    hideDetails: true,
    name: 'programName',
    title: 'Наименование программы*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    comment: '',
    isRequired: true,
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    yearPicker: true,
    name: 'startFinanceYear',
    title: 'Год начала финансирования*',
    options: [],
    required: true,
    default_value: '',
    descr: '',
    hideDetails: true,
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isRequired: true,
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    yearPicker: true,
    required: true,
    name: 'endFinanceYear',
    title: 'Год завершения финансирования*',
    options: [],
    default_value: '',
    descr: '',
    hideDetails: true,
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isRequired: true,
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    required: true,
    yearPicker: true,
    name: 'endProjectYear',
    title: 'Год завершения постмониторинга*',
    options: [],
    default_value: '',
    descr: '',
    hideDetails: true,
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isRequired: true,
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'name',
    group_name: 'granteeCompanies',
    title: 'Наименование организации - Участник консорциума - получатель гранта (при наличии)',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    index: 0,
    hideDetails: true,
    multiple: 0,
    rules: [['string', { max: '255' }]],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'actionButton',
    name: 'addName',
    text: 'Добавить',
    default_value: null,
    action: () => addNewFiled('name'),
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    name: 'supportKind',
    title: 'Форма поддержки*',
    hideDetails: true,
    options: SUPPORT_KIND,
    default_value: 'grant',
    descr: '',
    note: '',
    multiple: 0,
    rules: null,
    isIdValue: true,
    isRequired: true,
    sort: 110,
    itemValue: 'id',
    itemTitle: 'name',
    findType: 'wayCreated',
    findValue: 'new',
    comment: '',
    isEdit: false,
  },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'select',
  //   name: 'statusCode',
  //   title: 'Статус реализации проекта (код)*',
  //   options: STATUS_CODE,
  //   default_value: null,
  //   descr: '',
  //   hideDetails: true,
  //   note: '',
  //   multiple: 0,
  //   rules: null,
  //   sort: 110,
  //   itemValue: 'id',
  //   itemTitle: 'name',
  //   findType: 'wayCreated',
  //   findValue: 'new',
  //   comment: '',
  //   isEdit: false,
  // },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'attachmentDocs',
    title: 'Форма для прикрепления дополнительных документов (в случае необходимости)',
    links: [],
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    isRequired: true,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   hideDetails: true,
  //   type: 'text',
  //   name: 'contractNumber',
  //   title: 'Номер договора*',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   onlyNumbers: true,
  //   multiple: 0,
  //   rules: [['required'], ['string', { max: '255' }]],
  //   sort: 1,
  //   comment: '',
  //   isEdit: false,
  // },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'date',
  //   hideDetails: true,
  //   required: true,
  //   name: 'contractDate',
  //   title: 'Дата договора*',
  //   options: [],
  //   default_value: '',
  //   descr: '',
  //   note: '',
  //   multiple: 0,
  //   rules: [['required']],
  //   sort: 1,
  //   comment: '',
  //   isEdit: false,
  // },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    hideDetails: true,
    name: 'granteeRepresentativeName',
    title: 'В лице кого представлен получатель*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    isRequired: true,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    hideDetails: true,
    name: 'granteeRepresentativeJustification',
    title: 'На основании чего действует представитель получателя*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    isRequired: true,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    hideDetails: true,
    name: 'granteeEmail',
    title: 'Эл. почта получателя*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['email']],
    sort: 1,
    comment: '',
    isRequired: true,
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'number',
    hideDetails: true,
    name: 'granteeOGRN',
    title: 'ОГРН получателя*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    isRequired: true,
    rules: [['required'], ['granteeOGRN'], ['string', { max: '13' }]],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'number',
    hideDetails: true,
    name: 'granteeOKTMO',
    title: 'ОКТМО получателя*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    isRequired: true,
    multiple: 0,
    rules: [['required'], ['granteeOKTMO'], ['string', { max: '11' }]],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'number',
    hideDetails: true,
    name: 'grantAmountFull',
    title: 'Размер гранта (цифрами) общий*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
    isRequired: true,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'text',
    name: 'grantAmountFullText',
    title: 'Размер гранта (прописью) общий*',
    options: [],
    hideDetails: true,
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    isRequired: true,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'date',
    required: true,
    group_name: 'annualGrantAmounts',
    index: 0,
    name: 'year',
    title: 'Год предоставления гранта*',
    yearPicker: true,
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    hideDetails: true,
    isRequired: true,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'number',
    group_name: 'annualGrantAmounts',
    index: 0,
    name: 'amount',
    hideDetails: true,
    isAmount: true,
    title: 'Размер гранта (цифрами)*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    isRequired: true,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'text',
    group_name: 'annualGrantAmounts',
    index: 0,
    name: 'amountText',
    hideDetails: true,
    title: 'Размер гранта (прописью)*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    isRequired: true,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'actionButton',
    name: 'addName',
    text: 'Добавить',
    default_value: null,
    action: () => addNewGroupAnnualGrantAmount('annualGrantAmounts'),
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'text',
    hideDetails: true,
    name: 'bankAccountBIK',
    title: 'БИК*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    comment: '',
    isRequired: true,
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'text',
    hideDetails: true,
    name: 'bankAccountBankName',
    title: 'Наименование банка*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    comment: '',
    isRequired: true,
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'text',
    hideDetails: true,
    name: 'bankAccountSingleTreasuryAccount',
    title: 'Единый казначейский счет*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    comment: '',
    isRequired: true,
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'text',
    hideDetails: true,
    name: 'bankAccountTreasuryAccount',
    title: 'Казначейский счет*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    comment: '',
    isRequired: true,
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'text',
    hideDetails: true,
    name: 'bankAccountPersonalAccount',
    title: 'л/с*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    comment: '',
    isRequired: true,
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'text',
    name: 'bankAccountTreasuryDepartmentName',
    title: 'Наименование Управления Федерального казначейства*',
    options: [],
    hideDetails: true,
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
    comment: '',
    isRequired: true,
    isEdit: false,
  },
])

const filesFields = ref([
  {
    id: uuidv4(),
    sectionName: 'Контрольные события',
    description:
      'Скачайте шаблон таблицы 3.4. "Перечень контрольных событий, их описание (результаты) по направлениям реализации Программы, с\n' +
      'указанием даты их наступления" по кнопке "скачать шаблон", заполните ее и загрузите в форму ниже.',
    title: 'Форма для прикрепления документов',
    type: 'dropFile',
    links: [],
    templateType: 'achievement-performance',
    group_id: 0,
    name: 'controlEvents',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['file', { extensions: ['.xls', '.xlsx'] }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    sectionName: 'Оборудование и ПО',
    templateType: 'achievement-additional',
    description:
      'Скачайте шаблон таблицы 5.3.3. "Потребность в дополнительном оборудовании и программном обеспечении с указанием кратких характеристик и ориентировочной стоимости" по кнопке "скачать шаблон", заполните ее и загрузите в форму ниже.',
    title: 'Форма для прикрепления документов',
    type: 'dropFile',
    links: [],
    group_id: 0,
    name: 'hardwareSoftware',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['file', { extensions: ['.xls', '.xlsx'] }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    templateType: 'achievement-summary',
    sectionName: 'Показатели результативности',
    description:
      'Скачайте шаблон таблицы 6.1. "Сводные показатели результативности реализации программы и их целевые значения по годам" по кнопке "скачать шаблон", заполните ее и загрузите в форму ниже.',
    title: 'Форма для прикрепления документов',
    type: 'dropFile',
    links: [],
    group_id: 0,
    name: 'performanceIndicators',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['file', { extensions: ['.xls', '.xlsx'] }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    templateType: 'achievement-years',
    sectionName: 'Индикаторы',
    description:
      'Скачайте шаблон таблицы 7. "Индикаторы реализации программы и их целевые значения по годам" по кнопке "скачать шаблон", заполните ее и загрузите в форму ниже.',
    title: 'Форма для прикрепления документов',
    type: 'dropFile',
    links: [],
    group_id: 0,
    name: 'indicators',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['file', { extensions: ['.xls', '.xlsx'] }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    templateType: 'achievement-implementation',
    sectionName: 'План мероприятий',
    description:
      'Скачайте шаблон таблицы 8. "План мероприятий по реализации Программы" по кнопке "скачать шаблон", заполните ее и загрузите в форму ниже.',
    title: 'Форма для прикрепления документов',
    type: 'dropFile',
    links: [],
    group_id: 0,
    name: 'planProgram',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['file', { extensions: ['.xls', '.xlsx'] }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    sectionName: 'Программа',
    title: 'Форма для прикрепления документов',
    type: 'dropFile',
    links: [],
    group_id: 0,
    name: 'program',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['file', { extensions: ['.pdf'] }]],
    sort: 1,
  },
])
const stashData = ref(null)

const isDisableSubmitButton = computed(() => {
  const emptyInput = fields.value?.find(
    (el) =>
      el.type !== 'actionButton' && el.isRequired && !el.default_value && el.type !== 'dropFile',
  )

  const emptyFiles = filesFields.value?.find((el) => !el.isNoChanges && !el.links?.length)
  return !!emptyFiles || !!emptyInput
})

async function downloadTemplate(type, name) {
  try {
    await api
      .projects()
      .downloadTemplateForParsing(type)
      .then((response) => {
        downloadFileData(response, `Шаблон "${name}"`)
      })
  } catch (err) {
    toast.error('Ошибка при скачивании шаблона')
  }
}

function getIsLastElementInGroup(groupName, id, index, isCopyGroup) {
  if (!index) {
    return false
  }
  if ((!isCopyGroup && !route.params?.id) || groupName !== 'annualGrantAmounts') {
    return false
  }
  const groupFields = fields.value?.filter(
    (el) => el.group_name === groupName && index === el.index,
  )
  let lastField = groupFields[groupFields.length - 1]
  if (!lastField) {
    return false
  }
  return lastField?.id === id
}

function deleteFieldsGroup(group, index) {
  const groupFields = fields.value?.filter((el) => el.group_name === group && index === el.index)

  groupFields?.forEach((el) => {
    const filedIndex = fields.value?.findIndex((field) => field.id === el.id)
    if (filedIndex !== -1) {
      fields.value?.splice(filedIndex, 1)
    }
  })

  const el = fields.value?.find((el) => el?.group_name === group)
  if (el) {
    sendHash({ id: el.id, value: el.default_value })
  }
}

function deleteField(idField, group) {
  const filedIndex = fields.value?.findIndex((el) => el.id === idField)

  if (filedIndex !== -1) {
    fields.value?.splice(filedIndex, 1)
    const el = fields.value?.find((el) => el?.group_name === group)

    if (el) {
      sendHash({ id: el.id, value: el.default_value })
    }
  }
}

async function uploadIndicatorParserPerformance(name, file) {
  try {
    const form = new FormData()
    form.append('file', file)
    await api
      .projects()
      .uploadIndicatorParserPerformance(uniqueHash.value, form)
      .then(() => {
        uploadFile(name, file)
      })
  } catch (err) {
    toast.error('Ошибка при чтении файла. Попробуйте загрузить другой')
  }
}

async function uploadIndicatorParserAdditionalNeed(name, file) {
  try {
    const form = new FormData()
    form.append('file', file)
    await api
      .projects()
      .uploadIndicatorParserAdditionalNeed(uniqueHash.value, form)
      .then(() => uploadFile(name, file))
  } catch (err) {
    toast.error('Ошибка при чтении файла. Попробуйте загрузить другой')
  }
}

async function uploadIndicatorParserSummary(name, file) {
  try {
    const form = new FormData()
    form.append('file', file)
    await api
      .projects()
      .uploadIndicatorParserSummary(uniqueHash.value, form)
      .then(() => uploadFile(name, file))
  } catch (err) {
    toast.error('Ошибка при чтении файла. Попробуйте загрузить другой')
  }
}

async function uploadIndicatorParserYears(name, file) {
  try {
    const form = new FormData()
    form.append('file', file)
    await api
      .projects()
      .uploadIndicatorParserYears(uniqueHash.value, form)
      .then(() => uploadFile(name, file))
  } catch (err) {
    toast.error('Ошибка при чтении файла. Попробуйте загрузить другой')
  }
}

async function uploadIndicatorParserImplementationPlan(name, file) {
  try {
    const form = new FormData()
    form.append('file', file)
    await api
      .projects()
      .uploadIndicatorParserImplementationPlan(uniqueHash.value, form)
      .then(() => uploadFile(name, file))
  } catch (err) {
    toast.error('Ошибка при чтении файла. Попробуйте загрузить другой')
  }
}

function updateValue({ value, id }) {
  fields.value.forEach((item) => {
    if (item.id === id) {
      if (item.name === 'shortTitle') {
        item.default_value = value
        const fullTitle = fields.value.find((el) => el.name === 'fullTitle')
        if (fullTitle) {
          fullTitle.default_value = value
          // fullTitle.isEdit = value
          // item.isEdit = true
        }
        return
      }

      if (item.name === 'fullTitle') {
        item.default_value = value
        const shortTitle = fields.value.find((el) => el.name === 'shortTitle')
        if (shortTitle) {
          shortTitle.default_value = value
          // shortTitle.isEdit = true
          // item.isEdit = true
        }
        return
      }
      item.default_value = value
      // item.isEdit = true
      if (item.type === 'dropFile') {
        value?.forEach((file) => {
          uploadFile(item.name, file)
        })
      }
    }
  })
}

function updateFileValue({ value, id }) {
  filesFields.value.forEach((item) => {
    if (item.id === id) {
      item.default_value = value
      item.isEdit = true

      parsingFiles(item.name, value[0])
    }
  })
}

async function downloadFile(id, name) {
  try {
    await api
      .files()
      .downloadFile(id)
      .then((data) => {
        downloadFileWithLinkData(data, name)
      })
  } catch (err) {
    toast.error(err)
  }
}

async function deleteFile(id) {
  try {
    await api
      .files()
      .deleteFile(id)
      .then(() => {
        getFiles()
      })
  } catch (err) {
    toast.error(err)
  }
}

function parsingFiles(name, data) {
  if (name === 'controlEvents') {
    return uploadIndicatorParserPerformance(name, data)
  }

  if (name === 'hardwareSoftware') {
    return uploadIndicatorParserAdditionalNeed(name, data)
  }

  if (name === 'performanceIndicators') {
    return uploadIndicatorParserSummary(name, data)
  }

  if (name === 'indicators') {
    return uploadIndicatorParserYears(name, data)
  }

  if (name === 'planProgram') {
    return uploadIndicatorParserImplementationPlan(name, data)
  }

  return uploadFile(name, data)
}

async function uploadFile(name, data) {
  try {
    const form = new FormData()
    if (route.params?.id) {
      form.append('entityId', route.params?.id)
    }
    form.append('folder', WINNER_FOLDER)
    form.append('file', data)
    form.append('fileKey', name)
    await api
      .files()
      .uploadFile(bucket.value, form)
      .then(() => {
        getFiles()
      })
  } catch (err) {
    toast.error(err)
  }
}

async function getFiles() {
  try {
    const entityId = projectId.value ? { entityId: projectId.value } : null
    await api
      .files()
      .getFiles(bucket.value, entityId)
      .then((data) => {
        const files = data?.filesInFolders
        if (Object.values(files)?.length && !(WINNER_FOLDER in files)) {
          return
        }

        const winnerFiles = files[WINNER_FOLDER]

        fields.value?.forEach((el) => {
          if (winnerFiles && Object.values(winnerFiles)?.length && el.name in winnerFiles) {
            el.links = winnerFiles[el.name]
          } else {
            el.links = []
          }
        })

        filesFields.value?.forEach((el) => {
          if (winnerFiles && Object.values(winnerFiles)?.length && el.name in winnerFiles) {
            el.links = winnerFiles[el.name]
          } else {
            el.links = []
          }
        })
      })
  } catch (err) {
    console.log(err)
  }
}

function setProgramNumber(value) {
  const center = centerNtiFilter.value?.find((el) => el.id === +value)
  if (center) {
    programNumber.value = center.programNumber
  }
}

async function sendHashAdress(val) {
  try {
    const params = {
      hash: uniqueHash.value,
      value: val,
      field: 'matchesActualAddress',
    }
    await api
      .stash()
      .saveFieldInStash(params)
      .finally(() => (isLoading.value = false))
  } catch (err) {
    console.log(err)
  }
}

function getFormatValue(value, item) {
  if (item?.type === 'date' && !item?.yearPicker) {
    return moment(value).format('YYYY-MM-DD')
  }

  if (item.type === 'select') {
    return item?.isIdValue
      ? item.options?.find((el) => el.id === value)[item.itemValue]
      : item.options?.find((el) => el.id === value)[item.itemTitle]
  }

  if (item?.type === 'date') {
    return value ? value : ''
  }

  return value
}

function setIsEditForField({ id, value }) {
  const field = fields.value?.find((el) => el.id === id)

  if (!field.comment) {
    return
  }

  let saveValue = field.name in projectData.value ? projectData.value[field.name] : ''
  let relatedField

  if (field.name === 'shortTitle' || field.name === 'fullTitle') {
    saveValue =
      field.name in projectData.value?.centerNti
        ? projectData.value?.centerNti[field.name]
        : saveValue

    const relatedFieldName = field.name === 'shortTitle' ? 'fullTitle' : 'shortTitle'
    relatedField = fields.value?.find((el) => el.name === relatedFieldName)
  }

  if (field.name === 'amountText' || field.name === 'amount') {
    saveValue = projectData.value?.annualGrantAmounts[field.index][field.name]
  }

  if (field.name === 'name') {
    saveValue = projectData.value?.granteeCompanies[field.index][field.name]
  }

  if (field) {
    field.isEdit = saveValue !== getFormatValue(value, field)
  }

  if (relatedField) {
    relatedField.isEdit = saveValue !== getFormatValue(value, field)
  }
}

async function sendHash({ id, value }) {
  isLoading.value = true
  setIsEditForField({ id, value })
  for (const item of fields.value) {
    let params = {
      hash: uniqueHash.value,
    }

    if (item.id === id) {
      if (item?.group_name === 'granteeCompanies') {
        const arr = fields.value?.filter((el) => el?.group_name === 'granteeCompanies')
        const granteeCompaniesValue = arr.map((el) => {
          return {
            name: el.default_value,
          }
        })

        params = {
          ...params,
          field: 'granteeCompanies',
          value: granteeCompaniesValue,
        }
      } else if (item?.group_name === 'annualGrantAmounts') {
        const annualGrantAmountsArr = fields.value.filter(
          (el) => el?.group_name === 'annualGrantAmounts',
        )

        const annualGrantAmounts = []
        annualGrantAmountsArr.forEach((el) => {
          annualGrantAmounts[el?.index] = {
            ...annualGrantAmounts[el?.index],
            [el.name]: el.default_value,
          }
        })

        params = {
          ...params,
          field: 'annualGrantAmounts',
          value: annualGrantAmounts,
        }
      } else if (item?.type === 'date' && !item?.yearPicker) {
        params = {
          ...params,
          field: item.name,
          value: moment(value).format('YYYY-MM-DD'),
        }
      } else if (item.name === 'fullTitle' || item.name === 'shortTitle') {
        params = {
          ...params,
          field: 'centerNtiId',
          value: item.type === 'number' ? +value : value,
        }

        setProgramNumber(value)
      } else {
        params = {
          ...params,
          field: item.name,
          value: item.type === 'number' ? +value : value,
        }
      }

      await api
        .stash()
        .saveFieldInStash(params)
        .finally(() => (isLoading.value = false))
    }
  }
}

function getFieldsForGroup(idGroup) {
  return fields.value.filter((el) => el.group_id === idGroup)
}

function addNewFiled(nameField, value, index) {
  const findFieldsGroup = fields.value.filter((el) => el.name === nameField)
  if (findFieldsGroup.length) {
    const lastElementId = findFieldsGroup[findFieldsGroup.length - 1]
    const findElIndex = fields.value.findIndex((el) => el.id === lastElementId.id)
    const newField = {
      ...lastElementId,
      id: uuidv4(),
      default_value: value ? value : null,
      title: '',
      isEdit: false,
      index: index,
      hideDetails: true,
      isCopyField: true,
    }

    if (!value) {
      newField.comment = null
      newField.isEdit = false
    }
    fields.value.splice(findElIndex + 1, 0, newField)
  }
}

function addNewGroupAnnualGrantAmount(nameGroup, data) {
  const filterData = fields.value.filter((el) => el?.group_name === nameGroup)
  const copyField = filterData.slice(-3)
  if (!copyField.length) {
    return null
  }
  const allFields = fields.value.filter((el) => el?.group_name === nameGroup)

  const lastElement = allFields[allFields.length - 1]
  const findIndexForInsert = fields.value.findIndex((el) => el.id === lastElement.id)
  copyField?.reverse().forEach((el) => {
    const newValue = {
      ...el,
      index: el.index + 1,
      id: uuidv4(),
      default_value: data && data[el.name] ? data[el.name] : null,
      isCopyGroup: true,
      isAmount: true,
    }

    if (!data || !data[el.name]) {
      newValue.comment = null
      newValue.isEdit = false
    }
    fields.value.splice(findIndexForInsert + 1, 0, newValue)
  })
}

async function onSubmit() {
  isLoading.value = true
  try {
    if (form.value.errors.length) {
      const errInput = form.value.errors[0]

      focusErrorInput(errInput)
      return
    }

    const annualGrantAmountsArr = fields.value.filter(
      (el) => el.group_name === 'annualGrantAmounts',
    )

    const annualGrantAmounts = []
    annualGrantAmountsArr.forEach((el) => {
      annualGrantAmounts[el?.index] = {
        ...annualGrantAmounts[el?.index],
        [el.name]: el.default_value,
      }
    })

    let params = {
      annualGrantAmounts: annualGrantAmounts,
      hash: uniqueHash.value,
    }

    fields.value.forEach((el) => {
      if (el.name === 'name') {
        let grantValue

        if (params['granteeCompanies']?.length) {
          grantValue = [...params['granteeCompanies']]
        } else if (el.default_value) {
          grantValue = [{ name: el.default_value }]
        }

        params['granteeCompanies'] = grantValue
      } else if (el.type === 'date') {
        params[el.name] = el?.yearPicker
          ? String(el.default_value)
          : moment(el.default_value).format('YYYY-MM-DD')
      } else if (el.type === 'number') {
        params[el.name] = +el.default_value
      } else if (el.name === 'shortTitle' || el.name === 'fullTitle') {
        params['centerNtiId'] = el.default_value
      } else {
        params[el.name] = el.default_value
      }
    })

    if (isEdit.value) {
      await api
        .projects()
        .updateProject(projectId.value, params)
        .then((data) => {
          projectId.value = data?.id
          localStorage.removeItem('uniqueHash')
          clearStash()
        })
    } else {
      await api
        .projects()
        .createProject(params)
        .then(() => {
          clearStash()
          localStorage.removeItem('uniqueHash')
        })
    }

    router.push('/coordination-documents')
  } catch (err) {
    toast.error(err.message)
  } finally {
    isLoading.value = false
  }
}

async function clearStash() {
  try {
    await api.stash().clearStash(uniqueHash.value)
  } catch (err) {
    console.log(err)
  }
}

function setFieldsData(data) {
  fields.value.forEach((el) => {
    if (!data) {
      return
    }
    const value = data[el.name]

    if ((el.name === 'fullTitle' || el.name === 'shortTitle') && 'centerNtiId' in data) {
      setProgramNumber(data['centerNtiId'])
      el.default_value = +data['centerNtiId']
    } else if (
      value &&
      value?.group_name !== 'granteeCompanies' &&
      value?.group_name !== 'annualGrantAmounts' &&
      el.type !== 'checkbox'
    ) {
      el.default_value = value
    }
  })

  const granteeCompaniesField = fields.value.find((el) => el?.group_name === 'granteeCompanies')
  for (let i = 0; i < data['granteeCompanies']?.length; i++) {
    if (!i) {
      granteeCompaniesField.default_value = data['granteeCompanies'][i].name
    } else {
      addNewFiled('name', data['granteeCompanies'][i].name, i)
    }
  }

  for (let i = 0; i < data['annualGrantAmounts']?.length; i++) {
    if (!i) {
      const annualGrantAmountsFieldYear = fields.value.find(
        (el) => el?.group_name === 'annualGrantAmounts' && el.name === 'year',
      )

      const annualGrantAmountsFieldAmountText = fields.value.find(
        (el) => el?.group_name === 'annualGrantAmounts' && el.name === 'amountText',
      )

      const annualGrantAmountsFieldAmount = fields.value.find(
        (el) => el?.group_name === 'annualGrantAmounts' && el.name === 'amount',
      )

      annualGrantAmountsFieldYear.default_value = data['annualGrantAmounts'][i].year

      annualGrantAmountsFieldAmountText.default_value = data['annualGrantAmounts'][i]?.amountText

      annualGrantAmountsFieldAmount.default_value = data['annualGrantAmounts'][i].amount
    } else {
      addNewGroupAnnualGrantAmount('annualGrantAmounts', data['annualGrantAmounts'][i])
    }
  }

  if (isEdit.value && isWinner.value) {
    getComments()
  }
}

async function getSavedData() {
  try {
    await api
      .stash()
      .getStashData(uniqueHash.value)
      .then((data) => {
        stashData.value = data
        if ('matchesActualAddress' in data) {
          matchesActualAddress.value = !!data['matchesActualAddress']
        }

        if (
          'legalAddress' in data &&
          'granteeAddress' in data &&
          data['legalAddress'] === data['granteeAddress']
        ) {
          matchesActualAddress.value = true
        }
        if (!route.params?.id) {
          return setFieldsData(data)
        }
        return prepareFormData(projectData.value)
      })
  } catch (err) {
    console.log(err)
  }
}
async function getComments() {
  try {
    await api
      .projects()
      .getComments(projectId.value)
      .then((data) => {
        comments.value = data
        fields.value.forEach((el) => {
          const value = data[el.name]

          if (value) {
            el.comment = value
          }
        })

        const granteeCompanies = fields.value?.filter((el) => el?.group_name === 'granteeCompanies')

        // const annualGrantAmountsYears = fields.value?.filter(
        //   (el) => el?.group_name === 'annualGrantAmounts' && el?.name === 'year'
        // )

        const annualGrantAmountsAmountText = fields.value?.filter(
          (el) => el?.group_name === 'annualGrantAmounts' && el?.name === 'amountText',
        )

        const annualGrantAmountsAmount = fields.value?.filter(
          (el) => el?.group_name === 'annualGrantAmounts' && el?.name === 'amount',
        )

        data?.granteeCompanies?.forEach((item, index) => {
          if (item?.name && granteeCompanies[index]) {
            granteeCompanies[index].comment = item?.name
          }
        })

        data?.annualGrantAmounts?.forEach((item, index) => {
          // if (item.year && annualGrantAmountsYears[index]) {
          //   annualGrantAmountsYears[index].comment = item.year
          // }

          if (item.amountText && annualGrantAmountsAmountText[index]) {
            annualGrantAmountsAmountText[index].comment = item.amountText
          }

          if (item.amount && annualGrantAmountsAmount[index]) {
            annualGrantAmountsAmount[index].comment = item.amount
          }
        })
      })
  } catch (err) {
    console.log(err)
  }
}

function setEditedData(data) {
  const annualGrantAmountsFields = fields.value?.filter(
    (el) => el?.group_name === 'annualGrantAmounts' && el?.name === 'year',
  )

  const annualGrantAmountsFieldsAmount = fields.value?.filter(
    (el) => el?.group_name === 'annualGrantAmounts' && el?.name === 'amount',
  )

  const annualGrantAmountsFieldsAmountText = fields.value?.filter(
    (el) => el?.group_name === 'annualGrantAmounts' && el?.name === 'amountText',
  )

  const granteeCompanies = fields.value?.filter((el) => el?.group_name == 'granteeCompanies')

  data?.granteeCompanies?.forEach((item, index) => {
    if (granteeCompanies[index] && item.name !== granteeCompanies[index]?.default_value) {
      granteeCompanies[index].isEdit = true
    }
  })

  data?.annualGrantAmounts?.forEach((item, index) => {
    if (item.year !== annualGrantAmountsFields[index]?.default_value) {
      annualGrantAmountsFields[index].isEdit = true
    }

    if (item.amount !== annualGrantAmountsFieldsAmount[index]?.default_value) {
      annualGrantAmountsFieldsAmount[index].isEdit = true
    }

    if (item.amountText !== annualGrantAmountsFieldsAmountText[index]?.default_value) {
      annualGrantAmountsFieldsAmountText[index].isEdit = true
    }
  })

  fields.value?.forEach((el) => {
    if (el?.group_name === 'granteeCompanies' || el?.group_name === 'annualGrantAmounts') {
      return
    }
    if (
      (data[el.name] && data[el.name] !== el.default_value) ||
      projectData.value[el.name] !== el.default_value
    ) {
      if (el.name === 'shortTitle' || el.name === 'fullTitle') {
        const centerNtiName = projectData.value?.centerNtiId
        return (el.isEdit = centerNtiName !== el.default_value)
      }
      el.isEdit = true
    }
  })
}

function prepareFormData(data) {
  const value = { ...data }
  Object.keys(value)?.map((el) => {
    if (stashData.value[el]) {
      value[el] = stashData.value[el]
    }
  })
  setFieldsData(value)
  setEditedData(data)
}

async function getProjectData() {
  isLoading.value = true
  try {
    await api
      .projects()
      .getProjectData(projectId.value)
      .then((data) => {
        projectData.value = data
        uniqueHash.value = data?.hash

        getSavedData()
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function getCenterNtiList() {
  try {
    await api
      .projects()
      .getCenterNtiList()
      .then((data) => {
        centerNti.value = data
      })
  } catch (err) {
    console.log(err)
  }
}

function setUniqueHash() {
  const hash = localStorage.getItem('uniqueHash')
  if (!hash) {
    uniqueHash.value = uuidv4()
    return localStorage.setItem('uniqueHash', uniqueHash.value)
  }

  uniqueHash.value = hash
}

watch(matchesActualAddress, () => {
  const legalAddress = fields.value?.find((el) => el.name === 'legalAddress')
  if (matchesActualAddress.value) {
    const granteeAddress = fields.value?.find((el) => el.name === 'granteeAddress')
    legalAddress.disabled = 1
    legalAddress.default_value = granteeAddress.default_value
  } else {
    legalAddress.disabled = 0
  }
})

onMounted(() => {
  isLoadingPage.value = true
  if (!route.params?.id) {
    setUniqueHash()
  }

  Promise.allSettled([getCenterNtiList(), getFiles()])
    .then(() => {
      isLoadingPage.value = false
    })
    .then(() => {
      if (route.params?.id) {
        getProjectData()
      } else {
        getSavedData()
      }
    })
})
</script>
