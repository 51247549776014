<template>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <div v-else :key="data.length">
    <v-table v-if="data?.length" class="text-caption">
      <thead>
        <tr>
          <th v-for="el in head" :key="el" :style="`min-width: ${el.width}px`" class="text-left">
            {{ el.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!data.length">
          <td colspan="8" class="text-center py-4 text-caption text-grey">Список пуст</td>
        </tr>
        <tr v-for="(item, index) in data" :key="`tr-${index}`">
          <td
            v-for="val in Object.values(item)"
            :key="`data-${val}`"
            :style="`min-width: ${head[i]?.width}px`"
          >
            {{ val }}
          </td>
        </tr>
      </tbody>
    </v-table>
    <v-row v-else class="justify-center align-center py-12 text-caption text-grey">
      Нет данных
    </v-row>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'

import { api } from '@/api/Api'
import { useRoute } from 'vue-router/dist/vue-router'
const route = useRoute()

const isLoading = ref(false)
const data = ref([])
const head = ref([
  {
    title: '№ Договора о предоставлении гранта',
  },
  {
    title: '№ раздела мероприятий  Программы',
  },
  {
    title: '№ п/п Программы',
  },
  {
    title: 'Наименование работы',
    width: 500,
  },
  {
    title: 'Вид расходов: ФОТ, руб',
  },
  {
    title: 'Вид расходов: оборудование, руб.',
  },
  {
    title: 'Вид расходов: ПО, руб.',
  },
  {
    title: 'Вид расходов:  материалы и комплектующие, руб.',
  },
  {
    title: 'Вид расходов: иные - оплата услуг сторонних организаций, руб.',
  },
  {
    title: 'Вид расходов: иные - иные - прочие прямые, руб.',
  },
  {
    title: 'Вид расходов: иные - иные - накладные, руб.',
  },
  {
    title: 'Наименование организации, понесшей расходы.',
  },
  {
    title: 'Наименование и реквизиты подтверждающих расходы документов',
    width: 500,
  },
])

async function getAccountRegisterCoFinancing() {
  isLoading.value = true
  try {
    await api
      .reports()
      .getAccountRegisterCoFinancing(route.params.id)
      .then((res) => {
        data.value = res?.map((el) => {
          return {
            grantAgreementNumber: el.grantAgreementNumber,
            activitiesSectionProgramNumber: el.activitiesSectionProgramNumber,
            programNumber: el.programNumber,
            workTitle: el.workTitle,
            expensesPayrollTypeAmount: el.expensesPayrollTypeAmount,
            expensesEquipmentTypeAmount: el.expensesEquipmentTypeAmount,
            expensesSoftwareTypeAmount: el.expensesSoftwareTypeAmount,
            expensesMaterialsTypeAmount: el.expensesMaterialsTypeAmount,
            expensesOtherThirdPartyTypeAmount: el.expensesOtherThirdPartyTypeAmount,
            expensesOtherDirectTypeAmount: el.expensesOtherDirectTypeAmount,
            expensesOtherInvoicesTypeAmount: el.expensesOtherInvoicesTypeAmount,
            organizationIncurredCostName: el.organizationIncurredCostName,
            documentsConfirmingExpenses: el.documentsConfirmingExpenses,
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getAccountRegisterCoFinancing()
})
</script>
