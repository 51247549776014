<template>
  <v-form ref="form" lazy-validation class="px-0 w-100">
    <v-col cols="12" class="px-0 mt-1">
      <div v-for="field in fields" :key="field.id">
        <p v-if="field.title" class="text-body-1 mx-0 my-2">
          {{ field.title }}
        </p>
        <Component
          :is="inputs[getFormInput(field.type)]"
          v-bind="field"
          @change-value="updateDataInStash"
          @update-value="updateValue"
          @delete-file="deleteFile"
          @download-file="downloadFile"
        />
      </div>
      <v-col class="mt-4 px-0">
        <div class="text-body-2 text-grey">* - поля обязательные для заполнения</div>
        <div class="text-body-2 text-grey mt-2">Введенные данные сохраняются автоматически</div>
      </v-col>
      <v-row class="mt-6 mx-0 align-center">
        <v-btn :disabled="isDisableSaveButton" color="primary" @click="onSubmit"> Сохранить </v-btn>
        <v-btn
          class="ml-2"
          color="primary"
          variant="text"
          @click="router.push('/coordination-documents')"
        >
          Закрыть
        </v-btn>
      </v-row>
    </v-col>
  </v-form>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'

import { useToast } from '@/composables/useToast'
import { api } from '@/api/Api'
import { downloadFileWithLinkData, focusErrorInput, getFormInput, inputs } from '@/helpers'

import { PROGRAM_CHANGE_REQUEST_MANAGER } from '@/constants/stash'
import { TICKET_FILES } from '@/constants/buckets'

import { fields } from '@/views/Projects/ProgramChangeRequest/Fields'

const props = defineProps({
  files: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['get-files', 'submit-form'])

const route = useRoute()
const router = useRouter()
const { toast } = useToast()

const isLoading = ref(false)
const programId = ref(route.params?.id)
const form = ref(null)

const bucket = computed(() => TICKET_FILES)
const fieldFormHash = computed(() => `${PROGRAM_CHANGE_REQUEST_MANAGER}-${programId.value}`)

const isDisableSaveButton = computed(() =>
  fields.value?.find((el) => !el?.links?.length && !el.default_value),
)

async function uploadFile(name, data) {
  try {
    const form = new FormData()
    form.append('file', data)
    form.append('fileKey', name)
    form.append('entityId', route.params?.id)
    await api
      .files()
      .uploadFile(bucket.value, form)
      .then(() => {
        toast.success('Файл успешно загружен')
        emit('get-files')
      })
  } catch (err) {
    toast.error('Ошибка при загрузке файла')
  }
}

async function downloadFile(id, name) {
  try {
    await api
      .files()
      .downloadFile(id)
      .then((data) => {
        downloadFileWithLinkData(data, name)
      })
  } catch (err) {
    toast.error(err)
  }
}

async function deleteFile(id) {
  isLoading.value = true
  try {
    await api
      .files()
      .deleteFile(id)
      .then(() => {
        emit('get-files')

        fields.value?.forEach((el) => {
          if (el.type === 'dropFile') {
            el.links = el.links?.filter((el) => el.id !== id) || []
          }
        })
      })
  } catch (err) {
    toast.error(err)
  } finally {
    isLoading.value = false
  }
}

function updateValue({ value, id }) {
  fields.value.forEach((item) => {
    if (item.id === id) {
      item.default_value = value

      if (item.type === 'dropFile') {
        value?.forEach((file) => {
          uploadFile(item.name, file)
        })
      }
    }
  })
}

function clearData() {
  fields.value?.forEach((el) => {
    el.default_value = null
  })
}

async function getSavedData() {
  fields.value?.forEach((el) => (el.default_value = null))
  isLoading.value = true
  clearData()
  try {
    await api
      .stash()
      .getStashData(fieldFormHash.value)
      .then((data) => {
        fields.value?.forEach((el) => {
          if (el.name in data) {
            el.default_value = data[el.name]
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function onSubmit() {
  const { valid } = await form.value.validate()

  if (!valid || form.value?.errors?.length) {
    toast.error('Заполните все обязательные поля')
    const errInput = form.value.errors[0]
    focusErrorInput(errInput)
    return
  }
  const files = fields.value?.find((el) => el.type === 'dropFile')
  if (!files?.default_value?.length && !files?.links.length) {
    toast.error('Загрузите рекомендации Экспертного совета / Протокол КК*')
    return
  }

  let params = {}
  let fileKeys = []

  fields.value?.forEach((el) => {
    if (el.type !== 'dropFile') {
      params = {
        ...params,
        managerComment: el.default_value,
      }
    } else {
      fileKeys = [el.name]
    }
  })
  params = {
    ...params,
    fileKeys: fileKeys,
  }

  emit('submit-form', params)
}

async function updateDataInStash({ value, id }) {
  const findField = fields.value?.find((el) => el.id === id)
  if (findField) {
    const params = {
      field: findField.name,
      value: value,
      hash: fieldFormHash.value,
    }

    await api.stash().saveFieldInStash(params)
  }
}

function setData() {
  fields.value?.forEach((el) => {
    if (el.type === 'dropFile' && el.name in props.files) {
      return (el.links = props.files[el.name])
    }

    return (el.links = [])
  })
}

onMounted(() => {
  getSavedData()

  setData()
})
</script>
