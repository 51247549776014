<template>
  <v-form>
    <v-row class="my-4">
      <v-col cols="12" md="8">
        <div v-for="field in fieldsForm" :key="field.id">
          <div class="text-subtitle-1 font-weight-bold mb-2">
            {{ field.sectionName }} {{ field.isNoChanges ? '' : '*' }}
          </div>
          <v-checkbox
            v-if="!field?.isRequired && !field?.links?.length"
            :key="`${field.id}-${field.isNoChanges}`"
            v-model="field.isNoChanges"
            label="Изменений нет"
            color="primary"
            density="compact"
            class="text-caption pa-0 ma-0 justify-start align-center d-flex mb-2"
            @change="() => updateCheckedValue(field)"
          />
          <template v-if="field?.isRequired || field?.links?.length || !field.isNoChanges">
            <div class="text-blue-darken-4 font-weight-bold text-body-2 my-6">
              {{ field.description }}
            </div>
            <Component
              :is="inputs[getFormInput(field.type)]"
              v-bind="field"
              @update-value="(data) => updateValue(data)"
              @delete-file="(id) => $emit('delete-file', id)"
              @download-file="(id, name) => $emit('download-file', id, name)"
            />
            <v-row
              class="px-0 mx-0 align-center cursor-pointer mb-8 mt-2"
              @click="() => downloadTemplate(field?.templateType, field?.sectionName)"
            >
              <v-icon color="blue" size="s"> mdi-download </v-icon>
              <span class="ml-2 text-body-2 title">Скачать шаблон / инструкцию</span>
            </v-row>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { inputs, getFormInput, downloadFileData } from '@/helpers'
import { emit as baseEmit } from '@/composables/form/usePropsForm'
import { api } from '@/api/Api'
import { ZNI_CHECKED } from '@/constants/stash'
import { useRoute } from 'vue-router/dist/vue-router'

const props = defineProps({
  ticket: {
    type: Object,
    default: () => {},
  },
  files: {
    type: Array,
    default: () => [],
  },
})

const route = useRoute()

const isNotAllFiles = computed(
  () => !!fieldsForm.value?.find((el) => !el.isNoChanges && !el.links?.length),
)
const fieldFormHash = computed(() => `${ZNI_CHECKED}-${route.params?.id}`)

const emit = defineEmits(baseEmit)

const fieldsForm = ref([
  {
    id: uuidv4(),
    sectionName: 'Контрольные события',
    description:
      'Скачайте шаблон таблицы 3.4. "Перечень контрольных событий, их описание (результаты) по направлениям реализации Программы, с\n' +
      'указанием даты их наступления" по кнопке "скачать шаблон", заполните ее и загрузите в форму ниже.',
    title: 'Форма для прикрепления документов',
    type: 'dropFile',
    links: [],
    templateType: 'achievement-performance',
    group_id: 0,
    isNoChanges: false,
    name: 'controlEvents',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['file', { extensions: ['.xls', '.xlsx'] }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    sectionName: 'Оборудование и ПО',
    description:
      'Скачайте шаблон таблицы 5.3.3. "Потребность в дополнительном оборудовании и программном обеспечении с указанием кратких характеристик и ориентировочной стоимости" по кнопке "скачать шаблон", заполните ее и загрузите в форму ниже.',
    title: 'Форма для прикрепления документов',
    templateType: 'achievement-additional',
    type: 'dropFile',
    links: [],
    group_id: 0,
    name: 'hardwareSoftware',
    options: [],
    default_value: [],
    isNoChanges: false,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['file', { extensions: ['.xls', '.xlsx'] }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    sectionName: 'Показатели результативности',
    templateType: 'achievement-summary',
    description:
      'Скачайте шаблон таблицы 6.1. "Сводные показатели результативности реализации программы и их целевые значения по годам" по кнопке "скачать шаблон", заполните ее и загрузите в форму ниже.',
    title: 'Форма для прикрепления документов',
    type: 'dropFile',
    links: [],
    group_id: 0,
    name: 'performanceIndicators',
    options: [],
    default_value: [],
    isNoChanges: false,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['file', { extensions: ['.xls', '.xlsx'] }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    sectionName: 'Индикаторы',
    templateType: 'achievement-years',
    description:
      'Скачайте шаблон таблицы 7. "Индикаторы реализации программы и их целевые значения по годам" по кнопке "скачать шаблон", заполните ее и загрузите в форму ниже.',
    type: 'dropFile',
    links: [],
    group_id: 0,
    name: 'indicators',
    options: [],
    default_value: [],
    descr: '',
    isNoChanges: false,
    note: '',
    multiple: true,
    rules: [['required'], ['file', { extensions: ['.xls', '.xlsx'] }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    sectionName: 'План мероприятий',
    templateType: 'achievement-implementation',
    description:
      'Скачайте шаблон таблицы 8. "План мероприятий по реализации Программы" по кнопке "скачать шаблон", заполните ее и загрузите в форму ниже.',
    title: 'Форма для прикрепления документов',
    type: 'dropFile',
    links: [],
    group_id: 0,
    name: 'planProgram',
    options: [],
    isNoChanges: false,
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['file', { extensions: ['.xls', '.xlsx'] }]],
    sort: 1,
  },
])

const filesKeys = computed(() =>
  fieldsForm.value?.filter((el) => !!el.links?.length)?.map((el) => el.name),
)

function setData() {
  fieldsForm.value?.forEach((el) => {
    if (el.type !== 'dropFile') {
      return
    }

    if (props.files && el.name in props.files) {
      el.links = props.files[el.name]?.filter((el) => !el.isConfirmed)
    }
  })
}

function updateValue({ value, id }) {
  fieldsForm.value?.forEach((item) => {
    if (item.id === id) {
      item.default_value = value

      if (item.type === 'dropFile') {
        value?.forEach((file) => {
          emit('upload-file', item.name, file)
        })
      }
    }
  })

  emit('set-is-not-all-files', isNotAllFiles.value, filesKeys.value)
}

function updateCheckedValue(field) {
  emit('set-is-not-all-files', isNotAllFiles.value, filesKeys.value)
  updateDataInStash({ value: field.isNoChanges, id: field.id })
}

async function downloadTemplate(type, name) {
  try {
    await api
      .projects()
      .downloadTemplateForParsing(type)
      .then((response) => {
        downloadFileData(response, `Шаблон "${name}"`)
      })
  } catch (err) {
    console.log(err)
  }
}

async function updateDataInStash({ value, id }) {
  const findField = fieldsForm.value?.find((el) => el.id === id)

  if (findField) {
    const params = {
      field: findField?.name,
      value: value,
      hash: fieldFormHash.value,
    }

    await api.stash().saveFieldInStash(params)
  }
}

async function getSavedData() {
  try {
    await api
      .stash()
      .getStashData(fieldFormHash.value)
      .then((data) => {
        fieldsForm.value?.forEach((el) => {
          if (el.name in data) {
            el.isNoChanges = data[el.name]
            emit('set-is-not-all-files', isNotAllFiles.value, filesKeys.value)
          }
        })
      })
  } catch (err) {
    console.log(err)
  }
}

onMounted(() => {
  if (props.files) {
    setData()
    emit('set-is-not-all-files', isNotAllFiles.value, filesKeys.value)
  }

  getSavedData()
})
</script>
