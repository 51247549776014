<template>
  <the-container>
    <h3 class="mb-6">Запрос на создание карточки программы получателя поддержки</h3>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <template v-else>
      <v-row class="px-0 ma-a mb-4">
        <v-col class="pa-a ma-0">
          Номер программы:
          {{ programNumber }}
        </v-col>
      </v-row>
      <div class="custom-inputs">
        <div v-for="group in groups" :key="`group-${group.id}`" class="mb-6">
          <div class="text-subtitle-1 font-weight-bold text-grey-darken-1 mb-6">
            {{ group.title }}
          </div>
          <div>
            <div v-for="field in getFiledGroup(group.id)" :key="field.id">
              <div>
                <v-row class="px-0 mx-0 align-end">
                  <v-col cols="8" :class="['py-0 px-0 mx-0', isShowCommentForm ? 'mb-6' : 'mb-3']">
                    <v-row class="pa-0 ma-0 border-bottom-line text-grey-darken-1">
                      <v-col class="px-0 mx-0 pr-6" cols="6">
                        {{ field.title }}
                      </v-col>
                      <v-col v-if="getValue(field)" class="px-0 mx-0" cols="6">
                        {{ getValue(field) }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="4" />
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="files">
        <div v-if="files?.length" class="mb-4">
          <div class="font-weight-bold text-grey my-6">Прикрепленные документы</div>
          <div v-for="(fileGroup, i) in files" :key="`fileGroup-${i}`">
            <v-row v-for="file in fileGroup" :key="file.id" class="mx-0 px-0">
              <v-col class="ml-0 pl-0 font-weight-medium text-body-2 word-break" cols="6">
                {{ file.originalFileName }}
              </v-col>
              <v-col
                class="text-decoration-underline text-blue-darken-4 cursor-pointer"
                cols="2"
                @click="() => downloadFile(file.id, file.originalFileName)"
              >
                Просмотреть
              </v-col>
            </v-row>
          </div>
        </div>
      </div>

      <v-row class="px-0 mx-0 align-center mt-2">
        <v-btn
          class="mx-0 px-0 text-decoration-underline"
          color="primary"
          variant="text"
          @click="redirectBack"
        >
          Закрыть
        </v-btn>
      </v-row>
    </template>
  </the-container>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { useStore } from 'vuex'

import TheContainer from '@/components/TheContainer'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'
import { api } from '@/api/Api'
import { useToast } from '@/composables/useToast'
import { SUPPORT_KIND, STATUS_CODE, PROJECT_REQUEST_STATUSES } from '@/constants'
import { WINNER_FOLDER } from '@/constants/store'
import {
  ANNUAL_GRANT_AMOUNT_FILED,
  ANNUAL_GRANT_AMOUNT_TEXT_FILED,
  GRANTEE_COMPANIES,
} from '@/constants/fields'
import { downloadFileWithLinkData } from '@/helpers'
import { PROGRAM_FILES, TEMP } from '@/constants/buckets'

const router = useRouter()
const route = useRoute()
const store = useStore()
const { toast } = useToast()

const hash = computed(() => {
  return store.getters['user/hash']
})
const bucket = computed(() => (route.params?.id ? PROGRAM_FILES : TEMP))

const project = ref(null)
const centerNti = ref([])
const isLoading = ref(false)
const files = ref([])
defineProps({
  isShowCommentForm: {
    type: Boolean,
    default: false,
  },
})

const groups = [
  {
    id: 0,
    title: 'Общие сведения',
  },
  {
    id: 1,
    title: 'Сведения о гранте',
  },
  {
    id: 2,
    title: 'Реквизиты',
  },
  {
    id: 3,
    title: 'Данные для отправки корреспонденции',
  },
]
const programNumber = ref(null)
const fieldsForm = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'grantRecipientName',
    title: 'Полное наименование получателя Гранта',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'shortTitle',
    title: 'Краткое наименование Центра НТИ',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 1,
    comment: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'fullTitle',
    title: 'Полное наименование Центра НТИ',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 1,
    comment: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'technologyName',
    hideDetails: true,
    title: 'Наименование "Сквозной технологии"',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'text',
  //   name: 'curatorCutr',
  //   hideDetails: true,
  //   title: 'Куратор ЦУТР (ФИО)',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: 0,
  //   rules: [['required']],
  //   sort: 1,
  //   comment: '',
  //   isEdit: false,
  // },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    name: 'confirmProgramDate',
    hideDetails: true,
    title: 'Дата утверждения программы',
    options: [],
    isDate: true,
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'date',
  //   yearPicker: true,
  //   name: 'programYear',
  //   hideDetails: true,
  //   title: 'Год программы',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: 0,
  //   rules: [['required']],
  //   sort: 1,
  //   comment: '',
  //   isEdit: false,
  // },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'number',
  //   name: 'programNumber',
  //   hideDetails: true,
  //   title: 'Номер программы*',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: 0,
  //   rules: [['required']],
  //   sort: 1,
  //   comment: '',
  //   isEdit: false,
  // },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'granteeAddress',
    hideDetails: true,
    title: 'Фактический адрес',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'legalAddress',
    hideDetails: true,
    title: 'Юридический адрес',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'text',
    name: 'addressCorrespondence',
    hideDetails: true,
    title: 'Адрес',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'text',
    name: 'fullnameCorrespondence',
    title: 'ФИО',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'text',
    name: 'phoneNumberCorrespondence',
    title: 'Телефон',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeINN',
    title: 'ИНН получателя',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeKPP',
    title: 'КПП получателя',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'programName',
    title: 'Наименование программы',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'startFinanceYear',
    title: 'Год начала финансирования',
    options: '',
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'endFinanceYear',
    title: 'Год завершения финансирования',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'endProjectYear',
    title: 'Год завершения постмониторинга',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    isTemplate: true,
    name: 'granteeCompanies',
    title: 'Наименование организации - Участник консорциума - получатель гранта (при наличии)',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'supportKind',
    title: 'Форма поддержки',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'textarea',
  //   name: 'statusCode',
  //   title: 'Статус реализации проекта (код)',
  //   options: [],
  //   default_value: '',
  //   descr: '',
  //   note: '',
  //   multiple: true,
  //   rules: [],
  //   sort: 1,
  // },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'textarea',
  //   name: 'contractNumber',
  //   title: 'Номер договора',
  //   options: [],
  //   default_value: '',
  //   descr: '',
  //   note: '',
  //   multiple: true,
  //   rules: [],
  //   sort: 1,
  // },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'textarea',
  //   name: 'contractDate',
  //   title: 'Дата договора',
  //   isDate: true,
  //   options: [],
  //   default_value: '',
  //   descr: '',
  //   note: '',
  //   multiple: true,
  //   rules: [],
  //   sort: 1,
  // },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'grantRecipientName',
    title: 'В лице кого представлен получатель',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeRepresentativeJustification',
    title: 'На основании чего действует представитель получателя',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'granteeEmail',
    title: 'Эл. почта получателя',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'granteeOGRN',
    title: 'ОГРН получателя',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'granteeOKTMO',
    title: 'ОКТМО получателя',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'textarea',
    name: 'grantAmountFull',
    title: 'Размер гранта (цифрами) общий',
    options: [],
    isAmount: true,
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'textarea',
    name: 'grantAmountFullText',
    title: 'Размер гранта (прописью) общий',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    name: 'annualGrantAmounts',
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountBIK',
    title: 'БИК',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountBankName',
    title: 'Наименование банка',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountSingleTreasuryAccount',
    title: 'Единый казначейский счет',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountTreasuryAccount',
    title: 'Казначейский счет',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountPersonalAccount',
    title: 'л/с',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountTreasuryDepartmentName',
    title: 'Наименование Управления Федерального казначейства',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
])

async function getCenterNtiList() {
  try {
    await api
      .projects()
      .getCenterNtiList()
      .then((data) => {
        centerNti.value = data
      })
  } catch (err) {
    console.log(err)
  }
}

async function getFiles() {
  try {
    const params = route.params?.id ? { entityId: route.params?.id } : null
    await api
      .files()
      .getFiles(bucket.value, params)
      .then((data) => {
        const filesList = data?.filesInFolders
        if (filesList && WINNER_FOLDER in filesList) {
          files.value = Object.values(filesList[WINNER_FOLDER])
        } else {
          files.value = []
        }
      })
  } catch (err) {
    toast.error(err)
  }
}

async function downloadFile(id, name) {
  try {
    await api
      .files()
      .downloadFile(id)
      .then((data) => {
        downloadFileWithLinkData(data, name)
      })
  } catch (err) {
    toast.error(err)
  }
}

function fillAllFields(data) {
  let granteeCompaniesFields = []
  let annualGrantAmountsFields = []
  fieldsForm.value.forEach((el) => {
    const value = data[el.name]

    if (el.name === 'annualGrantAmounts') {
      value?.forEach((el) => {
        const amountFiled = {
          ...ANNUAL_GRANT_AMOUNT_FILED,
          id: uuidv4(),
          group_id: 1,
          default_value: el.amount,
          isAmount: true,
          title: ANNUAL_GRANT_AMOUNT_FILED.title + ' ' + el.year,
        }

        const amountTextField = {
          ...ANNUAL_GRANT_AMOUNT_TEXT_FILED,
          id: uuidv4(),
          group_id: 1,
          title: ANNUAL_GRANT_AMOUNT_TEXT_FILED.title + ' ' + el.year,
          default_value: el.amountText,
        }

        annualGrantAmountsFields = [...annualGrantAmountsFields, amountFiled, amountTextField]
      })
      return
    }

    if (el.name === 'granteeCompanies') {
      value?.reverse().forEach((el) => {
        const granteeCompaniesField = {
          ...GRANTEE_COMPANIES,
          id: uuidv4(),
          group_id: 0,
          default_value: el.name,
        }

        granteeCompaniesFields.push(granteeCompaniesField)
      })
      return
    }

    return (el.default_value = value)
  })

  annualGrantAmountsFields.forEach((el) => {
    fieldsForm.value.push(el)
  })

  const positionGranteeCompanies = fieldsForm.value?.findIndex(
    (el) => el.name === 'granteeCompanies',
  )

  granteeCompaniesFields?.forEach((el) => {
    fieldsForm.value.splice(positionGranteeCompanies, 0, el)
  })
}

async function getProjectData() {
  isLoading.value = true
  try {
    await api
      .projects()
      .getProjectData(route?.params?.id)
      .then((data) => {
        project.value = data
        if (data) {
          fillAllFields(data)
        }

        if (data.status === PROJECT_REQUEST_STATUSES.REJECTED) {
          getSavedData()
        }
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function getFiledGroup(idGroup) {
  return fieldsForm.value.filter((el) => el.group_id === idGroup)
}

async function getSavedData() {
  isLoading.value = true
  try {
    await api
      .stash()
      .getStashData(hash.value)
      .then((data) => {
        if (Object.keys(data).length) {
          fillAllFields(data)
        }
      })
  } catch (err) {
    toast.error(err)
  } finally {
    isLoading.value = false
  }
}

function redirectBack() {
  return router.back()
}

function getValue(item) {
  if (item.name === 'fullTitle') {
    const centerNtiId =
      project.value && 'centerNtiId' in project.value ? project.value['centerNtiId'] : null
    const center = centerNti.value?.find((el) => el?.id === centerNtiId)
    return center?.fullTitle
  }

  if (item.isAmount) {
    return item.default_value ? String(item.default_value)?.replace('.', ',') : ''
  }

  if (item.name === 'shortTitle') {
    const centerNtiId =
      project.value && 'centerNtiId' in project.value ? project.value['centerNtiId'] : null
    const center = centerNti.value?.find((el) => el?.id === centerNtiId)

    return center?.shortTitle
  }

  if (item.isDate) {
    return item.default_value ? moment(item.default_value).format('DD.MM.YYYY') : ''
  }

  if (item.name === 'supportKind') {
    return SUPPORT_KIND.find((el) => el.id === item.default_value)?.name
  }

  if (item.name === 'statusCode') {
    return STATUS_CODE.find((el) => el.id === item.default_value)?.name
  }

  return item.default_value
}

function setProgramNumber() {
  const center = centerNti.value?.find((el) => el?.id === project.value?.centerNtiId)
  if (center) {
    programNumber.value = center.programNumber
  }
}

watch(project, () => {
  if ((project.value, centerNti.value)) {
    setProgramNumber()
  }
})

watch(centerNti, () => {
  if ((project.value, centerNti.value)) {
    setProgramNumber()
  }
})

onMounted(() => {
  getCenterNtiList()
  getProjectData()
  getFiles()

  setTimeout(() => {
    const hash = window.location.hash.substr(1)
    if (hash) {
      document.getElementById(hash).scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, 500)
})
</script>
<style lang="scss">
.custom-inputs {
  .v-field__input {
    height: 56px;
  }
}
</style>
