<template>
  <the-container>
    <template #title> Анкеты </template>
    <QuestionnaireTable
      :all-items="allResults"
      :items="result"
      :form-fields="formFields"
      @update-questionnaire="loadData"
      @filter-result="filterResult"
      @filter-questionnaire="loadFilterData"
    />
  </the-container>
</template>

<script>
export default {
  name: 'QuestionnairesView',
}
</script>

<script setup>
import QuestionnaireTable from '@/components/Questionnaire/QuestionnaireTable'
import TheContainer from '@/components/TheContainer'
import { api } from '@/api/Api'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const result = ref([])
const allResults = ref([])
const formFields = ref([])
const page = ref(1)
const formIdValue = ref()

function loadFilterData(status) {
  allResults.value = []
  loadData(1, status)
}

async function loadData(page = 1, status) {
  const {
    items,
    _meta: { pageCount },
  } = await api.result().getResults(page, status)
  allResults.value = [...allResults.value, ...items].filter(Boolean)

  if (pageCount && pageCount !== page) {
    await loadData(++page, status)
    return
  }

  const contestId = route.query?.contest_id
  if (!contestId) {
    result.value = allResults.value
    return
  }

  result.value = allResults.value.filter((item) => item.contest.id === Number(contestId))
}

function filterResult(formId) {
  result.value = allResults.value.filter((el) => el?.forma?.id === formId)
  formFields.value = []

  if (formId) {
    page.value = formId && formId !== formIdValue.value ? 1 : page.value
    formIdValue.value = formId
    getFieldsById(formId)
  } else {
    page.value = 1

    loadData()
  }
}

async function getFieldsById(formId) {
  try {
    await api
      .formFields()
      .getFieldsByFormID(formId, page.value)
      .then((data) => {
        formFields.value = [...data?.items, ...formFields.value]
        if (data?._meta.pageCount > page.value) {
          page.value = page.value + 1
          getFieldsById(formIdValue.value, data?._meta.currentPage + 1)
        }
      })
  } catch (err) {
    console.log(err)
  }
}

onMounted(loadData)
</script>
