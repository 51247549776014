<template>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <the-tabs v-else :tabs="tabs" :reports="reports" />
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import { api } from '@/api/Api'

import { useToast } from '@/composables/useToast'

import TheTabs from '@/components/TheTabs'
import ReportCenterNtiPreview from '@/views/Reports/ReportCenterNtiPreview'
import ReportsList from '@/views/Reports/ReportsList'

const toast = useToast()

const tabs = ref([
  {
    id: uuidv4(),
    title: 'Центры НТИ',
    component: ReportCenterNtiPreview,
  },
  {
    id: uuidv4(),
    title: 'Отчеты',
    component: ReportsList,
  },
])
const reports = ref([])
const isLoading = ref(false)

async function getReports() {
  isLoading.value = true
  try {
    await api
      .reports()
      .getReports()
      .then((data) => {
        if (data) {
          reports.value = data
        }
      })
  } catch (err) {
    toast.error(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getReports()
})
</script>
