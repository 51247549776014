<template>
  <the-container>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <template v-else>
      <div class="m2-6 my-md-6">
        <h3 class="ma-0">{{ projectPointData?.name }}</h3>
        <div
          v-if="projectPointData?.description"
          class="py-2 my-2"
          style="font-size: 18px"
          v-html="projectPointData?.description"
        />
      </div>
      <ProjectForm v-if="isEditedForm" :is-vote="isVoteShowForm" :point-data="projectPointData" />
      <TaskPreview
        v-else-if="projectPointData"
        :point-data="projectPointData"
        @update="getProjectPoint"
      />
    </template>
  </the-container>
</template>
<script setup>
import TheContainer from '@/components/TheContainer'
import { api } from '@/api/Api'
import { useRoute } from 'vue-router/dist/vue-router'
import { computed, onMounted, ref } from 'vue'
import TaskPreview from '@/components/Tasks/TaskPreview'
import { POINT_TYPE_EXPERT, POINTS_STATUSES } from '@/constants/points'
import ProjectForm from '@/components/Project/ProjectForm.vue'

const route = useRoute()

const projectPointData = ref(null)
const isLoading = ref(false)

const isVoteShowForm = computed(() => projectPointData.value?.type === POINT_TYPE_EXPERT)
const isEditedForm = computed(
  () =>
    projectPointData.value?.status?.code1C === POINTS_STATUSES.NEW ||
    projectPointData.value?.status?.code1C === POINTS_STATUSES.FILLING ||
    projectPointData.value?.status?.code1C === POINTS_STATUSES.NEEDS_IMPROVEMENTS ||
    projectPointData.value?.status?.code1C === POINTS_STATUSES.SUSPENDED,
)

async function getProjectPoint() {
  isLoading.value = true
  try {
    await api
      .projects()
      .getPointById(route.params.id)
      .then((data) => {
        projectPointData.value = data
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getProjectPoint()
})
</script>
