<template>
  <the-container>
    <v-row v-if="!canSendForm" justify="center">
      <v-col cols="auto">
        <v-card-title>{{ infoNotCanSendForm.message }}</v-card-title>
        <v-btn color="primary" :block="true" @click="$router.push(infoNotCanSendForm.buttonLink)">
          {{ infoNotCanSendForm.buttonTitle }}
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="canSendForm">
      <h1 class="container__title">
        {{ formData.title }}
      </h1>
      <FormMain
        ref="formRef"
        :key="formKey"
        :data="fields"
        :can-no-validate="noValidate"
        @get-forms="sendForm"
      />
      <v-row>
        <v-col cols="auto">
          <v-btn color="primary" @click="getForm(true)"> Отправить на рассмотрение </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" @click="getForm(false)"> Сохранить черновик </v-btn>
        </v-col>
      </v-row>
    </div>
  </the-container>
</template>
<script setup>
import { api } from '@/api/Api'
import { computed, nextTick, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from '@/composables/useToast'
import FormMain from '@/components/Form/FormMain'
import { DRAFT_STATUS, NEW_STATUS, REJECT_STATUS } from '@/utils/constants'
import { useForms } from '@/composables/forms/useForms'
import { useStore } from 'vuex'
import { useLoading } from '@/composables/useLoading'
import TheContainer from '@/components/TheContainer'

const route = useRoute()
const router = useRouter()
const { toast } = useToast()

const store = useStore()
const { getValuesByFields, prepareSource } = useForms()
const { showLoading } = useLoading()

const formData = ref(null)
const formRef = ref(null)
const isNew = ref(false)
const formKey = ref(1)
const activeResult = ref(null)
const noValidate = ref(false)

const fields = computed({
  get() {
    const { fields } = formData.value
    const sortedFields = fields.sort((a, b) => a.sort - b.sort)
    return sortedFields ?? []
  },
  set(value) {
    formData.value.fields = value
  },
})

const contest = computed(() => formData.value?.contest ?? null)
const canSendForm = computed(() => {
  return (
    formData.value?.contest_status?.end === 'N' &&
    formData.value?.active === 1 &&
    !activeResult.value
  )
})
const currentUserId = computed(() => store.getters['user/currentUserId'])
const successMessage = computed(
  () =>
    formData.value.success_message ||
    contest.value.success_message ||
    'Анкета отравлена на рассмотрение',
)

const infoNotCanSendForm = computed(() => {
  if (!activeResult.value) {
    return {
      message: 'Подача анкет на данную форму не доступна',
      buttonTitle: 'Вернутся на главную',
      buttonLink: '/',
    }
  }

  return {
    message: 'Анкета на данную форму уже заполнена',
    buttonTitle: 'Просмотреть анкету',
    buttonLink: `/questionnaire/${activeResult.value.id}`,
  }
})

async function loadForm() {
  const { id } = route.params
  const params = new URLSearchParams({
    user_form: 'Y',
  }).toString()

  try {
    formData.value = await api.form().getFormById(id, `?${params}`)
    const { source_result: sourceResult } = formData.value
    if (!sourceResult) {
      return
    }

    const questionnaire = await api.result().getResultById(sourceResult)
    const source = prepareSource(questionnaire)
    fields.value = getValuesByFields(source, fields.value)
    setTimeout(() => {
      formKey.value += 1
    }, 300)
  } catch (err) {
    toast.error(err.message)
  }
}

function getForm(bool) {
  noValidate.value = !bool
  nextTick(() => {
    if (formRef.value) {
      formRef.value.getForm()
      isNew.value = bool
    }
  })
}

function getSortGroupFields(fields) {
  const fieldsValue = fields.filter((el) => !!el.group_id)
  const map = fieldsValue.reduce((r, i) => {
    r[i.group_id] = r[i.group_id] || []
    r[i.group_id].push(i)
    return r
  }, {})

  const sortFields = []
  for (const key in map) {
    sortFields.push(map[key])
  }

  return sortFields
}

function getSortMultipleFields(fields) {
  const map = fields.reduce((r, i) => {
    r[i.name] = r[i.name] || []
    r[i.name].push(i)
    return r
  }, {})

  const sortFields = []
  for (const key in map) {
    sortFields.push(map[key])
  }

  return sortFields
}

async function sendForm(inputs, valid) {
  if (!valid) {
    return
  }
  const { id: formId } = { ...formData.value }
  const { id: contestId } = { ...contest.value }
  const formBody = new FormData()
  formBody.append('contest_id', contestId)
  formBody.append('form_id', formId)
  formBody.append('status', isNew.value ? NEW_STATUS : DRAFT_STATUS)

  const sortFields = getSortGroupFields(inputs)
  const defaultFields = inputs.filter((el) => !el.group_id && el.type !== 'group')

  let startIndexGroup = 10
  sortFields?.forEach((items, indexGroup) => {
    let indexGroupValue = indexGroup ? startIndexGroup + indexGroup : startIndexGroup

    const multipleSortValue = getSortMultipleFields(items)

    multipleSortValue.forEach((sortField) => {
      sortField.forEach((field, index) => {
        const indexField = index < startIndexGroup ? `0${index}` : `${index}`

        const filedName = field.group_id
          ? `fields[${field.name}][${Number(`${indexGroupValue}${indexField}`)}]`
          : `fields[${field.name}]`

        const filedValue =
          field.type === 'file'
            ? field.default_value[0] || field.fileUrl || ''
            : field.default_value ?? ''
        formBody.append([filedName], filedValue)
      })
    })
  })

  defaultFields.forEach((field) => {
    const filedValue =
      field.type === 'file'
        ? field.default_value[0] || field.fileUrl || ''
        : field.default_value ?? ''
    formBody.append(`fields[${[field.name]}]`, filedValue)
  })

  try {
    const { id } = await api.result().sendResultByForm(formBody)
    const message = isNew.value ? successMessage.value : 'Черновик сохранен'
    toast.success(message)
    await router.push(`/questionnaire/${id}`)
  } catch ({ response }) {
    const { data, status } = response
    if (status === 500) {
      const message = 'Возникла ошибка, попробуйте повторить позднее'
      toast.error(message)
    } else {
      data.forEach((msg) => {
        toast.error(msg.message)
      })
    }
  }
}

function getActiveResult(results) {
  activeResult.value = results.find((result) => result.status !== REJECT_STATUS) ?? null
}

async function loadActiveResult() {
  const { id: formId } = route.params
  const { items } = await api
    .result()
    .getActiveResultsByFormIdAndUserId(formId, currentUserId.value)
  getActiveResult(items)
}

async function init() {
  showLoading()
  await loadActiveResult()
  if (!activeResult.value) {
    await loadForm()
  }
  showLoading(false)
}

onMounted(() => {
  init()
})
</script>
<style lang="scss" scoped>
.container {
  width: 1174px;
  margin: 0 auto;
  display: block;
  padding-top: 24px !important;
  padding-bottom: 80px !important;

  &__title {
    margin-top: 24px;
    margin-bottom: 24px;
    line-height: 1em;
  }

  @media (max-width: 1279.95px) {
    width: 100%;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    &__title {
      margin-top: 16px;
    }
  }

  @media (max-width: 599.95px) {
    padding-left: 15px;
    padding-right: 15px;
    &__title {
      font-size: 24px !important;
      line-height: 28px;
      margin-bottom: 24px !important;
    }
  }
}
</style>
