<template>
  <the-container>
    <template v-if="isManager || isMonitor">
      <reports-category-list />
    </template>
    <div v-if="isWinner" :key="activeTab">
      <v-col class="pa-0 ma-0">
        <div class="mb-6">
          <status-block
            :key="currentReport?.id"
            :title="getFormatDate()"
            icon="alert"
            is-reverse-row
            :class-name="getTimeStatusReport(reportStatus?.color)"
            :status="getLabels()"
            :period="getPeriodReport()"
          />
        </div>
        <report-changes-archive :key="currentReport?.id" :count="5" />

        <v-col v-if="currentReport" class="px-0">
          <RouterLink to="/report/archive-of-changes" class="text-blue-darken-4">
            Архив
          </RouterLink>
        </v-col>
        <div v-if="currentReport" class="text-subtitle-2 font-weight-bold text-blue mt-2">
          <div
            class="mb-4 text-blue-darken-4 text-decoration-underline cursor-pointer font-weight-medium text-body-1"
            @click="openDocument"
          >
            Структура расходов средств гранта
          </div>
        </div>
        <DataTableDialog
          ref="dataTableDialog"
          :dialog="dataTableDialog"
          title="Структура расходов средств гранта"
        >
          <div>
            <table
              v-if="Object.keys(paymentOrdersStructure)?.length > 1"
              class="main-table text-caption"
            >
              <tr>
                <th>№</th>
                <th>Наименование Гранта</th>
                <th>Плановая сумма расходов, руб</th>
                <th>Расход гранта на текущую дату, руб.</th>
                <th>Процент израсходованного гранта, %</th>
                <th>Сумма гранта на год, перечисленная центру, руб.</th>
              </tr>

              <template v-for="(yearData, index) in Object.keys(paymentOrdersStructure)">
                <template v-if="Object.keys(paymentOrdersStructure)?.length && !!+yearData">
                  <tr :key="`year-${index}`">
                    <td colspan="6">Грант {{ Object.keys(paymentOrdersStructure)[index] }}</td>
                  </tr>
                  <tr
                    v-for="(item, itemIndex) in filterPaymentOrdersStructure(yearData)"
                    :key="item.expenditureType"
                    class="mt-1"
                  >
                    <td
                      v-if="!itemIndex"
                      :rowspan="Object.values(paymentOrdersStructure[yearData]).length - 1"
                    >
                      {{ index }}
                    </td>
                    <td class="text-left">{{ getLabelStructure(item.expenditureType) }}</td>
                    <td>
                      {{ item?.plannedAmount ? getNumberWithSpaces(item?.plannedAmount) : '0' }}
                    </td>
                    <td>{{ getNumberWithSpaces(item.amount) }}</td>
                    <td>{{ item?.wastePercent ? `${item?.wastePercent}%` : '' }}</td>
                    <td
                      v-if="!itemIndex"
                      :rowspan="Object.values(paymentOrdersStructure[yearData]).length - 1"
                    >
                      {{ getNumberWithSpaces(paymentOrdersStructure[yearData].all?.amount) }}
                    </td>
                  </tr>
                </template>
              </template>
            </table>
            <v-row v-else class="justify-center align-center py-12 text-caption text-grey">
              Нет данных
            </v-row>
          </div>
        </DataTableDialog>
      </v-col>
      <period-reports @change-report="setActiveTab" />
    </div>
  </the-container>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

import { api } from '@/api/Api'

import { useToast } from '@/composables/useToast'

import {
  getNumberWithSpaces,
  getLabelStructure,
  getReportPeriod,
  getTimeStatusReport,
  getReportPeriodById,
} from '@/helpers'
import { REPORTS_TYPES, REPORT_STATUS_LABELS, REPORTS_STATUSES } from '@/constants'

import ReportsCategoryList from '@/views/Reports/ReportsCategoryList'
import PeriodReports from '@/views/NtiInformation/PeriodReports'

import TheContainer from '@/components/TheContainer'
import StatusBlock from '@/components/Common/StatusBlock'
import DataTableDialog from '@/components/Common/DataTableDialog'
import ReportChangesArchive from '@/components/Reports/ReportChangesArchive'
import moment from 'moment'

const store = useStore()
const { toast } = useToast()

const currentReport = computed(() => store.getters['reports/currentReport'])
const isManager = computed(() => store.getters['user/isManager'])
const isMonitor = computed(() => store.getters['user/isMonitor'])
const isWinner = computed(() => store.getters['user/isWinner'])

const dataTableDialog = ref(null)
const activeTab = ref(0)
const paymentOrdersStructure = ref(null)
const reportStatus = ref(null)

function openDocument() {
  if (dataTableDialog.value) {
    dataTableDialog.value.openDialog()
  }
}

function getLabels() {
  if (currentReport.value?.status === REPORTS_STATUSES.DRAFT) {
    return 'Ожидание отчета'
  }

  if (currentReport.value?.status in REPORT_STATUS_LABELS) {
    return REPORT_STATUS_LABELS[currentReport.value?.status]
  }

  return 'Ожидание отчета'
}

function getTemplateReportLabel() {
  switch (activeTab.value) {
    case 3:
      return 'Годовой отчет'
    default:
      return `Отчет за ${getReportPeriodById(activeTab.value)} квартал`
  }
}

async function getReports() {
  try {
    await store.dispatch('reports/getReports')
  } catch (err) {
    toast.error(err)
  }
}

async function getGrantSpendingStructure() {
  try {
    await api
      .reports()
      .getGrantSpendingStructure(currentReport.value?.id)
      .then((data) => {
        paymentOrdersStructure.value = data
      })
  } catch (err) {
    toast.error(err)
  }
}

function getPeriodReport() {
  if (!currentReport.value?.type) {
    return getTemplateReportLabel()
  }

  if (currentReport.value?.type === REPORTS_TYPES.ANNUAL) {
    return `Годовой отчет ${currentReport.value?.year} года`
  }
  return `Отчет за ${getReportPeriod(currentReport.value?.type)} квартал ${
    currentReport.value?.year
  } года`
}

function filterPaymentOrdersStructure(year) {
  return Object.values(paymentOrdersStructure.value[year]).filter(
    (el) => el.expenditureType !== 'all',
  )
}

async function getReportStatus() {
  try {
    let reportType = currentReport.value?.type
    if (!currentReport.value?.id) {
      reportType = getReportType()
    }
    await api
      .reports()
      .getReportStatus(currentReport.value?.id, reportType)
      .then((data) => {
        reportStatus.value = data
      })
  } catch (err) {
    console.log(err)
  }
}

function getFormatDate() {
  return `${moment(reportStatus.value?.begin || currentReport.value?.updatedAt)
    .lang('ru')
    .format('DD MMMM YYYY')} - ${moment(reportStatus.value?.end || currentReport.value?.endDate)
    .lang('ru')
    .format('DD MMMM YYYY')}`
}

function getReportType() {
  switch (activeTab.value) {
    case 0:
      return REPORTS_TYPES.FIRST_QUARTER
    case 1:
      return REPORTS_TYPES.SECOND_QUARTER
    case 2:
      return REPORTS_TYPES.THIRD_QUARTER
    case 3:
      return REPORTS_TYPES.ANNUAL
  }
}

watch(currentReport, () => {
  if (currentReport.value) {
    getGrantSpendingStructure()
  }
  getReportStatus()
})

function setActiveTab(val) {
  activeTab.value = val ? val : JSON.parse(localStorage.getItem('tab'))
}

watch(activeTab, () => {
  getReportStatus()
})

onMounted(() => {
  getReports()
})
</script>
