export const ANNUAL_GRANT_AMOUNT_FILED = {
  type: 'textarea',
  name: 'annualGrantAmounts',
  subField: 'amount',
  title: 'Размер гранта (цифрами) год',
  default_value: '',
  note: '',
  sort: 1,
}

export const ANNUAL_GRANT_AMOUNT_TEXT_FILED = {
  type: 'textarea',
  name: 'annualGrantAmounts',
  subField: 'amountText',
  title: 'Размер гранта (прописью) год',
  default_value: '',
  note: '',
  sort: 1,
}

export const GRANTEE_COMPANIES = {
  type: 'textarea',
  name: 'granteeCompanies',
  subField: 'name',
  title: 'Наименование организации - Участник консорциума - получатель гранта (при наличии)',
  options: [],
  default_value: '',
  note: '',
  sort: 1,
}
