export const TICKETS_STATUSES = {
  BLANK: 'blank',
  NEW: 'new',
  REVIEW: 'review',
  IMPROVE: 'improve',
  REPEAT_REVIEW: 'repeat-review',
  SEND_FOR_APPROVE: 'send-for-approve',
  MONITOR_ACCEPTED: 'monitor-accepted',
  SEND_WORK_GROUP: 'send-work-group',
  REJECTED: 'rejected',
  CONFIRMED_WORK_GROUP: 'confirmed-work-group',
  PARTIALLY_CONFIRMED_WORK_GROUP: 'partially-confirmed-work-group',
  END_IMPROVE: 'end-improve',
  END_CONFIRM: 'end-confirm',
  ACCEPTED: 'accepted',
}

export const TICKETS_LABELS = {
  [TICKETS_STATUSES.BLANK]: 'Черновик',
  [TICKETS_STATUSES.NEW]: 'Новый',
  [TICKETS_STATUSES.REVIEW]: 'На рассмотрении',
  [TICKETS_STATUSES.IMPROVE]: 'На доработке',
  [TICKETS_STATUSES.REPEAT_REVIEW]: 'Повторное рассмотрение',
  [TICKETS_STATUSES.SEND_FOR_APPROVE]: 'Передан Монитору',
  [TICKETS_STATUSES.MONITOR_ACCEPTED]: 'Заключение Монитора сформировано',
  [TICKETS_STATUSES.SEND_WORK_GROUP]: 'Передан РГ',
  [TICKETS_STATUSES.REJECTED]: 'Не согласован РГ',
  [TICKETS_STATUSES.CONFIRMED_WORK_GROUP]: 'Согласован РГ полностью',
  [TICKETS_STATUSES.PARTIALLY_CONFIRMED_WORK_GROUP]: 'Согласован РГ частично',
  [TICKETS_STATUSES.END_IMPROVE]: 'Итоговая доработка',
  [TICKETS_STATUSES.END_CONFIRM]: 'Итоговое согласование',
  [TICKETS_STATUSES.ACCEPTED]: 'Согласован',
}

export const TICKET_ROLE_STATUS = {
  WINNER: 'new',
  MANAGER: 'blank',
}
