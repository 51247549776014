<template>
  <the-container>
    <h2 class="mb-6">Центры НТИ</h2>
    <v-form class="w-100">
      <v-col class="align-stretch pa-0 ma-0">
        <v-col class="pa-0 ma-0" cols="6">
          <div>Номер программы центра НТИ*</div>
          <FormNumber v-model="programNumber" only-numbers />
        </v-col>
        <v-col class="pa-0 ma-0" cols="6">
          <div>Номер Центра НТИ в системе 1С*</div>
          <FormNumber v-model="codePpp" :rules="[['codePpp']]" />
        </v-col>
        <v-col class="pa-0 ma-0" cols="6">
          <div>Полное наименование Центра НТИ*</div>
          <FormText v-model="fullTitle" :rules="[['string', { max: '255' }]]" />
        </v-col>
        <v-col class="pa-0 ma-0" cols="6">
          <div>Краткое наименование Центра НТИ*</div>
          <FormText v-model="shortName" :rules="[['string', { max: '255' }]]" />
        </v-col>
        <v-col class="pa-0 ma-0">
          <v-btn color="primary" min-height="56px" @click="createCenterNti"> Добавить </v-btn>
        </v-col>
      </v-col>
    </v-form>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <template v-else>
      <v-row class="mt-8 pa-0 ma-0 flex-nowrap w-70 mb-2 border-bottom-line pb-2 font-weight-bold">
        <v-col class="flex-grow-1 mx-0" cols="2">Номер программы</v-col>
        <v-col class="flex-grow-1 mx-0" cols="2">Номер Центра НТИ в системе 1С</v-col>
        <v-col class="flex-grow-1 mx-0" cols="4">Полное наименование</v-col>
        <v-col class="flex-grow-1 mx-0" cols="2">Краткое наименование</v-col>
      </v-row>
      <v-row
        v-for="center in centersNti"
        class="pa-0 ma-0 flex-nowrap w-70 mb-2 border-bottom-line pb-2"
      >
        <v-col class="flex-grow-1 mx-0" cols="2">{{ center.programNumber }}</v-col>
        <v-col class="flex-grow-1 mx-0" cols="2">{{ center.codePpp }}</v-col>
        <v-col class="flex-grow-1 mx-0" cols="4">{{ center.fullTitle }}</v-col>
        <v-col class="flex-grow-1 mx-0" cols="2">{{ center.shortTitle }}</v-col>
        <button
          v-if="center.removeable"
          class="ml-2"
          type="button"
          @click="() => deleteCenter(center.id)"
        >
          <v-icon color="red" size="xs"> mdi-delete-outline </v-icon>
          <span class="ml-2 text-body-2 title text-red">Удалить</span>
        </button>
      </v-row>
    </template>
  </the-container>
</template>
<script setup>
import { onMounted, ref } from 'vue'

import { api } from '@/api/Api'

import { useToast } from '@/composables/useToast'

import TheContainer from '@/components/TheContainer'

import FormText from '@/components/Form/FormText'
import FormNumber from '@/components/Form/FormNumber'

const { toast } = useToast()

const centersNti = ref([])
const fullTitle = ref('')
const codePpp = ref(null)
const programNumber = ref('')
const shortName = ref('')
const isLoading = ref(false)

async function getCenterNtiList() {
  isLoading.value = true
  try {
    await api
      .projects()
      .getCenterNtiList()
      .then((data) => {
        centersNti.value = data
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function deleteCenter(id) {
  isLoading.value = true
  try {
    await api
      .projects()
      .deleteCenterNti(id)
      .then(() => {
        getCenterNtiList()
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function createCenterNti() {
  if (!fullTitle.value || !shortName.value || !programNumber.value || !codePpp.value) {
    return toast.error('Заполните все обязательные поля')
  }
  isLoading.value = true
  try {
    await api
      .projects()
      .createCenterNti(fullTitle.value, shortName.value, programNumber.value, codePpp.value)
      .then(() => {
        getCenterNtiList()
        fullTitle.value = null
        shortName.value = null
        programNumber.value = null
      })
  } catch (err) {
    if (err?.response?.data?.field === 'fullTitle') {
      return toast.error('Поле "Полное наименование Центра НТИ" должно быть уникальным')
    }
    if (err?.response?.data?.field === 'programNumber') {
      return toast.error('Поле "Номер программы центра НТИ" должно быть уникальным')
    }

    if (err?.response?.data?.field === 'shortTitle') {
      return toast.error('Поле "Краткое наименование Центра НТИ" должно быть уникальным')
    }

    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getCenterNtiList()
})
</script>
