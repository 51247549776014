<template>
  <div :key="links.length" class="w-100 mb-2 d-flex flex-column">
    <div class="text-caption mb-2 text-blue-darken-1 font-weight-bold line-height-1 w-100">
      {{ primaryTitle }}
    </div>
    <div v-if="links.length" :key="links.length" class="preview-container mt-4 w-100 mb-6">
      <div v-for="(file, index) in links" :key="file.id">
        <div class="preview-card d-flex">
          <div class="content flex-grow-1 d-flex w-100">
            <div class="text-body-2 font-weight-bold">
              {{
                Object.values(file)[0].originalFileName || file.originalFileName || file.fileName
              }}
            </div>
          </div>
          <v-row class="px-0 mx-0 justify-end flex-grow-0">
            <v-row v-if="file.signValid" class="pa-0 ma-0 mr-2 text-green d-flex align-center">
              <v-icon size="xs" color="green" class="mr-2">mdi-pen</v-icon>
              Файл подписан
            </v-row>
            <button class="mr-2" type="button" @click="handleDownloadClick(file)">
              <v-icon color="blue" size="xs"> mdi-download </v-icon>
              <span class="ml-2 text-body-2 title">Скачать</span>
            </button>
            <button class="ml-2" type="button" @click="() => handleDeleteFile(file, index)">
              <v-icon color="red" size="xs"> mdi-delete-outline </v-icon>
              <span class="ml-2 text-body-2 title text-red">Удалить</span>
            </button>
          </v-row>
        </div>
        <div v-if="file.managerComment" class="text-red text-caption w-100">
          {{ file.managerComment }}
        </div>
      </div>
    </div>
    <div v-if="files.length && defaultInput" class="preview-container mt-4">
      <div v-for="file in files" :key="file.name">
        <div class="preview-card">
          <div class="content">
            <div :title="file.name" class="text-body-2 font-weight-bold">
              {{ file.name }}
            </div>
          </div>
          <div>
            <button class="ml-2" type="button" @click="remove(files.indexOf(file))">
              <v-icon color="red" size="xs"> mdi-delete-outline </v-icon>
              <span class="ml-2 text-body-2 title text-red">Удалить</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <v-row class="pa-0 ma-0 w-100">
      <v-card class="wrapper-files" border="1" elevation="0">
        <div
          :class="['dropzone-container', isDragging ? 'bg-blue-light' : '']"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
        >
          <input
            v-if="showInput"
            :id="id"
            ref="file"
            type="file"
            :multiple="multiple"
            name="file"
            :rules="rulesCommon"
            class="hidden-input"
            :accept="fileTypes"
            @change="onChange"
          />

          <label :for="id" class="file-label">
            <div>
              <div class="title">Загрузите документ</div>
              <div class="description">или перенесите файл в эту область</div>
              <div v-if="maxSize" class="text-blue-grey-darken-4 text-caption mt-1">
                Максимальный размер файла {{ bytesForHuman(+maxSize) }}
              </div>
            </div>
          </label>
        </div>
      </v-card>
      <template v-if="parcelable">
        <v-icon id="info" color="grey" class="ml-2"> mdi-information-outline </v-icon>
        <v-tooltip activator="#info" location="bottom">
          При загрузке документа система распознает поля автоматически
        </v-tooltip>
      </template>
    </v-row>
    <v-row v-if="templateFileId > 0 && templateLink">
      <a class="ma-0 pa-3 text-decoration-underline text-blue cursor-pointer" :href="templateLink"
        >Скачать шаблон / инструкцию</a
      >
    </v-row>
    <v-row v-else-if="fileExampleId && parcelable">
      <v-row
        class="ma-0 pa-3 text-decoration-underline text-blue cursor-pointer"
        @click="() => $emit('download-example', fileExampleId)"
        >Скачать шаблон / инструкцию</v-row
      >
    </v-row>
  </div>
</template>
<script setup>
import { nextTick, ref, watch } from 'vue'

import { emit as baseEmit, props as baseProps } from '@/composables/form/usePropsForm'
import { useForm } from '@/composables/form/useForm'
import { useToast } from '@/composables/useToast'
import { findMaxNumber } from '@/helpers'

const { toast } = useToast()

const props = defineProps({
  ...baseProps,
  fileData: {
    type: String,
    default: '',
  },
  primaryTitle: {
    type: String,
    default: '',
  },
  links: {
    type: Array,
    default: () => [],
  },
  defaultInput: {
    type: Boolean,
    default: false,
  },
  maxSize: {
    type: Number,
    default: 20 * 1024 * 1024,
  },
  fileExampleId: {
    type: String,
    default: '',
    required: false,
  },
  parcelable: {
    type: Boolean,
    default: false,
  },
  signable: {
    type: Boolean,
    default: false,
  },
  fileTypes: {
    type: String,
    default: '',
  },
  hasError: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(baseEmit)
const files = ref([])
const isDragging = ref(false)
const file = ref(null)
const showInput = ref(true)

const { rulesCommon } = useForm(props, emit)

const handleDeleteFile = (file, index) => {
  files.value = files.value?.filter((el, fileIndex) => index !== fileIndex)
  return emit('delete-file', Object.values(file)[0].id || file.id, file?.attachCode)
}
const handleDownloadClick = (file) => {
  if (file.link) {
    let link = document.createElement('a')
    link.download = file.link
    link.href = file.link
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    return
  }

  emit(
    'download-file',
    Object.values(file)[0].id || file.id,
    Object.values(file)[0].originalFileName || '',
  )
}

function bytesForHuman(bytes, decimals = 2) {
  let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

  let i = 0

  for (i; bytes > 1024; i++) {
    bytes /= 1024
  }

  return parseFloat(bytes?.toFixed(decimals)) + ' ' + units[i]
}

function getFileType(file) {
  const m = file.name.match(/\.([^.]+)$/)
  return m && m[1]
}

function onChange(e) {
  let hasError = false
  if (!props.multiple && (props.links?.length || files.value?.length)) {
    return toast.error(`Данное поле поддерживает загрузку только одного файла`)
  }
  if (props.fileTypes) {
    Object.values(e.target.files)?.forEach((file) => {
      const mimeType = getFileType(file)
      if (!props.fileTypes.includes(mimeType)) {
        e.preventDefault()
        hasError = true
        return toast.error(
          `Неверный формат файла. Небходимо загрузить файл с расширением ${props.fileTypes}`,
        )
      }
    })
  }
  // e.preventDefault()
  if (hasError) {
    return
  }
  files.value = [...files.value, ...e.target?.files]
  showInput.value = false
  nextTick(() => {
    showInput.value = true
  })
  const filterSizeFiles = Object.values(e.target.files)?.filter((el) => {
    if (props.maxSize && el.size > props.maxSize) {
      toast.error(`Максимальный размер файла ${bytesForHuman(props.maxSize)}`)
      return null
    }

    return el
  })

  if (!filterSizeFiles?.length) {
    return
  }

  filterSizeFiles?.forEach((file, index) => {
    emit('update-signed-files', {
      value: file,
      id: props.id,
      index: getLastIndexLinks(index) + 1,
    })
  })
}

function remove(i) {
  files.value.splice(i, 1)
  if (document.getElementById(props.id)) {
    document.getElementById(props.id).value = ''
  }
}

function dragover(e) {
  e.preventDefault()
  isDragging.value = true
}

function dragleave() {
  isDragging.value = false
}

function getLastIndexLinks(index = 0) {
  const filterArr = props.links.map((object) => +object.valueIndex)
  return findMaxNumber(filterArr) + index || 0
}

function drop(e) {
  e.preventDefault()
  if (!props.multiple && (props.links?.length || files.value?.length)) {
    return toast.error(`Данное поле поддерживает загрузку только одного файла`)
  }
  Object.values(e.dataTransfer.files)?.forEach((el, index) => {
    if (!props.multiple && index) {
      return toast.error(`Данное поле поддерживает загрузку только одного файла`)
    }
    if (el.size > (props.maxSize || 20000000)) {
      toast.error(`Максимальный размер файла ${bytesForHuman(props.maxSize) || '20МБ'}`)
      return
    }

    const mimeType = getFileType(el)
    if (!props.fileTypes.includes(mimeType)) {
      return toast.error(`Неверный формат файла ${el.name}`)
    } else {
      files.value = [...files.value, el]
      emit('update-signed-files', {
        value: el,
        id: props.id,
        index: getLastIndexLinks() + index + 1,
      })
    }
  })

  isDragging.value = false
}

watch(props, () => {
  if (props.hasError) {
    files.value = []
  }
})
</script>

<style lang="scss" scoped>
.wrapper-files {
  max-width: 420px;
  width: 100%;
}

.dropzone-container {
  border: 1px solid var(--surface-500);
  background: var(--background-thead-table);
  border-radius: 4px;
  padding: 14px;

  &.bg-blue-light {
    background: var(--background-thead-table);
    border: 1px solid var(--border-th-table);
  }
}
.title {
  color: var(--blue-700);
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}

.description {
  font-size: 10px;
  line-height: 1.6;
  color: var(--gray-500);
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 20px;
  display: block;
  text-align: center;
  cursor: pointer;
}
.preview-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.preview-card {
  display: flex;
  padding: 5px 0;
}
.content {
  display: flex;
  flex: 1;
}
</style>
