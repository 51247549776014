<template>
  <DataTableDialog ref="dataTableDialog" :dialog="dataTableDialog" title="Учетные реестры">
    <the-tabs :tabs="tabs" />
  </DataTableDialog>
</template>
<script setup>
import { ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import DataTableDialog from '@/components/Common/DataTableDialog'
import IncomeRegister from '@/views/Reports/AccountingRegistriesParts/IncomeRegister'
import RegistrationRegisterUnderLicense from '@/views/Reports/AccountingRegistriesParts/RegistrationRegisterUnderLicense'
import RegistrationRegisterForEducation from '@/views/Reports/AccountingRegistriesParts/RegistrationRegisterForEducation'
import AccountRegisterByRid from '@/views/Reports/AccountingRegistriesParts/AccountRegisterByRid'
import AccountingRegisterCoFinancing from '@/views/Reports/AccountingRegistriesParts/AccountingRegisterCoFinancing'

import TheTabs from '@/components/TheTabs'
const dataTableDialog = ref(null)
const tabs = ref([
  {
    id: uuidv4(),
    title: 'Учётный реестр по образованию',
    component: RegistrationRegisterForEducation,
  },
  {
    id: uuidv4(),
    title: 'Учётный реестр по лицензиям',
    component: RegistrationRegisterUnderLicense,
  },
  {
    id: uuidv4(),
    title: 'Учётный реестр по доходам',
    component: IncomeRegister,
  },
  {
    id: uuidv4(),
    title: 'Учётный реестр по rid',
    component: AccountRegisterByRid,
  },
  {
    id: uuidv4(),
    title: 'Учётный реестр по софинансированию',
    component: AccountingRegisterCoFinancing,
  },
])

function openDocument() {
  if (dataTableDialog.value) {
    dataTableDialog.value.openDialog()
  }
}

defineExpose({
  openDocument,
})
</script>
