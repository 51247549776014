<template>
  <the-container>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>

    <the-tabs v-else :tabs="tabs" :projects="projects" @tab-click="openPage" />
  </the-container>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import TheContainer from '@/components/TheContainer'
import TheTabs from '@/components/TheTabs'
import GeneralInformation from '@/views/NtiInformation/GeneralInformation'
import Programs from '@/views/NtiInformation/ProgramsList'
import Agreements from '@/views/NtiInformation/AgreementsList'
import Consortia from '@/views/NtiInformation/ConsortiaList'
import CenterStaff from '@/views/NtiInformation/CenterStaff'
import ContactsGrantRecipients from '@/views/NtiInformation/ContactsGrantRecipients'
import { api } from '@/api/Api'
import { useToast } from '@/composables/useToast'
import { useRouter } from 'vue-router/dist/vue-router'

const { toast } = useToast()
const router = useRouter()

const projects = ref([])
const isLoading = ref(false)

const tabs = ref([
  {
    id: uuidv4(),
    title: 'Общие сведения',
    component: GeneralInformation,
  },
  {
    id: uuidv4(),
    title: 'Программы',
    component: Programs,
  },
  {
    id: uuidv4(),
    title: 'Договоры и ДС',
    component: Agreements,
  },
  {
    id: uuidv4(),
    title: 'Консорциумы',
    component: Consortia,
  },
  {
    id: uuidv4(),
    title: 'Сотрудники центров НТИ',
    component: CenterStaff,
  },
  {
    id: uuidv4(),
    title: 'Контакты грантополучателей',
    component: ContactsGrantRecipients,
  },
])

function openPage(tab) {
  router.replace(`/nti-information?tab=${tab}`)
}

async function getProjects() {
  isLoading.value = true
  try {
    await api
      .projects()
      .getProjects()
      .then((data) => {
        projects.value = data
      })
  } catch (err) {
    toast.error(err.message)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getProjects()
})
</script>
