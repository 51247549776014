<template>
  <v-col class="px-0 mx-0">
    <v-expansion-panels v-model="panel" class="pa-0 ma-0" accordion>
      <v-row
        v-if="!reportsList?.length"
        class="py-12 px-0 mx-0 text-caption text-grey text-center justify-center"
      >
        Список пуст
      </v-row>
      <v-expansion-panel
        v-for="(report, index) in reportsList"
        :key="`report-${report.id}-${index}`"
        class="pa-0 ma-0"
        elevation="0"
      >
        <v-expansion-panel-title
          class="pa-0 ma-0"
          color="white"
          hide-actions
          @click="() => getGrantSpendingStructure(report?.id)"
        >
          <status-block
            :title="report?.project?.centerNti?.fullTitle || report?.project?.centerNti?.shortTitle"
            :icon="getIcon(report?.status)"
            :status="REPORT_STATUS_LABELS[report.status]"
            :class-name="getTimeStatusReport(report?.color)"
            :period="getTitleReport(report)"
          />
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pa-0 ma-0" color="white">
          <div>
            <v-row v-if="isLoading" class="my-2 py-2 w-100 justify-center align-center">
              <v-progress-circular
                indeterminate
                color="primary"
                :size="20"
                class="ml-4"
                :width="3"
              />
            </v-row>
            <template v-else>
              <accordion-panel>
                <template #title>
                  <v-row
                    class="pa-0 ma-0 row-bordered flex-nowrap align-center justify-space-between flex-column flex-sm-row position-relative"
                    style="z-index: 9999"
                    @click="() => getHistory(report?.id)"
                  >
                    <v-col class="d-flex justify-start align-center py-6 px-0 mx-0">
                      {{ getFormatDateTime(report.updatedAt || report.createdAt) }}
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-6 px-0 mx-0">
                      {{ REPORTS_TYPES_LABEL[report.type] }}
                    </v-col>
                    <v-col class="d-flex justify-start align-center py-6 px-0 mx-0" cols="4">
                      {{ REPORT_STATUS_LABELS[report.status] }}
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-6 px-0 mx-0">
                      <RouterLink
                        v-if="report?.status !== REPORTS_STATUSES.DRAFT"
                        :to="`/reports/report-form/${report.id}`"
                      >
                        Перейти к отчету
                      </RouterLink>
                    </v-col>
                    <v-col class="d-flex justify-end align-center py-6 px-0 mx-0">
                      <div class="text-decoration-underline text-blue-darken-3 cursor-pointer">
                        История изменений
                      </div>
                    </v-col>
                  </v-row>
                </template>
                <template #text>
                  <v-col class="px-0 ma-0">
                    <v-row
                      v-if="isHistoryLoading"
                      class="my-2 py-2 w-100 justify-center align-center px-0 mx-0"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        :size="20"
                        class="ml-4"
                        :width="3"
                      />
                    </v-row>
                    <template v-else>
                      <template v-if="history.length">
                        <div class="text-body-1 font-weight-bold text-grey mb-4">
                          История изменений
                        </div>
                        <v-col
                          v-for="(item, index) in history"
                          :key="`text-${index}`"
                          class="pa-0 mb-3 my-0 mx-0"
                        >
                          <div class="text-caption text-grey text-uppercase">
                            {{ getFormatDate(item.createdAt || item.updatedAt) }}
                          </div>
                          <div
                            :class="['text-subtitle-2', index ? 'text-grey' : 'font-weight-bold']"
                          >
                            {{ getLabelHistoryItem(report) }} - {{ getActionReport(item?.action) }}
                          </div>
                        </v-col>
                      </template>
                      <v-col
                        v-else
                        class="text-caption text-grey justify-center align-center text-center"
                      >
                        История изменений пуста
                      </v-col>
                    </template>
                  </v-col>
                </template>
              </accordion-panel>

              <div class="text-subtitle-2 font-weight-bold text-blue">
                <v-btn
                  color="blue"
                  variant="text"
                  class="text-transform-none px-0 mt-6"
                  @click="openDocument"
                >
                  Структура расходов средств гранта
                </v-btn>
              </div>
            </template>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-col>
  <DataTableDialog
    ref="dataTableDialog"
    :dialog="dataTableDialog"
    title="Структура расходов средств гранта"
  >
    <div>
      <v-row
        v-if="Object.keys(paymentOrdersStructure)?.length < 2"
        class="align-center justify-center text-caption text-grey ma-0 mb-4"
      >
        Нет данных
      </v-row>
      <table v-else class="main-table text-caption">
        <tr>
          <th>№</th>
          <th>Наименование Гранта</th>
          <th>Плановая сумма расходов, руб</th>
          <th>Расход гранта на текущую дату, руб.</th>
          <th>Процент израсходованного гранта, %</th>
          <th>Сумма гранта на год, перечисленная центру, руб.</th>
        </tr>

        <template v-for="(yearData, index) in Object.keys(paymentOrdersStructure)">
          <template v-if="Object.keys(paymentOrdersStructure)?.length && !!+yearData">
            <tr :key="`year-${index}`">
              <td colspan="6">Грант {{ Object.keys(paymentOrdersStructure)[index] }}</td>
            </tr>
            <tr
              v-for="(item, itemIndex) in filterPaymentOrdersStructure(yearData)"
              :key="item.expenditureType"
              class="mt-1"
            >
              <td
                v-if="!itemIndex"
                :rowspan="Object.values(paymentOrdersStructure[yearData]).length - 1"
              >
                {{ index }}
              </td>
              <td class="text-left">{{ getLabelStructure(item.expenditureType) }}</td>
              <td>
                {{ item?.plannedAmount ? getNumberWithSpaces(item?.plannedAmount) : '0' }}
              </td>
              <td>{{ getNumberWithSpaces(item.amount) }}</td>
              <td>{{ item?.wastePercent ? `${item?.wastePercent}%` : '' }}</td>
              <td
                v-if="!itemIndex"
                :rowspan="Object.values(paymentOrdersStructure[yearData]).length - 1"
              >
                {{ getNumberWithSpaces(paymentOrdersStructure[yearData].all?.amount) }}
              </td>
            </tr>
          </template>
        </template>
      </table>
    </div>
  </DataTableDialog>
</template>
<script setup>
import { onMounted, ref } from 'vue'

import { api } from '@/api/Api'

import {
  getReportPeriod,
  getLabelStructure,
  getNumberWithSpaces,
  getFormatDate,
  getActionReport,
  getLabelHistoryItem,
  getTimeStatusReport,
  getFormatDateTime,
} from '@/helpers'

import { REPORTS_TYPES_LABEL, REPORT_STATUS_LABELS, REPORTS_STATUSES } from '@/constants'

import StatusBlock from '@/components/Common/StatusBlock'
import DataTableDialog from '@/components/Common/DataTableDialog'
import AccordionPanel from '@/components/Common/AccordeonPanel'
import { useToast } from '@/composables/useToast'

const { toast } = useToast

const reportsList = ref()
const history = ref([])
const panel = ref(['1'])
const isLoading = ref(false)
const isHistoryLoading = ref(false)
const paymentOrdersStructure = ref(null)
const dataTableDialog = ref(null)

const props = defineProps({
  reports: {
    type: Array,
    default: () => [],
  },
})

function openDocument() {
  if (dataTableDialog.value) {
    dataTableDialog.value.openDialog()
  }
}

function getIcon(status) {
  switch (status) {
    case 'accepted':
      return 'success'
    default:
      return 'alert'
  }
}

function filterPaymentOrdersStructure(year) {
  return Object.values(paymentOrdersStructure.value[year]).filter(
    (el) => el.expenditureType !== 'all',
  )
}

function getTitleReport(report) {
  const periodReport = getReportPeriod(report.type)
  const label = report.type === 'annual' ? 'Годовой отчет' : `Отчет за ${periodReport} квартал`
  return `${label} ${report.year} года`
}

async function getGrantSpendingStructure(report) {
  isLoading.value = true
  try {
    await api
      .reports()
      .getGrantSpendingStructure(report)
      .then((data) => {
        paymentOrdersStructure.value = data
      })
  } catch (err) {
    toast.error(err)
  } finally {
    setTimeout(() => {
      isLoading.value = false
    }, 500)
  }
}

async function getHistory(id) {
  isHistoryLoading.value = true
  history.value = []
  try {
    api
      .reports()
      .getHistoryReport(id)
      .then((data) => {
        history.value = data?.history
      })
  } catch (err) {
    console.log(err)
  } finally {
    setTimeout(() => {
      isHistoryLoading.value = false
    }, 500)
  }
}

async function getColorsReports(ids) {
  isLoading.value = true
  const params = new URLSearchParams()
  ids.forEach((player) => params.append('reportIds[]', player))
  try {
    await api
      .reports()
      .getColorsReports(params)
      .then((data) => {
        const newReportsList = props.reports?.map((el) => {
          const colorReports = data?.find((reportColor) => reportColor.reportId === el.id)
          return {
            ...el,
            color: colorReports.color,
          }
        })

        reportsList.value = newReportsList
      })
  } catch (err) {
    toast.error(err)
  } finally {
    setTimeout(() => {
      isLoading.value = false
    }, 500)
  }
}

onMounted(() => {
  if (props.reports?.length) {
    const ids = props.reports?.map((el) => el.id)
    getColorsReports(ids)
  }
})
</script>

<style lang="scss">
.info-wrapper {
  border-bottom: 1px solid var(--border-warning);
  background: var(--background-warning);
}

.success-wrapper {
  border-bottom: 1px solid var(--border-success);
  background: var(--backgroud-success);
}

.error-wrapper {
  border-bottom: 1px solid var(--border-error);
  background: var(--backgroud-error);
}

.pending-wrapper {
  border-bottom: 1px solid var(--border-pending);
  background: var(--backgroud-pending);
}
</style>
