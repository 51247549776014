export const MANAGER = 1
export const ADMIN = 2
export const ADMIN_COMPETITION = 3
export const MONITOR = 4
export const EXPERT = 5
export const WINNER = 7
export const USER = 8

export const roles = {
  [ADMIN]: 'Администратор',
  [MANAGER]: 'Менеджер',
  [ADMIN_COMPETITION]: 'Администратор конкурса',
  [MONITOR]: 'Монитор',
  [USER]: 'Пользователь',
  [WINNER]: 'Победитель',
  [EXPERT]: 'Эксперт',
}
