<template>
  <the-container>
    <h3 class="mb-6">Карточка Программы получателя поддержки</h3>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <template v-else>
      <v-row class="px-0 ma-a mb-4">
        <v-col class="pa-a ma-0">
          Номер программы:
          {{ programNumber }}
        </v-col>
      </v-row>
      <div class="custom-inputs">
        <div v-for="group in groups" :key="`group-${group.id}`" class="mb-6">
          <div class="text-subtitle-1 font-weight-bold text-grey-darken-1 mb-6">
            {{ group.title }}
          </div>
          <div>
            <div v-for="field in getFiledGroup(group.id)" :key="field.id">
              <v-row class="px-0 mx-0 align-end">
                <v-col cols="8" :class="['py-0 px-0 mx-0', isEditForm ? 'mb-9' : 'mb-3']">
                  <v-row
                    class="pa-0 ma-0 border-bottom-line text-grey-darken-1 word-break word-break-all"
                  >
                    <v-col class="px-0 mx-0 pr-6" cols="6">
                      {{ field.title }}
                    </v-col>
                    <v-col class="px-0 mx-0 word-break-all" cols="6">
                      {{ getValue(field) }}
                    </v-col>
                  </v-row>
                </v-col>
                <template v-if="showComent">
                  <v-col v-if="isEditForm" cols="4">
                    <Component
                      :is="inputs[getFormInput(field.type)]"
                      v-bind="field"
                      @change-value="sendHash"
                      @update-value="updateValue"
                    />
                  </v-col>
                  <v-col
                    v-else
                    cols="4"
                    :class="[
                      'border-bottom-line py-0 px-0 mx-0 mb-3 word-break-all',
                      !isEditForm && field.default_value && 'text-red border-red',
                    ]"
                  >
                    {{ field.default_value }}
                  </v-col>
                </template>
              </v-row>
            </div>
          </div>
        </div>
      </div>
      <div id="files">
        <div v-if="files.length" class="mb-4">
          <div class="font-weight-bold text-grey my-6">Прикрепленные документы</div>
          <div v-for="(fileGroup, i) in files" :key="`fileGroup-${i}`">
            <v-row v-for="file in fileGroup" :key="file.id" class="mx-0 px-0">
              <v-col class="ml-0 pl-0 word-break" cols="6">{{ file.originalFileName }}</v-col>
              <v-col
                class="text-decoration-underline text-blue-darken-4 cursor-pointer"
                cols="2"
                @click="() => downloadFile(file.id, file.originalFileName)"
              >
                Просмотреть
              </v-col>
            </v-row>
          </div>
        </div>
      </div>

      <v-col
        v-if="
          projectData?.managerComment && projectData?.status !== PROJECT_REQUEST_STATUSES.ACCEPTED
        "
        cols="8"
        class="w-100 px-0 mx-0"
      >
        <div class="text-body-1 mx-0 my-2">Комментарий Менеджера</div>
        <div class="text-body-1 mx-0 my-2 box-comment">
          {{ projectData?.managerComment }}
        </div>
      </v-col>
      <template
        v-if="
          projectData?.status !== PROJECT_REQUEST_STATUSES.ACCEPTED &&
          projectData?.status !== PROJECT_REQUEST_STATUSES.REJECTED
        "
      >
        <v-col v-if="projectData?.monitorComment" cols="8" class="w-100 px-0 mx-0">
          <div class="text-body-1 mx-0 my-2">Комментарий Монитора</div>
          <div class="text-body-1 mx-0 my-2 box-comment">
            {{ projectData?.monitorComment }}
          </div>
        </v-col>
      </template>
      <v-col v-if="isEditForm" cols="8" class="px-0 mx-0">
        <v-form ref="form" lazy-validation>
          <div v-if="commentManager.title" class="text-body-1 mx-0 my-2">
            {{ commentManager.title }}
          </div>
          <Component
            :is="inputs[getFormInput(commentManager.type)]"
            v-bind="commentManager"
            @change-value="updsteCommentManagerInStash"
            @update-value="updateValueCommentManager"
          />
        </v-form>
        <v-col class="mt-4 px-0">
          <div class="text-body-2 text-grey">* - поля обязательные для заполнения</div>
          <div class="text-body-2 text-grey mt-2">Введенные данные сохраняются автоматически</div>
        </v-col>
        <v-row class="px-0 mx-0 align-center mt-2">
          <v-btn
            v-if="projectData?.status === PROJECT_REQUEST_STATUSES.REVIEWED"
            depressed
            color="primary"
            variant="elevated"
            class="mr-4"
            @click="acceptProjectRequest"
          >
            Принять
          </v-btn>
          <v-btn
            depressed
            :color="projectData?.status === PROJECT_REQUEST_STATUSES.REVIEWED ? 'grey' : 'primary'"
            variant="elevated"
            class="mr-4"
            @click="sendForRevision"
          >
            На доработку
          </v-btn>
          <v-btn depressed color="primary" variant="outlined" @click="submitFormToMonitor">
            {{
              projectData?.status === PROJECT_REQUEST_STATUSES.REVIEWED
                ? 'Вернуть Монитору'
                : 'Передать Монитору'
            }}
          </v-btn>
          <v-btn
            class="ml-2 text-decoration-underline"
            color="primary"
            variant="text"
            @click="router.push('/coordination-documents')"
          >
            Закрыть
          </v-btn>
        </v-row>
      </v-col>
      <v-btn
        v-else
        class="px-0 text-decoration-underline"
        color="primary"
        variant="text"
        @click="onBack"
      >
        Закрыть
      </v-btn>
    </template>
  </the-container>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'

import { api } from '@/api/Api'

import { inputs, getFormInput, downloadFileWithLinkData } from '@/helpers/index'

import TheContainer from '@/components/TheContainer'

import { STATUS_CODE, SUPPORT_KIND, PROJECT_REQUEST_STATUSES } from '@/constants'

import { useToast } from '@/composables/useToast'
import { PROGRAM_FILES, TEMP } from '@/constants/buckets'
import { MONITOR_FOLDER, WINNER_FOLDER, MANAGER_FOLDER } from '@/constants/store'

const route = useRoute()
const router = useRouter()
const store = useStore()
const { toast } = useToast()

const showComent = computed(
  () =>
    requestStatus.value === PROJECT_REQUEST_STATUSES.REJECTED ||
    requestStatus.value === PROJECT_REQUEST_STATUSES.IMPROVED ||
    requestStatus.value === PROJECT_REQUEST_STATUSES.NEW ||
    requestStatus.value === PROJECT_REQUEST_STATUSES.REVIEWED,
)
const commentHash = computed(() => {
  return `comments-${store.getters['user/hash']}-${route.params?.id}`
})

const centerNti = ref([])
const programNumber = ref(null)
const requestStatus = ref('')
const isEditForm = ref(true)
const form = ref(null)
const commentManager = ref({
  id: uuidv4(),
  group_id: 0,
  type: 'textarea',
  name: 'comment',
  title: 'Комментарий Менеджера*',
  options: [],
  default_value: '',
  descr: '',
  note: '',
  multiple: true,
  rules: [['required']],
  sort: 1,
})

const groups = [
  {
    id: 0,
    title: 'Общие сведения',
  },
  {
    id: 1,
    title: 'Сведения о гранте',
  },
  {
    id: 2,
    title: 'Реквизиты',
  },
  {
    id: 3,
    title: 'Данные для отправки корреспонденции',
  },
]
const fieldsForm = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'grantRecipientName',
    title: 'Полное наименование получателя Гранта',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'shortTitle',
    title: 'Краткое наименование Центра НТИ',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 1,
    comment: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'fullTitle',
    title: 'Полное наименование Центра НТИ',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 1,
    comment: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'technologyName',
    title: 'Наименование "Сквозной технологии"',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'textarea',
  //   name: 'curatorCutr',
  //   title: 'Куратор ЦУТР (ФИО)',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: 0,
  //   rules: [['required']],
  //   sort: 1,
  //   comment: '',
  //   isEdit: false,
  // },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'confirmProgramDate',
    title: 'Дата утверждения программы',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    isDate: true,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'textarea',
  //   yearPicker: true,
  //   name: 'programYear',
  //   title: 'Год программы',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: 0,
  //   rules: [['required']],
  //   sort: 1,
  //   comment: '',
  //   isEdit: false,
  // },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'textarea',
  //   name: 'programNumber',
  //   title: 'Номер программы',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: 0,
  //   rules: [['required']],
  //   sort: 1,
  //   comment: '',
  //   isEdit: false,
  // },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'granteeAddress',
    title: 'Фактический адрес',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'legalAddress',
    title: 'Юридический адрес',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'textarea',
    name: 'addressCorrespondence',
    title: 'Адрес',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'textarea',
    name: 'fullnameCorrespondence',
    title: 'ФИО',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'textarea',
    name: 'phoneNumberCorrespondence',
    title: 'Телефон',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    comment: '',
    isEdit: false,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeINN',
    title: 'ИНН получателя',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required']],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeKPP',
    title: 'КПП получателя',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'programName',
    title: 'Наименование программы',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'startFinanceYear',
    title: 'Год начала финансирования',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'endFinanceYear',
    title: 'Год завершения финансирования',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'endProjectYear',
    title: 'Год завершения постмониторинга',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'supportKind',
    title: 'Форма поддержки',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'textarea',
  //   name: 'statusCode',
  //   title: 'Статус реализации проекта (код)',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: true,
  //   rules: [],
  //   sort: 1,
  //   first_value: '',
  // },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'textarea',
  //   name: 'contractNumber',
  //   title: 'Номер договора',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: true,
  //   rules: [],
  //   sort: 1,
  //   first_value: '',
  // },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: 'textarea',
  //   name: 'contractDate',
  //   isDate: true,
  //   title: 'Дата договора',
  //   options: [],
  //   default_value: null,
  //   descr: '',
  //   note: '',
  //   multiple: true,
  //   rules: [],
  //   sort: 1,
  //   first_value: '',
  // },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeRepresentativeName',
    title: 'В лице кого представлен получатель',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeRepresentativeJustification',
    title: 'На основании чего действует представитель получателя',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeEmail',
    title: 'Эл. почта получателя',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeOGRN',
    title: 'ОГРН получателя',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'granteeOKTMO',
    title: 'ОКТМО получателя',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'textarea',
    name: 'grantAmountFull',
    title: 'Размер гранта (цифрами) общий',
    options: [],
    isAmount: true,
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'textarea',
    name: 'grantAmountFullText',
    title: 'Размер гранта (прописью) общий',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountBIK',
    title: 'БИК',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountBankName',
    title: 'Наименование банка',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountSingleTreasuryAccount',
    title: 'Единый казначейский счет',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountTreasuryAccount',
    title: 'Казначейский счет',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountPersonalAccount',
    title: 'л/с',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'textarea',
    name: 'bankAccountTreasuryDepartmentName',
    title: 'Наименование Управления Федерального казначейства',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
    first_value: '',
  },
])
const files = ref([])
const projectId = ref(route.params?.id)
const isLoading = ref(false)
const projectData = ref(null)

const bucket = computed(() => (projectId.value ? PROGRAM_FILES : TEMP))

function getFiledGroup(idGroup) {
  return fieldsForm.value.filter((el) => el.group_id === idGroup)
}

function onBack() {
  router.back()
}

function updateValue({ value, id }) {
  fieldsForm.value.forEach((item) => {
    if (item.id === id) {
      item.default_value = value
    }
  })
}

async function getFiles() {
  try {
    const entityId = projectId.value ? { entityId: projectId.value } : null
    files.value = []
    await api
      .files()
      .getFiles(bucket.value, entityId)
      .then((data) => {
        const filesList = data?.filesInFolders
        if (filesList && WINNER_FOLDER in filesList) {
          files.value = [...files.value, ...Object.values(filesList[WINNER_FOLDER])]
        }

        if (filesList && MONITOR_FOLDER in filesList) {
          files.value = [...files.value, ...Object.values(filesList[MONITOR_FOLDER])]
        }

        if (filesList && MANAGER_FOLDER in filesList) {
          files.value = [...files.value, ...Object.values(filesList[MANAGER_FOLDER])]
        }
      })
  } catch (err) {
    toast.error(err)
  }
}

async function downloadFile(id, name) {
  try {
    await api
      .files()
      .downloadFile(id)
      .then((data) => {
        downloadFileWithLinkData(data, name)
      })
  } catch (err) {
    toast.error(err)
  }
}

async function getCenterNtiList() {
  try {
    await api
      .projects()
      .getCenterNtiList()
      .then((data) => {
        centerNti.value = data
      })
  } catch (err) {
    console.log(err)
  }
}

async function updateValueCommentManager({ value }) {
  commentManager.value.default_value = value
}

async function acceptProjectRequest() {
  try {
    await api
      .projects()
      .acceptProjectRequest(projectId.value)
      .then(() => {
        getProjectData()
      })
  } catch (err) {
    console.log(err)
  }
}

async function updsteCommentManagerInStash({ value }) {
  const params = {
    field: commentManager.value.name,
    value: value,
    hash: commentHash.value,
  }

  await api.stash().saveFieldInStash(params)
}

async function sendHash({ id, value }) {
  for (const item of fieldsForm.value) {
    let params = null
    if (item.id === id) {
      if (item?.group_name === 'granteeCompanies') {
        const arr = fieldsForm.value?.filter((el) => el?.group_name === 'granteeCompanies')
        const granteeCompaniesValue = arr.map((el) => {
          return {
            name: el.default_value ? el.default_value : '',
          }
        })

        params = {
          field: 'granteeCompanies',
          value: granteeCompaniesValue,
          hash: commentHash.value,
        }
      } else if (item?.group_name === 'annualGrantAmounts') {
        const annualGrantAmountsArr = fieldsForm.value.filter(
          (el) => el.group_name === 'annualGrantAmounts',
        )

        const annualGrantAmounts = []
        annualGrantAmountsArr.forEach((el) => {
          annualGrantAmounts[el?.index] = {
            ...annualGrantAmounts[el?.index],
            year: getYearAnnualGrantAmountsItem(el?.index),
            [el.name]: el.default_value ? el.default_value : '',
          }
        })

        params = {
          field: 'annualGrantAmounts',
          value: annualGrantAmounts,
          hash: commentHash.value,
        }
      } else {
        params = {
          field: item.name,
          value: value,
          hash: commentHash.value,
        }
      }

      await api.stash().saveFieldInStash(params)
    }
  }
}

function getYearAnnualGrantAmountsItem(index) {
  if (!projectData.value) {
    return ''
  }

  const findEl = projectData.value['annualGrantAmounts']
  return findEl ? findEl[index].year : ''
}

function getFirstValue(index, key) {
  if (!projectData.value) {
    return ''
  }

  const findEl = projectData.value['annualGrantAmounts']
  return findEl ? findEl[index][key] : ''
}

function addAnnualGrantAmountsFields(data) {
  data?.annualGrantAmounts?.forEach((el, index) => {
    const year = getYearAnnualGrantAmountsItem(index)
    const firstValueAmount = getFirstValue(index, 'amount')
    const firstValueAmountText = getFirstValue(index, 'amountText')
    const newValueAmout = {
      first_value: firstValueAmount,
      id: uuidv4(),
      group_id: 1,
      type: 'textarea',
      name: 'amount',
      isAmount: true,
      group_name: 'annualGrantAmounts',
      title: `Размер гранта (цифрами) год ${year || ''}`,
      index: index,
    }

    const newValueTextAmout = {
      first_value: firstValueAmountText,
      id: uuidv4(),
      group_id: 1,
      type: 'textarea',
      name: 'amountText',
      group_name: 'annualGrantAmounts',
      title: `Размер гранта (прописью) год ${year || ''}`,
      index: index,
    }
    fieldsForm.value.push(newValueAmout)
    fieldsForm.value.push(newValueTextAmout)
  })
}

function addGranteeCompaniesFileds() {
  const indexForInsert = fieldsForm.value.findIndex((el) => el.name === 'endProjectYear')
  projectData.value?.granteeCompanies?.forEach((el, index) => {
    const newValueAmount = {
      first_value: el.name,
      id: uuidv4(),
      group_id: 0,
      type: 'textarea',
      name: 'name',
      group_name: 'granteeCompanies',
      title: 'Наименование организации - Участник консорциума - получатель гранта (при наличии)',
    }
    fieldsForm.value.splice(indexForInsert + index + 1, 0, newValueAmount)
  })
}

async function getSavedData() {
  isLoading.value = true
  try {
    await api
      .stash()
      .getStashData(commentHash.value)
      .then((data) => {
        commentManager.value.default_value = data?.comment

        const findGranteeCompanies = fieldsForm.value?.filter(
          (item) => item?.group_name === 'granteeCompanies',
        )

        const findAmounts = fieldsForm.value?.filter(
          (item) => item?.group_name === 'annualGrantAmounts' && item?.name === 'amount',
        )

        const findAmountTexts = fieldsForm.value?.filter(
          (item) => item?.group_name === 'annualGrantAmounts' && item?.name === 'amountText',
        )

        data?.granteeCompanies?.forEach((el, index) => {
          if (findGranteeCompanies && findGranteeCompanies[index]) {
            findGranteeCompanies[index].comment = el.name
            findGranteeCompanies[index].default_value = el.name
          }
        })

        data?.annualGrantAmounts?.forEach((el, index) => {
          if (findAmounts && findAmounts[index]) {
            findAmounts[index].comment = el.amount
            findAmounts[index].default_value = el.amount
          }

          if (findAmountTexts && findAmountTexts[index]) {
            findAmountTexts[index].comment = el.amountText
            findAmountTexts[index].default_value = el.amountText
          }
        })

        fieldsForm.value?.forEach((el) => {
          const value = data[el.name]
          if (value) {
            el.default_value = value
            el.comment = value
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function submitFormToMonitor() {
  if (!commentManager.value.default_value) {
    return toast.error('Поле Комментарий менеджера - обязательное')
  }

  try {
    api
      .projects()
      .managerSubmitFormToMonitor(projectId.value, commentManager.value.default_value)
      .then(() => {
        clearStash(projectData.value?.hash)
        clearStash(commentHash.value)
        router.push('/coordination-documents')
      })
  } catch (err) {
    console.log(err)
  }
}

async function getProjectData() {
  isLoading.value = true
  try {
    await api
      .projects()
      .getProjectData(projectId.value)
      .then((data) => {
        getSavedData()
        projectData.value = data
        commentManager.value.default_value = data?.managerComment
        requestStatus.value = data.status
        isEditForm.value = !!(
          data.status === PROJECT_REQUEST_STATUSES.NEW ||
          data.status === PROJECT_REQUEST_STATUSES.IMPROVED ||
          data?.status === PROJECT_REQUEST_STATUSES.REVIEWED
        )

        addAnnualGrantAmountsFields(data)
        addGranteeCompaniesFileds(data)

        fieldsForm.value?.forEach((el) => {
          const value = data[el.name]
          if (value) {
            el.first_value = value
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function clearStash(hash) {
  try {
    await api.stash().clearStash(hash)
  } catch (err) {
    console.log(err)
  }
}

async function sendForRevision() {
  isLoading.value = true

  try {
    const { valid } = await form.value.validate()
    if (!valid) {
      toast.error('Поле "Комментарий Менеджера" - обязательное')

      return
    }

    const annualGrantAmountsArr = fieldsForm.value.filter(
      (el) => el.group_name === 'annualGrantAmounts',
    )

    const annualGrantAmounts = []
    annualGrantAmountsArr.forEach((el) => {
      annualGrantAmounts[el?.index] = {
        ...annualGrantAmounts[el?.index],
        year: getYearAnnualGrantAmountsItem(el?.index),
        [el.name]: el.default_value,
      }
    })

    let params = {
      annualGrantAmounts: annualGrantAmounts,
      hash: projectData.value?.hash,
    }

    const granteeCompanies = []
    const granteeCompaniesArr = fieldsForm.value?.filter(
      (el) => el?.group_name === 'granteeCompanies',
    )
    granteeCompaniesArr.forEach((el, index) => {
      granteeCompanies[index] = {
        name: el.default_value,
      }
    })
    params['granteeCompanies'] = granteeCompanies
    fieldsForm.value.forEach((el) => {
      if (el.name !== 'name') {
        params[el.name] = el.default_value
      }
    })
    await api.projects().sendCommentsToFields(projectId.value, params)
    await api
      .projects()
      .returnsProjectFormToWinnerForRevision(projectId.value, commentManager.value.default_value)

    isEditForm.value = !isEditForm.value
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function getValue(item) {
  if (item.name === 'fullTitle') {
    const centerNtiId =
      projectData.value && 'centerNtiId' in projectData.value
        ? projectData.value['centerNtiId']
        : null
    const center = centerNti.value?.find((el) => el?.id === centerNtiId)
    return center?.fullTitle
  }

  if (item.isAmount) {
    return item.first_value ? String(item.first_value)?.replace('.', ',') : ''
  }

  if (item.name === 'shortTitle') {
    const centerNtiId =
      projectData.value && 'centerNtiId' in projectData.value
        ? projectData.value['centerNtiId']
        : null
    const center = centerNti.value?.find((el) => el?.id === centerNtiId)

    return center?.shortTitle
  }

  if (item.isDate) {
    return moment(item.first_value).format('DD.MM.YYYY')
  }

  if (item.name === 'supportKind') {
    return SUPPORT_KIND.find((el) => el.id === item.first_value)?.name
  }

  if (item.name === 'statusCode') {
    return STATUS_CODE.find((el) => el.id === item.first_value)?.name
  }

  return item.first_value
}

function setProgramNumber() {
  const center = centerNti.value?.find((el) => el?.id === projectData.value?.centerNtiId)
  if (center) {
    programNumber.value = center.programNumber
  }
}

watch(projectData, () => {
  if ((projectData.value, centerNti.value)) {
    setProgramNumber()
  }
})

watch(centerNti, () => {
  if ((projectData.value, centerNti.value)) {
    setProgramNumber()
  }
})

onMounted(() => {
  getProjectData()
  getFiles()

  getCenterNtiList()

  setTimeout(() => {
    const hash = window.location.hash.substr(1)
    if (hash) {
      document.getElementById(hash).scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, 500)
})
</script>
<style lang="scss">
.custom-inputs {
  .v-field__input {
    height: 56px;
  }
}
.border-bottom-line {
  &.border-red {
    border-color: red;
  }
}
</style>
