<template>
  <v-expansion-panels :key="projects.length" class="flat-panel" accordion>
    <template v-for="project in projects">
      <v-expansion-panel
        v-if="getPointsProject(project.id) && getPointsProject(project.id).length"
        :key="project.id"
        elevation="0"
        class="mb-md-4 mb-2"
      >
        <v-expansion-panel-title class="text-subtitle-2 font-weight-black">
          {{ project?.template?.name }} - {{ project?.name }}
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pb-md-2">
          <v-col
            v-for="point in getPointsProject(project.id)"
            class="pa-0 ma-0 my-2 my-md-6 w-100 justify-center align-center bordered-item"
          >
            <v-row
              class="pa-2 ma-0 align-md-center align-start justify-start justify-md-space-between flex-md-row flex-column"
            >
              <v-col class="pa-2 pa-md-5 ma-0 font-weight-bold" cols="12" md="4" lg="4">
                <div>{{ point.name }}</div>
                <div
                  class="text-caption text-grey expertise-description"
                  v-html="point.description"
                />
              </v-col>
              <v-col class="pa-2 pa-md-5 ma-0 text-grey" cols="12" md="6" lg="6">
                Статус: {{ point.status.name }}
              </v-col>
              <v-col
                class="pa-2 pa-md-5 text-start text-md-end ma-0 font-weight-regular justify-start justify-md-end"
                cols="12"
                md="2"
                lg="2"
              >
                <RouterLink :to="`/tasks/${point.id}`">
                  {{ point.statusCode === POINTS_STATUSES.SUSPENDED ? 'Редактировать' : 'Перейти' }}
                </RouterLink>
              </v-col>
              <!--              <v-col class="pa-5 ma-0 font-weight-regular" cols="2">-->
              <!--                <RouterLink :to="`/calendar`"> Календарь </RouterLink>-->
              <!--              </v-col>-->
            </v-row>
          </v-col>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </template>
    <v-row v-if="!hasPoints" class="justify-center align-center py-6 text-grey">
      Список пуст
    </v-row>
  </v-expansion-panels>
</template>
<script setup>
import { computed } from 'vue'
import { POINTS_STATUSES } from '../../constants/points'

const props = defineProps({
  projects: {
    type: Array,
    default: () => [],
  },
  points: {
    type: Array,
    default: () => [],
  },
})

const hasPoints = computed(() => {
  let pointsCount = 0
  props.projects.forEach((el) => {
    pointsCount += getPointsProject(el.id)?.length
  })

  return pointsCount
})

function getPointsProject(projectId) {
  return props.points?.filter((el) => el.projectId === projectId)
}
</script>
<style lang="scss">
.bordered-item {
  border: 1px solid var(--border-th-table);
}
</style>
