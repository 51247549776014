<template>
  <div
    id="expense-report-form"
    location="top"
    contained
    scrollable
    content-class="top-dialog-hey"
    height="70vh"
  >
    <div width="800">
      <div>
        <div class="text-subtitle-1 font-weight-bold mb-2">
          Подлежит возврату Грантодателю (сумма с начала текущего финансового года)
        </div>
        <v-row v-if="isLoading" class="justify-center align-center py-6">
          <v-progress-circular color="primary" :indeterminate="true" />
        </v-row>
        <v-form ref="form" lazy-validation>
          <div v-for="field in fieldsForm" :key="field.id" class="position-relative">
            <p v-if="field.title" class="text-body-1 ma-0">
              {{ field.title }}
            </p>
            <Component
              :is="inputs[getFormInput(field.type)]"
              :key="isShowFieldsPreviousYear"
              :prepend-icon="
                field.type === 'actionButton'
                  ? !showFieldsPreviousYear
                    ? 'mdi-chevron-down'
                    : 'mdi-chevron-up'
                  : ''
              "
              :max-date="field?.maxDate"
              v-bind="field"
              @change-value="updateDataInStash"
              @update-value="updateValue"
            />
          </div>
          <template v-if="isShowFieldsPreviousYear">
            <div v-for="group in fieldsGroups" :key="`group-${group.id}`">
              <div class="font-weight-bold text-subtitle-1 mb-2">{{ group.title }}</div>
              <div v-for="field in getFieldsGroup(group.id)" :key="field.id">
                <p v-if="field.title" class="text-body-1 ma-0">
                  {{ field.title }}
                </p>
                <Component
                  :is="inputs[getFormInput(field.type)]"
                  v-bind="field"
                  @change-value="updateDataPrevYearInStash"
                  @update-value="updatePrevYearValue"
                />
              </div>
            </div>
          </template>
        </v-form>
      </div>
      <v-row class="justify-start px-0 mx-0 mt-12">
        <v-btn
          color="primary"
          variant="elevated"
          class="mr-2"
          @click="emit('print-expense-structure')"
        >
          Печать
        </v-btn>
        <v-btn
          :disabled="isDisableSubmitButton"
          color="primary"
          variant="elevated"
          @click="onSubmit"
        >
          Отправить
        </v-btn>
        <v-btn class="ml-2" color="primary" variant="text" @click="() => emit('close')">
          Закрыть
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { api } from '@/api/Api'
import { v4 as uuidv4 } from 'uuid'
import { useRoute } from 'vue-router/dist/vue-router'

import { inputs, getFormInput, focusErrorInput } from '@/helpers/index'
import { useToast } from '@/composables/useToast'
import moment from 'moment'
import { EXPENSE_REPORT_FORM, EXPENSE_REPORT_PREV_YEAR_FORM } from '@/constants/stash'

const { toast } = useToast()
const route = useRoute()

const fieldFormHash = computed(() => `${EXPENSE_REPORT_FORM}-${route.params?.id}`)
const fieldFormPrevYearHash = computed(() => `${EXPENSE_REPORT_PREV_YEAR_FORM}-${route.params?.id}`)
const showFieldsPreviousYear = computed(() => !!isShowFieldsPreviousYear.value)

const dialog = ref(false)
const form = ref(null)
const isLoading = ref(false)
const isShowFieldsPreviousYear = ref(false)
const fieldsForm = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'number',
    name: 'wasteOtherPurposes',
    title: 'Израсходованных не по целевому назначению',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'number',
    name: 'wastePenalty',
    title: 'В результате применения штрафных санкций',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'number',
    name: 'wasteNotConfirmed',
    title: 'В сумме остатка гранта на начало года, потребность в которой не подтверждена',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    options: [],
    default_value: null,
    note: '',
    rules: [['required']],
    sort: 1,
    hideDetails: true,
    required: true,
    name: 'submissionDate',
    title: 'Дата сдачи отчета в ЭБ',
    maxDate: new Date(new Date().getFullYear(), 11, 31),
    minDate: new Date(new Date().getFullYear(), 0, 1),
  },
  {
    id: uuidv4(),
    type: 'actionButton',
    name: 'add-block',
    text: 'Добавить данные за предыдущий год',
    default_value: null,
    action: () => addNewData(),
  },
])
const fieldsPreviousYear = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'number',
    name: 'amountFull',
    title: 'Остаток гранта на конец прошлого периода, всего',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'number',
    name: 'liabilities',
    title: 'Требуется для оплаты денежных обязательств',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'number',
    name: 'refundableGrantor',
    title: 'Подлежит возврату грантодателя',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'number',
    name: 'amountFull',
    title: 'Остаток гранта на конец прошлого периода, всего',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'number',
    name: 'liabilities',
    title: 'Требуется для оплаты денежных обязательств',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'number',
    name: 'refundableGrantor',
    title: 'Подлежит возврату грантодателя',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },

  {
    id: uuidv4(),
    group_id: 2,
    type: 'number',
    name: 'wasteOtherPurposes',
    title: 'Израсходованных не по целевому назначению',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'number',
    name: 'wastePenalty',
    title: 'В результате применения штрафных санкций',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'number',
    name: 'wasteNotConfirmed',
    title: 'В сумме остатка гранта на начало года, потребность в которой не подтверждена',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['string', { max: '15' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'date',
    name: 'submissionDate',
    title: 'Дата сдачи отчета в ЭБ',
    options: [],
    default_value: new Date(new Date().getFullYear() - 1, 11, 31),
    descr: '',
    note: '',
    multiple: true,
    hideDetails: true,
    maxDate: new Date(new Date().getFullYear(), 2, 31),
    rules: [['required']],
    sort: 1,
  },
])
const fieldsGroups = ref([
  {
    id: 0,
    title: 'Остатки гранта на конец прошлого периода (сумма всего с даты заключения соглашения)',
  },
  {
    id: 1,
    title: 'Остатки гранта на конец прошлого периода (сумма с начала текущего финансового года)',
  },
  {
    id: 2,
    title: 'Подлежит возврату Грантодателю (сумма с даты заключения договора)',
  },
])

const isDisableSubmitButton = computed(() => {
  return isShowFieldsPreviousYear.value
    ? !!fieldsForm.value?.find((el) => !el.default_value && el.name !== 'add-block') ||
        !!fieldsPreviousYear.value?.find((el) => !el.default_value)
    : !!fieldsForm.value?.find((el) => !el.default_value && el.name !== 'add-block')
})

function getFieldsGroup(idGroup) {
  return fieldsPreviousYear.value?.filter((el) => el.group_id === idGroup)
}

function addNewData() {
  isShowFieldsPreviousYear.value = !isShowFieldsPreviousYear.value
}

function dialogToggle() {
  dialog.value = !dialog.value
}

function updateValue(data) {
  const { value, id } = data

  fieldsForm.value?.forEach((item) => {
    if (item.id === id) {
      item.default_value = value
    }
  })
}

function getValue(field, value) {
  if (field.type === 'number') {
    return +value
  }

  if (field.type === 'date') {
    return value ? moment(value).format('YYYY-MM-DD') : value
  }

  return value
}

async function updateDataInStash({ value, id }) {
  const findField = fieldsForm.value?.find((el) => el.id === id)

  if (findField) {
    const params = {
      field: findField?.name,
      value: getValue(findField, value),
      hash: fieldFormHash.value,
    }

    await api.stash().saveFieldInStash(params)
  }
}

async function updateDataPrevYearInStash({ value, id }) {
  const findFieldPrevYear = fieldsPreviousYear.value?.find((el) => el.id === id)

  if (findFieldPrevYear) {
    const params = {
      field: findFieldPrevYear?.name,
      value: getValue(findFieldPrevYear, value),
      hash: fieldFormPrevYearHash.value,
    }

    await api.stash().saveFieldInStash(params)
  }
}

async function getSavedDataPrevYear() {
  isLoading.value = true
  try {
    await api
      .stash()
      .getStashData(fieldFormPrevYearHash.value)
      .then((data) => {
        fieldsPreviousYear.value?.forEach((el) => {
          if (el.name in data) {
            el.default_value = data[el.name]
            isShowFieldsPreviousYear.value = true
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function getSavedData() {
  isLoading.value = true
  try {
    await api
      .stash()
      .getStashData(fieldFormHash.value)
      .then((data) => {
        fieldsForm.value?.forEach((el) => {
          if (el.name in data) {
            el.default_value = data[el.name]
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function updatePrevYearValue(data) {
  const { value, id } = data

  fieldsPreviousYear.value?.forEach((item) => {
    if (item.id === id) {
      item.default_value = value
    }
  })
}

async function sendListFundsByYears(year, fields) {
  try {
    const formData = new FormData()
    fields?.forEach((el) => {
      formData.append(el.name, el.default_value)
    })

    await api.balance().sendListFundsByYears(route.params?.id, year, formData)
  } catch (err) {
    toast.error(err?.response?.data?.message)
  }
}

async function clearStash() {
  try {
    await api.stash().clearStash(fieldFormHash.value)
  } catch (err) {
    console.log(err)
  }
}

async function clearPrevYearStash() {
  try {
    await api.stash().clearStash(fieldFormPrevYearHash.value)
  } catch (err) {
    console.log(err)
  }
}

async function sendListFundsReturnedToGrantor(year, fields) {
  try {
    const thisYear = year ? year : moment(new Date()).format('YYYY')
    const formData = new FormData()
    const formFields = fields ? fields : fieldsForm.value
    formFields?.forEach((el) => {
      if (el.type === 'actionButton') {
        return
      }
      formData.append(
        el.name,
        el.type === 'date' ? moment(el.default_value).format('YYYY-MM-DD') : el.default_value,
      )
    })
    await api
      .balance()
      .sendListFundsReturnedToGrantor(route.params?.id, thisYear, formData)
      .then(() => {
        clearStash()
        if (!isShowFieldsPreviousYear.value) {
          emit('update-data')
        }
      })
  } catch (err) {
    toast.error(err?.response?.data?.message)
  }
}

async function onSubmit() {
  const { valid } = await form.value.validate()

  if (!valid || form.value?.errors?.length) {
    const errInput = form.value.errors[0]
    focusErrorInput(errInput)
    return
  }
  isLoading.value = true

  try {
    await sendListFundsReturnedToGrantor()
    if (isShowFieldsPreviousYear.value) {
      await Promise.all([
        sendListFundsByYears(new Date().getFullYear() - 1, getFieldsGroup(0)),
        sendListFundsByYears(new Date().getFullYear(), getFieldsGroup(1)),
        sendListFundsReturnedToGrantor(new Date().getFullYear() - 1, getFieldsGroup(2)),
      ]).then(() => {
        clearPrevYearStash().then(() => emit('update-data'))
      })
    }
  } catch (err) {
    toast.error(err.message)
  } finally {
    isLoading.value = false
  }
}

const emit = defineEmits(['update-data'])
defineExpose({
  dialogToggle,
})

onMounted(() => {
  getSavedData()
  getSavedDataPrevYear()
})
</script>

<style lang="scss">
.top-dialog-hey {
  align-self: flex-start;
  top: 100px;
}
</style>
