<template>
  <template v-if="isManager || isExpert">
    <div class="font-weight-bold mt-6">Выгрузить анкеты</div>
    <v-col class="ma-0 pa-0 mt-5 flex-wrap">
      <div class="flex-grow-0 text-no-wrap">
        Выберите форму, по которой необходимо выгрузить анкеты:
      </div>
      <v-select
        v-model="selectedForm"
        :items="forms"
        return-object
        item-title="title"
        item-value="id"
        variant="outlined"
        hide-details="auto"
        clearable
        @change="filterResult"
      />
      <template v-if="fields.length">
        <div class="mt-6">Список полей:</div>
        <v-combobox
          v-model="selectedFields"
          :items="fields"
          return-object
          item-title="title"
          item-value="name"
          variant="outlined"
          hide-details="auto"
          multiple
        />
      </template>
      <div class="mt-6">Тип выгрузки:</div>
      <v-select
        v-model="selectedTypeExport"
        :items="typesExport"
        return-object
        item-title="title"
        item-value="id"
        variant="outlined"
        hide-details="auto"
      />
      <v-checkbox
        v-model="isDraftExport"
        hide-details
        class="mb-0 pb-0 mt-4"
        label="Выгрузить анкеты со статусом 'Черновик'"
      />
      <v-btn
        :disabled="!selectedForm"
        color="primary"
        variant="text"
        class="my-6 px-0"
        @click="exportResult"
      >
        Выгрузить в .xls
      </v-btn>
    </v-col>
  </template>
  <v-col class="px-0 mx-0">
    <div class="flex-grow-0 text-no-wrap">Статус анкеты:</div>
    <v-select
      v-model="selectFilterStatus"
      :items="filterStatuses"
      return-object
      item-title="name"
      item-value="value"
      variant="outlined"
      hide-details="auto"
      clearable
    />
  </v-col>
  <TheTable
    v-model:selection="selectedProduct3"
    :items="items"
    :attributes="{
      sortField: 'date_create',
      sortOrder: -1,
      dataKey: 'id',
    }"
  >
    <Column v-if="isManager" selection-mode="multiple" />
    <Column field="id" header="ID-анкеты" :sortable="true">
      <template #body="{ data: { id: id } }">
        <router-link :to="`/questionnaire/${id}`">
          {{ id }}
        </router-link>
      </template>
    </Column>
    <Column field="date_submit" header="Дата и время подачи" :sortable="true" />
    <Column field="forma.title" header="Название формы анкеты" :sortable="true" />
    <Column field="org_name_value" header="Название организации" :sortable="true" />
    <Column field="command_name_value" header="Название команды" :sortable="true" />
    <Column field="contest_status.status" header="Статус заявки" :sortable="true" />
    <Column field="status" header="Статус анкеты" :sortable="true">
      <template #body="{ data: { status }, data }">
        <span v-if="!hasSetStatus(status)">
          {{ defaultStatus[status] }}
        </span>
        <v-select
          v-if="hasSetStatus(status)"
          :items="statusesCurrent"
          :model-value="defaultStatus[status]"
          item-title="name"
          item-value="value"
          variant="underlined"
          hide-details="auto"
          @update:model-value="openDialog($event, data)"
        />
      </template>
    </Column>
  </TheTable>
  <v-row v-if="isManager" class="mt-1">
    <v-col cols="3">
      <p>Изменить статус анкет(ы) на:</p>
    </v-col>
    <v-col cols="2">
      <v-select
        v-model="allSelectedStatus"
        :items="statuses"
        item-title="name"
        item-value="value"
        variant="underlined"
        hide-details="auto"
      />
    </v-col>
    <v-col cols="auto" class="align-self-center">
      <v-btn color="primary" variant="text" @click="setMultiStatus"> Изменить </v-btn>
    </v-col>
  </v-row>

  <v-dialog v-model="isShowDialog">
    <v-card>
      <v-card-text>
        Установить анкете статус - "{{ defaultStatus[selectedStatus.status] }}"
      </v-card-text>
      <v-card-actions>
        <v-row :no-gutters="true" justify="center">
          <v-col cols="auto">
            <v-btn @click="setCurrentStatus"> Да </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn @click="closeDialog"> Нет </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import TheTable from '@/components/TheTable'
import Column from 'primevue/column'
import { computed, onMounted, ref, watch } from 'vue'
import { defaultStatus } from '@/utils/common'
import { useStore } from 'vuex'
import { api } from '@/api/Api'
import { useToast } from '@/composables/useToast'
import { DRAFT_STATUS } from '@/utils/constants'

const store = useStore()
const { toast } = useToast()

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  hasSelection: {
    type: Boolean,
    default: false,
  },
  allItems: {
    type: Array,
    default: () => [],
  },
  formFields: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['update-questionnaire', 'filter-result', 'filter-questionnaire'])

const filterStatuses = ref([
  {
    value: 'draft',
    name: 'Черновик',
  },
  {
    value: 'sent',
    name: 'Повторная проверка',
  },
  {
    value: 'revision',
    name: 'Требует доработки',
  },
  {
    value: 'accept',
    name: 'Анкета одобрена',
  },
  {
    value: 'reject',
    name: 'Анкета отклонена',
  },
  {
    value: 'new',
    name: 'На рассмотрении',
  },
])

const selectFilterStatus = ref(null)

const statuses = ref([
  {
    value: 'accept',
    name: 'Одобрить',
  },
  {
    value: 'reject',
    name: 'Отклонить',
  },
])
const statusesCurrent = ref([
  ...statuses.value,
  {
    value: 'revision',
    name: 'На доработку',
  },
])

const typesExport = ref([
  {
    id: 0,
    title: 'Горизонтальная',
  },
  {
    id: 1,
    title: 'Вертикальная',
  },
])

const filteredSelectedItems = ref([])
const allSelectedStatus = ref(null)
const isShowDialog = ref(false)
const selectedStatus = ref({})
const selectedForm = ref(null)
const selectedTypeExport = ref(typesExport.value[1])
const isDraftExport = ref(false)
const selectedFields = ref(null)
const forms = ref([])
const selectedProduct3 = computed({
  get() {
    return filteredSelectedItems.value
  },
  set(value) {
    //filteredSelectedItems.value = value.filter(item => hasSetStatus(item.status))
    filteredSelectedItems.value = value
  },
})

const isManager = computed(() => store.getters['user/isManager'])
const isExpert = computed(() => store.getters['user/isExpert'])
// const forms = computed(() => {
//   const formIds = [...new Set(props.allItems.map(item => item.forma.id))]
//
//   return formIds.map(id => props.allItems.find(item => item.forma.id === id)).map(item => item.forma)
// })

const fields = computed(() => {
  return props.formFields
})

async function getForms() {
  try {
    await api
      .form()
      .getForms(1, 1000, { exclude: 'fields' })
      .then((data) => {
        forms.value = data?.items?.map((el) => {
          return {
            ...el,
            title: (el.title || '') + ' - ' + (el?.contest?.name || ''),
          }
        })
      })
  } catch (err) {
    console.log(err)
  }
}

function hasSetStatus(status) {
  if (!isManager.value) {
    return false
  }

  return (
    defaultStatus.new === defaultStatus[status] ||
    defaultStatus.sent === defaultStatus[status] ||
    defaultStatus.accept === defaultStatus[status]
  )
}

async function sendStatus(sendItems, status) {
  const promises = sendItems.map((item) => {
    return api.result().setStatus(item.id, status)
  })

  return await Promise.all(promises).then((response) => {
    response.forEach((message) => {
      toast.success(message)
    })
    emit('update-questionnaire')
  })
}

async function setMultiStatus() {
  if (filteredSelectedItems.value.length === 0) {
    return
  }

  if (!allSelectedStatus.value) {
    return
  }

  await sendStatus(filteredSelectedItems.value, allSelectedStatus.value)
  filteredSelectedItems.value = []
  allSelectedStatus.value = null
}
function openDialog(status, id) {
  selectedStatus.value = { id, status }
  isShowDialog.value = true
}
function closeDialog() {
  isShowDialog.value = false
  selectedStatus.value = {}
}
function setCurrentStatus() {
  sendStatus([selectedStatus.value?.id], selectedStatus.value?.status)
  closeDialog()
}

function exportResult() {
  const filteredItems = filteredSelectedItems.value?.map((el) => el?.id)
  let fieldsNames = selectedFields.value?.map((el) => el.name)

  fields.value?.forEach((el) => {
    if (el.type === 'group') {
      const id = el.id
      const findGroup = selectedFields.value?.filter((el) => el.group_id === id)

      if (findGroup?.length) {
        const isHasField = fieldsNames.find((field) => field === el.name)
        if (!isHasField) {
          fieldsNames.push(el.name)
        }
      }
    }
  })

  selectedFields.value?.forEach((el) => {
    if (el.type === 'group') {
      const filterFieldsGroup = fields.value?.filter((field) => field.group_id === el.id)
      filterFieldsGroup?.forEach((fieldField) => {
        const isHasInArray = fieldsNames.find((item) => item === fieldField.name)
        if (!isHasInArray) {
          fieldsNames.push(fieldField.name)
        }
      })
    }
  })

  const form = new FormData()
  form.append('direction', selectedTypeExport.value?.id)
  form.append('id', selectedForm.value.id)
  filteredItems?.forEach((el) => {
    form.append('results[]', el)
  })

  if (fieldsNames.length !== fields.value.length) {
    fieldsNames?.forEach((el) => {
      form.append('fields[]', el)
    })
  }

  if (isDraftExport.value) {
    form.append('status', DRAFT_STATUS)
  }

  api
    .result()
    .exportResultByFormId(form)
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      )
      const filename = selectedForm.value.title
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
    .catch(() => {
      toast.error('Не удалось сформировать файл')
    })
}

function filterResult() {
  emit('filter-result', selectedForm.value?.id)
  fields.value = []
  selectedFields.value = null
}

watch(selectedForm, () => {
  filterResult()
})

watch(fields, () => {
  selectedFields.value = fields.value
})

watch(selectFilterStatus, () => {
  emit('filter-questionnaire', selectFilterStatus.value?.value)
})

onMounted(() => {
  getForms()
})
</script>
<script>
export default {
  name: 'QuestionnaireTable',
}
</script>

<style scoped></style>
