<template>
  <v-dialog v-model="dialog">
    <v-card width="700">
      <v-card-title>
        <v-row class="px-2 justify-space-between align-center ma-0">
          <h3>История заявки</h3>
          <v-btn variant="text" icon="mdi-close" color="primary" @click="closeDialog" />
        </v-row>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-card class="pa-0 ma-0 overflow-auto" elevation="0" max-height="240">
          <v-card-text class="px-0 overflow-x-hidden">
            <v-row v-if="loading" class="px-0 justify-center align-center">
              <v-progress-circular color="primary" :indeterminate="true" />
            </v-row>
            <template v-else>
              <v-row
                v-for="item in filteredHistory"
                id="beginningList"
                :key="item.id"
                class="px-0 mx-0 mt-0 mb-2 flex-nowrap"
              >
                <template v-if="item.fio">
                  <v-col class="pa-0 mr-2 flex-grow-0 w-100" cols="4">
                    {{ item.date }}
                  </v-col>
                  <v-col
                    class="pa-0 mr-2 flex-grow-1 white-space-word-break line-height-1"
                    cols="8"
                  >
                    <v-col class="pa-0 text-caption text-grey">
                      {{ item.fio }}
                    </v-col>
                    <v-col class="pa-0">
                      {{ item.message }}
                    </v-col>
                    <v-col class="pa-0">
                      <a
                        v-for="(file, index) in item.attachments"
                        :key="`file${index}`"
                        :href="getUrlFile(file)"
                        target="_blank"
                        class="text-blue d-block"
                      >
                        <span class="d-flex">{{ getFileName(file) }}</span>
                      </a>
                    </v-col>
                  </v-col>
                </template>
              </v-row>
              <v-row
                v-if="!filteredHistory.length"
                class="px-0 justify-center text-grey overflow-hidden"
              >
                История заявки пуста
              </v-row>
              <v-row v-if="page < meta?.pageCount" class="px-0 justify-center">
                <v-btn variant="text" color="primary" @click="loadData"> Загрузить еще </v-btn>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-col class="ma-0 justify-space-between flex-nowrap align-center">
          <v-form class="w-100">
            <div v-for="field in fieldsForm" :key="field.id">
              <p v-if="field.title" class="text-body-1 ma-0">
                {{ field.title }}
              </p>
              <Component
                :is="inputs[getFormInput(field.type)]"
                v-bind="field"
                @update-value="updateValue"
              />
            </div>
          </v-form>
          <v-row class="px-0 ma-0 justify-end align-center">
            <v-btn color="primary" variant="text" @click="submitForm"> Отправить сообщение </v-btn>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useStore } from 'vuex'

import { api } from '@/api/Api'
import FormTextarea from '@/components/Form/FormTextarea'
import FormFile from '@/components/Form/FormFile'

import { CONTEXT_CODES, DEFAULT_ID_PROJECT } from '@/constants'
import router from '@/router'

const store = useStore()

const dialog = ref(false)
const history = ref([])
const authors = ref([])
const authorsIds = ref([])
const loading = ref(false)
const order = ref(null)
const meta = ref(null)
const page = ref(1)

const isUser = computed(() => store.getters['user/isUser'])
const isManager = computed(() => store.getters['user/isManager'])
const filteredHistory = computed(() => {
  return history.value?.map((el) => {
    const findUser = authors.value?.find((user) => user?.id === el?.author)
    return {
      ...el,
      fio: findUser ? findUser.fio : '',
    }
  })
})

const fieldsForm = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'message',
    title: 'Текст сообщения',
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 20,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'file',
    name: 'attachments',
    title: 'Прикрепить файлы',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 1,
    rules: [],
    sort: 1,
  },
])

const inputs = {
  FormTextarea,
  FormFile,
}

function getUrlFile(url) {
  if (!url) {
    return ''
  }
  return url
}
function getFileName(url) {
  if (!url) {
    return ''
  }

  return url.substring(url.lastIndexOf('/') + 1)
}

function getFormInput(type) {
  switch (type) {
    case 'textarea': {
      return 'FormTextarea'
    }
    case 'file': {
      return 'FormFile'
    }
    default: {
      return ''
    }
  }
}

function updateValue({ value, id }) {
  fieldsForm.value.forEach((item) => {
    if (item.id === id) {
      item.default_value = value
    }
  })
}

function dialogToggle() {
  dialog.value = !dialog.value
}

function closeDialog() {
  dialog.value = false

  if (isUser.value) {
    return router.push('/my-orders')
  }

  return router.push('/orders')
}

function setData(data) {
  order.value = data
  loading.value = true
  getHistory().then(() => {
    loading.value = false
  })
}

async function submitForm() {
  const formData = new FormData()
  formData.append('project', DEFAULT_ID_PROJECT)
  fieldsForm.value.map((el) => {
    if (el.name !== 'attachments') {
      formData.append(el.name, el.default_value)

      return formData
    } else if (el.default_value.length) {
      el.default_value.forEach((el) => {
        formData.append('attachments[]', el)
      })
    }
  })

  formData.append('context', CONTEXT_CODES.APPLICATION)
  formData.append('object', order.value?.id)
  formData.append('parent_object', order.value?.contest.id)

  if (isManager.value) {
    formData.append('recipients[]', order.value?.user.id)
  } else {
    order.value?.contest?.managers.forEach((el) => {
      formData.append('recipients[]', el?.user?.id)
    })
  }

  try {
    await api
      .notifications()
      .sendMessage(formData)
      .then((message) => {
        history.value = [message.data, ...history.value]
        page.value = 1

        fieldsForm.value.forEach((el) => (el.default_value = []))

        const elem = document.getElementById('beginningList')
        elem.scrollIntoView({ block: 'start', behavior: 'smooth' })
      })
  } catch (err) {
    console.log(err)
  }
}

function loadData() {
  page.value += 1

  getHistory(true)
}

function getUniqueIds(array) {
  return array.reduce((acc, item) => {
    if (acc.includes(item)) {
      return acc
    }
    return [...acc, item]
  }, [])
}

function getNamesAuthors(ids) {
  loading.value = true
  try {
    ids?.forEach(async (el) => {
      const findUser = authors.value?.find((user) => user.id === el)
      if (findUser) {
        return
      }
      await api
        .user()
        .getUserInfoById(el)
        .then((newUser) => {
          authors.value = [...authors.value, newUser]
        })
    })
  } catch (err) {
    console.log(err)
  } finally {
    loading.value = false
  }
}

async function getHistory(hasPage = false) {
  try {
    const params = {
      context: CONTEXT_CODES.APPLICATION,
      page: page.value,
      pageSize: 6,
      object: order.value?.id,
      parent_object: order.value?.contest.id,
    }

    await api
      .notifications()
      .getMessages(params)
      .then((data) => {
        const newAuthorsIds = data?.data?.items?.map((el) => el.author)
        authorsIds.value = [...authorsIds.value, ...newAuthorsIds]
        const uniqueIdsAuthors = getUniqueIds(authorsIds.value)
        getNamesAuthors(uniqueIdsAuthors)

        const newValue = data?.data.items
        if (hasPage) {
          history.value = [...history.value, ...newValue]
        } else {
          history.value = newValue
          page.value = 1
        }

        meta.value = data?.data._meta
      })
  } catch (err) {
    console.log(err)
  }
}

defineExpose({
  dialogToggle,
  setData,
})
</script>
