<template>
  <the-container>
    <h3 class="mb-5">Отчет о достижении значений результатов предоставления Гранта</h3>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <template v-else>
      <div style="overflow: scroll">
        <table class="overflow-x-auto main-table text-body-2">
          <tbody>
            <tr>
              <th colspan="2" rowspan="2">Направление расходов</th>
              <th rowspan="3">Результат предоставления Гранта</th>
              <th colspan="2" rowspan="2">Единица измерения</th>
              <th rowspan="3">Код строки</th>
              <th colspan="2" rowspan="2">Плановые значения</th>
              <th rowspan="3">Размер Гранта, предусмотренный Договором</th>
              <th colspan="6">Фактически достигнутые значения</th>
              <th rowspan="3">
                Объем обязательств, принятых в целях достижения результатов предоставления Гранта
              </th>
              <th rowspan="3">Неиспользованный размер Гранта (гр. 9 - гр. 16)</th>
            </tr>
            <tr>
              <th colspan="2">на отчетную дату</th>
              <th colspan="2">отклонение от планового значения</th>
              <th colspan="2">причина отклонения</th>
            </tr>
            <tr>
              <th>наименование</th>
              <th>код по БК</th>
              <th>наименование</th>
              <th>код по ОКЕИ</th>
              <th>с даты заключени я Договора</th>
              <th>из них с начала текущего финансово го года</th>
              <th>с даты заключения Договора</th>
              <th>из них с начала текущего финансового года</th>
              <th>в абсолютных величинах (гр. 7 - гр. 10)</th>
              <th>в процентах гр.12 / гр. 7 x 100%</th>
              <th>код</th>
              <th>наименование</th>
            </tr>
            <tr>
              <th>1</th>
              <th>2</th>
              <th>3</th>
              <th>4</th>
              <th>5</th>
              <th>6</th>
              <th>7</th>
              <th>8</th>
              <th>9</th>
              <th>10</th>
              <th>11</th>
              <th>12</th>
              <th>13</th>
              <th>14</th>
              <th>15</th>
              <th>16</th>
              <th>17</th>
            </tr>
            <tr>
              <td rowspan="5" style="min-width: 300px; text-align: left">
                Государственная поддержка центров Национальной технологической инициативы на базе
                образовательных организаций высшего образования и научных организаций
              </td>
              <td rowspan="5">67706</td>
              <td style="min-width: 400px; text-align: left">
                Реализация программы создания и развития центра - значение оценки реализации
                программы создания и развития центра, в том числе:
              </td>
              <td>Единица</td>
              <td>642</td>
              <td>0100</td>
              <td>1,00</td>
              <td>1,00</td>
              <td rowspan="5">
                {{ getNumberWithSpaces(formatPrice(dataTable?.grantFullAmount)) }}
              </td>
              <td>1,00</td>
              <td>1,00</td>
              <td>0,00</td>
              <td>0,00</td>
              <td>-</td>
              <td>-</td>
              <td rowspan="5">
                {{ getNumberWithSpaces(formatPrice(dataTable?.grantFactAmount)) }}
              </td>
              <td rowspan="5">{{ getNumberWithSpaces(formatPrice(dataTable?.grantBalance)) }}</td>
            </tr>
            <tr>
              <td style="min-width: 400px; text-align: left">
                Показатель №1. Обеспеченная деятельностью центров численность подготовленных
                специалистов, имеющих высшее образование, по основным образовательным программам
                высшего образования и дополнительным образовательным программам, в том числе с
                использованием сетевых форм и дистанционных образовательных технологий, необходимых
                для разработки и (или) практического использования сквозных технологий Национальной
                технологической инициативы.
              </td>
              <td>Человек</td>
              <td>792</td>
              <td>0101</td>
              <td>
                {{ formatPrice(dataTable?.indicatorTrainedSpecialist?.fullPlanAmount) }}
              </td>
              <td>
                {{ formatPrice(dataTable?.indicatorTrainedSpecialist?.yearPlanAmount) }}
              </td>
              <td>
                {{ formatPrice(dataTable?.indicatorTrainedSpecialist?.fullFactAmount) }}
              </td>
              <td>
                {{ formatPrice(dataTable?.indicatorTrainedSpecialist?.yearFactAmount) }}
              </td>
              <td>
                {{ formatPrice(dataTable?.indicatorTrainedSpecialist?.factAbsolutAmount) }}
              </td>
              <td>{{ formatPrice(dataTable?.indicatorTrainedSpecialist?.factPercent) }}</td>
              <td>{{ reasons?.indicatorTrainedSpecialist }}</td>
              <td>{{ reasons?.reasonRejectionTrainedSpecialist }}</td>
            </tr>
            <tr>
              <td style="min-width: 400px; text-align: left">
                Показатель №2. Число заключенных организацией, структурным подразделением которой
                является центр, лицензионных соглашений на передачу права использования и (или)
                отчуждения права на результаты интеллектуальной деятельности, созданные центром и
                (или) находящиеся под управлением центра.
              </td>
              <td>Штука</td>
              <td>796</td>
              <td>0102</td>
              <td>{{ formatPrice(dataTable?.indicatorLicenses?.fullPlanAmount) }}</td>
              <td>{{ formatPrice(dataTable?.indicatorLicenses?.yearPlanAmount) }}</td>
              <td>{{ formatPrice(dataTable?.indicatorLicenses?.fullFactAmount) }}</td>
              <td>{{ formatPrice(dataTable?.indicatorLicenses?.yearFactAmount) }}</td>
              <td>{{ formatPrice(dataTable?.indicatorLicenses?.factAbsolutAmount) }}</td>
              <td>{{ formatPrice(dataTable?.indicatorLicenses?.factPercent) }}</td>
              <td>{{ reasons?.indicatorLicenses }}</td>
              <td>{{ reasons?.reasonRejectionLicenses }}</td>
            </tr>
            <tr>
              <td style="min-width: 400px; text-align: left">
                Показатель №3. Размер средств, получаемых организацией, структурным подразделением
                которой является центр, от приносящей доход деятельности, источником которых
                является деятельность центра, в том числе управление правами на результаты
                интеллектуальной деятельности, реализация платных образовательных услуг, реализация
                научных исследований и разработок, экспертно-аналитическая и консультационная
                деятельность, осуществляемая на возмездной основе, а также получаемых за счет
                предоставления доступа к объектам информационной инфраструктуры и инфраструктуры
                научной, научно-технической и инновационной деятельности
              </td>
              <td>Рубль</td>
              <td>383</td>
              <td>0103</td>
              <td>{{ formatPrice(dataTable?.indicatorIncome?.fullPlanAmount) }}</td>
              <td>{{ formatPrice(dataTable?.indicatorIncome?.yearPlanAmount) }}</td>
              <td>{{ formatPrice(dataTable?.indicatorIncome?.fullFactAmount) }}</td>
              <td>{{ formatPrice(dataTable?.indicatorIncome?.yearFactAmount) }}</td>
              <td>{{ formatPrice(dataTable?.indicatorIncome?.factAbsolutAmount) }}</td>
              <td>{{ formatPrice(dataTable?.indicatorIncome?.factPercent) }}</td>
              <td>{{ reasons?.indicatorIncome }}</td>
              <td>{{ reasons?.reasonRejectionIncome }}</td>
            </tr>
            <tr>
              <td style="min-width: 400px; text-align: left">
                Показатель №4. Число полученных по итогам прикладных научных исследований и (или)
                экспериментальных разработок, осуществляемых центром, охраняемых результатов
                интеллектуальной деятельности, предусмотренных Гражданским кодексом Российской
                Федерации
              </td>
              <td>Штука</td>
              <td>796</td>
              <td>0104</td>
              <td>
                {{ formatPrice(dataTable?.indicatorExperimentalDevelopments?.fullPlanAmount) }}
              </td>
              <td>
                {{ formatPrice(dataTable?.indicatorExperimentalDevelopments?.yearPlanAmount) }}
              </td>
              <td>
                {{ formatPrice(dataTable?.indicatorExperimentalDevelopments?.fullFactAmount) }}
              </td>
              <td>
                {{ formatPrice(dataTable?.indicatorExperimentalDevelopments?.yearFactAmount) }}
              </td>
              <td>
                {{ formatPrice(dataTable?.indicatorExperimentalDevelopments?.factAbsolutAmount) }}
              </td>
              <td>{{ formatPrice(dataTable?.indicatorExperimentalDevelopments?.factPercent) }}</td>
              <td>{{ reasons?.indicatorExperimentalDevelopments }}</td>
              <td>{{ reasons?.reasonRejectionExperimentalDevelopments }}</td>
            </tr>
            <tr>
              <td class="border-none"></td>
              <td class="border-none"></td>
              <td class="border-none"></td>
              <td class="border-none"></td>
              <td class="border-none"></td>
              <td class="border-none"></td>
              <td class="border-none"></td>
              <td class="border-none" style="text-align: right">Всего</td>
              <td>{{ getNumberWithSpaces(formatPrice(dataTable?.grantFullAmount)) }}</td>
              <td class="border-none"></td>
              <td class="border-none"></td>
              <td class="border-none"></td>
              <td class="border-none"></td>
              <td class="border-none"></td>
              <td class="border-none" style="text-align: right">Всего</td>
              <td>{{ getNumberWithSpaces(formatPrice(dataTable?.grantFactAmount)) }}</td>
              <td>{{ getNumberWithSpaces(formatPrice(dataTable?.grantBalance)) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <template
        v-if="
          isWinner &&
          (report.status === REPORTS_STATUSES.DRAFT || report.status === REPORTS_STATUSES.REJECTED)
        "
      >
        <h4>Причины отклонения</h4>
        <form>
          <v-col v-for="group in groups" cols="12" class="px-0 mt-1">
            <div v-if="group?.title" class="font-weight-bold">
              {{ getGroupName(group?.id) }}
            </div>
            <div v-for="input in getField(group?.id)" :key="input.id">
              <p v-if="input.title" class="text-body-1 mx-0 my-2">
                {{ input.title }}
              </p>
              <Component
                :is="inputs[getFormInput(input.type)]"
                v-bind="input"
                @change-value="updateDataInStash"
                @update-value="updateValue"
              />
            </div>
          </v-col>
        </form>
      </template>
      <template v-if="isWinner">
        <v-btn
          depressed
          color="primary"
          variant="elevated"
          class="mr-2"
          @click="printAchievementResult"
        >
          Печать
        </v-btn>
        <v-btn
          v-if="
            isWinner &&
            (report.status === REPORTS_STATUSES.DRAFT ||
              report.status === REPORTS_STATUSES.REJECTED)
          "
          :disabled="isDisableSubmitButton"
          depressed
          color="primary"
          variant="outlined"
          @click="onSubmit"
        >
          Сохранить
        </v-btn>
        <v-btn
          class="ml-2 text-decoration-underline"
          color="primary"
          variant="text"
          @click="goBack"
        >
          Закрыть
        </v-btn>
      </template>
      <v-btn
        v-else
        class="text-decoration-underline px-0"
        color="primary"
        variant="text"
        @click="goBack"
      >
        Закрыть
      </v-btn>
    </template>
  </the-container>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'

import { api } from '@/api/Api'

import {
  inputs,
  getFormInput,
  getNumberWithSpaces,
  downloadFilePdfData,
  clearStash,
} from '@/helpers/index'

import { REPORTS_STATUSES } from '@/constants'

import TheContainer from '@/components/TheContainer'
import { RESULT_VALUE_ACHIEVEMENT_REPORT_FORM } from '@/constants/stash'

const route = useRoute()
const router = useRouter()
const store = useStore()

const isWinner = computed(() => store.getters['user/isWinner'])
const fieldFormHash = computed(() => `${RESULT_VALUE_ACHIEVEMENT_REPORT_FORM}-${route.params?.id}`)

const reasons = ref()
const report = ref([])
const isLoading = ref(false)
const dataTable = ref(null)
const fieldsForm = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'number',
    name: 'indicatorTrainedSpecialist',
    title: 'Код',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '10' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'reasonRejectionTrainedSpecialist',
    title: 'Причина отклонения',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'number',
    name: 'indicatorLicenses',
    title: 'Код',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '10' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'text',
    name: 'reasonRejectionLicenses',
    title: 'Причина отклонения',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'number',
    name: 'indicatorIncome',
    title: 'Код',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '10' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 2,
    type: 'text',
    name: 'reasonRejectionIncome',
    title: 'Причина отклонения',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'number',
    name: 'indicatorExperimentalDevelopments',
    title: 'Код',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '10' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 3,
    type: 'text',
    name: 'reasonRejectionExperimentalDevelopments',
    title: 'Причина отклонения',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 4,
    type: 'date',
    name: 'reportSubmissionDate',
    title: 'Дата сдачи отчета в ЭБ',
    default_value: null,
    descr: '',
    note: '',
    multiple: false,
    rules: [['required']],
    sort: 1,
  },
])
const groups = ref([
  {
    id: 0,
    name: 'indicatorTrainedSpecialist',
    title: 'Показатель 1',
  },
  {
    id: 1,
    name: 'indicatorLicenses',
    title: 'Показатель 2',
  },
  {
    id: 2,
    name: 'indicatorIncome',
    title: 'Показатель 3',
  },
  {
    id: 3,
    name: 'indicatorExperimentalDevelopments',
    title: 'Показатель 4',
  },
  {
    id: 4,
    name: 'date',
  },
])

const isDisableSubmitButton = computed(() => !!fieldsForm.value?.find((el) => !el.default_value))

function goBack() {
  return router.go(-1)
}

function getGroupName(id) {
  const group = groups.value?.find((el) => el.id === id)

  return group?.title || ''
}

function getField(id) {
  return fieldsForm.value?.filter((el) => el.group_id === id)
}

async function updateDataInStash({ value, id }) {
  const findField = fieldsForm.value?.find((el) => el.id === id)
  if (findField) {
    const params = {
      field: findField.name,
      value: value,
      hash: fieldFormHash.value,
    }

    await api.stash().saveFieldInStash(params)
  }
}

async function getReportData() {
  isLoading.value = true
  try {
    await api
      .reports()
      .getReportData(+route.params.id)
      .then((data) => {
        report.value = data
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function printAchievementResult() {
  try {
    api
      .reports()
      .printAchievementResult(route.params?.id)
      .then((response) => {
        downloadFilePdfData(response, 'Детализированный отчет о структуре расходов')
      })
  } catch (err) {
    console.log(err)
  }
}

function updateValue({ value, id }) {
  fieldsForm.value.forEach((item) => {
    if (item.id === id) {
      item.default_value = value
    }
  })
}

async function onSubmit() {
  try {
    let data = {}
    fieldsForm.value?.forEach((el) => {
      data[el.name] =
        el.type === 'date' ? moment(el.default_value).format('YYYY-MM-DD') : el.default_value
    })
    await api
      .reports()
      .submitAchievementOutcomeValuesForm(route.params?.id, data)
      .then(() => {
        clearStash(fieldFormHash.value).then(() => getSavedData())
        getAchievementOutcomeValues()
        getAchievementResultStructure()
      })
  } catch (err) {
    console.log(err)
  }
}

function formatPrice(str) {
  if (!str) {
    return '0,00'
  }

  return getNumberWithSpaces(`${parseFloat(str)?.toFixed(2)}`?.replace('.', ','))
}

async function getAchievementOutcomeValues() {
  isLoading.value = true
  try {
    await api
      .reports()
      .getAchievementOutcomeValues(route.params?.id)
      .then((data) => {
        reasons.value = data
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function getAchievementResultStructure() {
  isLoading.value = true
  try {
    await api
      .reports()
      .getAchievementResultStructure(route.params?.id)
      .then((data) => {
        dataTable.value = data
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function getSavedData() {
  fieldsForm.value?.forEach((el) => (el.default_value = null))
  isLoading.value = true
  try {
    await api
      .stash()
      .getStashData(fieldFormHash.value)
      .then((data) => {
        if (!data) {
          return
        }
        fieldsForm.value?.forEach((el) => {
          if (el.name in data) {
            el.default_value = data[el.name]
            console.log(el.default_value)
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getSavedData()
  getAchievementOutcomeValues()
  getAchievementResultStructure()
  getReportData()
})
</script>
