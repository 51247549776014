<template>
  <the-container>
    <h3 class="mb-6">
      {{ route.params?.id ? 'Редактирование участника' : 'Добавление участника' }}
    </h3>
    <v-row
      v-if="isLoading"
      class="w-100 h-100 d-flex align-center justify-center"
      style="min-height: 400px"
    >
      <v-progress-circular indeterminate color="primary" />
    </v-row>
    <v-form v-else ref="form" lazy-validation>
      <v-row>
        <v-col cols="12" md="6">
          <div v-for="field in fieldsForm" :key="field.id">
            <p v-if="field.title" class="text-body-1 ma-0">
              {{ field.title }}
            </p>
            <Component
              :is="inputs[getFormInput(field.type)]"
              v-bind="field"
              @change-value="updateDataInStash"
              @update-value="(data) => updateValue(fieldsForm, data)"
            />
          </div>
          <v-col class="px-0 mt-6">
            <div class="v-messages">
              <span class="text-body-2">* - поля обязательные для заполнения</span>
            </div>
            <div class="v-messages">
              <span class="text-body-2">Введенные данные сохраняются автоматически</span>
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-col>
        <v-row class="px-0 align-center">
          <v-btn depressed color="primary" variant="elevated" @click="onSubmit"> Сохранить </v-btn>
          <v-btn
            class="ml-2 text-decoration-underline"
            color="primary"
            variant="text"
            @click="openConsortiumsPage"
          >
            Закрыть
          </v-btn>
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
            :size="20"
            class="ml-4"
            :width="3"
          />
        </v-row>
      </v-col>
    </v-form>
  </the-container>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router/dist/vue-router'

import { api } from '@/api/Api'

import { inputs, getFormInput, updateValue, focusErrorInput } from '@/helpers/index'
import {
  CATEGORY_BUSINESS_ENTITY,
  CONSORTIA_LAW_FORM,
  CONSORTIA_MEMBERS_TYPE,
  CONSORTIA_UNIQUE,
  NTI_INFORMATION_TABS,
} from '@/constants'

import TheContainer from '@/components/TheContainer'

const router = useRouter()
const route = useRoute()
const store = useStore()

const fieldFormHash = computed(() => {
  if (route.params?.id) {
    return `consortium-${route.params?.id}-${store.getters['user/hash']}`
  }

  return `consortium-${store.getters['user/hash']}`
})
const centerNti = computed(() => store.getters['project/centerNti'])

const validForm = ref(true)
const form = ref(null)
const isLoading = ref(false)
const fieldsForm = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'centerNtiShortTitle',
    title: 'Центр НТИ',
    options: [],
    default_value: null,
    disabled: true,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'partnerName',
    title: 'Наименование партнера (участника консорциума)',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 2,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    name: 'lawForm',
    title: 'Организационно-правовая форма',
    options: CONSORTIA_LAW_FORM,
    default_value: null,
    descr: '',
    itemValue: 'value',
    itemTitle: 'value',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 3,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    name: 'entrepreneurSubjectCategory',
    title: 'Категория субъекта предпринимательства',
    options: CATEGORY_BUSINESS_ENTITY,
    itemValue: 'value',
    itemTitle: 'value',
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 4,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'number',
    name: 'participantINN',
    title: 'ИНН участника консорциума*',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['granteeINN'], ['string', { max: '10' }]],
    sort: 5,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    name: 'participantType',
    title: 'Тип участника консорциума',
    options: CONSORTIA_MEMBERS_TYPE,
    itemValue: 'value',
    itemTitle: 'value',
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 5,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'entryAgreementNumber',
    title: 'Номер соглашения и вступлении в консорциум',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 6,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'date',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    required: true,
    name: 'entryAgreementDate',
    title: 'Дата соглашения о вступлении в консорциум',
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    name: 'otherParticipation',
    title: 'Уникальность',
    options: CONSORTIA_UNIQUE,
    default_value: null,
    descr: '',
    note: '',
    itemValue: 'value',
    itemTitle: 'title',
    multiple: 0,
    rules: [['required']],
    sort: 8,
  },
])

async function getSavedData() {
  isLoading.value = true
  try {
    await api
      .stash()
      .getStashData(fieldFormHash.value)
      .then((data) => {
        fieldsForm?.value?.forEach((el) => {
          if (data[el.name] !== undefined) {
            if (el.type === 'select') {
              const findEl = el.options?.find((el) => el.value === data[el.name])
              return (el.default_value = findEl ? findEl.title : data[el.name])
            }

            el.default_value = data[el.name]
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function updateDataInStash({ value, id }) {
  const findField = fieldsForm.value?.find((el) => el.id === id)
  if (findField) {
    const params = {
      field: findField.name,
      value: value,
      hash: fieldFormHash.value,
    }

    await api.stash().saveFieldInStash(params)
  }
}

async function clearStash() {
  try {
    await api.stash().clearStash(fieldFormHash.value)
  } catch (err) {
    console.log(err)
  }
}

async function getConsortiums() {
  isLoading.value = true
  try {
    await api
      .information()
      .getConsortium(route.params?.id)
      .then((data) => {
        fieldsForm.value?.forEach((el) => {
          if (data[el.name] !== undefined) {
            if (data[el.name] !== undefined) {
              if (el.type === 'select') {
                const findEl = el.options?.find((el) => el.value === data[el.name])
                return (el.default_value = findEl ? findEl.title : data[el.name])
              }

              el.default_value = data[el.name]
            }

            const value = data[el.name]
            if (value) {
              el.default_value = value
            }
          }
        })

        getSavedData()
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function createConsortiums(data) {
  isLoading.value = true
  try {
    await api
      .information()
      .createConsortiums(data)
      .then(() => {
        clearStash()
        openConsortiumsPage()
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function updateConsortiums(data) {
  isLoading.value = true
  try {
    await api
      .information()
      .updateConsortiums(data, route.params?.id)
      .then(() => {
        clearStash()
        openConsortiumsPage()
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function onSubmit() {
  await form.value.validate()

  if (!validForm.value || form.value.errors.length) {
    const errInput = form.value.errors[0]
    focusErrorInput(errInput)
    return
  }

  isLoading.value = true

  try {
    let params = {}
    fieldsForm.value?.forEach((el) => {
      if (el.type === 'date') {
        return (params[el.name] = moment(el.default_value).format('YYYY-MM-DD'))
      }

      if (el.type === 'number') {
        return (params[el.name] = +el.default_value)
      }

      params[el.name] = el.default_value
    })
    params['centerNtiId'] = centerNti.value?.id
    if (route.params?.id) {
      return updateConsortiums(params)
    }

    return createConsortiums(params)
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function openConsortiumsPage() {
  return router.push(`/nti-information?tab=${NTI_INFORMATION_TABS.CONSORTIA}`)
}

onMounted(() => {
  if (route.params?.id) {
    return getConsortiums()
  }

  fieldsForm.value?.forEach((el) => {
    if (el.name === 'centerNtiShortTitle' && centerNti.value) {
      el.default_value = centerNti.value?.shortTitle
    }
  })

  return getSavedData()
})
</script>
