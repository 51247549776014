<template>
  <the-container>
    <template #title>
      <h1 class="text-h4">Управление формами</h1>
    </template>
    <v-container class="px-0">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-title> Наименование формы </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-row>
              <v-col cols="auto">
                <v-btn variant="text" class="text-body-2"> Управление менеджерами </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn variant="text" class="text-body-2"> Редактировать конкурс </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title> Наименование формы </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="auto">
                  <v-btn variant="text" class="text-body-2"> Управление менеджерами </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn variant="text" class="text-body-2"> Редактировать конкурс </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col>
        <v-btn variant="text" class="text-body-1" to="/new-service"> Добавить новую форму </v-btn>
      </v-col>
    </v-row>
  </the-container>
</template>

<script>
export default {
  name: 'FormsView',
}
</script>

<script setup>
import TheContainer from '@/components/TheContainer'
</script>
<style scoped></style>
