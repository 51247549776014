<template>
  <div
    v-if="isVisible"
    class="control"
    :class="{
      'control--end': !isLastOrder,
    }"
  >
    <span v-if="isLastOrder" class="control__item">
      <v-btn v-if="showAddButton" variant="text" class="control__btn" @click="add(fieldId)">
        <template v-if="!isShowIcon">
          {{ btnAdd }}
        </template>
        <template v-else>
          <i class="pi-plus pi" />
        </template>
      </v-btn>
    </span>
    <span v-if="!isOneField" class="control__item">
      <v-btn variant="text" class="control__btn text-red" @click="deleteItem(fieldId)">
        <template v-if="!isShowIcon"> Удалить </template>
        <i v-else class="pi-trash pi" />
      </v-btn>
    </span>
  </div>
</template>

<script setup>
defineProps({
  isVisible: {
    type: [Boolean, Number],
    default: false,
  },
  btnAdd: {
    type: String,
    default: '',
  },
  fieldId: {
    type: [String, Number],
    default: null,
  },
  isLastOrder: {
    type: Boolean,
    default: false,
  },
  isOneField: {
    type: Boolean,
    default: false,
  },
  isShowIcon: {
    type: Boolean,
    default: false,
  },
  showAddButton: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['add-new-input', 'add-new-group', 'delete-input', 'delete-group'])

function add(id) {
  emit('add-new-input', id)
  emit('add-new-group')
}

function deleteItem(id) {
  emit('delete-input', id)
  emit('delete-group', id)
}
</script>

<style lang="scss" scoped>
.control {
  display: flex;
  margin-bottom: 16px;
  width: 100%;
  justify-content: space-between;
  &--end {
    justify-content: flex-end;
  }

  &__item {
    display: inline-flex;
    position: relative;
    align-items: center;
  }

  &__btn {
    color: #1a2574;
    cursor: pointer;
    transition: 0.2s;
    line-height: 22px;
    background-color: transparent !important;
    @media (max-width: 599.95px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
