<template>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <div v-else :key="data.length">
    <v-table v-if="data?.length" class="text-caption">
      <thead>
        <tr>
          <th v-for="el in head" :key="el" :style="`min-width: ${el.width}px`" class="text-left">
            {{ el.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!data.length">
          <td colspan="8" class="text-center py-4 text-caption text-grey">Список пуст</td>
        </tr>
        <tr v-for="(item, index) in data" :key="`tr-${index}`">
          <td
            v-for="val in Object.values(item)"
            :key="`data-${val}`"
            :style="`min-width: ${head[i]?.width}px`"
          >
            {{ val }}
          </td>
        </tr>
      </tbody>
    </v-table>
    <v-row v-else class="justify-center align-center py-12 text-caption text-grey">
      Нет данных
    </v-row>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'

import { api } from '@/api/Api'
import { useRoute } from 'vue-router/dist/vue-router'
const route = useRoute()

const isLoading = ref(false)
const data = ref([])
const head = ref([
  {
    title: 'Наименование РИД',
  },
  {
    title: 'Вид РИД',
  },
  {
    title: 'Регистрационный номер РИД',
  },
  {
    title: 'Дата регистрации РИД',
  },
  {
    title: 'Международный патентный код',
  },
  {
    title: 'Правообладатель РИД',
  },
  {
    title: 'Авторы РИД - штатные сотрудники Центра НТИ (ФИО)',
  },
  {
    title: 'Авторы РИД - штатные сотрудники участника Консорциума (ФИО)',
  },
])

async function getAccountRegisterRid() {
  isLoading.value = true
  try {
    await api
      .reports()
      .getAccountRegisterRid(route.params.id)
      .then((res) => {
        data.value = res?.map((el) => {
          return {
            name: el.name,
            type: el.type,
            registrationNumber: el.registrationNumber,
            registrationDate: el.registrationDate,
            internationalPatentCode: el.internationalPatentCode,
            owner: el.owner,
            authorsStaffMembersNTI: el.authorsStaffMembersNTI,
            authorsStaffMembersConsortium: el.authorsStaffMembersConsortium,
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getAccountRegisterRid()
})
</script>
