<template>
  <the-container>
    <h3 v-if="isManager || isMonitor" class="mb-6">Карточки Программ получателей поддержки</h3>
    <h3 v-else class="mb-6">Запросы на создание карточки программы получателя поддержки</h3>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <template v-else>
      <v-col v-if="!projects.length" class="align-center justify-start pa-0 ma-0">
        <div v-if="isWinner" class="text-caption text-grey">
          Список пуст. Нажмите "Подать запрос" чтобы создать новую заявку
        </div>
        <div v-else>Список пуст.</div>
      </v-col>
      <v-expansion-panels v-else class="mt-6" accordion>
        <v-expansion-panel v-for="program in projects" :key="program.id" elevation="0">
          <v-expansion-panel-title class="text-subtitle-2 font-weight-black">
            {{ program?.programName }}
          </v-expansion-panel-title>

          <v-expansion-panel-text class="pb-2">
            <v-row
              v-if="isTicketLoading"
              style="height: 132px"
              class="pa-0 ma-0 w-100 justify-center align-center"
            >
              <v-progress-circular indeterminate color="primary" :size="20" :width="2" />
            </v-row>
            <div v-else>
              <v-expansion-panels class="border" accordion>
                <v-expansion-panel elevation="0">
                  <v-expansion-panel-title
                    color="#F7F9FC"
                    class="p-2 font-weight-black text-caption"
                  >
                    Запрос на создание карточки программы получателя поддержки
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="pa-4 pb-2">
                    <v-row :class="['align-center text-caption d-flex flex-column flex-md-row']">
                      <v-col sm="12" md="6" class="text-grey pa-0 pl-0">
                        Статус: {{ getStatusProgram(program.status) }}
                      </v-col>
                      <v-col sm="12" md="6" class="text-left text-md-center pa-0 justify-end">
                        <RouterLink
                          class="text-blue-darken-1"
                          :to="getRequestRoute(program.id, program.status)"
                        >
                          Перейти
                        </RouterLink>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels class="mt-6 border" accordion>
                <v-expansion-panel elevation="0">
                  <v-expansion-panel-title
                    color="#F7F9FC"
                    class="p-2 font-weight-black text-caption"
                  >
                    Запрос на изменение программы
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="py-0 pb-2 px-0 custom-panel">
                    <v-col class="align-center text-caption pa-4">
                      <v-row
                        v-if="!tickets.length"
                        class="px-0 mx-0 text-caption text-grey text-center justify-center"
                      >
                        Список пуст
                      </v-row>

                      <template v-else>
                        <v-row class="pa-4 mx-0">
                          <v-col sm="12" md="3" class="font-weight-black pa-0">
                            Дата и время подачи
                          </v-col>
                          <v-col sm="12" md="3" class="font-weight-black pa-0"> Инициатор </v-col>
                          <v-col sm="12" md="3" class="text-grey pa-0"> Статус </v-col>
                          <v-col sm="12" md="3" class="text-center pa-0" />
                        </v-row>
                        <v-row
                          v-for="ticket in tickets"
                          :key="ticket.id"
                          class="ma-0 align-center text-caption pa-4 border-t-sm"
                        >
                          <v-col sm="12" md="3" class="pa-0">
                            {{ getFormatDateTime(ticket?.createdAt) }}
                          </v-col>
                          <v-col sm="12" md="3" class="pa-0">
                            {{ getInitiators(ticket?.initiatorGroupIds) }}
                          </v-col>
                          <v-col sm="12" md="3" class="text-grey pa-0">
                            {{ getLabelStatus(ticket.status) }}
                          </v-col>
                          <v-col
                            v-if="
                              ticket.status !== TICKETS_STATUSES.BLANK ||
                              (isManager && ticket.status === TICKETS_STATUSES.BLANK)
                            "
                            sm="12"
                            md="3"
                            class="text-center pa-0"
                          >
                            <RouterLink
                              class="text-blue-darken-1"
                              :to="getUrlRequestChangeProgram(program.id, ticket.id)"
                            >
                              Перейти
                            </RouterLink>
                          </v-col>
                        </v-row>
                      </template>
                    </v-col>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-btn
                v-if="
                  !isTicketLoading &&
                  isShowCreateTicketButton &&
                  (isManager || isWinner) &&
                  getIsActiveProject(program)
                "
                color="primary"
                class="mt-6"
                @click="submitRequest"
              >
                Подать запрос на изменение программы
              </v-btn>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-btn
        v-if="isWinner && isShowCreateButton"
        color="primary"
        class="mt-6"
        @click="createNewProjectRequest"
      >
        Подать запрос на создание карточки
      </v-btn>
    </template>
  </the-container>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import { api } from '@/api/Api'
import router from '@/router'

import { getStatusProgram } from '@/helpers'

import TheContainer from '@/components/TheContainer'
import { useToast } from '@/composables/useToast'
import { TICKETS_LABELS, TICKETS_STATUSES } from '@/constants/tickets'
import { WINNER } from '@/enums/roles'

import { getFormatDateTime } from '@/helpers'
import { PROJECT_REQUEST_STATUSES } from '@/constants'

const store = useStore()
const { toast } = useToast()

const projects = ref([])
const isLoading = ref(false)
const isTicketLoading = ref(false)
const tickets = ref([])
const isShowCreateTicketButton = ref(true)

const isShowCreateButton = computed(() => !projects.value.length)
const isManager = computed(() => store.getters['user/isManager'])
const isMonitor = computed(() => store.getters['user/isMonitor'])
const isWinner = computed(() => store.getters['user/isWinner'])

function submitRequest() {
  if (!tickets.value.length) {
    toast.info('Данные сохраняются автоматически')
  }

  return router.push(`/coordination-documents/program-change-request-form`)
}

function createNewProjectRequest() {
  toast.info('Данные сохраняются автоматически')
  return router.push(`/coordination-documents/request-create-support-recipient-program-card/`)
}

function getRequestRoute(id, status) {
  if (status === 'rejected' && isWinner.value) {
    return `/coordination-documents/request-create-support-recipient-program-card/${id}`
  }

  if (isMonitor.value) {
    return `/coordination-documents/request-create-support-recipient-program-card-view-for-monitor/${id}`
  }

  if (!isManager.value) {
    return `/coordination-documents/request-create-support-recipient-program-card-view-for-winner/${id}`
  }

  return `/coordination-documents/request-create-support-recipient-program-card-view-for-manager/${id}`
}

function getUrlRequestChangeProgram(projectId, id) {
  return `/coordination-documents/program-change-request-form/${id}`
}

async function getProjects() {
  isLoading.value = true
  try {
    await api
      .projects()
      .getProjects()
      .then((data) => {
        projects.value = data
        //projects.value = isWinner.value && data?.length ? [data[0]] : data
        //isShowCreateButton.value = !projects.value?.length && isWinner.value
      })
  } catch (err) {
    toast.error(err.message)
  } finally {
    isLoading.value = false
  }
}

function getLabelStatus(status) {
  if (!status) {
    return ''
  }
  return TICKETS_LABELS[status]
}

function getInitiators(ids) {
  const isWinner = ids?.includes(WINNER)
  if (isWinner) {
    return 'Победитель'
  }

  return 'Менеджер'
}

function getIsActiveProject(program) {
  return program.status === PROJECT_REQUEST_STATUSES.ACCEPTED
}

onMounted(() => {
  getProjects()
})
</script>

<style lang="scss">
.custom-panel {
  padding: 0;
  > div {
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  div {
    flex: 1;
    margin: 0;
  }
}
</style>
