<template>
  <v-form ref="form" lazy-validation>
    <section v-if="fields.length !== 0" class="form__container">
      <v-row :no-gutters="true">
        <v-col>
          <div v-for="(field, i) in fields" :key="field.id">
            <template v-if="!field.group_id">
              <p v-if="field.title" class="text-body-1 ma-0">
                {{ field.title }}<span v-if="isRequired(field)">*</span>
              </p>
              <Component
                :is="inputs[getFormInput(field.type)]"
                :file-data="field.fileUrl"
                v-bind="field"
                @update-value="updateValue"
              />
              <div v-if="comments?.hasOwnProperty(field.id)" class="text-red mb-6">
                Комментарий менеджера: {{ comments[field?.id] }}
              </div>
            </template>
            <FormButtonControls
              v-if="!canEdit && isVisibleFormControl(field)"
              :is-visible="isVisibleFormControl(field)"
              :field-id="field.id"
              :readonly="readOnly"
              :is-last-order="isLastOrder(field)"
              :is-one-field="isOneField(field)"
              btn-add="Добавить поле"
              @add-new-input="addNewInput(field.id, fields)"
              @delete-input="deleteInput"
            />
            <FormButtonControlsEdit
              v-if="canEdit"
              :is-visible="canEdit && field.type !== 'group' && !field.group_id"
              :field-id="field.id"
              btn-add="Изменить поле"
              btn-delete="Удалить поле"
              @delete-filed="$emit('delete', field.id)"
              @update-field="$emit('update', field.id, false)"
            />
            <div v-if="field.type === 'group'" class="border mb-5 pa-5">
              <div class="v-input__details">
                <div class="v-messages">
                  <div class="v-messages__message">
                    {{ field.descr }}
                  </div>
                </div>
              </div>
              <div v-for="(item, index) in groupById(field, fields)" :key="item.id">
                <v-row class="align-center flex-nowrap">
                  <v-col class="flex-grow-1">
                    <p class="text-body-1 ma-0">
                      {{ item.title }}<span v-if="isRequired(item)">*</span>
                    </p>
                    <Component
                      :is="inputs[getFormInput(item.type)]"
                      v-bind="item"
                      @update-value="updateValue"
                    />
                    <div v-if="comments?.hasOwnProperty(item.id)" class="text-red mb-6">
                      Комментарий менеджера: {{ comments[item?.id] }}
                    </div>
                  </v-col>
                  <v-row class="flex-grow-0">
                    <FormButtonControls
                      v-if="!canEdit"
                      :is-visible="isVisibleFormControlByGroup(item)"
                      :field-id="item.id"
                      :show-add-button="false"
                      is-show-icon
                      :is-last-order="isLastOrder(item)"
                      :is-one-field="isOneField(item)"
                      btn-add="Добавить поле"
                      @add-new-input="addNewInput(item.id, fields)"
                      @delete-input="deleteInput"
                    />
                    <FormButtonControlsEdit
                      v-if="canEdit"
                      :is-visible="canEdit && !field.group_id"
                      btn-add="Изменить поле"
                      btn-delete="Удалить поле"
                      @delete-filed="$emit('delete', field.id)"
                      @update-field="$emit('update', field.id, false)"
                    />
                  </v-row>
                </v-row>
                <v-col v-if="getIsShowButtonControl(item, groupById(field, fields), index)">
                  <v-btn
                    v-if="isVisibleFormControlByGroup(item)"
                    color="white"
                    plain
                    @click="addNewInput(item.id, fields)"
                  >
                    Добавить поле
                    <i class="pi-plus pi ml-2" style="font-size: 16px" />
                  </v-btn>
                </v-col>
              </div>
            </div>
            <FormButtonControls
              v-if="!canEdit"
              :show-add-button="getIsShowButtonControl(field, fields, i)"
              :is-visible="isVisibleFormControlGroup(field)"
              :field-id="field.id"
              :is-last-order="isLastOrder(field)"
              :is-one-field="isOneField(field)"
              :btn-add="field.buttonAdd ? field.buttonAdd : 'Добавить группу полей'"
              @add-new-group="addNewGroup(field.id, fields)"
              @delete-group="deleteGroup"
            />
            <FormButtonControlsEdit
              v-if="canEdit"
              :is-visible="canEdit && field.type === 'group'"
              :field-id="field.id"
              btn-add="Изменить группу"
              btn-delete="Удалить группу"
              @delete-filed="$emit('delete', field.id)"
              @update-field="$emit('update', field.id, true)"
            />
          </div>
          <div class="v-input__details pb-2">
            <div class="v-messages">
              <span class="text-body-2">* - поля обязательные для заполнения</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </section>
  </v-form>
</template>

<script setup>
import FormText from '@/components/Form/FormText'
import FormSelect from '@/components/Form/FormSelect'
import FormCombobox from '@/components/Form/FormCombobox'
import FormTextCombobox from '@/components/Form/FormTextCombobox'
import FormFile from '@/components/Form/FormFile'
import FormTextarea from '@/components/Form/FormTextarea'
import FormButtonControls from '@/components/Form/FormButtonControls'
import FormRadio from '@/components/Form/FormRadio'
import FormCheckbox from '@/components/Form/FormCheckbox'
import FormTextRules from '@/components/Form/FormTextRules'
import { api } from '@/api/Api'
import { computed, onMounted, ref } from 'vue'
import { useToast } from '@/composables/useToast'
import FormButtonControlsEdit from '@/components/Form/FormButtonControlsEdit'
import cloneDeep from 'lodash/cloneDeep'
import { useForms } from '@/composables/forms/useForms'
const { toast } = useToast()
const { addNewGroup, addNewInput } = useForms()

const validForm = ref(true)
const form = ref(null)
const inputs = {
  FormText,
  FormTextarea,
  FormSelect,
  FormCombobox,
  FormTextCombobox,
  FormFile,
  FormRadio,
  FormCheckbox,
  FormTextRules,
}

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  canEdit: {
    type: Boolean,
    default: false,
  },
  isDialog: {
    type: Boolean,
    default: false,
  },
  canNoValidate: {
    type: Boolean,
    default: false,
  },
  comments: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits(['update-forms', 'get-forms', 'delete-input', 'delete', 'update'])

const formData = ref(null)
const isFetch = ref(true)

const fields = computed({
  get() {
    const array = formData.value ? formData.value : []
    return array.sort((a, b) => a.sort - b.sort)
  },
  set(value) {
    formData.value = value
  },
})

function getFormInput(type) {
  switch (type) {
    case 'hidden': {
      return ''
    }
    case 'text': {
      return 'FormText'
    }
    case 'textarea': {
      return 'FormTextarea'
    }
    case 'select': {
      return 'FormSelect'
    }
    case 'select-other': {
      return 'FormSelect'
    }
    case 'combobox': {
      return 'FormCombobox'
    }
    case 'combobox_other': {
      return 'FormTextCombobox'
    }
    case 'group': {
      return ''
    }
    case 'checkbox': {
      return 'FormCheckbox'
    }
    case 'radio': {
      return 'FormRadio'
    }
    case 'file': {
      return 'FormFile'
    }
    case 'rules': {
      return 'FormTextRules'
    }
    default: {
      return ''
    }
  }
}

function getIsShowButtonControl(item, fields, index) {
  const newArr = fields.map((el) => el.name)
  const findIndex = newArr?.lastIndexOf(item.name)
  return index === findIndex
}

function groupById(field, fields) {
  const { id, type } = field
  if (type !== 'group') {
    return
  }
  return fields.filter((item) => item.group_id === id)
}

function updateValue({ value, id }) {
  formData.value.forEach((item) => {
    if (item.id === id) {
      item.default_value = value
    }
  })
  emit('update-forms', formData.value)
}

function focusErrorInput({ id }) {
  const inputId = '#' + id
  document.querySelector(inputId).focus()
}

async function getForm() {
  if (props.canNoValidate) {
    emit('get-forms', formData.value, true)
    return
  }

  const { valid } = await form.value.validate()
  if (!valid) {
    const errInput = form.value.errors[0]
    focusErrorInput(errInput)
    return
  }

  emit('get-forms', formData.value, valid)
}

async function sendForm() {
  await form.value.validate()
  if (!validForm.value) {
    const errInput = form.value.errors[0]
    focusErrorInput(errInput)
    return
  }
  const { id: formId, fields } = { ...formData.value }
  const formBody = new FormData()
  formBody.append('form_id', formId)
  fields.forEach((field) => {
    if (field.multiple) {
      if (field.type === 'file') {
        formBody.append(
          [`fields[${field.name}][]`],
          field.default_value[0] || field.fileUrl ? field.default_value[0] || field.fileUrl : '',
        )
      } else {
        formBody.append([`fields[${field.name}][]`], field.default_value ?? '')
      }
    } else {
      formBody.append(
        [`fields[${field.name}]`],
        field.default_value || field.fileUrl ? field.default_value || field.fileUrl : '',
      )
    }
  })
  try {
    await api.result().sendResultByForm(formBody)
    await form.value.reset()
  } catch ({ response }) {
    const { data, status } = response
    if (status === 500) {
      const message = 'Возникла ошибка, попробуйте повторить позднее'
      toast.error(message)
    } else {
      data.forEach((msg) => {
        toast.error(msg.message)
      })
    }
  }
}

function isVisibleFormControl({ multiple, type, group_id }) {
  return multiple === 1 && type !== 'group' && type !== 'checkbox' && !group_id
}

function isVisibleFormControlGroup({ multiple, type }) {
  return multiple === 1 && type === 'group'
}

function isVisibleFormControlByGroup({ multiple, type }) {
  return multiple === 1 && type !== 'checkbox'
}

function deleteInput(id) {
  fields.value = fields.value.filter((item) => item.id !== id)
}

function deleteGroup(id) {
  fields.value = fields.value.filter((item) => item.id !== id)
  fields.value = fields.value.filter((item) => item.group_id !== id)
}

function isLastOrder({ name, sort, group_id }) {
  const lastItem = fields.value
    .filter((item) => item.name === name && item.group_id === group_id)
    .pop()
  return lastItem.sort === sort
}

function isOneField({ name, group_id }) {
  const items = fields.value.filter((item) => item.name === name && item.group_id === group_id)
  return items.length === 1
}

function isRequired({ rules }) {
  if (!Array.isArray(rules)) {
    return false
  }

  if (props.canEdit) {
    return false
  }

  return rules?.flat().includes('required')
}

onMounted(() => {
  if (props.isDialog) {
    setTimeout(() => {
      isFetch.value = false
    }, 1000)
  }
  fields.value = cloneDeep(props.data)
})

defineExpose({
  sendForm,
  getForm,
})
</script>
<script>
export default {
  name: 'FormMain',
}
</script>

<style lang="scss" scoped></style>
