<template>
  <the-container>
    <h3 class="mb-6">
      Детализированный отчет о структуре расходов, источником финансового обеспечения которых
      являются средства гранта
    </h3>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <template v-else>
      <template v-if="!isEdited">
        <report-form-preview
          :form-fields="formFields"
          :form-groups="formGroups"
          @download-file="downloadFile"
          @print-pdf="printReportDetailsForm"
        />
      </template>
      <v-form v-else ref="form" lazy-validation>
        <v-col
          v-for="category in formGroups"
          :key="`category-${category.id}-${formFields?.length}`"
          class="pa-0 ma-0"
        >
          <p v-if="!!category.title" class="text-subtitle-1 mx-0 my-2 mb-4 font-weight-bold">
            {{ category.title }} {{ category.id && currentYear ? ` год ${currentYear}` : '' }}
            {{ category.idDescriptionTitle }}
          </p>
          <template v-if="category?.subcategories?.length">
            <div v-for="subcategory in category?.subcategories" :key="subcategory.id" class="my-4">
              <div v-if="subcategory.title" class="text-body-1 font-weight-bold mb-2">
                {{ subcategory.title }}
              </div>
              <div
                v-for="field in getSubcategoryField(category.id, subcategory.id)"
                :key="field.id"
              >
                <div v-if="field.title" class="text-body-1 mx-0">
                  {{ field.title }}{{ isEdited && field?.type !== 'dropFile' ? '*' : '' }}
                </div>
                <div :class="[field.disabled ? 'disabled' : '']">
                  <Component
                    :is="inputs[getFormInput(field.type)]"
                    v-bind="field"
                    @update-value="(data) => updateValue(formFields, data)"
                    @change-value="updateTotalValue(field, category.id)"
                    @delete-file="deleteFile"
                    @download-file="downloadFile"
                  />
                </div>
              </div>
            </div>
          </template>

          <div v-if="getCategoryFields(category.id)?.length" class="mb-4">
            <div v-for="field in getCategoryFields(category.id)" :key="`field-${field.id}`">
              <p v-if="field.title" class="text-body-1 mx-0 my-0">
                {{ field.title }}{{ isEdited && field?.type !== 'dropFile' ? '*' : '' }}
              </p>
              <div :class="[field.disabled ? 'disabled' : '']">
                <Component
                  :is="inputs[getFormInput(field.type)]"
                  v-bind="field"
                  @update-value="(data) => updateValue(formFields, data)"
                  @change-value="updateTotalValue(field, category.id)"
                  @delete-file="deleteFile"
                  @download-file="downloadFile"
                />
              </div>
              <v-row class="justify-end pa-0 ma-0">
                <button
                  v-if="
                    field.copy_group &&
                    getIsLastElementInGroup(field.id, category.id, field.copy_group)
                  "
                  class="ml-2"
                  type="button"
                  @click="() => deleteFieldsGroup(field.group_id, field.copy_group)"
                >
                  <v-icon color="red" size="xs"> mdi-delete-outline </v-icon>
                  <span class="text-body-2 title text-red">Удалить блок</span>
                </button>
              </v-row>
            </div>
          </div>
          <div v-if="category.buttons?.length">
            <div v-for="field in category.buttons" :key="`field-${field.id}`">
              <v-row
                v-if="
                  (field.name === 'add-data-prev-year' && !route.params?.year) ||
                  field.name !== 'add-data-prev-year'
                "
                class="pa-0 ma-0 justify-space-between align-center"
              >
                <div :class="[field.disabled ? 'disabled' : '']">
                  <Component
                    :is="inputs[getFormInput(field.type)]"
                    v-bind="field"
                    :text="category.id === 5 ? buttonTitle : field.text"
                    @update-value="(data) => updateValue(formFields, data)"
                    @change-value="updateTotalValue(field, category.id)"
                  />
                </div>
              </v-row>
            </div>
          </div>
          <div v-if="category.total" class="mt-8 font-weight-bold text-subtitle-1">
            <div :key="getCategoryTotalAmountKey(category.id)" class="mb-2">Итого:</div>
            <div>{{ category.total }} руб.</div>
          </div>

          <div v-if="category.note" class="my-4">
            <div
              v-for="(note, index) in category.note"
              :key="`note-${index}`"
              class="text-grey text-caption mb-1"
            >
              {{ note }}
            </div>
          </div>
        </v-col>
        <v-row class="px-0 align-center mx-0 mt-6">
          <v-btn
            depressed
            color="primary"
            variant="elevated"
            class="mr-4"
            @click="printReportDetailsForm"
          >
            Печать
          </v-btn>
          <v-btn depressed color="primary" variant="outlined" @click="onSubmit"> Сохранить </v-btn>
          <v-btn
            class="ml-2 text-decoration-underline"
            color="primary"
            variant="text"
            @click="goBack"
          >
            Закрыть
          </v-btn>
        </v-row>
      </v-form>
    </template>
  </the-container>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'

import { api } from '@/api/Api'

import {
  inputs,
  getFormInput,
  focusErrorInput,
  downloadFileWithLinkData,
  downloadFilePdfData,
  round,
} from '@/helpers/index'

import {
  WINNER_FOLDER,
  DETAILED_REPORT_FORM_FOLDER,
  DETAILED_REPORT_PREV_YEAR_FORM_FOLDER,
} from '@/constants/store'
import { FORM_DETAILS_STRUCTURE } from '@/constants/forms'

import TheContainer from '@/components/TheContainer'
import { useToast } from '@/composables/useToast'
import ReportFormPreview from '@/components/Reports/ReportFormPreview'
import { DETAILED_EXPENSE_STRUCTURE_REPORT_HASH } from '@/constants/cachesIds'
import store from '@/store'
import { REPORTS_STATUSES } from '@/constants'
import { REPORT_FILES } from '@/constants/buckets'

const { toast } = useToast()
const route = useRoute()
const router = useRouter()

const isManager = computed(() => store.getters['user/isManager'])
const isWinner = computed(() => store.getters['user/isWinner'])
const isMonitor = computed(() => store.getters['user/isMonitor'])
const bucket = computed(() => REPORT_FILES)
const currentYear = computed(() => currentReport.value?.year)
const buttonTitle = computed(() =>
  currentReport.value?.previousYear
    ? 'Редактировать данные за предыдущий год'
    : 'Добавить данные за предыдущий год',
)

const isEdited = ref(true)
const isLoading = ref(true)
const idForm = ref(null)
const currentReport = ref(null)
const formData = ref(null)
const form = ref(null)
const hash = ref(
  `${DETAILED_EXPENSE_STRUCTURE_REPORT_HASH}-${route.params?.id || ''}${
    route.params?.year ? `-${route.params?.year}` : ''
  }`,
)
const softwareAcquisitionCosts = ref(0)

const formFields = ref([...FORM_DETAILS_STRUCTURE])

const formGroups = ref([
  {
    id: 5,
    buttons: [
      {
        id: uuidv4(),
        type: 'actionButton',
        name: 'add-data-prev-year',
        isPrevYear: route.params?.year,
        text: buttonTitle.value,
        default_value: null,
        action: () => addDataForExpectedYear(),
      },
    ],
  },
  {
    id: 0,
    title: 'Общие сведения',
  },
  {
    id: 1,
    title: 'I. Структура расходов средств гранта',
    name: 'reportExpenseStructureGeneralSection',
    idDescriptionTitle: '*6',
    subcategories: [
      {
        id: 0,
        title: 'Выплаты персоналу, всего (включая начисления на фонд оплаты труда)',
      },
      {
        id: 1,
        title: 'Закупка работ и услуг',
      },
      {
        id: 2,
        title: 'Приобретение оборудования',
      },
      {
        id: 3,
        title: 'Приобретение программного обеспечения',
      },
      {
        id: 4,
        title: 'ИТОГОВАЯ СУММА:',
        isTotal: true,
      },
    ],
    note: [
      '*6 - Таблица заполняется по каждому гранту, предоставленному в соответствующем году, в случае если в отчетном периоде было движение средств',
      '*7 - Отражаются кассовые расходы, произведенные Получателем гранта, за отчетный период.',
    ],
  },
  {
    id: 2,
    title: 'II. Расходы средств гранта на приобретение оборудования',
    idDescriptionTitle: '*8',
    note: [
      '*8 - Таблица заполняется по каждому гранту, предоставленному в соответствующем году, в случае если в отчетном периоде было движение средств',
      '*9 - Наименование оборудования указывается в соответствии с договором поставки. В случае если в рамках одного договора приобретаются разные группы оборудования (не предполагающегося для совместного использования), то такие группы/позиции указываются в разных строках таблицы',
      '*10 - Указываются реквизиты договоров, актов, товарных накладных, платежных поручений',
    ],
    name: 'reportExpenseStructureEquipment',
    total: softwareAcquisitionCosts.value,
    buttons: [
      {
        id: uuidv4(),
        type: 'actionButton',
        name: 'add-block',
        text: 'Добавить блок',
        default_value: null,
        action: () => addBlock(2),
      },
    ],
  },
  {
    id: 3,
    idDescriptionTitle: '*11',
    title: 'III. Расходы средств гранта на приобретение программного обеспечения',
    name: 'reportExpenseStructureSoftwareSection',
    total: 0,
    note: [
      '*11 - Таблица заполняется по каждому гранту, предоставленному в соответствующем году, в случае если в отчетном периоде было движение средств',
      '*12 - Наименование программного обеспечения указывается в соответствии с договором поставки. В случае если в рамках одного договора приобретаются разные группы программного обеспечения (не предполагающегося для совместного использования), то такие группы/позиции указываются в разных строках таблицы',
      '*13 - Указываются реквизиты договоров, актов, товарных накладных, платежных поручений',
    ],
    buttons: [
      {
        id: uuidv4(),
        type: 'actionButton',
        name: 'add-block',
        text: 'Добавить блок',
        default_value: null,
        action: () => addBlock(3),
      },
    ],
  },
  {
    id: 4,
    title: 'IV. Расходы средств гранта на оплату оказанных услуг, выполненных работ',
    idDescriptionTitle: '*14',
    total: 0,
    name: 'reportExpenseStructureServiceSection',
    buttons: [
      {
        id: uuidv4(),
        type: 'actionButton',
        name: 'add-block',
        text: 'Добавить блок',
        default_value: null,
        action: () => addBlock(4),
      },
    ],
  },
  {
    id: 6,
    note: [
      '*14 - Таблица заполняется по каждому гранту, предоставленному в соответствующем году, в случае если в отчетном периоде было движение средств',
      '*15 - Наименование услуг, и (или) работ указывается в соответствии с договором. В случае если в рамках одного договора были заказаны разные услуги и (или) работы, то такие позиции указываются в разных строках таблицы',
      '*16 - Указываются реквизиты договоров, актов, платежных поручений',
    ],
  },
])

function groupFields(fields, key) {
  return fields?.reduce((acc, obj) => {
    const property = obj[key] || 0
    acc[property] = acc[property] || []
    acc[property].push(obj)
    return acc
  }, {})
}

function goBack() {
  clearForm()
  return router.go(-1)
}

function deleteFieldsGroup(categoryId, copyGroup) {
  const categoryFields = formFields.value?.filter((el) => el.group_id === categoryId)
  const copyFields = categoryFields?.filter((el) => el.copy_group === copyGroup)
  const findIndexFirstElement = formFields.value?.findIndex((el) => copyFields[0].id === el.id)
  if (findIndexFirstElement !== -1 && copyFields.length) {
    formFields.value?.splice(findIndexFirstElement, copyFields.length)
    updateTotalValue(categoryFields[0], categoryId)
  }
}

function getIsLastElementInGroup(id, categoryId, copyGroupId) {
  const categoryFields = formFields.value?.filter((el) => el.group_id === categoryId)
  const copyFields = categoryFields?.filter((el) => el.copy_group === copyGroupId)
  const element = copyFields?.findIndex((el) => el.id === id)
  return element === copyFields.length - 1
}

function getGroupValue(groupId, data) {
  if (groupId === 1) {
    return data[0]
  }
  if (groupId === 2) {
    return data?.map((el) => {
      return {
        equipmentName: el.equipmentName,
        unitPrice: +el.unitPrice,
        count: +el.count,
        nameAndDetailsOfDocuments: el.nameAndDetailsOfDocuments,
      }
    })
  }

  if (groupId === 3) {
    return data?.map((el) => {
      return {
        softwareName: el.softwareName,
        unitPrice: +el.unitPrice,
        count: +el.count,
        nameAndDetailsOfDocuments: el.nameAndDetailsOfDocuments,
      }
    })
  }

  if (groupId === 4) {
    return data?.map((el) => {
      return {
        serviceName: el.serviceName,
        price: +el.price,
        nameAndDetailsOfDocuments: el.nameAndDetailsOfDocuments,
      }
    })
  }
}

async function clearStash() {
  try {
    await api.stash().clearStash(hash.value)
  } catch (err) {
    console.log(err)
  }
}

async function updateDataInStash({ id }) {
  const findField = formFields.value?.find((el) => el.id === id)

  if (findField) {
    const group = findField?.group_id

    const selectGroupName = formGroups.value?.find((el) => el.id === group)?.name
    const fields = formFields.value?.filter((el) => el.group_id === group)
    const filterData = groupFields(fields, 'copy_group')

    if (!filterData) {
      return
    }

    const newValue = Object.values(filterData)?.map((el) => {
      let data
      el?.forEach((field) => {
        data = {
          ...data,
          [field.name]: field.default_value,
        }
        return data
      })
      return data
    })

    const fieldsValue = getGroupValue(group, newValue)

    const params = {
      field: selectGroupName,
      value: fieldsValue,
      hash: hash.value,
    }

    await api
      .stash()
      .saveFieldInStash(params)
      .then(() => {
        if (selectGroupName === 'reportExpenseStructureGeneralSection') {
          updateTotalAmount(fieldsValue)
        }
      })
  }
}

function updateTotalAmount(value) {
  const fields = formFields.value?.filter((el) => el.group_id === 1)
  fields?.forEach((el) => {
    if (el.name === 'balanceStartPeriod') {
      el.default_value = round(
        +value?.expenseEquipmentBeginning +
          +value?.expenseSoftwareBeginning +
          +value?.expenseStaffBeginning +
          +value?.expenseServiceBeginning,
      )
    }

    if (el.name === 'factSizeExpenses') {
      el.default_value = round(
        +value?.expenseEquipmentActual +
          +value?.expenseSoftwareActual +
          +value?.expenseStaffActual +
          +value?.expenseServiceActual,
      )
    }

    if (el.name === 'balanceEnd') {
      el.default_value = round(
        +value?.expenseEquipmentEnd +
          +value?.expenseSoftwareEnd +
          +value?.expenseStaffEnd +
          +value?.expenseServiceEnd,
      )
    }
  })
}

async function getDetailsReportData(year) {
  isLoading.value = true
  try {
    await api
      .reports()
      .getDetailsReportData(route.params?.id, year)
      .then((data) => {
        idForm.value = data.id
        formData.value = data
        const { reportExpenseStructureGeneralSection } = data
        formFields.value?.forEach((el) => {
          if (el.group_id === 1 && el.name in reportExpenseStructureGeneralSection) {
            el.default_value = round(reportExpenseStructureGeneralSection[el.name])
          }
        })
        if (!isWinner.value) {
          setSavedData(data)
        }
        currentReport.value = data
        if (
          isManager.value ||
          isMonitor.value ||
          (isWinner.value &&
            data?.reportStatus !== REPORTS_STATUSES.DRAFT &&
            data?.reportStatus !== REPORTS_STATUSES.REJECTED)
        ) {
          isEdited.value = false
        }

        if (
          isWinner.value &&
          (currentReport.value?.reportStatus === REPORTS_STATUSES.DRAFT ||
            currentReport.value?.reportStatus === REPORTS_STATUSES.REJECTED)
        ) {
          getSavedData()
        } else if (isWinner.value) {
          setSavedData(data)
        }
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function setSavedData(data) {
  if (!data) {
    return
  }
  const { reportExpenseStructureGeneralSection } = data

  formFields.value?.forEach((el) => {
    if (el.name in data && data[el.name]) {
      el.default_value = data[el.name]
    }
  })

  formGroups.value?.forEach((group) => {
    if (
      group?.name &&
      data &&
      group?.name in data &&
      typeof data[group?.name]?.length === 'number'
    ) {
      data[group?.name]?.forEach((data, index) => {
        if (!index) {
          const fields = formFields.value?.filter((el) => el.group_id === group.id)
          fields?.forEach((field) => {
            if (field.name && field.name in data) {
              field.default_value = data[field.name]
              updateTotalValue(field, group.id, true)
            }
          })
        } else {
          const hasGroup = formFields.value?.find(
            (el) => el.group_id === group.id && el.copy_group === index,
          )

          if (!hasGroup) {
            addBlock(group.id)
          }
          const fields = formFields.value?.filter(
            (el) => el.group_id === group.id && el.copy_group === index,
          )
          fields.forEach((field) => {
            if (field.name && field.name in data) {
              field.default_value = data[field.name]
              updateTotalValue(field, group.id, true)
            }
          })
        }
      })
    }

    if (group?.name in data && typeof data[group?.name]?.length !== 'number') {
      const savedData = data[group?.name]
      formFields.value?.forEach((field) => {
        if (
          field.name === 'balanceEnd' ||
          field.name === 'balanceStartPeriod' ||
          field.name === 'factSizeExpenses'
        ) {
          return
        }
        if (field.name in savedData) {
          field.default_value = savedData[field.name]
        }
      })
    }
  })

  formFields.value?.forEach((el) => {
    getAmountSection(el)
  })

  updateTotalAmount(reportExpenseStructureGeneralSection)
}

async function getSavedData() {
  isLoading.value = true
  try {
    await api
      .stash()
      .getStashData(hash.value)
      .then((data) => {
        const concatData = formData.value
        const keys = Object.keys(concatData)
        keys?.forEach((el) => {
          if (el in data) {
            concatData[el] = data[el]
          }
        })
        setSavedData(concatData)
        updateTotalAmount(concatData.reportExpenseStructureGeneralSection)
      })
  } catch (err) {
    toast.error(err)
  } finally {
    isLoading.value = false
  }
}

function addDataForExpectedYear() {
  clearForm()
  router.push(
    `/reports/detailed-expense-structure-report-form/${route.params?.id}/${
      +currentReport.value?.year - 1
    }`,
  )
}

function getCategoryTotalAmountKey(categoryId) {
  switch (categoryId) {
    case 2:
      return softwareAcquisitionCosts.value
    default:
      return
  }
}

function updateValue(fieldsForm, data) {
  const { value, id } = data
  fieldsForm.forEach((item) => {
    if (item.id === id) {
      item.default_value = value
      item.isEdit = true

      if (item.type === 'dropFile') {
        value?.forEach((el) => {
          uploadFile(item.name, el, item.copy_group)
        })
      }
    }
  })
}

async function downloadFile(id, name) {
  try {
    await api
      .files()
      .downloadFile(id)
      .then((data) => {
        downloadFileWithLinkData(data, name)
      })
  } catch (err) {
    toast.error(err)
  }
}

async function deleteFile(id) {
  try {
    await api
      .files()
      .deleteFile(id)
      .then(() => {
        getFiles()
      })
  } catch (err) {
    toast.error(err)
  }
}

async function uploadFile(name, data, copyGroup) {
  try {
    const form = new FormData()
    form.append('file', data)
    form.append('fileKey', name)
    form.append('entityId', route.params?.id)
    form.append(
      'folder',
      `${WINNER_FOLDER}/${
        route.params?.year ? DETAILED_REPORT_PREV_YEAR_FORM_FOLDER : DETAILED_REPORT_FORM_FOLDER
      }`,
    )

    if (copyGroup) {
      form.append('groupId', copyGroup)
    }

    await api
      .files()
      .uploadFile(bucket.value, form)
      .then(() => {
        getFiles()
      })
  } catch (err) {
    toast.error(err)
  }
}

async function getFiles() {
  try {
    const params = { entityId: route.params?.id }
    await api
      .files()
      .getFiles(bucket.value, params)
      .then((data) => {
        const allContent = data?.filesInFolders
        if (!allContent && allContent[WINNER_FOLDER]) {
          return
        }
        const filesForms = allContent[WINNER_FOLDER]

        const files = route.params?.year
          ? filesForms[DETAILED_REPORT_PREV_YEAR_FORM_FOLDER]
          : filesForms[DETAILED_REPORT_FORM_FOLDER]

        if (!files) {
          formFields.value?.forEach((el) => {
            el.links = []
          })
        }
        formFields.value?.forEach((el) => {
          if (el.type !== 'dropFile') {
            return
          }

          if (el.name in files) {
            el.links = files[el.name]?.filter((file) => file.groupId == el?.copy_group)
          } else {
            el.links = []
          }
        })
      })
  } catch (err) {
    //toast.error(err)
  }
}

function addBlock(idGroup) {
  const templateFieldsGroup = formFields.value?.filter(
    (el) => el.group_id === idGroup && !el?.copy_group,
  )

  const countGroups = formFields.value?.filter(
    (el) => el.name === templateFieldsGroup[0].name && el.group_id === idGroup,
  )?.length

  const newGroupFields = templateFieldsGroup?.map((el) => {
    return {
      ...el,
      id: uuidv4(),
      copy_group: countGroups,
      default_value: null,
      links: [],
    }
  })
  formFields.value.splice(formFields.value?.length, 0, ...newGroupFields)
}

function getSubcategoryField(categoryId, subcategoryId) {
  const categoryFields = formFields.value?.filter((el) => el.group_id === categoryId)
  const subcategoryFields = categoryFields?.filter((el) => el.subcategory === subcategoryId)
  return subcategoryFields
}

function getCategoryFields(categoryId) {
  const categoryFields = formFields.value?.filter(
    (el) => el.group_id === categoryId && typeof el?.subcategory !== 'number',
  )
  return categoryFields
}

function getAmountSection(field) {
  const fields = formFields.value?.filter((el) => el.subcategory === field.subcategory)

  if (!fields?.length || field.subcategory === undefined) {
    return
  }
  const beginningBalance = fields.find((el) => el.computedProperty === 'beginningBalance')
  const actualExpenses = fields.find((el) => el.computedProperty === 'actualExpenses')
  const balanceEndDate = fields.find((el) => el.computedProperty === 'balanceEndDate')
  const total = +beginningBalance.default_value - +actualExpenses.default_value
  balanceEndDate.default_value = +`${parseFloat(String(total))?.toFixed(2)}`
}

function updateTotalValue(field, idCategory, isSetData) {
  if (field?.computedProperty) {
    getAmountSection(field)
  }

  if (!isSetData) {
    updateDataInStash({ id: field.id })
  }

  if (idCategory > 4 && idCategory < 1) {
    return
  }

  if (field.name !== 'count' && field.name !== 'unitPrice' && field.name !== 'price') {
    return
  }

  const fields = formFields.value?.filter((el) => el.group_id === idCategory)
  const group = formGroups.value?.find((el) => el.id === idCategory)

  if (group) {
    const newValue = groupFields(fields, 'copy_group')

    let sum = 0
    if (idCategory === 4) {
      Object.values(newValue)?.forEach((fields) => {
        const price = fields?.find((el) => el.name === 'price')?.default_value
        sum += round(+price)
      })
      return (group.total = String(sum || 0).replace('.', ','))
    }

    Object.values(newValue)?.forEach((fields) => {
      const count = fields?.find((el) => el.name === 'count')?.default_value || 0
      const pricePart = fields?.find((el) => el.name === 'unitPrice')?.default_value || 0
      const amountField = fields?.find((el) => el.name === 'amount')
      sum += round(+count * +pricePart)
      if (amountField) {
        amountField.default_value = round((+count || '0') * (+pricePart || '0'))
      }
    })
    return (group.total = String(sum || 0).replace('.', ','))
  }
}

function queryDataGeneration() {
  const reportExpenseStructureEquipmentFields = formFields.value?.filter((el) => el.group_id === 2)
  const reportExpenseStructureEquipment = groupFields(
    reportExpenseStructureEquipmentFields,
    'copy_group',
  )

  const reportExpenseStructureEquipmentData = Object.values(reportExpenseStructureEquipment)?.map(
    (el) => {
      const equipmentName = el?.find((el) => el.name === 'equipmentName')
      const unitPrice = el?.find((el) => el.name === 'unitPrice')
      const count = el?.find((el) => el.name === 'count')
      const nameAndDetailsOfDocuments = el?.find((el) => el.name === 'nameAndDetailsOfDocuments')
      return {
        equipmentName: equipmentName.default_value,
        unitPrice: +unitPrice.default_value,
        count: +count.default_value,
        nameAndDetailsOfDocuments: nameAndDetailsOfDocuments.default_value,
      }
    },
  )

  let reportExpenseStructureGeneralSectionFields = formFields.value?.filter(
    (el) => el.group_id === 1,
  )

  let reportExpenseStructureGeneralSection = {}
  reportExpenseStructureGeneralSectionFields.forEach((el) => {
    if (el.type !== 'dropFile') {
      reportExpenseStructureGeneralSection[el.name] =
        el.type === 'number' ? +el.default_value : el.default_value
    }
  })

  const reportExpenseStructureSoftwareSectionFields = formFields.value?.filter(
    (el) => el.group_id === 3,
  )
  const reportExpenseStructureSoftwareSection = groupFields(
    reportExpenseStructureSoftwareSectionFields,
    'copy_group',
  )

  const reportExpenseStructureSoftwareSectionData = Object.values(
    reportExpenseStructureSoftwareSection,
  )?.map((el) => {
    const softwareName = el?.find((el) => el.name === 'softwareName')
    const unitPrice = el?.find((el) => el.name === 'unitPrice')
    const count = el?.find((el) => el.name === 'count')
    const nameAndDetailsOfDocuments = el?.find((el) => el.name === 'nameAndDetailsOfDocuments')
    return {
      softwareName: softwareName.default_value,
      unitPrice: +unitPrice.default_value,
      count: +count.default_value,
      nameAndDetailsOfDocuments: nameAndDetailsOfDocuments.default_value,
    }
  })

  const reportExpenseStructureWorkSectionFields = formFields.value?.filter(
    (el) => el.group_id === 4,
  )

  const reportExpenseStructureWorkSection = groupFields(
    reportExpenseStructureWorkSectionFields,
    'copy_group',
  )

  const reportExpenseStructureWorkSectionData = Object.values(
    reportExpenseStructureWorkSection,
  )?.map((el) => {
    const serviceName = el?.find((el) => el.name === 'serviceName')
    const price = el?.find((el) => el.name === 'price')
    const nameAndDetailsOfDocuments = el?.find((el) => el.name === 'nameAndDetailsOfDocuments')
    return {
      serviceName: serviceName.default_value,
      price: +price.default_value,
      nameAndDetailsOfDocuments: nameAndDetailsOfDocuments.default_value,
    }
  })

  const params = {
    reportExpenseStructureEquipment: reportExpenseStructureEquipmentData,
    reportExpenseStructureSoftwareSection: reportExpenseStructureSoftwareSectionData,
    reportExpenseStructureGeneralSection: reportExpenseStructureGeneralSection,
    reportExpenseStructureServiceSection: reportExpenseStructureWorkSectionData,
  }

  return params
}

function clearForm() {
  form.value.reset()
  formFields.value?.forEach((el) => {
    if (el?.type === 'dropFile') {
      el.links = []
    }
  })
}

async function onSubmit() {
  await form.value.validate()
  if (form.value.errors?.length) {
    const errInput = form.value.errors[0]

    focusErrorInput(errInput)
    return
  }

  const data = queryDataGeneration()

  try {
    const params = {
      ...data,
      id: idForm.value,
    }

    if (route.params?.year) {
      params.year = route.params?.year
    }
    await api
      .reports()
      .saveExpenseStructure(route.params?.id, params)
      .then(() => {
        clearForm()
        clearStash()
        getFormData()
      })
  } catch (err) {
    toast.error('Ошибка при сохранении данных')
  }
}

async function printReportDetailsForm() {
  try {
    await api
      .reports()
      .printExpenseStructure(route.params?.id)
      .then((response) => {
        downloadFilePdfData(response, 'Детализированный отчет о структуре расходов')
      })
  } catch (err) {
    console.log(err)
  }
}

async function getGeneralReportInfo() {
  isLoading.value = true
  try {
    await api
      .reports()
      .getGeneralReportInfo(route.params?.id)
      .then((data) => {
        formFields.value?.map((el) => {
          if (el.name in data) {
            el.default_value = data[el.name]
          }

          if (el.name === 'year' && route.params?.year) {
            el.default_value = +route.params?.year
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function getFormData() {
  getGeneralReportInfo()
  getFiles()
  if (!route.params?.year) {
    return getDetailsReportData()
  } else {
    return getDetailsReportData(route.params?.year)
  }
}

onMounted(() => {
  getFormData()
})
</script>
