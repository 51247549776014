<template>
  <TheTable :items="users" show-search-filter>
    <Column field="id" header="ID" :sortable="true" />
    <Column field="fio" header="ФИО" :sortable="true" class="text-break" />
    <Column field="email" header="E-Mail" :sortable="true" />
    <Column field="phone" header="Телефон" :sortable="true" />
    <Column field="groups" header="Группы">
      <template #body="{ data: { groups } }">
        <span>{{ setGroups(groups) }}</span>
      </template>
    </Column>
    <Column field="addGroups">
      <template #body="{ data: { id, groups } }">
        <v-btn
          variant="text"
          color="primary"
          class="text-caption"
          @click.prevent="addGroup(id, groups)"
        >
          Назначить группу
        </v-btn>
      </template>
    </Column>
  </TheTable>
  <UsersAddGroupDialog
    ref="addGroupDialog"
    :user-id="selectedUserId"
    :user-groups="selectedGroups"
    @on-close="resetFormGroups"
  />
</template>

<script setup>
import { ref } from 'vue'
import TheTable from '@/components/TheTable'
import Column from 'primevue/column'
import { replaceRole } from '@/utils/common'
import UsersAddGroupDialog from '@/components/Users/UsersAddGroupDialog'

defineProps({
  users: {
    type: Array,
    default: () => [],
  },
})

const addGroupDialog = ref(null)
const selectedUserId = ref(null)
const selectedGroups = ref([])

function concatGroup(groups) {
  return groups.map((group) => replaceRole(group.group_id)).join(', ')
}

function setGroups(groups) {
  if (groups.length === 0) {
    return 'нет группы'
  }

  return concatGroup(groups)
}

function addGroup(userId, groups) {
  if (addGroupDialog.value) {
    selectedUserId.value = userId
    selectedGroups.value = groups
    addGroupDialog.value.open()
  }
}
function resetFormGroups() {
  selectedUserId.value = null
  selectedGroups.value = []
}
</script>

<script>
export default {
  name: 'UsersTable',
}
</script>
