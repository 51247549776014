import { createRouter, createWebHistory } from 'vue-router'
import ServiceView from '@/views/Service/ServiceView.vue'
import CenterNtiView from '@/views/CenterNti/CenterNtiView'
import ReportsArchive from '@/views/Reports/ReportsArchive'
import ProgramProgressReportForm from '@/views/Reports/Forms/ProgramProgressReportForm.vue'
import RequestCreateSupportRecipientProgramCardViewForMonitor from '@/views/Projects/ProgramCardRequestsViewForMonitor'
import RequestCreateSupportRecipientProgramCardViewForManager from '@/views/Projects/ProgramCardRequestsViewForManager'
import ProgramCardRequestsView from '@/views/Projects/ProgramCardRequestsView'
import RequestCreateSupportRecipientProgramCardEdit from '@/views/Projects/ProgramCardRequestsEdit'
import RequestCreateSupportRecipientProgramCard from '@/views/Projects/ProgramCardRequests'
import CoordinationDocumentsView from '@/views/Projects/CoordinationDocumentsView'
import ProgramExpenditureReportForm from '@/views/Reports/Forms/ProgramExpenditureReportForm'
import ReportsView from '@/views/Reports/ReportsView'
import TasksView from '@/views/Tasks/TasksView'
import TaskView from '@/views/Tasks/TaskView'
import ProjectView from '@/views/Project/ProjectView'
import ProjectPreview from '@/views/Project/ProjectPreview'
import ProjectsView from '@/views/Programs/ProjectsView'
import ReportImplementationCostsFromFinancing from '@/views/Reports/Views/ReportImplementationCostsFromFinancing'
import DetailedExpenseStructureReport from '@/views/Reports/Views/DetailedExpenseStructureReport'
import ResultValueAchievementReportForm from '@/views/Reports/ReportingForms/ResultValueAchievementReportForm'
import DetailedExpenseStructureReportForm from '@/views/Reports/ReportingForms/DetailedExpenseStructureReportForm'
import DetailedExpenseStructureReportFormNew from '@/views/Reports/ReportingForms/DetailedExpenseStructureReportFormNew'
import ImplementationCostReportForm from '@/views/Reports/ReportingForms/ImplementationCostReportForm'
import ExpenseReport from '@/views/Reports/ReportingForms/ExpenseReport'
import ReportForm from '@/views/Reports/Forms/ReportForm'
import ProgramChangeRequestPage from '@/views/Projects/ProgramChangeRequest/ProgramChangeRequestPage'
import NtiInformationView from '@/views/NtiInformation/NtiInformationView'
import ChangingContactListGranteesForm from '@/views/NtiInformation/Forms/ChangingContactListGranteesForm'
import ChangeListEmployeesForm from '@/views/NtiInformation/Forms/ChangeListEmployeesForm'
import ConsortiaEditForm from '@/views/NtiInformation/Forms/ConsortiaEditForm'
import ServiceIdView from '@/views/Service/ServiceIdView.vue'
import ServiceFormView from '@/views/Service/ServiceFormView.vue'
import CallbackView from '@/views/CallbackView.vue'
import DefaultLayout from '@/layouts/default.vue'
import { tokenService } from '@/services/token'
import UsersAndRolesView from '@/views/UsersAndRolesView.vue'
import OrdersView from '@/views/OrdersView'
import QuestionnairesView from '@/views/Questionnaire/QuestionnairesView.vue'
import QuestionnaireView from '@/views/Questionnaire/QuestionnaireView.vue'
import FormsView from '@/views/Form/FormsView.vue'
import FormEditView from '@/views/Form/FormEditView.vue'
import store from '@/store'
import {
  ROUTE_PAGE_APPLICATION_FOR_MANAGER,
  ROUTE_PAGE_APPLICATION_FOR_USER,
  ROUTE_OPEN_HISTORY_APPLICATION_FOR_USER,
} from '@/constants'
import ProgramsView from '@/views/Programs/ProgramsView'
import CalendarView from '@/views/Calendar/CalendarView.vue'

const routes = [
  {
    path: '/programs',
    name: 'programs',
    component: DefaultLayout,
    meta: {
      privatePage: true,
      canUser: true,
      canMonitor: true,
      canManager: true,
      isSelections: true,
    },
    children: [
      {
        path: '/programs',
        name: 'programs-list',
        component: ProjectsView,
        meta: {
          privatePage: true,
          canUser: true,
          canMonitor: true,
          canManager: true,
          isSelections: true,
        },
      },
      {
        path: '/programs/:id',
        name: 'programs-view-data',
        component: ProgramsView,
        meta: {
          privatePage: true,
          canUser: true,
          canMonitor: true,
          canManager: true,
          isSelections: true,
        },
      },
      {
        path: '/programs/create/:code1C',
        name: 'create-project-by-code-1c',
        component: ProjectView,
        meta: {
          privatePage: true,
          canUser: true,
          isSelections: true,
          canManager: true,
        },
      },
      {
        path: '/program/preview/:id',
        name: 'project-preview',
        component: ProjectPreview,
        meta: {
          privatePage: true,
          canUser: true,
          isSelections: true,
          canManager: true,
        },
      },
      {
        path: '/edit',
        name: 'edit-project',
        component: ProjectView,
        meta: {
          privatePage: true,
        },
      },
      {
        path: '/project/:id',
        name: 'view-project',
        component: ProjectView,
        meta: {
          privatePage: true,
        },
      },
    ],
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: DefaultLayout,
    meta: {
      privatePage: true,
      canUser: true,
      canMonitor: true,
      canManager: true,
      isSelections: true,
    },
    children: [
      {
        path: '/calendar',
        name: 'calendar-view',
        component: CalendarView,
        meta: {
          privatePage: true,
          canUser: true,
          canMonitor: true,
          canManager: true,
          isSelections: true,
        },
      },
    ],
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: DefaultLayout,
    meta: {
      privatePage: true,
      canUser: true,
      canMonitor: true,
      canManager: true,
      isSelections: true,
    },
    children: [
      {
        path: '/tasks',
        name: 'tasks-list',
        component: TasksView,
        meta: {
          privatePage: true,
          canUser: true,
          canMonitor: true,
          canManager: true,
          isSelections: true,
        },
      },
      {
        path: '/tasks/:id',
        name: 'task-by-id',
        component: TaskView,
        meta: {
          privatePage: true,
          canUser: true,
          canMonitor: true,
          canManager: true,
          isSelections: true,
        },
      },
    ],
  },
  {
    path: '/reports',
    name: 'reports',
    meta: {
      privatePage: true,
      canWinner: true,
      canManager: true,
      canMonitor: true,
    },
    component: DefaultLayout,
    children: [
      {
        path: '/reports',
        name: 'reports-all',
        component: ReportsView,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
          canManager: true,
          canMonitor: true,
        },
      },
      {
        path: '/report/archive-of-changes',
        name: 'report-archive-of-changes',
        component: ReportsArchive,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
          canManager: true,
          canMonitor: true,
        },
      },
      {
        path: '/reports/program-expenditure-report-form/:id',
        name: 'ProgramExpenditureReportForm',
        component: ProgramExpenditureReportForm,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
        },
      },
      {
        path: '/reports/program-progress-report-form/:id',
        name: 'ProgramProgressReportForm ',
        component: ProgramProgressReportForm,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
        },
      },
      {
        path: '/reports/expense-report-form/:id',
        name: 'ExpenseReport',
        component: ExpenseReport,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
        },
      },
      {
        path: '/reports/result-value-achievement-report-form/:id',
        name: 'ResultValueAchievementReport',
        component: ResultValueAchievementReportForm,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
        },
      },
      {
        path: '/reports/detailed-expense-structure-report-form/:id',
        component: DetailedExpenseStructureReportForm,
        name: 'DetailedExpenseStructureReportForm',
        meta: {
          privatePage: true,
          isSelections: true,
          canWinner: true,
        },
      },
      {
        path: '/reports/detailed-expense-structure-report-form/:id/:year',
        component: DetailedExpenseStructureReportForm,
        name: 'DetailedExpenseStructureReportFormPrevYear',
        meta: {
          privatePage: true,
          isSelections: true,
          canWinner: true,
        },
      },
      {
        path: '/reports/detailed-expense-structure-report-form-new/:id',
        component: DetailedExpenseStructureReportFormNew,
        name: 'DetailedExpenseStructureReportFormNew',
        meta: {
          privatePage: true,
          isSelections: true,
          canWinner: true,
        },
      },
      {
        path: '/reports/detailed-expense-structure-report/:id',
        component: DetailedExpenseStructureReport,
        name: 'DetailedExpenseStructureReport',
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
          canAdmin: true,
          canMonitor: true,
        },
      },
      {
        component: ReportImplementationCostsFromFinancing,
        path: '/reports/report-implementation-costs-from-financing/:id',
        name: 'ReportImplementationCostsFromFinancing',
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
          canAdmin: true,
          canMonitor: true,
        },
      },
      {
        path: '/reports/implementation-cost-report-form/:id',
        name: 'ImplementationCostReportForm',
        component: ImplementationCostReportForm,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
          canAdmin: true,
        },
      },
      {
        path: '/reports/report-form/:id',
        name: 'ReportForm',
        component: ReportForm,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
        },
      },
    ],
  },
  {
    path: '/center-nti',
    name: 'centers-nti-default',
    component: DefaultLayout,
    meta: {
      privatePage: true,
      canAdmin: true,
      canManager: true,
      isSelections: true,
    },
    children: [
      {
        path: '/center-nti',
        name: 'centers-nti',
        component: CenterNtiView,
        meta: {
          privatePage: true,
          canAdmin: true,
          canManager: true,
          isSelections: true,
        },
      },
    ],
  },
  {
    path: '/nti-information',
    name: 'NtiInformation',
    meta: {
      privatePage: true,
      canMonitor: true,
      canManager: true,
      canWinner: true,
    },
    component: DefaultLayout,
    children: [
      {
        path: '/nti-information',
        name: 'information',
        component: NtiInformationView,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
          canManager: true,
          canMonitor: true,
        },
      },
      {
        path: '/consortia-form',
        name: 'ConsortiaForm',
        component: ConsortiaEditForm,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
        },
      },
      {
        path: '/consortia-form/:id',
        name: 'ConsortiaEditForm',
        component: ConsortiaEditForm,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
        },
      },
      {
        path: '/employees-form',
        name: 'ChangeListEmployeesForm',
        component: ChangeListEmployeesForm,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
        },
      },
      {
        path: '/employees-form/:id',
        name: 'ChangeListEmployeesEditForm',
        component: ChangeListEmployeesForm,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
        },
      },
      {
        path: '/grantee-contacts-form',
        name: 'ChangingContactListGranteesForm',
        component: ChangingContactListGranteesForm,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
        },
      },
      {
        path: '/grantee-contacts-form/:id',
        name: 'ChangingContactListGranteesEditForm',
        component: ChangingContactListGranteesForm,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
        },
      },
    ],
  },
  {
    path: '/coordination-documents',
    name: 'CoordinationDocuments',
    meta: {
      privatePage: true,
      canMonitor: true,
    },
    component: DefaultLayout,
    children: [
      {
        path: '/coordination-documents',
        name: 'coordination',
        component: CoordinationDocumentsView,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
          canMonitor: true,
        },
      },
      {
        path: '/coordination-documents/request-create-support-recipient-program-card',
        name: 'RequestCreateSupportRecipientProgramCardNew',
        component: RequestCreateSupportRecipientProgramCard,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
          canManager: true,
        },
      },
      {
        path: '/coordination-documents/request-create-support-recipient-program-card/:id',
        name: 'RequestCreateSupportRecipientProgramCard',
        component: RequestCreateSupportRecipientProgramCard,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
          canManager: false,
          canMonitor: false,
          isEdit: true,
        },
      },
      {
        path: '/coordination-documents/request-create-support-recipient-program-card-view-for-winner/:id',
        component: ProgramCardRequestsView,
        name: 'ProgramCardRequestsView',
        meta: {
          privatePage: true,
          isSelections: true,
          canWinner: true,
        },
      },
      {
        path: '/coordination-documents/request-create-support-recipient-program-card-view-for-manager/:id',
        component: RequestCreateSupportRecipientProgramCardViewForManager,
        name: 'RequestCreateSupportRecipientProgramCardViewForManager',
        meta: {
          privatePage: true,
          isSelections: true,
          canManager: true,
        },
      },
      {
        path: '/coordination-documents/request-create-support-recipient-program-card-view-for-monitor/:id',
        component: RequestCreateSupportRecipientProgramCardViewForMonitor,
        name: 'RequestCreateSupportRecipientProgramCardViewForMonitor',
        meta: {
          privatePage: true,
          isSelections: true,
          canManager: true,
        },
      },
      {
        path: '/coordination-documents/request-create-support-recipient-program-card-edit/:id',
        name: 'RequestCreateSupportRecipientProgramCardEdit',
        component: RequestCreateSupportRecipientProgramCardEdit,
        meta: {
          privatePage: true,
          canWinner: true,
          isSelections: true,
          canAdmin: true,
        },
      },
      {
        path: '/coordination-documents/program-change-request-form',
        name: 'ProgramChangeRequestPage',
        component: ProgramChangeRequestPage,
        meta: {
          privatePage: true,
          isSelections: true,
          canManager: true,
          canMonitor: true,
        },
      },
      {
        path: '/coordination-documents/program-change-request-form/:id',
        name: 'ProgramChangeRequestTicketPage',
        component: ProgramChangeRequestPage,
        meta: {
          privatePage: true,
          isSelections: true,
          canManager: true,
          canMonitor: true,
        },
      },
    ],
  },
  {
    path: '/selections',
    name: 'Selections',
    meta: {
      privatePage: true,
    },
    component: DefaultLayout,
    children: [
      {
        path: '/selections',
        name: 'selections',
        component: ServiceView,
        meta: {
          privatePage: true,
          canUser: true,
          isSelections: true,
        },
      },
      {
        path: '/selections/:id',
        name: 'selectionsServiceId',
        component: ServiceIdView,
        props: true,
        meta: {
          privatePage: true,
          canUser: true,
          isSelections: true,
        },
      },
      {
        path: '/selections/form/:id',
        name: 'selectionsFormId',
        component: ServiceFormView,
        props: true,
        meta: {
          privatePage: true,
          canUser: true,
          isSelections: true,
        },
      },
      {
        path: '/selections/my-orders',
        name: 'selectionsMyOrders',
        component: OrdersView,
        meta: {
          privatePage: true,
          canUser: true,
          isSelections: true,
        },
      },
      {
        path: '/selections/questionnaire/:id',
        name: 'selectionsQuestionnaireId',
        component: QuestionnaireView,
        meta: {
          privatePage: true,
          canUser: true,
          isSelections: true,
        },
      },
    ],
  },
  {
    path: '/',
    name: 'Default',
    redirect: '/programs',
    meta: {
      privatePage: true,
      canMonitor: true,
      canUser: true,
    },
    component: DefaultLayout,
    children: [
      {
        path: 'form/:id',
        name: 'formId',
        component: ServiceFormView,
        props: true,
        meta: {
          privatePage: true,
          canUser: true,
          canAdmin: true,
        },
      },
      {
        path: 'users-and-roles',
        name: 'usersAndRoles',
        component: UsersAndRolesView,
        meta: {
          privatePage: true,
          canAdmin: true,
          canAdminCompetition: false,
        },
      },
      {
        path: 'my-orders',
        name: 'myOrders',
        component: OrdersView,
        meta: {
          privatePage: true,
          canUser: true,
        },
      },
      {
        path: 'orders',
        name: 'orders',
        component: OrdersView,
        meta: {
          privatePage: true,
          canManager: true,
          canExpert: true,
        },
      },
      {
        path: 'questionnaires',
        name: 'questionnaires',
        component: QuestionnairesView,
        meta: {
          privatePage: true,
          canManager: true,
          canExpert: true,
        },
      },
      {
        path: 'questionnaire/:id',
        name: 'questionnaireId',
        component: QuestionnaireView,
        meta: {
          privatePage: true,
          canManager: true,
          canUser: true,
          canExpert: true,
        },
      },
      {
        path: 'forms',
        name: 'forms',
        component: FormsView,
        meta: {
          privatePage: true,
          canAdmin: true,
        },
      },
      {
        path: 'form-edit',
        name: 'formEdit',
        component: FormEditView,
        props: true,
        meta: {
          privatePage: true,
          canAdmin: true,
        },
      },
      {
        path: 'form-edit/:id',
        name: 'formEditId',
        component: FormEditView,
        props: true,
        meta: {
          privatePage: true,
          canAdmin: true,
        },
      },
    ],
  },
  {
    path: '/callback',
    name: 'callback',
    component: CallbackView,
    meta: {
      privatePage: false,
    },
  },
  {
    path: '/personal-account-v2-auth',
    name: 'personal-account',
    component: CallbackView,
    props: true,
    meta: {
      privatePage: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, behavior: 'smooth' }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, behavior: 'smooth' }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'formId') {
    const fullPath = to.fullPath
    localStorage.setItem('entryPath', fullPath)
  }
  const {
    privatePage,
    canAdmin,
    canManager,
    canUser,
    canAdminCompetition,
    canMonitor,
    canWinner,
    canExpert,
  } = to.meta
  const isLoggedIn = Boolean(tokenService.getToken())

  if (!isLoggedIn) {
    if (to.fullPath.includes(process.env.VUE_APP_REDIRECT_KEY_LK)) {
      if (to.query?.token) {
        tokenService.saveToken(to.query?.token)
        next('/')
      }
    } else {
      window.location.href = `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_REDIRECT_KEY_LK}`
      next()
    }
    return
  }

  if (privatePage && !isLoggedIn) {
    localStorage.setItem('redirectRoute', to.fullPath)
    next({ name: 'login' })
    return
  }

  if (!privatePage && isLoggedIn) {
    next('/')
  }

  if (!store.getters['user/currentUserId'] && isLoggedIn) {
    await store.dispatch('user/getUserInfo')
  }

  const isExpert = store.getters['user/isExpert']
  const isAdmin = store.getters['user/isAdmin']
  const isManager = store.getters['user/isManager']
  const isUser = store.getters['user/isUser']
  const isAdminCompetiton = store.getters['user/isAdminCompetition']
  const isMonitor = store.getters['user/isMonitor']
  const isWinner = store.getters['user/isWinner']
  if (!isUser && to.fullPath.includes(ROUTE_OPEN_HISTORY_APPLICATION_FOR_USER)) {
    const newUrl = to.fullPath.replace(
      ROUTE_PAGE_APPLICATION_FOR_USER,
      ROUTE_PAGE_APPLICATION_FOR_MANAGER,
    )
    next(newUrl)
  }

  if (isExpert) {
    return next()
  }

  if ((canUser && isUser) || (canMonitor && isMonitor)) {
    return next()
  }

  if (canAdmin && canUser) {
    isAdmin || isUser ? next() : next('/')
    return
  }

  if (canAdminCompetition && isAdminCompetiton) {
    next()
    return
  }

  if (canExpert && isExpert) {
    next()
    return
  }

  if (canAdmin) {
    isAdmin ? next() : next('/')
    return
  }

  if (canManager && canUser) {
    isManager || isUser ? next() : next('/')
    return
  }

  if (!canMonitor) {
    if (isMonitor) {
      next('/coordination-documents')
      return
    }
  }

  if (canManager || canMonitor || canWinner) {
    isManager || isMonitor || isWinner ? next() : next('/')
    return
  }

  if (canUser) {
    isUser ? next() : next('/')
    return
  }

  next()
})

export default router
