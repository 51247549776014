<template>
  <div v-if="descr" class="v-input__details">
    <div class="v-messages">
      <div class="v-messages__message" v-html="descr" />
    </div>
  </div>
  <div v-for="(option, idx) in [value]" :key="option.value" class="checkbox-wrapper">
    <v-checkbox
      :rules="rulesCommon"
      :label="`${title} ${required ? '*' : ''}`"
      :model-value="inputModel"
      :value="value"
      :readonly="readOnly"
      :error-messages="errorMessages"
      :disabled="disabled"
      color="primary"
      density="compact"
      @change="setNewValue"
    />
    <v-list-item
      v-if="option.description"
      :class="{
        'checkbox__description--last': options.length - 1 === idx,
      }"
      class="text-caption checkbox__description"
      v-html="option.description"
    />
  </div>
</template>

<script setup>
import { props as baseProps, emit as baseEmit } from '@/composables/form/usePropsForm'
import { useForm } from '@/composables/form/useForm'

const props = defineProps({
  ...baseProps,
})
console.log(props, '===')
const emit = defineEmits(baseEmit)

function setNewValue(event) {
  inputModel.value = event?.target.checked ? props.value : ''
  emit('change-value', { id: props.id, default_value: event?.target.checked ? props.value : '' })
}
const { rulesCommon, inputModel } = useForm(props, emit)
</script>

<style lang="scss">
.checkbox {
  &__description {
    padding: 0;
    margin-top: -20px;
    margin-left: 40px;
    &--last {
      margin-bottom: 20px;
    }
  }
}

.checkbox-wrapper {
  .v-selection-control {
    display: flex;
    align-items: flex-start;

    .v-selection-control__wrapper {
      margin-right: 20px;
      margin-top: 5px;
    }
  }
}
</style>
