<template>
  <the-container>
    <template #title> Данные команды </template>

    <v-row v-if="loading" class="justify-center align-center py-6">
      <v-progress-circular :indeterminate="true" color="primary" />
    </v-row>
    <template v-else>
      <OrderList v-if="orders" :orders="orders" @update-orders="loadData" />
    </template>
    <div class="text-center mt-6">
      <v-pagination v-model="page" :length="meta?.pageCount" />
    </div>
  </the-container>
</template>

<script>
export default {
  name: 'OrdersView',
}
</script>

<script setup>
import TheContainer from '@/components/TheContainer'
import OrderList from '@/components/Order/Orders'
import { onMounted, ref, watch } from 'vue'
import { api } from '@/api/Api'

const orders = ref({})
const page = ref(1)
const meta = ref({})
const loading = ref(true)

async function loadData() {
  loading.value = true
  try {
    if (meta.value?.pageCount < page.value) {
      loading.value = false
      return
    }

    await api
      .result()
      .getOrders(page.value, 20)
      .then((data) => {
        orders.value = data?.items
        meta.value = data?._meta
      })
  } catch (err) {
    console.log(err)
  } finally {
    loading.value = false
  }
}

watch(page, loadData)

onMounted(loadData)
</script>
