<template>
  <DataTableDialog
    ref="dataTableDialog"
    :head="head"
    :data="data"
    :dialog="dataTableDialog"
    title="Реестр сотрудников Центра НТИ"
    is-dark-theme
    is-table-dialog
    has-export
    @export="exportConsortiums"
    @get-data="getEmployees"
  />
</template>
<script setup>
import { ref } from 'vue'

import DataTableDialog from '@/components/Common/DataTableDialog'
import { api } from '@/api/Api'
import { downloadFileData } from '@/helpers'

const props = defineProps({
  centerNtiId: {
    type: Number,
    default: null,
  },
})

const dataTableDialog = ref(null)
const isLoading = ref(false)
const head = ref([
  {
    title: '№ п/п',
  },
  {
    title: 'ФИО',
  },
  {
    title: 'Должность в Центре НТИ',
  },
  {
    title: 'Дата приема',
  },
  {
    title: 'Дата увольнения',
  },
])
const data = ref([])

async function exportConsortiums() {
  try {
    api
      .information()
      .exportEmployees(props.centerNtiId)
      .then((response) => {
        downloadFileData(response, 'Сотрудники центров НТИ')
      })
  } catch (err) {
    console.log(err)
  }
}

function openDocument() {
  if (dataTableDialog.value) {
    dataTableDialog.value.openDialog()
  }
}

async function getEmployees() {
  isLoading.value = true
  try {
    await api
      .information()
      .getEmployees(props.centerNtiId)
      .then((res) => {
        data.value = res?.map((el) => {
          return {
            PPNumber: el.PPNumber,
            fullName: el.fullName,
            NTICenterJobTitle: el.NTICenterJobTitle,
            receiptDate: el.receiptDate,
            dismissalDate: el.dismissalDate,
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

defineExpose({
  openDocument,
})
</script>
