<template>
  <the-container>
    <h3 class="mb-6">
      Отчет о расходах, источником финансового обеспечения которых являются средства Гранта
    </h3>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0" cols="6"> Наименование показателя </v-col>
      <v-col md="2" class="px-0" cols="2"> Код строки </v-col>
      <v-col md="3" class="px-0" cols="2"> Сумма всего с даты заключения Соглашения </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        Сумма с начала текущего финансового года
      </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0" cols="6"> Остаток гранта на начало года, всего: </v-col>
      <v-col md="2" class="px-0" cols="2"> 0100 </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(previousExpenses?.beginTotal) }}
      </v-col>
      <v-col md="3" class="px-0" cols="2"> {{ formatAmount(currentExpenses?.beginTotal) }} </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0 pl-4" cols="6">
        в том числе:<br />
        требуется для оплаты денежных обязательств
      </v-col>
      <v-col md="2" class="px-0" cols="2"> 0110 </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(previousExpenses?.beginLiabilities) }}
      </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{ formatAmount(currentExpenses?.beginLiabilities) }}
      </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0 pl-4" cols="6"> подлежащий возврату Грантодателю </v-col>
      <v-col md="2" class="px-0" cols="2"> 0120 </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(previousExpenses?.beginRefundableGrantor) }}
      </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{ formatAmount(currentExpenses?.beginRefundableGrantor) }}
      </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0" cols="6"> Поступило средств, всего: </v-col>
      <v-col md="2" class="px-0" cols="2"> 0200 </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{ formatAmount(previousExpenses?.income) }}
      </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(currentExpenses?.income) }}
      </v-col>
    </v-row>
    <!--    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">-->
    <!--      <v-col md="4" class="px-0 pl-4" cols="6"> в том числе: </v-col>-->
    <!--      <v-col md="2" class="px-0" cols="2"> 0210 </v-col>-->
    <!--      <v-col md="3" class="px-0" cols="2"> - </v-col>-->
    <!--      <v-col md="3" class="px-0 col-12 col-md-3" cols="2"> - </v-col>-->
    <!--    </v-row>-->
    <!--    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">-->
    <!--      <v-col md="4" class="px-0" cols="6"></v-col>-->
    <!--      <v-col md="2" class="px-0" cols="2"> 0220 </v-col>-->
    <!--      <v-col md="3" class="px-0" cols="2"> - </v-col>-->
    <!--      <v-col md="3" class="px-0 col-12 col-md-3" cols="12"> - </v-col>-->
    <!--    </v-row>-->
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0" cols="6"> Выплаты по расходам, всего: </v-col>
      <v-col md="2" class="px-0" cols="2"> 0300 </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{ formatAmount(paymentOrdersStructureDetailedAllYears?.all?.amount) }}
      </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(paymentOrdersStructureDetailedYearExactly?.all?.amount) }}
      </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0 pl-4" cols="6">
        в том числе: <br />
        выплаты персоналу, всего:
      </v-col>
      <v-col md="2" class="px-0" cols="2"> 0310 </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{ formatAmount(paymentOrdersStructureDetailedAllYears?.fot?.amount) }}
      </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(paymentOrdersStructureDetailedYearExactly?.fot?.amount) }}
      </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0 pl-8" cols="6"> заработная плата </v-col>
      <v-col md="2" class="px-0" cols="2"> </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{ formatAmount(paymentOrdersStructureDetailedAllYears?.fot?.details?.salary?.amount) }}
      </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(paymentOrdersStructureDetailedYearExactly?.fot?.details?.salary?.amount) }}
      </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0 pl-8" cols="6"> взносы </v-col>
      <v-col md="2" class="px-0" cols="2"> </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{
          formatAmount(paymentOrdersStructureDetailedAllYears?.fot?.details?.contributions?.amount)
        }}
      </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{
          formatAmount(
            paymentOrdersStructureDetailedYearExactly?.fot?.details?.contributions?.amount,
          )
        }}
      </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0 pl-8" cols="6"> НДФЛ </v-col>
      <v-col md="2" class="px-0" cols="2"> </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{ formatAmount(paymentOrdersStructureDetailedAllYears?.fot?.details?.ndfl?.amount) }}
      </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(paymentOrdersStructureDetailedYearExactly?.fot?.details?.ndfl?.amount) }}
      </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0 pl-4" cols="6"> закупка работ и услуг, всего: </v-col>
      <v-col md="2" class="px-0" cols="2"> 0320 </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{ formatAmount(paymentOrdersStructureDetailedAllYears?.services?.amount) }}
      </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(paymentOrdersStructureDetailedYearExactly?.services?.amount) }}
      </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0 pl-4" cols="6">
        закупка непроизведенных активов, нематериальных активов, материальных запасов и основных
        средств, всего:
      </v-col>
      <v-col md="2" class="px-0" cols="2"> 0330 </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{ formatAmount(paymentOrdersStructureDetailedAllYears?.main?.amount) }}
      </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(paymentOrdersStructureDetailedYearExactly?.main?.amount) }}
      </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0 pl-4" cols="6">
        уплата налогов, сборов и иных платежей в бюджеты бюджетной системы Российской Федерации,
        всего:
      </v-col>
      <v-col md="2" class="px-0" cols="2"> 0340 </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{ formatAmount(paymentOrdersStructureDetailedAllYears?.taxes?.amount) }}
      </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(paymentOrdersStructureDetailedYearExactly?.taxes?.amount) }}
      </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0 pl-4" cols="6"> иные выплаты, всего: </v-col>
      <v-col md="2" class="px-0" cols="2"> 0350 </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{ formatAmount(paymentOrdersStructureDetailedAllYears?.others?.amount) }}
      </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(paymentOrdersStructureDetailedYearExactly?.others?.amount) }}
      </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0" cols="6"> Подлежит возврату Грантодателю, всего: </v-col>
      <v-col md="2" class="px-0" cols="2"> 0400 </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(previousExpenses?.refundGrantorTotal) }}
      </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{ formatAmount(currentExpenses?.refundGrantorTotal) }}
      </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0 pl-4" cols="6">
        в том числе: израсходованных не по целевому назначению
      </v-col>
      <v-col md="2" class="px-0" cols="2"> 0410 </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(previousExpenses?.wasteOther) }}
      </v-col>
      <v-col md="3" class="px-0" cols="2"> {{ formatAmount(currentExpenses?.wasteOther) }} </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0 pl-4" cols="6"> в результате применения штрафных санкций </v-col>
      <v-col md="2" class="px-0" cols="2"> 0420 </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(previousExpenses?.wastePenalties) }}
      </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{ formatAmount(currentExpenses?.wastePenalties) }}
      </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0 pl-4" cols="6">
        в сумме остатка гранта на начало года, потребность в которой не подтверждена
      </v-col>
      <v-col md="2" class="px-0" cols="2"> 0430 </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(previousExpenses?.wasteNotConfirmed) }}
      </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{ formatAmount(currentExpenses?.wasteNotConfirmed) }}
      </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0" cols="6">
        Остаток гранта на конец отчетного периода, всего:
      </v-col>
      <v-col md="2" class="px-0" cols="2"> 0500 </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(previousExpenses?.endTotal) }}
      </v-col>
      <v-col md="3" class="px-0" cols="2"> {{ formatAmount(currentExpenses?.endTotal) }} </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0 pl-4" cols="6">
        в том числе: требуется для оплаты денежных обязательств
      </v-col>
      <v-col md="2" class="px-0" cols="2"> 0510 </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(previousExpenses?.endLiabilities) }}
      </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{ formatAmount(currentExpenses?.endLiabilities) }}
      </v-col>
    </v-row>
    <v-row class="px-0 border-bottom-line align-center text-grey mx-0 flex-nowrap">
      <v-col md="4" class="px-0 pl-4" cols="6"> подлежит возврату Грантодателю </v-col>
      <v-col md="2" class="px-0" cols="2"> 0520 </v-col>
      <v-col md="3" class="px-0 col-12 col-md-3" cols="2">
        {{ formatAmount(previousExpenses?.endRefundableGrantor) }}
      </v-col>
      <v-col md="3" class="px-0" cols="2">
        {{ formatAmount(currentExpenses?.endRefundableGrantor) }}
      </v-col>
    </v-row>
    <form v-if="isShowForm" class="mt-12 w-100 pt-6">
      <expense-report-form
        ref="expenseReportFormDialog"
        @update-data="updatePage"
        @print-expense-structure="printExpenseStructure"
        @close="openReport"
      />
    </form>
    <v-row v-if="!isShowForm" class="mx-0 mt-12 px-0 align-center">
      <v-btn
        v-if="isWinner"
        color="primary"
        variant="elevated"
        class="mr-4"
        @click="printExpenseStructure"
      >
        Печать
      </v-btn>
      <v-btn
        class="px-0 mx-0 text-decoration-underline"
        color="primary"
        variant="text"
        @click="openReport"
      >
        Закрыть
      </v-btn>
    </v-row>
  </the-container>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'

import { api } from '@/api/Api'

import { downloadFilePdfData, getNumberWithSpaces } from '@/helpers/index'
import { REPORTS_STATUSES } from '@/constants'

import ExpenseReportForm from '@/views/Reports/ReportingForms/ExpenseReportForm'

import TheContainer from '@/components/TheContainer'
import store from '@/store'

const route = useRoute()
const router = useRouter()

const isWinner = computed(() => store.getters['user/isWinner'])

const isShowForm = computed(
  () =>
    isWinner.value &&
    (report.value?.status === REPORTS_STATUSES.DRAFT ||
      report.value?.status === REPORTS_STATUSES.REJECTED),
)

const report = ref(null)
const expenseReportFormDialog = ref(null)
const paymentOrdersStructureDetailedAllYears = ref(null)
const paymentOrdersStructureDetailedYearExactly = ref(null)
const currentExpenses = ref(null)
const previousExpenses = ref(null)

async function printExpenseStructure() {
  try {
    api
      .reports()
      .printGrantBalance(route.params?.id, new Date().getFullYear())
      .then((response) => {
        downloadFilePdfData(
          response,
          'Отчет о расходах, источником финансового обеспечения которых являются средства Гранта\n',
        )
      })
  } catch (err) {
    console.log(err)
  }
}

function openReport() {
  router.go(-1)
}

async function getReport() {
  try {
    await api
      .reports()
      .getReportData(route.params?.id)
      .then((data) => {
        report.value = data
        getExpenseReport()
      })
  } catch (err) {
    console.log(err)
  }
}

async function getExpenseReport() {
  try {
    await api
      .balance()
      .getExpenseReport(route.params?.id, new Date().getFullYear())
      .then((data) => {
        paymentOrdersStructureDetailedAllYears.value = data?.previous?.paymentOrders
        paymentOrdersStructureDetailedYearExactly.value = data?.current?.paymentOrders
        currentExpenses.value = data?.current
        previousExpenses.value = data?.previous
      })
  } catch (err) {
    console.log(err)
  }
}

function formatAmount(amount) {
  if (!amount) {
    return amount || '0,00'
  }

  return getNumberWithSpaces(`${parseFloat(amount)?.toFixed(2)}`?.replace('.', ','))
}

function updatePage() {
  location.reload()
}

onMounted(() => {
  getReport()
})
</script>
