<template>
  <v-text-field
    :id="`inputCustom${id}`"
    v-model="inputModel"
    type="datetime-local"
    :hint="descr"
    :persistent-hint="Boolean(descr)"
    :maxlength="maxLength"
    :counter="maxLength"
    :error-messages="errorMessages"
    :rules="rulesCommon"
    :readonly="readOnly"
    color="primary"
    variant="outlined"
    class="text-pre-wrap"
    @change="changeValueInput"
  />
</template>

<script setup>
import { props as baseProps, emit as baseEmit } from '@/composables/form/usePropsForm'
import { useForm } from '@/composables/form/useForm'
import moment from 'moment'

const props = defineProps({
  ...baseProps,
})
const emit = defineEmits(baseEmit)

const { maxLength, rulesCommon, inputModel } = useForm(props, emit)

function changeValueInput(e) {
  let date = new Date(e.target.value)
  date?.toISOString().substring(0, 10)
  const formatDate = moment(date).format('DD.MM.YYYY, HH:mm:ss')
  emit('change-value', { id: props.id, value: formatDate })
}
</script>
