<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="item in items" :key="item.id" elevation="1">
      <v-expansion-panel-title class="panel" @click="selectedContestFormId = null">
        <v-row align="center" class="flex-column flex-sm-row flex-nowrap">
          <v-col sm="3" md="2">
            <img v-if="item.logo" :src="item.logo" alt="" class="panel__logo" />
            <p v-else class="ma-0">Нет логотипа</p>
          </v-col>
          <v-col md="10" sm="9">
            <h3 class="content__title text-sm-start">
              {{ item.name }} ({{ getTypeValue(item.type) }})
            </h3>
          </v-col>
        </v-row>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="mt-1 flex-column flex-sm-row" align="center">
          <v-col v-if="isAdmin || isAdminCompetition" cols="auto">
            <v-btn variant="text" class="text-body-2" @click="openExpertsDialog(item.id)">
              Управление экспертами
            </v-btn>
          </v-col>
          <v-col v-if="isAdmin || isAdminCompetition" cols="auto">
            <v-btn variant="text" class="text-body-2" @click="openMonitorsDialog(item.id)">
              Управление монитором
            </v-btn>
          </v-col>
          <v-col v-if="isAdmin || isAdminCompetition" cols="auto">
            <v-btn variant="text" class="text-body-2" @click="openManagersDialog(item.id)">
              Управление менеджерами
            </v-btn>
          </v-col>
          <v-col v-if="isAdmin || isAdminCompetition" cols="auto">
            <v-btn variant="text" class="text-body-2" :to="`/service-edit/${item.id}`">
              Редактировать конкурс
            </v-btn>
          </v-col>
          <v-col
            v-if="item.activeforms.length !== 0 && (isAdmin || isAdminCompetition)"
            cols="auto"
            class="pt-0 text-center"
          >
            <v-select
              v-model="selectedContestFormId"
              :chips="true"
              :items="item.activeforms"
              item-value="id"
              item-title="title"
              variant="underlined"
              hide-details="auto"
              :label="selectedContestFormId ? '' : 'Выберите форму конкурса'"
              class="panel__select"
            />
            <v-btn
              v-if="selectedContestFormId"
              variant="text"
              class="text-body-2"
              :to="`/form-edit/${selectedContestFormId}`"
            >
              Редактировать форму
            </v-btn>
          </v-col>
          <v-col v-if="isAdmin || isAdminCompetition" cols="auto">
            <v-btn variant="text" class="text-body-2" :to="`/form-edit?contest_id=${item.id}`">
              Создать новую форму
            </v-btn>
          </v-col>
          <v-col v-if="isManager || isExpert" cols="auto">
            <v-btn variant="text" class="text-body-2" :to="`orders?contest_id=${item.id}`">
              Заявки конкурса
            </v-btn>
          </v-col>
          <v-col v-if="isManager || isAdminCompetition || isExpert" cols="auto">
            <v-btn variant="text" class="text-body-2" :to="`/questionnaires?contest_id=${item.id}`">
              Анкеты конкурса
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-row v-if="!isExpert" class="mt-1">
    <v-col>
      <v-btn
        v-if="!isLoading"
        variant="text"
        class="text-body-1"
        to="/service-edit"
        @click.prevent="isLoading = true"
      >
        Добавить новый конкурс
      </v-btn>
      <v-progress-circular v-else color="primary" :indeterminate="true" />
    </v-col>
  </v-row>
  <template v-if="isAdminCompetition || isAdmin">
    <ServiceMangersDialog
      ref="managersDialog"
      :contest-managers="contestManagers"
      :contest-id="selectedContestId"
      @get-contest-managers="getContestManagers"
    />
    <ServiceMonitorsDialog
      ref="monitorDialog"
      :contest-id="selectedContestId"
      :contest-monitors="contestMonitors"
    />
    <ServiceExpertsDialog
      ref="expertDialog"
      :contest-id="selectedContestId"
      :contest-experts="contestExperts"
    />
  </template>
</template>

<script setup>
import ServiceMangersDialog from '@/components/Service/ServiceMangersDialog'
import { SERVISE_TYPES } from '@/utils/constants'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { api } from '@/api/Api'
import ServiceMonitorsDialog from '@/components/Service/ServiceMonitorsDialog'
import ServiceExpertsDialog from '@/components/Service/ServiceExpertsDialog'

defineProps({
  items: {
    type: Array,
    default: () => [],
  },
})

const store = useStore()

const managersDialog = ref(null)
const monitorDialog = ref(null)
const expertDialog = ref(null)
const contestMonitors = ref([])
const contestExperts = ref([])
const selectedContestId = ref(null)
const isLoading = ref(false)
let selectedContestFormId = ref(null)
const contestManagers = ref([])

const isAdmin = computed(() => store.getters['user/isAdmin'])
const isAdminCompetition = computed(() => store.getters['user/isAdminCompetition'])
const isManager = computed(() => store.getters['user/isManager'])
const isExpert = computed(() => store.getters['user/isExpert'])

function openManagersDialog(contestId) {
  selectedContestId.value = null

  if (managersDialog.value) {
    selectedContestId.value = contestId
    getContestManagers(contestId)
  }
}

async function openMonitorsDialog(contestId) {
  selectedContestId.value = null

  if (monitorDialog.value) {
    selectedContestId.value = contestId

    try {
      await getContestById(selectedContestId.value).then(() => {
        monitorDialog.value.open()
      })
    } catch (err) {
      console.log(err)
    }
  }
}

async function openExpertsDialog(contestId) {
  selectedContestId.value = null

  if (expertDialog.value) {
    selectedContestId.value = contestId

    try {
      await getContestById(selectedContestId.value).then(() => {
        expertDialog.value.open()
      })
    } catch (err) {
      console.log(err)
    }
  }
}

async function getContestById(contestId) {
  try {
    await api
      .contest()
      .getContest(contestId)
      .then((data) => {
        const { managers, monitors, experts } = data
        contestManagers.value = managers?.map((manager) => manager.user)
        contestMonitors.value = monitors?.map((monitor) => monitor.user)
        const filterExperts = experts?.filter((expert) => expert.user)
        contestExperts.value = filterExperts?.map((expert) => expert.user)
      })
  } catch (err) {
    console.log(err)
  }
}

async function getContestManagers() {
  try {
    await getContestById(selectedContestId.value).then(() => {
      managersDialog.value.open()
    })
  } catch (err) {
    console.log(err)
  }
}

function getTypeValue(type) {
  return type ? SERVISE_TYPES.SELECTIONS.name : SERVISE_TYPES.SERVISE.name
}
</script>
<script>
export default {
  name: 'ServicePanels',
}
</script>

<style scoped lang="scss">
.panel {
  min-height: 95px;
  &__logo {
    height: 60px;
    max-width: 115px;
  }
  &__select {
    width: 250px;
    max-width: 300px;
  }
}
</style>
