<template>
  <the-container>
    <template #title> Анкета № {{ questionnaire.id }} </template>
    <div class="px-0">
      <v-row>
        <v-col md="3" sm="12">
          <span class="text-body-1">Наименование анкеты</span>
        </v-col>
        <v-col md="3" sm="12">
          {{ questionnaire?.forma?.title }}
        </v-col>
      </v-row>
      <v-row v-if="questionnaire?.date_submit">
        <v-col md="3" sm="12">
          <span class="text-body-1">Дата и время подачи</span>
        </v-col>
        <v-col md="3" sm="12">
          {{ questionnaire?.date_submit }}
        </v-col>
      </v-row>
      <v-row>
        <v-col md="3" sm="12">
          <span class="text-body-1">Название конкурса</span>
        </v-col>
        <v-col md="3" sm="12">
          {{ questionnaire?.contest?.name }}
        </v-col>
      </v-row>
      <v-row>
        <v-col md="3" sm="12">
          <span class="text-body-1">Статус</span>
        </v-col>
        <v-col md="3" sm="12">
          {{ defaultStatus[questionnaire?.status] }}
        </v-col>
      </v-row>
      <v-row class="py-4 mb-2">
        <v-col>
          <v-divider />
        </v-col>
      </v-row>
      <FormMain
        v-if="canEdit"
        ref="formRef"
        :key="keyForm"
        :data="fieldsForm"
        :can-no-validate="noValidate"
        :comments="collectionOfComments"
        @get-forms="sendForm"
      />
      <template v-if="!canEdit">
        <v-row class="d-none d-md-flex">
          <v-col md="3">
            <span class="text-body-1 font-weight-bold">Наименование</span>
          </v-col>
          <v-col md="4">
            <span class="text-body-1 font-weight-bold">Значение</span>
          </v-col>
          <v-col>
            <span class="text-body-1 font-weight-bold">Комментарий</span>
          </v-col>
        </v-row>
        <div v-for="value in getSortFields()" :key="value.id">
          <template v-if="!value.group_id && value.type !== 'group'">
            <v-row class="flex-column flex-md-row mt-4">
              <v-col md="3">
                <span class="text-body-1">{{ value.title }}</span>
              </v-col>
              <v-col md="4" class="text-break">
                {{ value.default_value || value.fileUrl || '' }}
              </v-col>
              <v-col v-if="canComment">
                <v-textarea
                  :model-value="collectionOfComments[value.id]"
                  density="compact"
                  variant="outlined"
                  rows="2"
                  hide-details="auto"
                  @update:model-value="setValue($event, value.id)"
                />
              </v-col>
              <v-col v-if="!canComment">
                {{ collectionOfComments[value.id] }}
              </v-col>
            </v-row>
          </template>
          <template v-else-if="value.type === 'group'">
            <div class="text-body-1 ma-0 mt-4">{{ value.title }}</div>
            <div class="border mb-5 pa-5">
              <v-row
                v-for="field in getGroupValues(value.id)"
                :key="field?.id"
                class="flex-column flex-md-row"
              >
                <v-col md="3">
                  <span class="text-body-1">{{ field?.title }}</span>
                </v-col>
                <v-col md="4" class="text-break">
                  {{ field?.default_value }}
                </v-col>
                <v-col v-if="canComment">
                  <v-textarea
                    :model-value="collectionOfComments[field?.id]"
                    density="compact"
                    variant="outlined"
                    rows="2"
                    hide-details="auto"
                    @update:model-value="setValue($event, field?.id)"
                  />
                </v-col>
                <v-col v-if="!canComment">
                  {{ collectionOfComments[field?.id] }}
                </v-col>
              </v-row>
            </div>
          </template>
        </div>
      </template>
      <v-row v-if="canComment" justify="center" class="mt-4">
        <v-col cols="auto">
          <v-btn color="primary" width="150" @click.prevent="sendStatus(ACCEPT_STATUS)">
            Принять
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" width="150" @click.prevent="sendStatus(REJECT_STATUS)">
            Отклонить
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            width="150"
            :disabled="!canSendStatusRevision"
            @click.prevent="sendStatus(REVISION_STATUS)"
          >
            На доработку
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="canEdit">
        <v-col cols="auto">
          <v-btn color="primary" @click="getForm(null)"> Отправить на рассмотрение </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" @click="getForm(DRAFT_STATUS)"> Сохранить черновик </v-btn>
        </v-col>
      </v-row>
    </div>
    <template
      v-if="
        (isManager || isExpert) &&
        questionnaire.status !== 'draft' &&
        questionnaire.status !== 'revision'
      "
    >
      <div class="font-weight-bold mt-12">Выгрузить анкету</div>
      <v-col class="ma-0 pa-0 mt-5 flex-wrap">
        <template v-if="fieldsList.length">
          <div class="mt-6">Список полей:</div>
          <v-combobox
            v-model="selectedFields"
            :items="fieldsList"
            return-object
            item-title="title"
            item-value="name"
            variant="outlined"
            hide-details="auto"
            multiple
          />
        </template>
        <div class="mt-6">Тип выгрузки:</div>
        <v-select
          v-model="selectedTypeExport"
          :items="typesExport"
          return-object
          item-title="title"
          item-value="id"
          variant="outlined"
          hide-details="auto"
        />
        <v-btn color="primary" variant="text" class="my-6 px-0" @click="exportResult">
          Выгрузить в .xls
        </v-btn>
      </v-col>
    </template>
  </the-container>
</template>

<script>
export default {
  name: 'QuestionnaireView',
}
</script>

<script setup>
import TheContainer from '@/components/TheContainer'
import { computed, nextTick, onMounted, ref } from 'vue'
import { api } from '@/api/Api'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from '@/composables/useToast'
import { useStore } from 'vuex'
import {
  ACCEPT_STATUS,
  DRAFT_STATUS,
  NEW_STATUS,
  REJECT_STATUS,
  REVISION_STATUS,
  SENT_STATUS,
} from '@/utils/constants'
import { useForms } from '@/composables/forms/useForms'
import FormMain from '@/components/Form/FormMain'
import { defaultStatus } from '@/utils/common'
const route = useRoute()
const router = useRouter()
const { toast } = useToast()
const store = useStore()
const { getValues } = useForms()

const questionnaire = ref([])
const collectionOfComments = ref({})
const comments = ref([])
const fieldsForm = ref([])
const keyForm = ref(1)
const formRef = ref(null)
const nextStatus = ref(null)
const noValidate = ref(false)

const fieldsList = ref([])
const selectedFields = ref()

const typesExport = ref([
  {
    id: 0,
    title: 'Горизонтальная',
  },
  {
    id: 1,
    title: 'Вертикальная',
  },
])
const selectedTypeExport = ref(typesExport.value[1])

const questionnaireId = computed(() => route.params?.id ?? null)
const recipientId = computed(() => questionnaire.value?.user?.id)
const isManager = computed(() => store.getters['user/isManager'])
const isExpert = computed(() => store.getters['user/isExpert'])
const canSendStatusRevision = computed(() =>
  Object.values(collectionOfComments.value).some(Boolean),
)

const canComment = computed(() => {
  if (!isManager.value) {
    return false
  }

  const statuses = [NEW_STATUS, SENT_STATUS]
  return statuses.includes(questionnaire.value?.status)
})
const canEdit = computed(() => {
  if (isManager.value || isExpert.value) {
    return false
  }

  const statuses = [DRAFT_STATUS, REVISION_STATUS]
  return statuses.includes(questionnaire.value?.status)
})

function getSortFields() {
  return fieldsForm?.value?.sort((x, y) => x.sort - y.sort)
}

async function exportResult() {
  try {
    const form = new FormData()
    let fieldsNames = selectedFields.value?.map((el) => el.name)

    questionnaire.value?.forma?.fields?.forEach((el) => {
      if (el.type === 'group') {
        const id = el.id
        const findGroup = selectedFields.value?.filter((el) => el.group_id === id)

        if (findGroup?.length) {
          const isHasField = fieldsNames.find((field) => field === el.name)
          if (!isHasField) {
            fieldsNames.push(el.name)
          }
        }
      }
    })

    selectedFields.value?.forEach((el) => {
      if (el.type === 'group') {
        const filterFieldsGroup = questionnaire.value?.forma?.fields?.filter(
          (field) => field.group_id === el.id,
        )
        filterFieldsGroup?.forEach((fieldField) => {
          const isHasInArray = fieldsNames.find((item) => item === fieldField.name)
          if (!isHasInArray) {
            fieldsNames.push(fieldField.name)
          }
        })
      }
    })

    fieldsNames?.forEach((el) => {
      form.append('fields[]', el)
    })
    form.append('id', questionnaire.value?.forma.id)
    form.append('direction', selectedTypeExport.value.id)
    form.append('results[]', questionnaireId.value)

    await api
      .result()
      .exportResultByFormId(form)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
        )
        const filename = questionnaire.value?.forma.title
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((err) => {
        console.log(err)
        toast.error('Не удалось сформировать файл')
      })
  } catch (err) {
    console.log(err)
  }
}

const key = `questionnaireReview${questionnaireId.value}`

function setValue(value, id) {
  collectionOfComments.value[id] = value
  localStorage.setItem(key, JSON.stringify(collectionOfComments.value))
}

function setFields(fields) {
  fieldsForm.value = fields
}

function getGroupValues(id) {
  return fieldsForm.value?.filter((el) => el.group_id === id)
}

async function getMessages(values) {
  try {
    const params = new URLSearchParams({
      context: 'contest_form_results_values',
    })
    params.append('parent_object', route.params?.id || '')
    params.append('pageSize', 100)
    params.append('project', process.env.VUE_APP_PROJECT_ID)
    const data = params.toString()

    await api
      .message()
      .getBatchMessages(data)
      .then((data) => {
        const { items } = data
        let messagesList = items.reduce(
          (acc, message) => {
            if (acc.map[message.object]) {
              return acc
            }
            acc.map[message.object] = true
            acc.messages.push(message)
            return acc
          },
          { map: {}, messages: [] },
        ).messages

        messagesList.forEach((item, index) => {
          collectionOfComments.value[item.object] = item.message
          comments.value[index] = item
        })
      })
  } catch (err) {
    console.log(err)
  }
}

async function loadData() {
  questionnaire.value = await api.result().getResultById(questionnaireId.value)

  fieldsList.value = questionnaire.value?.forma.fields
  selectedFields.value = fieldsList.value
  const fields = questionnaire.value?.forma.fields
  const values = questionnaire.value?.values

  await getMessages(values)

  const newValue = getValues(questionnaire.value?.forma?.fields, questionnaire.value.values)
  fields.value = newValue
  setFields(fields.value)

  setTimeout(() => {
    keyForm.value += 1
  }, 300)
}

async function sendStatus(status) {
  if (status === REVISION_STATUS) {
    await sendMessage()
    localStorage.removeItem(key)
  }

  await api
    .result()
    .setStatus(questionnaireId.value, status)
    .then((message) => {
      toast.success(message)
    })
  await router.push('/questionnaires')
}

function sendMessage() {
  const formData = new FormData()
  formData.append('project', process.env.VUE_APP_PROJECT_ID)
  formData.append('recipients[]', recipientId.value)
  formData.append('context', 'contest_form_results_values')

  Object.entries(collectionOfComments.value).forEach(([key, value], index) => {
    if (value === '') {
      return
    }
    const comment = comments.value?.find((el) => el?.object === +key)
    const newIndex = comment ? comment?.id : `index-${key}`
    formData.append(`parent_object[${newIndex}]`, route.params?.id)
    formData.append(`object[${newIndex}]`, key)
    formData.append(`message[${newIndex}]`, value)
  })

  api.message().sendBatchMessages(formData)
}

function getForm(next = null) {
  noValidate.value = false

  if (!formRef.value) {
    return
  }

  const status = questionnaire.value.status

  if (next) {
    nextStatus.value = next
  }

  if (next === DRAFT_STATUS) {
    noValidate.value = true
  }

  if (status === DRAFT_STATUS && !next) {
    nextStatus.value = NEW_STATUS
  }

  if (status === REVISION_STATUS && !next) {
    nextStatus.value = SENT_STATUS
  }

  nextTick(() => {
    formRef.value.getForm()
  })
}

function getSortGroupFields(fields) {
  const fieldsValue = fields.filter((el) => !!el.group_id)
  const map = fieldsValue.reduce((r, i) => {
    r[i.group_id] = r[i.group_id] || []
    r[i.group_id].push(i)
    return r
  }, {})

  const sortFields = []
  for (const key in map) {
    sortFields.push(map[key])
  }

  return sortFields
}

function getSortMultipleFields(fields) {
  const map = fields.reduce((r, i) => {
    r[i.name] = r[i.name] || []
    r[i.name].push(i)
    return r
  }, {})

  const sortFields = []
  for (const key in map) {
    sortFields.push(map[key])
  }

  return sortFields
}

async function sendForm(inputs, valid) {
  if (!valid) {
    return
  }
  const {
    contest: { id: contestId },
    forma: { id: formId },
  } = questionnaire.value

  const formBody = new FormData()
  formBody.append('contest_id', contestId)
  formBody.append('form_id', formId)
  formBody.append('status', nextStatus.value)
  const sortFields = getSortGroupFields(inputs)
  const defaultFields = inputs.filter((el) => !el.group_id && el.type !== 'group')

  let startIndexGroup = 10
  sortFields?.forEach((items, indexGroup) => {
    let indexGroupValue = indexGroup ? startIndexGroup + indexGroup : startIndexGroup

    const multipleSortValue = getSortMultipleFields(items)

    multipleSortValue.forEach((sortField) => {
      sortField.forEach((field, index) => {
        const indexField = index < startIndexGroup ? `0${index}` : `${index}`

        const filedName = field.group_id
          ? `fields[${field.name}][${Number(`${indexGroupValue}${indexField}`)}]`
          : `fields[${field.name}]`

        const filedValue =
          field.type === 'file'
            ? field.default_value[0] || field.fileUrl || ''
            : field.default_value ?? ''
        formBody.append([filedName], filedValue)
      })
    })
  })

  defaultFields.forEach((field) => {
    const filedValue =
      field.type === 'file'
        ? field.default_value[0] || field.fileUrl || ''
        : field.default_value ?? ''

    formBody.append(`fields[${[field.name]}]`, filedValue)
  })

  try {
    await api.result().updateResult(questionnaireId.value, formBody)
    const message =
      nextStatus.value === DRAFT_STATUS ? 'Черновик сохранен' : 'Анкета отравлена на рассмотрение'
    toast.success(message)

    await loadData()
  } catch ({ response }) {
    const { data, status } = response
    if (status === 500) {
      const message = 'Возникла ошибка, попробуйте повторить позднее'
      toast.error(message)
    } else {
      data.forEach((msg) => {
        toast.error(msg.message)
      })
    }
  }
}

onMounted(() => {
  collectionOfComments.value = JSON.parse(localStorage.getItem(key)) ?? {}
  loadData()
})
</script>
