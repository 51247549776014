<template>
  <v-form>
    <v-row>
      <v-col cols="12" md="8">
        <div v-for="field in fieldsForm" :key="field.id">
          <div v-if="field.title" class="text-subtitle-1 font-weight-bold">{{ field.title }}</div>
          <div class="text-caption text-grey">{{ field.description }}</div>
          <v-row class="text-body-1 ma-0 flex-nowrap">
            <div class="my-2">
              <div v-for="(item, index) in field.items" :key="index" class="text-caption">
                {{ item }}
              </div>
            </div>
            <template v-if="field?.hint">
              <v-btn
                size="x-small"
                icon="mdi-information-outline"
                variant="text"
                color="grey"
                class="pa-0 ma-0 ml-2"
              />
              <v-tooltip v-if="field?.hint" activator="parent" location="top">{{
                field?.hint
              }}</v-tooltip>
            </template>
          </v-row>
          <Component
            :is="inputs[getFormInput(field.type)]"
            v-bind="field"
            @update-value="(data) => updateValue(data, fieldsForm)"
            @delete-file="(id) => $emit('delete-file', id)"
            @download-file="(id, name) => $emit('download-file', id, name)"
          />
        </div>
        <v-col class="px-0 mt-6">
          <div class="v-messages">
            <span class="text-body-2">* - поля обязательные для заполнения</span>
          </div>
          <div class="v-messages">
            <span class="text-body-2">Введенные данные сохраняются автоматически</span>
          </div>
        </v-col>
      </v-col>
    </v-row>
    <v-row class="px-0 align-center mx-0">
      <v-btn
        depressed
        color="primary"
        variant="elevated"
        :disabled="isDisableConfirmButton || isNotAllFiles"
        @click="changeTicketStatus"
      >
        Отправить
      </v-btn>
      <v-btn
        class="ml-2 text-decoration-underline"
        color="primary"
        variant="text"
        @click="router.push('/coordination-documents')"
      >
        Закрыть
      </v-btn>
    </v-row>
  </v-form>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import { inputs, getFormInput, getErrorMessageParsingExcelFiles } from '@/helpers'
import { emit as baseEmit } from '@/composables/form/usePropsForm'
import { api } from '@/api/Api'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'
import { TICKETS_STATUSES } from '@/constants/tickets'
import { useToast } from '@/composables/useToast'

const route = useRoute()
const router = useRouter()
const { toast } = useToast()

const emit = defineEmits(baseEmit)
const props = defineProps({
  ticket: {
    type: Object,
    default: () => {},
  },
  files: {
    type: Array,
    default: () => [],
  },
  isNotAllFiles: {
    type: Boolean,
    default: false,
  },
  winnerFilesKeys: {
    type: Array,
    default: () => [],
  },
})

const isDisableConfirmButton = computed(() => {
  return !!fieldsForm.value?.find((el) => !el?.links?.length)
})
const isLoading = ref(false)
const fieldsForm = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    description: 'Загрузите документы',
    name: 'signedProgram',
    items: [
      '* Текст программы Центра НТИ в обновленной редакции в формате электронного документа MS Word в режиме отображения исправлений',
      '* Проект программы Центра НТИ в обновленной редакции (чистовая версия) в формате .pdf',
    ],
    title: 'Обновленная программа',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    links: [],
    multiple: true,
    rules: [['required']],
    sort: 6,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'updatedVersionProgram',
    title: 'Подтверждающие документы',
    description: 'Загрузите документы',
    options: [],
    default_value: [],
    hint: 'По Форме в сответствии с Приложением 1 Порядка согласования Оператором изменений в Программы',
    items: [
      '* Перечень изменений в Программу Центра НТИ с обоснованием необходимости их внесения',
      'Иные документы, обосновывающие необходимость внесения изменений в Программу Центра НТИ (при необходимости)',
    ],
    isDocs: true,
    descr: '',
    note: '',
    multiple: true,
    links: [],
    rules: [['required']],
    sort: 6,
  },
])

function updateValue({ value, id }, fields) {
  fields?.forEach((item) => {
    if (item.id === id) {
      item.default_value = value

      if (item.type === 'dropFile') {
        value?.forEach((file) => {
          emit('upload-file', item.name, file)
        })
      }
    }
  })
}

async function changeTicketStatus() {
  isLoading.value = true
  try {
    const filterFiles = fieldsForm.value?.filter((el) => !!el.links?.length)
    const fileKeys = filterFiles.map((el) => el.name)
    const data = { fileKeys: [...fileKeys, ...props.winnerFilesKeys] }
    await api
      .tickets()
      .editStatusChangeRequest(route.params?.id, data, props.ticket?.status)
      .then(() => {
        emit('get-data')
      })
  } catch (err) {
    toast.error(getErrorMessageParsingExcelFiles(err?.response?.data?.message))
  } finally {
    isLoading.value = false
  }
}

function setData() {
  fieldsForm.value?.forEach((el) => {
    if (el.type !== 'dropFile') {
      return
    }

    if (props.ticket?.status === TICKETS_STATUSES.IMPROVE) {
      if (props.files && el.name in props.files && !el?.isConfirmed) {
        el.links = props.files[el.name]?.filter((el) => !el?.isConfirmed)
      }
      return
    }

    if (props.files && el.name in props.files) {
      el.links = props.files[el.name]
    }
  })
}

onMounted(() => {
  if (props.files) {
    setData()
  }
})
</script>
