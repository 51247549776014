<template>
  <v-form>
    <v-row>
      <v-col cols="12" md="8">
        <div v-for="field in fieldsForm" :key="field.id">
          <div v-if="field.title" class="text-body-1 ma-0">
            <div v-if="field.title" class="text-subtitle-1 font-weight-bold">{{ field.title }}</div>
            <div class="text-caption text-grey">{{ field.description }}</div>
            <v-row class="text-body-1 ma-0 flex-nowrap">
              <div class="my-2">
                <div v-for="(item, index) in field.items" :key="index" class="text-caption">
                  {{ item }}
                </div>
              </div>
              <template v-if="field?.hint">
                <v-btn
                  size="x-small"
                  icon="mdi-information-outline"
                  variant="text"
                  color="grey"
                  class="pa-0 ma-0 ml-2"
                />
                <v-tooltip v-if="field?.hint" activator="parent" location="top">{{
                  field?.hint
                }}</v-tooltip>
              </template>
            </v-row>
          </div>
          <Component
            :is="inputs[getFormInput(field.type)]"
            v-bind="field"
            @change-value="updateDataInStash"
            @update-value="(data) => updateValue(data, fieldsForm)"
            @delete-file="(id) => $emit('delete-file', id)"
            @download-file="(id, name) => $emit('download-file', id, name)"
          />
        </div>
        <v-col class="px-0 mt-6">
          <div class="v-messages">
            <span class="text-body-2">* - поля обязательные для заполнения</span>
          </div>
          <div class="v-messages">
            <span class="text-body-2">Введенные данные сохраняются автоматически</span>
          </div>
        </v-col>
      </v-col>
    </v-row>
    <v-row class="align-center px-0 mx-0 mt-4">
      <v-btn
        :disabled="isDisabledConfirmButton || isNotAllFiles"
        color="primary"
        class="mr-2"
        @click="() => submitMemberChanges(false)"
      >
        Принять изменения
      </v-btn>
      <v-btn
        :disabled="isDisabledRejectButton"
        color="primary"
        class="mr-2"
        variant="outlined"
        @click="() => submitMemberChanges(true)"
      >
        Отклонить изменения
      </v-btn>
      <v-btn
        class="text-decoration-underline"
        color="primary"
        variant="text"
        @click="() => router.push('/coordination-documents')"
      >
        Закрыть
      </v-btn>
    </v-row>
  </v-form>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'

import { api } from '@/api/Api'

import { useToast } from '@/composables/useToast'
import { inputs, getFormInput, clearStash, getErrorMessageParsingExcelFiles } from '@/helpers'
import { emit as baseEmit } from '@/composables/form/usePropsForm'
import { PROGRAM_CHANGE_REQUEST_MANAGER } from '@/constants/stash'
import { TICKETS_STATUSES } from '@/constants/tickets'

const route = useRoute()
const router = useRouter()
const { toast } = useToast()

const emit = defineEmits(baseEmit)
const props = defineProps({
  ticket: {
    type: Object,
    default: () => {},
  },
  files: {
    type: Array,
    default: () => [],
  },
  isNotAllFiles: {
    type: Boolean,
    default: false,
  },
  winnerFilesKeys: {
    type: Array,
    default: () => [],
  },
})
const isDisabledConfirmButton = computed(() => {
  return fieldsForm.value?.find((el) => el.type === 'dropFile' && !el?.links?.length)
})

const isDisabledRejectButton = computed(() =>
  fieldsForm.value?.find((el) => el?.type === 'textarea' && !el.default_value),
)
const fieldFormHash = computed(() => `${PROGRAM_CHANGE_REQUEST_MANAGER}-${route.params?.id}`)

const isLoading = ref(false)
const fieldsForm = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'userComment',
    title: 'Комментарий Участника*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    description: 'Загрузите документы',
    name: 'signedProgram',
    items: [
      '* Текст программы Центра НТИ в обновленной редакции в формате электронного документа MS Word в режиме отображения исправлений',
      '* Проект программы Центра НТИ в обновленной редакции (чистовая версия) в формате .pdf',
    ],
    title: 'Обновленная программа',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    links: [],
    multiple: true,
    rules: [['required']],
    sort: 6,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'userConfirmDocs',
    title: 'Подтверждающие документы',
    description: 'Загрузите документы',
    options: [],
    default_value: [],
    hint: 'По Форме в сответствии с Приложением 1 Порядка согласования Оператором изменений в Программы',
    items: [
      '* Перечень изменений в Программу Центра НТИ с обоснованием необходимости их внесения',
      'Иные документы, обосновывающие необходимость внесения изменений в Программу Центра НТИ (при необходимости)',
    ],
    isDocs: true,
    descr: '',
    note: '',
    multiple: true,
    links: [],
    rules: [['required']],
    sort: 6,
  },
])

function updateValue({ value, id }, fields) {
  fields?.forEach((item) => {
    if (item.id === id) {
      item.default_value = value

      if (item.type === 'dropFile') {
        value?.forEach((file) => {
          emit('upload-file', item.name, file)
        })
      }
    }
  })
}

async function updateDataInStash({ value, id }) {
  const findField = fieldsForm.value?.find((el) => el.id === id)
  if (findField) {
    const params = {
      field: findField.name,
      value: value,
      hash: fieldFormHash.value,
    }

    await api.stash().saveFieldInStash(params)
  }
}

async function submitMemberChanges(isDiscard = false) {
  isLoading.value = true
  try {
    const userFiles = fieldsForm.value?.filter((el) => el.type === 'dropFile' && el.links?.length)
    const fileKeys = userFiles?.map((el) => el.name)
    if (!fileKeys?.length) {
      toast.error('Загрузите необходимые файлы')
      return
    }

    const userComment = fieldsForm.value?.find((el) => el.name === 'userComment')
    let data = {
      fileKeys: [...fileKeys, ...props.winnerFilesKeys],
      isDiscard: !!isDiscard,
      userComment: userComment?.default_value || '',
    }

    await api
      .tickets()
      .editStatusChangeRequest(route.params?.id, data, props.ticket?.status)
      .then(() => {
        emit('get-data')
        emit('get-files')
        clearStash(fieldFormHash.value)
      })
  } catch (err) {
    toast.error(getErrorMessageParsingExcelFiles(err?.response?.data?.message))
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function setData() {
  fieldsForm.value?.forEach((el) => {
    if (el.type !== 'dropFile') {
      return
    }

    if (props.ticket?.status === TICKETS_STATUSES.END_IMPROVE) {
      if (props.files && el.name in props.files && !el?.isConfirmed) {
        el.links = props.files[el.name]?.filter((el) => !el?.isConfirmed)
      }
      return
    }

    if (props.files && el.name in props.files) {
      el.links = props.files[el.name]
    }
  })
}

async function getSavedData() {
  isLoading.value = true
  try {
    await api
      .stash()
      .getStashData(fieldFormHash.value)
      .then((data) => {
        fieldsForm.value?.forEach((el) => {
          if (el.type === 'dropFile') {
            return
          }
          if (el.name in data) {
            el.default_value = data[el.name]
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  if (props.files) {
    setData()
  }

  getSavedData()
})
</script>
