<template>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <!--  <div v-else-if="hasActiveZNI" class="text-caption">-->
  <!--    <v-alert-->
  <!--      type="info"-->
  <!--      text="Карточка программы получателя поддержки в процессе изменения. Формирование отчета будет доступно-->
  <!--    после ее согласования"-->
  <!--      variant="tonal"-->
  <!--    />-->
  <!--  </div>-->
  <template v-else>
    <v-btn v-if="!currentReport" color="primary" class="mb-4" @click="createReport">
      Создать отчет
    </v-btn>
    <template v-else>
      <table class="simple-light-table">
        <thead>
          <tr class="d-xs-flex flex-xs-column d-sm-table-row flex-sm-row">
            <th>Наименование документа</th>
            <th>Дата последнего обновления</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="currentReport">
          <tr v-for="item in documentsList" :key="item.title">
            <td>{{ item.title }}</td>
            <td>
              <v-row class="align-center pa-0 ma-0 flex-nowrap justify-start">
                <div>{{ item.date }}</div>
                <v-btn
                  v-if="item.hasFile && item.fileId"
                  icon="mdi-download"
                  density="comfortable"
                  variant="text"
                  color="blue-darken-4"
                  class="mr-2"
                  @click="() => downloadFile(item.fileId, item.fileName)"
                />
              </v-row>
            </td>
            <td>
              <v-col class="pa-0 ma-0 flex-grow-1">
                <div
                  class="text-decoration-underline text-blue-darken-3 cursor-pointer"
                  @click="() => onItemFormClick(item)"
                >
                  {{ getLinkLabel(item) }}
                </div>
                <div
                  v-if="item.hasTemplate"
                  class="text-decoration-underline text-blue-darken-3 cursor-pointer mt-2 white-space-nowrap"
                  @click="() => downloadTemplate(item)"
                >
                  Скачать шаблон / инструкцию
                </div>
              </v-col>
            </td>
          </tr>
        </tbody>
      </table>
      <FormFile
        id="AccountingRegistries"
        v-model="accountingRegistriesModel"
        accept=".xls, .xlsx"
        reverse
        style="display: none"
        :rules="[['required'], ['file', { extensions: ['.xlsx', '.xls'] }]]"
        clearable
        single-line
        :multiple="0"
        variant="outlined"
      />
      <FormFile
        id="RegisterPaymentOrders"
        v-model="registerPaymentOrdersModel"
        accept=".xls, .xlsx"
        reverse
        style="display: none"
        :rules="[['required'], ['file', { extensions: ['.xlsx', '.xls'] }]]"
        clearable
        :multiple="0"
        single-line
        variant="outlined"
      />
      <FormFile
        id="ExpenseReportModel"
        v-model="expenseReportModel"
        accept=".pdf"
        :multiple="0"
        reverse
        style="display: none"
        :rules="[['required'], ['file', { extensions: ['.pdf'] }]]"
        clearable
        single-line
        variant="outlined"
      />
      <FormFile
        id="AchievementReport"
        v-model="achievementReportModel"
        accept=".pdf"
        reverse
        :multiple="0"
        style="display: none"
        :rules="[['required'], ['file', { extensions: ['.pdf'] }]]"
        clearable
        single-line
        variant="outlined"
      />

      <v-btn
        id="formButton"
        :disabled="!report || !report?.readyToReview"
        class="mt-6"
        color="primary"
        @click="router.push(`/reports/report-form/${currentReport?.id}`)"
      >
        {{ titleCreateButton }}
      </v-btn>
      <v-tooltip
        v-if="currentReport.status !== REPORTS_STATUSES.ACCEPTED"
        activator="#formButton"
        location="bottom"
      >
        Для формирования отчета загрузите необходимые файлы и заполните все формы
      </v-tooltip>
    </template>
  </template>
  <div v-if="reports.length" class="mt-12 text-body-2 pt-6">
    <quarter-reports-list :reports="reports" />
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router/dist/vue-router'
import moment from 'moment'
import { useStore } from 'vuex'

import { api } from '@/api/Api'
import { useToast } from '@/composables/useToast'
import { downloadFileData, downloadFileWithLinkData, showDocument } from '@/helpers'

import FormFile from '@/components/Form/FormFile'
import {
  EXPENSE_REPORT,
  ACHIEVEMENT_REPORT,
  WINNER_FOLDER,
  REGISTER_PAYMENT_ORDERS,
  ACCOUNTING_REGISTRIES,
} from '@/constants/store'
import { REPORT_FILES } from '@/constants/buckets'
import QuarterReportsList from '@/components/Reports/QuarterReportsList'
import { REPORTS_STATUSES } from '@/constants'

const router = useRouter()
const { toast } = useToast()
const store = useStore()

const registerPaymentOrders = ref(null)
const accountingRegistriesModel = ref(null)
const registerPaymentOrdersModel = ref(null)
const expenseReportModel = ref(null)
const achievementReportModel = ref(null)
const isLoading = ref(false)
const currentReport = ref()
const annualDocumentsList = [
  {
    title: 'Отчет о ходе реализации Программы Центра НТИ',
    date: '',
    isForm: true,
    id: 10,
    link: `/reports/program-progress-report-form/`,
    isNeedIdReport: true,
    dateId: 'implementationProgramUpdated',
  },
  {
    title: 'Отчет о расходах на реализацию Программы Центра НТИ из средств софинансирования',
    date: '',
    isForm: true,
    id: 11,
    link: `/reports/program-expenditure-report-form/`,
    isNeedIdReport: true,
    dateId: 'coFinanceUpdated',
  },
]
const documentsList = ref([
  {
    title: 'Реестр платежных поручений',
    date: '',
    id: 3,
    isParsingFile: true,
    idInput: 'inputCustomRegisterPaymentOrders',
    ref: registerPaymentOrders,
    name: REGISTER_PAYMENT_ORDERS,
    dateId: 'paymentOrdersUpdated',
    upload: registerPaymentOrdersModel,
    hasTemplate: true,
    templateType: 'payment-order-register',
  },
  {
    title: 'Учетные реестры',
    date: '',
    id: 4,
    isParsingFile: true,
    name: ACCOUNTING_REGISTRIES,
    idInput: 'inputCustomAccountingRegistries',
    upload: accountingRegistriesModel,
    dateId: 'accountUpdated',
    hasFile: false,
    hasTemplate: true,
    templateType: 'account-general',
  },
  {
    title: 'Реестр сотрудников',
    date: '',
    isForm: true,
    id: 5,
    link: '/nti-information?tab=4&edit=true',
  },
  {
    title: 'Отчет о расходах, источником финансового обеспечения которых являются средства гранта',
    date: '',
    isForm: true,
    id: 6,
    link: `/reports/expense-report-form/`,
    isNeedIdReport: true,
    dateId: 'grantorRefundableUpdated',
  },
  {
    title:
      'ЭЛЕКТРОННЫЙ БЮДЖЕТ. Отчет о расходах, источником финансового обеспечения которых являются средства гранта',
    date: '',
    id: 7,
    idInput: 'inputCustomExpenseReportModel',
    name: EXPENSE_REPORT,
    upload: expenseReportModel,
    hasFile: false,
    // dateId: 'paymentOrdersUpdated',
    isParsingFile: true,
  },
  {
    title: 'Отчет о достижении значений результатов предоставления Гранта',
    date: '',
    id: 8,
    isNeedIdReport: true,
    isForm: true,
    dateId: 'achievementResultUpdated',
    link: '/reports/result-value-achievement-report-form/',
  },
  {
    title: 'ЭЛЕКТРОННЫЙ БЮДЖЕТ. Отчет о достижении значений результатов предоставления Гранта',
    date: '',
    id: 9,
    idInput: 'inputCustomAchievementReport',
    upload: achievementReportModel,
    hasFile: false,
    // dateId: 'accountUpdated',
    name: ACHIEVEMENT_REPORT,
    isParsingFile: true,
  },
  {
    title:
      'Детализированный отчет о структуре расходов, источником финансового обеспечения которых являются средства гранта',
    date: '',
    isForm: true,
    id: 2,
    dateId: 'expenseStructureUpdated',
    link: `/reports/detailed-expense-structure-report-form/`,
    isNeedIdReport: true,
  },
])
const isManager = computed(() => store.getters['user/isManager'])
const isMonitor = computed(() => store.getters['user/isMonitor'])
const reports = ref([])
const report = ref(null)
const projectId = computed(() => store.getters['project/projectId'])
const bucket = computed(() => REPORT_FILES)
const titleCreateButton = computed(() => {
  if (currentReport.value?.status === REPORTS_STATUSES.DRAFT) {
    return 'Сформировать отчет'
  }

  if (currentReport.value?.status === REPORTS_STATUSES.ACCEPTED || isManager || isMonitor) {
    return 'Просмотреть отчет'
  }

  return 'Обновить отчет'
})

const props = defineProps({
  quarterId: {
    type: Number,
    default: null,
  },
  quarterType: {
    type: String,
    default: '',
  },
  isAnnualReport: {
    type: Boolean,
    default: false,
  },
})

function onItemFormClick(item) {
  if (item.isParsingFile) {
    return findFile(item.idInput)
  }

  if (item.hasFile) {
    return downloadFile(item.fileId, item.fileName)
  }

  if (item.link) {
    return router.push(item.link)
  }

  return showDocument(item)
}

function getLinkLabel(item) {
  if (item.isForm) {
    return 'Редактировать'
  }

  if (item.idInput) {
    return 'Загрузить'
  }

  return 'Просмотреть'
}

function findFile(id) {
  if (!id) {
    return
  }

  document.getElementById(id).click()
}

function setIdReportInDocumentsLink(data) {
  documentsList.value.forEach((el) => {
    if (el?.isNeedIdReport) {
      el.link = el.link + data.id
    }
  })
}

async function createReport() {
  try {
    const params = {
      projectId: projectId.value,
      type: props.quarterType,
      year: moment(new Date()).format('YYYY'),
    }
    await api
      .reports()
      .createReport(params)
      .then((data) => {
        if (!reports.value?.length) {
          toast.info('Данные сохраняются автоматически')
        }
        currentReport.value = data
        store.commit('reports/setCurrentReport', data)
        //setIdReportInDocumentsLink(data)
        getReports()
      })
  } catch (err) {
    toast.error(err)
  }
}

async function getReportById(id) {
  try {
    await api
      .reports()
      .getReportData(id)
      .then((data) => {
        report.value = data
      })
  } catch (err) {
    console.log(err)
  }
}

// async function updateReport() {
//   try {
//     const params = {
//       projectId: currentReport.value?.projectId,
//       type: currentReport.value?.type,
//       year: +currentReport.value?.year,
//     }
//     await api
//       .reports()
//       .updateReport(currentReport.value?.id, params)
//       .then(() => {
//         toast.success('Данные отчета обновлены')
//       })
//   } catch (err) {
//     console.log(err)
//   }
// }

async function getFiles() {
  isLoading.value = true
  try {
    const params = { entityId: currentReport.value?.id }
    await api
      .files()
      .getFiles(bucket.value, params)
      .then((data) => {
        if (!data?.filesInFolders) {
          return
        }

        documentsList.value?.forEach((el) => {
          const files = data?.filesInFolders[WINNER_FOLDER]

          if ('name' in el && files && files[el?.name]) {
            el.hasFile = true
            const lastFile = files[el?.name]?.length - 1 || 0
            el.fileId = files[el?.name][lastFile]?.id
            el.fileName = files[el?.name][lastFile]?.originalFileName
            el.date = moment(files[el?.name][lastFile]?.createdAt).format('DD.MM.YYYY')
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function downloadFile(id, name) {
  try {
    await api
      .files()
      .downloadFile(id)
      .then((data) => {
        downloadFileWithLinkData(data, name)
      })
  } catch (err) {
    toast.error(err)
  }
}

async function getReports() {
  isLoading.value = true
  try {
    await api
      .reports()
      .getReports(props.quarterType)
      .then((data) => {
        if (!data) {
          return
        }
        reports.value = data.filter((el) => el.status !== REPORTS_STATUSES.DRAFT)
        if (data?.length) {
          const findReport = data.find((el) => el.year === new Date().getFullYear())
          if (findReport) {
            getReportById(findReport.id)
            currentReport.value = findReport
            setIdReportInDocumentsLink(findReport)
            store.commit('reports/setCurrentReport', findReport)
            getFiles()
            getDateLastUpdateForms()
          }
        } else {
          store.commit('reports/setCurrentReport', null)
        }
      })
  } catch (err) {
    toast.error(err)
  } finally {
    isLoading.value = false
  }
}

async function downloadTemplate(item) {
  try {
    await api
      .reports()
      .getTemplateFile(item?.templateType)
      .then((response) => {
        downloadFileData(response, item?.title)
      })
  } catch (err) {
    console.log(err)
  }
}

async function uploadRegisterPaymentOrdersForm() {
  try {
    const form = new FormData()
    form.append('file', registerPaymentOrdersModel.value[0])
    await api
      .reports()
      .uploadRegisterPaymentOrdersForm(form, currentReport.value?.id)
      .then(() => {
        toast.success('Реестр платежных поручений успешно загружен')
        getDateLastUpdateForms()
        uploadFile(REGISTER_PAYMENT_ORDERS, registerPaymentOrdersModel.value[0])
      })
  } catch (err) {
    console.log(err)
    toast.error('Ошибка формата файла. Выберите корректный файл с данными для загрузки.')
  }
}

async function uploadAccountingRegistries() {
  try {
    const form = new FormData()
    form.append('file', accountingRegistriesModel.value[0])
    await api
      .reports()
      .uploadAccountingRegistries(form, currentReport.value?.id)
      .then(() => {
        toast.success('Учетные реестры успешно загружены')
        getDateLastUpdateForms()
        uploadFile(ACCOUNTING_REGISTRIES, accountingRegistriesModel.value[0])
      })
  } catch (err) {
    console.log(err)
    toast.error('Ошибка формата файла. Выберите корректный файл с данными для загрузки.')
  }
}

async function uploadFile(name, data) {
  isLoading.value = true
  try {
    const form = new FormData()
    form.append('file', data)
    form.append('fileKey', name)
    form.append('entityId', currentReport.value?.id)
    form.append('folder', WINNER_FOLDER)
    await api
      .files()
      .uploadFile(bucket.value, form)
      .then(() => {
        getFiles()
      })
  } catch (err) {
    toast.error(err)
  } finally {
    isLoading.value = false
  }
}

async function getDateLastUpdateForms() {
  try {
    await api
      .reports()
      .getUpdatedDates(currentReport.value?.id)
      .then((data) => {
        documentsList.value?.forEach((el) => {
          if (el?.dateId in data) {
            el.date = data[el?.dateId] ? moment(data[el?.dateId]).format('DD.MM.YYYY') : ''
          }
        })

        annualDocumentsList.value?.forEach((el) => {
          if (el?.dateId in data) {
            el.date = data[el?.dateId] ? moment(data[el?.dateId]).format('DD.MM.YYYY') : ''
          }
        })
      })
  } catch (err) {
    console.log(err)
  }
}

watch(accountingRegistriesModel, () => {
  uploadAccountingRegistries()
})

watch(registerPaymentOrdersModel, () => {
  uploadRegisterPaymentOrdersForm()
})

watch(achievementReportModel, () => {
  uploadFile(ACHIEVEMENT_REPORT, achievementReportModel.value[0])
})

watch(expenseReportModel, () => {
  uploadFile(EXPENSE_REPORT, expenseReportModel.value?.[0])
})

onMounted(() => {
  getReports()

  if (props.isAnnualReport) {
    documentsList.value = documentsList.value.concat(annualDocumentsList)
  }
})
</script>
