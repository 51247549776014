<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel elevation="0">
      <v-expansion-panel-title class="pa-0 ma-0" color="white" hide-actions>
        <slot name="title" />
      </v-expansion-panel-title>
      <v-expansion-panel-text class="pa-0 ma-0" color="white">
        <slot name="text" />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script setup>
import { ref } from 'vue'
const panel = ref(['1'])
</script>
