import { api } from '@/api/Api'
import {
  ADMIN,
  ADMIN_COMPETITION,
  EXPERT,
  MANAGER,
  MONITOR,
  roles,
  USER,
  WINNER,
} from '@/enums/roles'
import { PROJECT_CARD_STASH_TEMPLATE } from '@/constants'

const state = {
  userInfo: {
    id: null,
    username: null,
    fio: null,
    phone: null,
    email: null,
    userProfile: {
      firstname: null,
      middlename: null,
      lastname: null,
      avatar_path: null,
      avatar_base_url: null,
      birthday: null,
      gender: null,
      country: null,
      region: null,
      city: null,
      site: null,
      work_position: null,
      work_company: null,
    },
    groups: [],
    created_at: null,
    logged_at: null,
  },
  users: [],
  hash: null,
}

const getters = {
  userInfo(state) {
    return state.userInfo
  },
  hash(state) {
    return state.hash
  },
  userName(state) {
    if (!state.userInfo.userProfile?.lastname && !state.userInfo.userProfile?.firstname) {
      return null
    }

    const lastname = state.userInfo.userProfile.lastname ?? ''
    const firstname = state.userInfo.userProfile.firstname ?? ''
    if (lastname === '' && firstname === '') {
      return null
    }

    return `${lastname} ${firstname}`
  },
  currentUserId(state) {
    return state.userInfo?.id ?? null
  },
  currentRoles(state) {
    if (!state.userInfo || !state.userInfo?.groups) {
      return []
    }

    return state.userInfo.groups.map((group) => group.group_id)
  },
  allUsers(state) {
    return state.users
  },
  isAdminCompetition(_, getters) {
    return getters.currentRoles.includes(ADMIN_COMPETITION)
  },
  isAdmin(_, getters) {
    return getters.currentRoles.includes(ADMIN)
  },
  isMonitor(_, getters) {
    return getters.currentRoles.includes(MONITOR)
  },
  isWinner(_, getters) {
    return getters.currentRoles.includes(WINNER)
  },
  isManager(_, getters) {
    return getters.currentRoles.includes(MANAGER)
  },
  isUser(_, getters) {
    return getters.currentRoles.includes(USER)
  },
  isExpert(_, getters) {
    return getters.currentRoles.includes(EXPERT)
  },
  userRoleInString(_, getters) {
    return getters.currentRoles
      .map((role) => roles[role])
      .reverse()
      .join(', ')
  },
}

const actions = {
  async getUserInfo({ commit }) {
    try {
      const data = await api.user().getCurrentUserInfo()
      commit('setUserInfo', data)
      commit('setHash', data?.id)
    } catch (e) {
      console.log(e)
    }
  },
  async getUsers({ commit }) {
    try {
      const { items } = await api.user().getUsers()
      commit('setAllUsers', items)
    } catch (e) {
      console.log(e)
    }
  },
}

const mutations = {
  setUserInfo(state, value) {
    state.userInfo = value
  },
  setAllUsers(state, value) {
    state.users = value
  },
  setHash(state, value) {
    state.hash = `${PROJECT_CARD_STASH_TEMPLATE}-${value}`
  },
}

export const user = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
