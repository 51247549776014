<template>
  <v-row
    :class="[
      'align-center justify-lg-space-between flex-column pa-0 ma-0',
      isReverseRow ? 'flex-sm-row-reverse' : 'flex-sm-row',
      className,
    ]"
  >
    <v-col :class="['w-100', isReverseRow ? 'flex-grow-0 white-space-nowrap' : 'flex-grow-1']">
      <div class="text-subtitle-1 font-weight-bold">
        {{ title }}
      </div>
    </v-col>
    <v-col :class="[isReverseRow ? 'flex-grow-1' : 'w-40 flex-grow-0 white-space-nowrap']">
      <v-row class="pa-0 align-center ma-0 flex-nowrap">
        <v-icon size="xs" color="grey">
          {{ getIcon() }}
        </v-icon>
        <div class="ml-2 text-uppercase text-grey text-caption" style="white-space: break-spaces">
          {{ status }}
        </div>
      </v-row>
      <div class="text-subtitle-2 font-weight-bold">
        {{ period }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    className: {
      type: String || Array,
      default: 'success-wrapper',
    },
    icon: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    period: {
      type: String,
      default: '',
    },
    isReverseRow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getIcon() {
      switch (this.icon) {
        case 'success':
          return 'mdi-check-circle-outline'
        case 'alert':
          return 'mdi-alert-outline'
        default:
          return ''
      }
    },
  },
}
</script>
<style lang="scss">
.w-40 {
  min-width: 25%;
}
</style>
