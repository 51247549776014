<template>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <DataTableDialog
    v-else
    ref="dataTableDialog"
    :head="head"
    :data="data"
    :max-width="1200"
    :dialog="dataTableDialog"
    title="Реестр платежных поручений"
    is-dark-theme
    is-table-dialog
  />
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router/dist/vue-router'

import { api } from '@/api/Api'

import DataTableDialog from '@/components/Common/DataTableDialog'
import { getExpenditureType } from '@/helpers'
import moment from 'moment'

const route = useRoute()
const head = ref([
  {
    title: 'Наименование статьи расходов',
  },
  {
    title: 'Год предоставления гранта',
  },
  {
    title: 'Номер лицевого счета',
  },
  {
    title: 'Номер платежного поручения',
  },
  {
    title: 'Дата платежного поручения',
  },
  {
    title: 'Сумма, руб.',
  },
  {
    title: 'Назначение платежа',
  },
])
const data = ref([])
const isLoading = ref(false)
const dataTableDialog = ref(null)

async function getPaymentOrders() {
  isLoading.value = true
  try {
    await api
      .reports()
      .getPaymentOrders(route.params?.id)
      .then((res) => {
        data.value = res?.map((el) => {
          return {
            expenditureType: getExpenditureType(el.expenditureType),
            year: el.year,
            accountNumber: el.accountNumber,
            paymentOrderNumber: el.paymentOrderNumber,
            paymentOrderDate: formatDate(el.paymentOrderDate),
            amount: el.amount,
            paymentPurpose: el.paymentPurpose,
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function formatDate(date) {
  return date ? moment(date).format('DD.MM.YYYY') : ''
}

function openDocument() {
  if (dataTableDialog.value) {
    dataTableDialog.value.openDialog()
  }
}

onMounted(() => {
  getPaymentOrders()
})

defineExpose({
  openDocument,
})
</script>
