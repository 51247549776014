<template>
  <v-layout>
    <TheHeader :tabs="tabs" />
    <div class="main-container w-100">
      <v-main class="main content">
        <router-view :key="$route.fullPath" />
      </v-main>
      <div class="footer">
        <TheFooter />
      </div>
    </div>
  </v-layout>
</template>

<script>
export default {
  name: 'DefaultLayout',
}
</script>
<script setup>
import TheHeader from '@/components/TheHeader'
import TheFooter from '@/components/TheFooter'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
// import { ADMIN, ADMIN_COMPETITION, EXPERT, MANAGER, WINNER, USER, MONITOR } from '@/enums/roles'

const store = useStore()

// const projectId = computed(() => store.getters['project/projectId'])

const recipientSupport = [
  {
    title: 'Текущие конкурсы',
    to: '/programs',
  },
  {
    title: 'Мои задачи',
    to: '/tasks',
  },
]

// const tabsToMonitor = [
//   {
//     title: 'Конкурсы',
//     to: '/service',
//   },
//   {
//     title: 'Согласование документов',
//     to: '/coordination-documents',
//   },
//   {
//     title: 'Отчеты',
//     to: '/reports',
//   },
//   {
//     title: 'СВЕДЕНИЯ НТИ',
//     to: '/nti-information',
//   },
// ]
//
// const tabsToWinnerWithProject = [
//   {
//     title: 'Согласование документов',
//     to: '/coordination-documents',
//   },
//   {
//     title: 'Отчеты',
//     to: '/reports',
//   },
//   {
//     title: 'СВЕДЕНИЯ НТИ',
//     to: '/nti-information',
//   },
//   {
//     title: 'Конкурсы',
//     to: '/service',
//   },
// ]

// const tabsToWinner = [
//   {
//     title: 'Согласование документов',
//     to: '/coordination-documents',
//   },
//   {
//     title: 'Конкурсы',
//     to: '/service',
//   },
// ]

// const tabsToUser = [
//   {
//     title: 'Конкурсы',
//     to: '/service',
//   },
//   {
//     title: 'Отборы',
//     to: '/selections',
//   },
//   {
//     title: 'Мои заявки',
//     to: '/my-orders',
//   },
// ]
//
// const tabsToExpert = [
//   {
//     title: 'Конкурсы',
//     to: '/service',
//   },
//   {
//     title: 'Анкеты',
//     to: '/questionnaires',
//   },
// ]
//
// const tabsToAdmin = [
//   {
//     title: 'Конкурсы',
//     to: '/service',
//   },
//   {
//     title: 'Пользователи и роли',
//     to: '/users-and-roles',
//   },
//   {
//     title: 'Центры НТИ',
//     to: '/center-nti',
//   },
// ]
// const tabsToAdminCompetition = [
//   {
//     title: 'Конкурсы',
//     to: '/service',
//   },
// ]
// const tabsToManger = [
//   {
//     title: 'Согласование документов',
//     to: '/coordination-documents',
//   },
//   {
//     title: 'Отчеты',
//     to: '/reports',
//   },
//   {
//     title: 'СВЕДЕНИЯ НТИ',
//     to: '/nti-information',
//   },
//   {
//     title: 'Конкурсы',
//     to: '/service',
//   },
//   {
//     title: 'Данные команды',
//     to: '/orders',
//   },
//   {
//     title: 'Анкеты',
//     to: '/questionnaires',
//   },
// ]

// const currentRoles = computed(() => {
//   return store.getters['user/currentRoles']
// })

const tabs = computed(() => {
  return recipientSupport

  // const flatTabs = currentRoles.value.flatMap((role) => {
  //   // todo
  //
  //   return recipientSupport
  //
  //   // if (role === USER || role === MANAGER) {
  //   //   return recipientSupport
  //   // }
  //   //
  //   // if (role === WINNER && !projectId.value) {
  //   //   return tabsToWinner
  //   // }
  //   //
  //   // if (role === WINNER) {
  //   //   return tabsToWinnerWithProject
  //   // }
  //   //
  //   // if (role === ADMIN) {
  //   //   return tabsToAdmin
  //   // }
  //   //
  //   // if (role === MANAGER) {
  //   //   return tabsToManger
  //   // }
  //   //
  //   // if (role === EXPERT) {
  //   //   return tabsToExpert
  //   // }
  //   //
  //   // if (role === ADMIN_COMPETITION) {
  //   //   return tabsToAdminCompetition
  //   // }
  //   //
  //   // if (role === MONITOR) {
  //   //   return tabsToMonitor
  //   // }
  //   //
  //   // if (role === USER) {
  //   //   return tabsToUser
  //   // }
  //   //
  //   // return tabsToUser
  // })
  //
  // return filterTabs(flatTabs)
})

// function filterTabs(tabs) {
//   const filteredTabs = []
//   const titleTabs = new Set(tabs.map((tab) => tab?.title))
//   titleTabs?.forEach((title) => {
//     const findItem = tabs.find((tab) => tab?.title === title)
//
//     if (findItem) {
//       filteredTabs.push(findItem)
//     }
//   })
//   return filteredTabs
// }

onMounted(() => {
  store.dispatch('group/getGroupSystem')
})
</script>

<style lang="scss" scoped>
.main {
  height: auto;
  background: var(--light-background);
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}

.main-container {
  display: flex;
  flex-direction: column;
}
</style>
