<template>
  <v-row v-if="isLoading" class="justify-center align-center py-6">
    <v-progress-circular color="primary" :indeterminate="true" :width="2" />
  </v-row>
  <div v-else>
    <v-col v-for="(item, index) in historyReport" :key="`text-${index}`" class="pa-0 mb-4">
      <div class="text-caption text-grey text-uppercase">
        {{ getFormatDate(item.createdAt || item.updatedAt) }}
      </div>
      <div :class="['text-subtitle-2', index ? 'text-grey' : 'font-weight-bold']">
        {{ getLabelHistoryItem(item) }} - {{ getActionReport(item?.action) }}
      </div>
    </v-col>
    <v-btn
      v-if="isShowCloseButton"
      class="mx-0 px-0 text-decoration-underline mt-4"
      color="primary"
      variant="text"
      @click="router.push('/reports')"
    >
      Закрыть
    </v-btn>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router/dist/vue-router'

import { getFormatDate, getLabelHistoryItem, getActionReport } from '@/helpers'

import { api } from '@/api/Api'
import { useStore } from 'vuex'

const props = defineProps({
  count: {
    type: Number,
    default: null,
  },
  isShowCloseButton: {
    type: Boolean,
    default: false,
  },
})

const router = useRouter()
const store = useStore()

const currentUserId = computed(() => store.getters['user/currentUserId'])

const historyReport = ref([])
const isLoading = ref(false)

async function getHistoryReport() {
  isLoading.value = true
  try {
    await api
      .reports()
      .getHistoryReportsWinner(currentUserId.value)
      .then((data) => {
        const history = props.count ? data?.splice(0, props.count) : data
        historyReport.value = [...history]
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getHistoryReport()
})
</script>
