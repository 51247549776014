<template>
  <the-container>
    <v-col class="mb-2 px-0 mx-0">
      <h3>{{ titlePage }}</h3>
    </v-col>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <div v-else-if="isManager && ticket.status === TICKETS_STATUSES.BLANK">
      <div :key="filesKey">
        <program-change-request-form-for-manager
          :files="files"
          @get-files="getFiles"
          @submit-form="createProgramChangeRequest"
        />
      </div>
    </div>
    <template v-else-if="ticket">
      <program-change-request-preview
        :key="`${filesKey}-${ticket.status}`"
        :project="project"
        :ticket="ticket"
        :files="files?.recommendationsExpertCouncilDocs"
      />
      <div
        v-if="
          isWinner &&
          (ticket?.status === TICKETS_STATUSES.END_IMPROVE ||
            ticket?.status === TICKETS_STATUSES.NEW)
        "
        :key="filesKey"
      >
        <program-change-winner-upload-documents-form
          :files="files"
          @upload-file="uploadFile"
          @download-file="downloadFile"
          @delete-file="deleteFile"
          @set-is-not-all-files="setIsNotAllFiles"
        />
      </div>
      <div v-if="documentsWinner?.length" class="mb-12">
        <div class="font-weight-bold text-grey my-6">Прикрепленные документы</div>
        <v-row
          v-for="item in documentsWinner"
          :key="`${item.id}`"
          class="mx-0 px-0 w-100 word-break"
        >
          <v-col class="text-body-2 px-0 font-weight-bold pr-2 mx-0" cols="4">
            {{ item?.originalFileName }}
          </v-col>
          <v-col class="px-0 mx-0 justify-end d-flex" cols="2">
            <v-btn
              class="text-decoration-underline text-body-1 text-blue-darken-4"
              variant="text"
              @click="() => downloadFile(item.id, item?.originalFileName)"
            >
              Просмотреть
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-col
        v-if="
          isWinner &&
          (ticket?.status === TICKETS_STATUSES.REJECTED ||
            ticket?.status === TICKETS_STATUSES.IMPROVE) &&
          ticket?.managerComment
        "
        class="comment error mt-6 mb-4 pa-0 ma-0"
        cols="12"
        md="8"
      >
        <div>Комментарий Менеджера</div>
        <div class="field">{{ ticket?.managerComment }}</div>
      </v-col>
      <div
        v-if="
          (ticket?.status === TICKETS_STATUSES.NEW ||
            ticket?.status === TICKETS_STATUSES.IMPROVE) &&
          isWinner
        "
        :key="filesKey"
      >
        <program-change-winner-form
          :files="files"
          :ticket="ticket"
          :is-not-all-files="isNotAllFiles"
          :winner-files-keys="winnerFilesKeys"
          @get-data="getTicketData"
          @upload-file="uploadFile"
          @download-file="downloadFile"
          @delete-file="deleteFile"
        />
      </div>
      <div
        v-if="
          (ticket?.status === TICKETS_STATUSES.REVIEW ||
            ticket?.status === TICKETS_STATUSES.REPEAT_REVIEW) &&
          isManager
        "
        :key="filesKey"
      >
        <program-change-manager-form
          :files="files"
          :ticket="ticket"
          @get-data="getTicketData"
          @upload-file="uploadFile"
          @download-file="downloadFile"
          @delete-file="deleteFile"
        />
      </div>
      <div v-if="ticket?.status === TICKETS_STATUSES.SEND_FOR_APPROVE && isMonitor" :key="filesKey">
        <program-change-monitor-form
          :files="files"
          :ticket="ticket"
          @get-data="getTicketData"
          @upload-file="uploadFile"
          @download-file="downloadFile"
          @delete-file="deleteFile"
        />
      </div>
      <div
        v-if="
          !isWinner &&
          (ticket?.status === TICKETS_STATUSES.REJECTED ||
            ticket?.status === TICKETS_STATUSES.IMPROVE)
        "
      >
        <v-row
          v-if="isWinner"
          :class="['pa-0 ma-0 mt-6 border-bottom-line', isWinner ? 'text-red' : 'text-grey']"
        >
          <v-col class="px-0 mx-0" cols="6"> Комментарий Менеджера </v-col>
          <v-col class="px-0 mx-0" cols="6">
            {{ ticket.managerComment }}
          </v-col>
        </v-row>
      </div>
      <div v-if="ticket?.status === TICKETS_STATUSES.MONITOR_ACCEPTED && isManager" :key="filesKey">
        <program-change-manager-conclusion-form
          :files="files"
          :ticket="ticket"
          @get-data="getTicketData"
          @upload-file="uploadFile"
          @download-file="downloadFile"
          @delete-file="deleteFile"
        />
      </div>
      <div v-if="ticket?.status === TICKETS_STATUSES.SEND_WORK_GROUP && isManager" :key="filesKey">
        <program-change-manager-w-g-conclusion-form
          :files="files"
          :ticket="ticket"
          @get-data="getTicketData"
          @upload-file="uploadFile"
          @download-file="downloadFile"
          @delete-file="deleteFile"
        />
      </div>
      <div v-if="!!isRgConfirm" :key="filesKey">
        <program-change-manager-m-e-s-conclusion-form
          :files="files"
          :ticket="ticket"
          @get-data="getTicketData"
          @upload-file="uploadFile"
          @download-file="downloadFile"
          @delete-file="deleteFile"
        />
      </div>
      <div v-if="isWinner && ticket?.status === TICKETS_STATUSES.END_IMPROVE" :key="filesKey">
        <program-change-winner-update-program-form
          :files="files"
          :ticket="ticket"
          :is-not-all-files="isNotAllFiles"
          :winner-files-keys="winnerFilesKeys"
          @get-data="getTicketData"
          @upload-file="uploadFile"
          @download-file="downloadFile"
          @delete-file="deleteFile"
        />
      </div>
      <div v-if="isManager && ticket?.status === TICKETS_STATUSES.END_CONFIRM" :key="filesKey">
        <program-change-manager-confirm-form
          :files="files"
          :ticket="ticket"
          @get-data="getTicketData"
          @upload-file="uploadFile"
          @download-file="downloadFile"
          @delete-file="deleteFile"
        />
      </div>
      <v-btn
        v-if="isShowCloseButton"
        class="px-0 text-blue-darken-4 text-decoration-underline mt-2"
        variant="text"
        color="blue-darken-4"
        @click="router.push('/coordination-documents')"
      >
        Закрыть
      </v-btn>
    </template>
  </the-container>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'

import { api } from '@/api/Api'

import { TICKET_FILES } from '@/constants/buckets'
import store from '@/store'
import { TICKET_ROLE_STATUS, TICKETS_STATUSES } from '@/constants/tickets'

import { useToast } from '@/composables/useToast'
import { downloadFileWithLinkData, clearStash } from '@/helpers'

import TheContainer from '@/components/TheContainer'
import ProgramChangeRequestFormForManager from '@/views/Projects/ProgramChangeRequest/Forms/ProgramChangeRequestForManagerForm'
import ProgramChangeRequestPreview from '@/views/Projects/ProgramChangeRequest/Components/ProgramChangeRequestPreview'
import ProgramChangeWinnerForm from '@/views/Projects/ProgramChangeRequest/Forms/ProgramChangeWinnerForm'
import ProgramChangeManagerForm from '@/views/Projects/ProgramChangeRequest/Forms/ProgramChangeManagerForm'
import ProgramChangeMonitorForm from '@/views/Projects/ProgramChangeRequest/Forms/ProgramChangeMonitorForm'
import ProgramChangeManagerConclusionForm from '@/views/Projects/ProgramChangeRequest/Forms/ProgramChangeManagerConclusionForm'
import ProgramChangeManagerWGConclusionForm from '@/views/Projects/ProgramChangeRequest/Forms/ProgramChangeManagerWGConclusionForm'
import ProgramChangeManagerMESConclusionForm from '@/views/Projects/ProgramChangeRequest/Forms/ProgramChangeManagerMESConclusionForm'
import ProgramChangeWinnerUploadDocumentsForm from '@/views/Projects/ProgramChangeRequest/Forms/ProgramChangeWinnerUploadDocumentsForm'
import ProgramChangeWinnerUpdateProgramForm from '@/views/Projects/ProgramChangeRequest/Forms/ProgramChangeWinnerUpdateProgramForm'
import ProgramChangeManagerConfirmForm from '@/views/Projects/ProgramChangeRequest/Forms/ProgramChangeManagerConfirmForm'
import { PROGRAM_CHANGE_REQUEST_MANAGER } from '@/constants/stash'

const route = useRoute()
const router = useRouter()
const { toast } = useToast()

const isLoading = ref(true)
const isShowDraftManagerForm = ref(false)
const ticket = ref(null)
const project = ref(null)
const files = ref([])
const documentsWinner = ref([])
const isNotAllFiles = ref(false)
const winnerFilesKeys = ref([])

const fieldFormHash = computed(() => `${PROGRAM_CHANGE_REQUEST_MANAGER}-${route.params?.id}`)

const projectId = computed(() => store.getters['project/projectId'])
const isManager = computed(() => store.getters['user/isManager'])
const isMonitor = computed(() => store.getters['user/isMonitor'])
const isWinner = computed(() => store.getters['user/isWinner'])
const isRgConfirm = computed(() => {
  return (
    isManager.value &&
    (ticket.value?.status === TICKETS_STATUSES.CONFIRMED_WORK_GROUP ||
      ticket.value?.status === TICKETS_STATUSES.PARTIALLY_CONFIRMED_WORK_GROUP)
  )
})

const isShowCloseButton = computed(() => {
  return (
    ticket.value?.status === TICKETS_STATUSES.ACCEPTED ||
    ((isWinner.value || isMonitor.value) &&
      (ticket.value?.status === TICKETS_STATUSES.REVIEW ||
        ticket.value?.status === TICKETS_STATUSES.REPEAT_REVIEW)) ||
    ((isManager.value || isMonitor.value) && ticket.value?.status === TICKETS_STATUSES.IMPROVE) ||
    ((isManager.value || isWinner.value) &&
      ticket.value?.status === TICKETS_STATUSES.SEND_FOR_APPROVE) ||
    ((isMonitor.value || isWinner.value) &&
      ticket.value?.status === TICKETS_STATUSES.MONITOR_ACCEPTED) ||
    ((isManager.value || isMonitor.value) &&
      ticket.value?.status === TICKETS_STATUSES.END_IMPROVE) ||
    ((isWinner.value || isMonitor.value) &&
      ticket.value?.status === TICKETS_STATUSES.END_CONFIRM) ||
    ((isWinner.value || isMonitor.value) &&
      ticket.value?.status === TICKETS_STATUSES.SEND_WORK_GROUP) ||
    ((isManager.value || isMonitor.value) && ticket.value?.status === TICKETS_STATUSES.NEW) ||
    ((isWinner.value || isMonitor.value) &&
      (ticket.value?.status === TICKETS_STATUSES.CONFIRMED_WORK_GROUP ||
        ticket.value?.status === TICKETS_STATUSES.PARTIALLY_CONFIRMED_WORK_GROUP)) ||
    ticket.value?.status === TICKETS_STATUSES.REJECTED
  )
})
const filesKey = computed(() => (files.value ? Object.values(files.value).length : files.value))
const bucket = computed(() => TICKET_FILES)
const titlePage = computed(() => {
  if (ticket.value?.status === TICKETS_STATUSES.BLANK) {
    return 'Запрос на внесение изменений в программу от Инициатора'
  }

  return 'Запрос на внесение изменений в программу'
})

function setIsNotAllFiles(value, filesKeys) {
  isNotAllFiles.value = value
  winnerFilesKeys.value = filesKeys
}

async function uploadFile(name, data) {
  try {
    const form = new FormData()
    form.append('file', data)
    form.append('fileKey', name)
    form.append('entityId', route.params?.id)
    await api
      .files()
      .uploadFile(bucket.value, form)
      .then(() => {
        toast.success('Файл успешно загружен')
        getFiles()
      })
  } catch (err) {
    toast.error('Ошибка при загрузке файла')
  }
}

async function downloadFile(id, name) {
  try {
    await api
      .files()
      .downloadFile(id)
      .then((data) => {
        downloadFileWithLinkData(data, name)
      })
  } catch (err) {
    toast.error(err)
  }
}

async function deleteFile(id) {
  try {
    await api
      .files()
      .deleteFile(id)
      .then(() => {
        getFiles()
      })
  } catch (err) {
    toast.error(err)
  }
}

async function getFiles() {
  try {
    const params = route.params?.id ? { entityId: route.params?.id } : null
    documentsWinner.value = []
    files.value = []

    await api
      .files()
      .getFiles(bucket.value, params)
      .then((data) => {
        if (data?.filesInRoot) {
          files.value = data?.filesInRoot
        }

        const filesList = data?.filesInRoot
        if (!filesList) {
          return
        }
        const filterFiles = Object.keys(filesList)
          .filter((key) => key !== 'recommendationsExpertCouncilDocs')
          .reduce((res, key) => {
            res[key] = filesList[key]
            return res
          }, {})
        Object.values(filterFiles)?.forEach((files) => {
          files?.forEach((file) => {
            if (file?.isConfirmed) {
              return documentsWinner.value.push(file)
            }
          })
        })
      })
  } catch (err) {
    console.log(err)
  }
}

async function createTicket() {
  isLoading.value = true
  try {
    const status = isWinner.value ? TICKET_ROLE_STATUS.WINNER : TICKET_ROLE_STATUS.MANAGER
    await api
      .tickets()
      .createTicket(projectId.value, status)
      .then((data) => {
        const idTicket = data?.id

        if (idTicket) {
          router.push(`/coordination-documents/program-change-request-form/${idTicket}`)
        }
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function getTicketData() {
  try {
    await api
      .tickets()
      .getTicket(route.params?.id)
      .then((data) => {
        ticket.value = data
        isShowDraftManagerForm.value = data?.status === TICKETS_STATUSES.BLANK && isManager
        getFiles()
      })
  } catch (err) {
    console.log(err)
  }
}

async function createProgramChangeRequest(data) {
  isLoading.value = true
  try {
    await api
      .tickets()
      .createProgramChangeRequest(route.params?.id, data)
      .then(() => {
        getData()
        clearStash(fieldFormHash.value)
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function getProjectData() {
  try {
    await api
      .projects()
      .getProjectData(projectId.value)
      .then((data) => {
        project.value = data
      })
  } catch (err) {
    console.log(err)
  }
}

function getData() {
  if (!route.params?.id) {
    return createTicket()
  } else {
    isLoading.value = true
    Promise.all([getTicketData(), getProjectData()]).then(() => {
      isLoading.value = false
    })
  }
}

onMounted(() => {
  getData()
})
</script>
