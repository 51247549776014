<template>
  <v-row v-if="isLoading" class="px-0 justify-center align-center" style="min-height: 400px">
    <v-progress-circular color="primary" :indeterminate="true" />
  </v-row>
  <template v-else>
    <the-custom-table
      :head="head"
      :data="data"
      :has-delete="isWinner"
      :has-edit="isWinner"
      @delete="deleteGranteeContacts"
      @edit="(id) => $router.push(`/grantee-contacts-form/${id}`)"
    />
    <template v-if="isWinner">
      <FormFile
        id="importFile"
        v-model="importFile"
        accept=".xls, .xlsx"
        reverse
        style="display: none"
        :rules="[['required'], ['file', { extensions: ['.xlsx', '.xls'] }]]"
        clearable
        single-line
        variant="outlined"
      />
      <v-row class="mt-12 mx-0 align-center">
        <v-btn
          class="mr-4"
          color="primary"
          variant="outlined"
          @click="downloadGranteeContactsTemplate"
        >
          Скачать шаблон / инструкцию
        </v-btn>
        <v-btn color="primary" @click="findFile"> Загрузить </v-btn>
        <!--    <v-progress-circular indeterminate color="primary" :size="20" class="ml-4" :width="3" />-->
      </v-row>
      <v-row class="mt-12 mx-0 align-center">
        <v-btn color="primary" @click="() => $router.push('/grantee-contacts-form')">
          Создать контакт
        </v-btn>
      </v-row>
    </template>
    <v-row v-else class="mt-12 mx-0 align-center">
      <v-btn class="mr-4" color="primary" @click="exportGranteeContacts"> Выгрузить </v-btn>
    </v-row>
  </template>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import TheCustomTable from '@/components/TheCustomTable'
import { api } from '@/api/Api'
import { useStore } from 'vuex'
import FormFile from '@/components/Form/FormFile'
import { downloadFileData } from '@/helpers'
import { ACTUALITY_OPTION } from '@/constants'
import { useToast } from '@/composables/useToast'

const store = useStore()
const { toast } = useToast()

const head = ref([
  {
    id: uuidv4(),
    title: 'Центр НТИ',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'Актуальность',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'Категория контактов',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'E-mail',
  },
  {
    id: uuidv4(),
    title: 'ФИО',
  },
  {
    id: uuidv4(),
    title: 'Должность',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'Тел.',
  },
])
const data = ref([])
const isLoading = ref(false)
const importFile = ref()

const isWinner = computed(() => store.getters['user/isWinner'])

function findFile() {
  document.getElementById('inputCustomimportFile').click()
}

async function exportGranteeContacts() {
  try {
    api
      .information()
      .exportGranteeContacts()
      .then((response) => {
        downloadFileData(response, 'Контакты грантополучателей')
      })
  } catch (err) {
    console.log(err)
  }
}

async function importFileGranteeContact() {
  try {
    const form = new FormData()
    form.append('file', importFile.value[0])
    await api
      .information()
      .importFileGranteeContact(form)
      .then(() => {
        getGranteeContacts()
      })
  } catch (err) {
    toast.error('Ошибка при чтении файла. Попробуйте загрузить другой файл')
    console.log(err)
  }
}

async function downloadGranteeContactsTemplate() {
  try {
    await api
      .information()
      .downloadGranteeContactTemplate()
      .then((response) => {
        downloadFileData(response, 'Шаблон "Контакты грантополучателей"')
      })
  } catch (err) {
    console.log(err)
  }
}

async function deleteGranteeContacts(id) {
  try {
    await api
      .information()
      .deleteGranteeContact(id)
      .then(() => getGranteeContacts())
  } catch (err) {
    console.log(err)
  }
}

async function getGranteeContacts() {
  isLoading.value = true
  try {
    await api
      .information()
      .getGranteeContacts()
      .then((res) => {
        const newData = res?.map((el) => {
          const findEl = ACTUALITY_OPTION.find((item) => item.value === el?.actual)
          el.actual = findEl ? findEl.title : el.actual
          delete el.centerNtiId
          return {
            centerNtiShortTitle: el?.centerNtiShortTitle,
            actual: el?.actual,
            category: el?.category,
            email: el?.email,
            fullName: el?.fullName,
            jobTitle: el?.jobTitle,
            phone: el?.phone,
          }
        })
        console.log(newData)
        data.value = newData
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getGranteeContacts()
})

watch(importFile, () => {
  importFileGranteeContact()
})
</script>
