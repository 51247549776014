<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="order in orders" :key="order.contest.id" elevation="1">
      <v-expansion-panel-title>
        <v-row align="center" class="flex-sm-row flex-column">
          <v-col md="2"> Заявка № {{ order.id }} </v-col>
          <v-col md="3">
            {{ order.contest.name }}
          </v-col>
          <v-col md="2">
            {{ order.created }}
          </v-col>
          <v-col v-if="!isUser" md="3" class="text-break">
            {{ order.command_name_value }}
          </v-col>
          <v-col md="2">
            <span v-if="isUser || isExpert">{{ order.status }}</span>
            <v-select
              v-if="!isUser && !isExpert"
              :model-value="order.status"
              :items="order?.contest?.status"
              item-title="name"
              item-value="name"
              variant="underlined"
              hide-details="auto"
              @update:model-value="onSetOrderStatus(order, $event)"
            />
          </v-col>
          <v-col v-if="isUser && order?.contest?.email" md="cols">
            <v-btn
              color="primary"
              variant="text"
              size="x-small"
              :href="`mailto:${order?.contest?.email}`"
              @click.stop
            >
              Отправить e-mail организаторам
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row>
          <v-col>
            <DataTable
              :value="order.results"
              sort-field="id"
              :sort-order="1"
              class="p-datatable-sm test text-caption"
            >
              <template #header>
                <template v-if="!isUser">
                  <v-col class="pa-0 ma-0">
                    Название команды:
                    <span class="font-weight-medium">
                      {{ order?.command_name_value }}
                    </span>
                  </v-col>

                  <v-col class="pa-0 ma-0">
                    ФИО:
                    <span class="font-weight-medium">
                      {{ order?.user?.fio }}
                    </span>
                  </v-col>

                  <v-col class="pa-0 ma-0">
                    Название организации:
                    <span class="font-weight-medium">
                      {{ order?.org_name_value }}
                    </span>
                  </v-col>

                  <v-col class="pa-0 ma-0"> Анкеты участника: </v-col>
                </template>
                <v-row
                  v-if="!order.results?.length"
                  class="mt-4 align-center justify-center text-caption text-grey"
                >
                  Анкеты не найдены
                </v-row>
              </template>
              <div>
                <template v-if="order.results?.length">
                  <Column field="id" header="ID-анкеты">
                    <template #body="{ data: { id } }">
                      <router-link :to="`/questionnaire/${id}`">
                        {{ id }}
                      </router-link>
                    </template>
                  </Column>
                  <Column field="date_submit" header="Дата и время подачи" />
                  <Column field="forma.title" header="Название формы анкеты" />
                  <Column v-if="!isUser" field="command_name_value" header="Название команды" />
                  <Column v-if="!isUser" field="org_name_value" header="Название организации" />
                  <Column field="status" header="Статус анкеты">
                    <template #body="{ data: { status } }">
                      {{ defaultStatus[status] }}
                    </template>
                  </Column>
                  <Column v-if="isUser">
                    <template #body="{ data: { id } }">
                      <v-btn
                        color="primary"
                        size="x-small"
                        :to="`/questionnaire/${id}`"
                        @click.stop
                      >
                        Перейти в анкету
                      </v-btn>
                    </template>
                  </Column>
                </template>
              </div>
            </DataTable>
            <v-row class="mt-6 mb-2 justify-end">
              <v-btn
                v-if="!isExpert"
                variant="text"
                color="primary"
                @click="() => openApplicationHistoryDialog(order)"
              >
                История заявки
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-row v-if="Object.values(orders).length === 0" justify="center">
    <v-col cols="auto">
      <p>Нет поданных заявок</p>
    </v-col>
  </v-row>
  <OrdersConfirmStatus
    ref="confirmStatus"
    :selected-status="selectedStatus"
    @on-set-status="setOrderStatus"
  />
  <ApplicationHistoryDialog ref="applicationHistoryDialog" />
</template>

<script setup>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { defaultStatus } from '@/utils/common'
import { useStore } from 'vuex'
import { computed, ref, onMounted } from 'vue'
import OrdersConfirmStatus from '@/components/Order/OrdersConfirmStatus'
import ApplicationHistoryDialog from '@/components/Dialogs/ApplicationHistoryDialog'
import { api } from '@/api/Api'
import { useToast } from '@/composables/useToast'
import { useRoute } from 'vue-router/dist/vue-router'

const store = useStore()
const { toast } = useToast()
const route = useRoute()

defineProps({
  orders: {
    type: Object,
    default: () => {
      return {}
    },
  },
})

const emit = defineEmits(['update-orders'])
const confirmStatus = ref(null)
const selectedStatus = ref({})
const applicationHistoryDialog = ref(null)

const isUser = computed(() => store.getters['user/isUser'])
const isExpert = computed(() => store.getters['user/isExpert'])

function openApplicationHistoryDialog(val) {
  if (applicationHistoryDialog.value) {
    applicationHistoryDialog.value.dialogToggle()
    applicationHistoryDialog.value.setData(val)
  }
}

function onSetOrderStatus(order, status) {
  const {
    contest: {
      id: contestId,
      status: { id: orderId },
    },
    user: { id: userId },
  } = order
  selectedStatus.value = { contestId, userId, status, orderId }
  confirmStatus.value.openDialog()
}

function setOrderStatus() {
  const { contestId, userId, status } = selectedStatus.value
  const formData = new FormData()
  formData.append('contest_id', contestId)
  formData.append('user_id', userId)
  formData.append('status', status)
  api
    .contest()
    .setStatusForContest(formData)
    .then(() => {
      toast.success(`Статус заявки № ${contestId} изменен на - "${status}"`)
      emit('update-orders')
    })
    .catch(() => {
      toast.error('Произошла ошибка при смене статуса заявки')
    })
    .finally(() => {
      selectedStatus.value = {}
    })
}

async function getOrder(id) {
  await api
    .result()
    .getOrder(id)
    .then((data) => {
      openApplicationHistoryDialog(data)
    })
}

onMounted(() => {
  const findOrderId = route.query?.chat
  if (route.query?.chat) {
    getOrder(findOrderId)
  }
})
</script>
<script>
export default {
  name: 'OrderList',
}
</script>

<style scoped>
.test::v-deep(.p-datatable-header) {
  background-color: inherit !important;
  border: none;
}
.test::v-deep(th) {
  background-color: inherit !important;
}
</style>
