import { axiosCustom } from '@/plugins/axios'

export class User {
  getCurrentUserInfo() {
    return axiosCustom.get('/api/user/info').then((response) => response.data)
  }

  getUserInfoById(userId) {
    return axiosCustom.get(`/api/user/${userId}`).then((response) => response?.data)
  }

  updateInfoByUser(userId, form) {
    return axiosCustom.put(`/api/user/${userId}`, form).then((response) => response.data)
  }

  setGroupToUser(form) {
    return axiosCustom.post('/api/user/setgroups', form).then((response) => response.data)
  }

  getUsers(page = 1, pageSize = 1000) {
    const params = new URLSearchParams({
      page,
      pageSize,
    }).toString()
    return axiosCustom.get(`/api/user?${params}`).then((response) => response.data)
  }

  getUsersByGroup(group) {
    const params = new URLSearchParams({
      group_id: group,
    }).toString()
    return axiosCustom.get(`/api/user?${params}`).then((response) => response.data)
  }
}
