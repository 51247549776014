<template>
  <v-row
    v-for="report in reports"
    :key="`report-${report.id}`"
    class="px-0 mx-0 row-bottom-bordered"
  >
    <v-col class="px-0 mx-0 font-weight-bold">{{ getTitleReport(report) }}</v-col>
    <v-col class="px-0 mx-0 font-weight-bold"
      >Дата отправки: {{ formatDate(report?.updatedAt || report?.createdAt) }}</v-col
    >
    <v-col class="px-0 mx-0 text-grey">Статус: {{ REPORT_STATUS_LABELS[report.status] }}</v-col>
    <v-col class="px-0 mx-0 justify-end d-flex pr-12 flex-grow-0">
      <RouterLink :to="`/reports/report-form/${report.id}`"> Посмотреть </RouterLink>
    </v-col>
  </v-row>
</template>
<script setup>
import { REPORT_STATUS_LABELS } from '@/constants/index'
import { getReportPeriod } from '@/helpers/index'
import moment from 'moment'
defineProps({
  reports: {
    type: Array,
    default: () => [],
  },
})

function getTitleReport(report) {
  const periodReport = getReportPeriod(report.type)
  const label = report.type === 'annual' ? 'Годовой отчет' : `Отчет за ${periodReport} квартал`
  return `${label} ${report.year} года`
}

function formatDate(date) {
  return moment(date).format('DD.MM.YYYY')
}
</script>
<style scoped lang="scss">
.row-bottom-bordered {
  border-bottom: 1px solid var(--surface-300);
}
</style>
