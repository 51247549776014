import store from '@/store'
import cloneDeep from 'lodash/cloneDeep'

export const commonValidationRules = {
  required: (v) => (v !== '' && v !== undefined && v !== null) || 'Заполните поле',
  email: (v) => v === '' || /.+@.+\..+/.test(v) || 'Введите корректный E-mail',
  [`phone-number`]: (v) =>
    v === '' ||
    /^\+?(\d{1,3})?[- .]?\(?\d{2,3}\)?[- .]?\d\d\d[- .]?\d\d\d\d$/.test(v) ||
    'Введите корректный номер телефона',
  url: (v) =>
    v === '' ||
    /[-a-zA-Z0-9@:%_+.~#?&/=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&/=]*)?/gi.test(v) ||
    'Введите корректный URL',
  latin: (v) => v === '' || /^[a-zA-Z-_0-9]+$/i.test(v) || 'Ввод доступен только на латинице',
  cannotStartWithNumber: (v) =>
    v === '' || /^[^0-9]/i.test(v) || 'Начало строки не может начинаться с числа',
  granteeKPP: (v) => String(v)?.length === 9 || `КПП получателя должно содержать 9 символов`,
  granteeINN: (v) => {
    return String(v)?.length === 10 || `ИНН получателя 10 символов`
  },
  granteeOGRN: (v) => String(v)?.length === 13 || `ОГРН получателя должно содержать 13 символов`,
  granteeOKTMO: (v) => String(v)?.length === 11 || `ОКТМО получателя должно содержать 13 символов`,
  codePpp: (v) => v.length === 7 || `Номер Центра НТИ в системе 1С 7 символов`,
}

export function generateId() {
  return Date.now() + Math.floor(Math.random() * 100)
}

export function formatPrimitiveChar(char) {
  return char === 'Y'
}

/**
 * Максимальный размер файлов в байтах (100мб)
 * @type {number}
 */
export const maxFileSize = 104857600

export function convertMb(size) {
  const oneMb = 1048576
  if (size <= oneMb) {
    return '1Mb'
  }
  return Math.floor(size / oneMb) + 'Mb'
}

export function replaceRole(id) {
  const groups = store.getters['group/group']
  const role = groups?.find((group) => group.id === id)
  return role ? role.name : ''
}

export function prepareFields(inputs) {
  const cloneInputs = cloneDeep(inputs)
  return cloneInputs
    .map((field) => {
      if (field.type === 'group') {
        return
      }
      if (field.group_id === 0 || field.group_id === '') {
        return field
      }
      const groupName = cloneInputs.find((group) => group.id === field.group_id).name
      const foundGroups = cloneInputs.filter((group) => group.name === groupName)
      const index = foundGroups.findIndex((group) => group.id === field.group_id)
      field.name = field.name.replace('[]', `[n${index}]`)
      return field
    })
    .filter(Boolean)
}

export function findItem(findType, findValue, items) {
  return items.find((item) => item[findType] === findValue)
}

export const defaultStatus = {
  accept: 'Анкета одобрена',
  reject: 'Анкета отклонена',
  revision: 'Требует доработки',
  new: 'На рассмотрении',
  draft: 'Черновик',
  sent: 'Повторная проверка',
}
