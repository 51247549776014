<template>
  <the-container>
    <h3 class="mb-6">
      Детализированный отчет о структуре расходов, источником финансового обеспечения которых
      являются средства гранта
    </h3>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <template v-else>
      <template v-if="!isEdited">
        <report-form-preview
          :form-fields="formFields"
          :form-groups="formGroups"
          @download-file="downloadFile"
          @print-pdf="printReportDetailsForm"
        />
      </template>
      <v-form v-else ref="form" lazy-validation>
        <v-col
          v-for="category in formGroups"
          :key="`category-${category.id}-${formFields?.length}-${category.title}`"
          class="px-0"
        >
          <p v-if="category.title" class="text-subtitle-1 mx-0 my-2 mb-4 font-weight-bold">
            {{ category.title }} {{ category.idDescriptionTitle }}
          </p>
          <template v-if="category?.subcategories?.length">
            <div v-for="subcategory in category?.subcategories" :key="subcategory.id" class="my-4">
              <div v-if="subcategory.title" class="text-body-1 font-weight-bold mb-2">
                {{ subcategory.title }}
              </div>
              <div
                v-for="field in getSubcategoryField(category.id, subcategory.id)"
                :key="field.id"
              >
                <div v-if="field.title" class="text-body-1 mx-0">
                  {{ field.title }}
                </div>
                <div :class="[field.disabled ? 'disabled' : '']">
                  <Component
                    :is="inputs[getFormInput(field.type)]"
                    v-bind="field"
                    @update-value="(data) => updateValue(formFields, data)"
                    @change-value="updateTotalValue(field, category.id)"
                    @delete-file="deleteFile"
                    @download-file="downloadFile"
                  />
                </div>
              </div>
            </div>
          </template>

          <div v-if="getCategoryFields(category.id)?.length" class="mb-4">
            <div v-for="field in getCategoryFields(category.id)" :key="`field-${field.id}`">
              <p v-if="field.title" class="text-body-1 mx-0 my-0">
                {{ field.title }}
              </p>
              <div :class="[field.disabled ? 'disabled' : '']">
                <Component
                  :is="inputs[getFormInput(field.type)]"
                  v-bind="field"
                  @update-value="(data) => updateValue(formFields, data)"
                  @change-value="updateTotalValue(field, category.id)"
                  @delete-file="deleteFile"
                  @download-file="downloadFile"
                />
              </div>
            </div>
          </div>
          <v-row
            v-if="category.buttons?.length"
            class="align-center justify-space-between pa-0 ma-0"
          >
            <div class="d-flex align-center">
              <div
                v-for="field in category.buttons"
                :key="`field-${field.id}`"
                :class="[field.disabled ? 'disabled' : '']"
              >
                <Component
                  :is="inputs[getFormInput(field.type)]"
                  v-bind="field"
                  class-names="my-2 d-block"
                  @update-value="(data) => updateValue(formFields, data)"
                  @change-value="updateTotalValue(field, category.id)"
                />
              </div>
            </div>
            <v-btn
              v-if="!index"
              class="ml-2"
              variant="text"
              type="button"
              @click="() => deleteGroup(field)"
            >
              <v-icon color="red" size="xs"> mdi-delete-outline </v-icon>
              <span class="ml-2 text-body-2 title text-red">Удалить</span>
            </v-btn>
          </v-row>
          <div v-if="category.total" class="mt-8 font-weight-bold text-subtitle-1">
            <div class="mb-2">Итого:</div>
            <div>{{ category.total }} руб.</div>
          </div>

          <div v-if="category.note" class="my-4">
            <div
              v-for="(note, index) in category.note"
              :key="`note-${index}`"
              class="text-grey text-caption mb-1"
            >
              {{ note }}
            </div>
          </div>
        </v-col>
        <v-row class="px-0 align-center mx-0 mt-6">
          <v-btn
            depressed
            color="primary"
            variant="elevated"
            class="mr-4"
            @click="printReportDetailsForm"
          >
            Печать
          </v-btn>
          <v-btn depressed color="primary" variant="outlined" @click="onSubmit"> Сохранить </v-btn>
          <v-btn
            class="ml-2 text-decoration-underline"
            color="primary"
            variant="text"
            @click="router.push('/reports')"
          >
            Закрыть
          </v-btn>
        </v-row>
      </v-form>
    </template>
  </the-container>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'

import { api } from '@/api/Api'

import {
  inputs,
  getFormInput,
  focusErrorInput,
  downloadFileWithLinkData,
  downloadFileData,
} from '@/helpers/index'

import { WINNER_FOLDER, DETAILED_REPORT_FORM_FOLDER } from '@/constants/store'
import { FORM_DETAILS_STRUCTURE } from '@/constants/forms'

import TheContainer from '@/components/TheContainer'
import { useToast } from '@/composables/useToast'
import ReportFormPreview from '@/components/Reports/ReportFormPreview'
import { DETAILED_EXPENSE_STRUCTURE_REPORT_HASH } from '@/constants/cachesIds'
import store from '@/store'
import { REPORT_FILES } from '@/constants/buckets'

const { toast } = useToast()
const route = useRoute()
const router = useRouter()

const isWinner = computed(() => store.getters['user/isWinner'])
const projectId = computed(() => store.getters['project/projectId'])
const bucket = computed(() => REPORT_FILES)

const isEdited = ref(true)
const isLoading = ref(true)
const idForm = ref(null)

const currentReport = ref(null)

const form = ref(null)
const hash = ref(`${DETAILED_EXPENSE_STRUCTURE_REPORT_HASH}-${route.params?.id || ''}`)

const formFields = ref([...FORM_DETAILS_STRUCTURE])

const formGroups = ref([
  {
    id: 0,
    title: 'Общие сведения',
  },
  {
    id: 1,
    title: 'I. Структура расходов средств гранта',
    idDescriptionTitle: '*6',
    subcategories: [
      {
        id: 0,
        title: 'Выплаты персоналу, всего (включая начисления на фонд оплаты труда)',
      },
      {
        id: 1,
        title: 'Закупка работ и услуг',
      },
      {
        id: 2,
        title: 'Приобретение оборудования',
      },
      {
        id: 3,
        title: 'Приобретение программного обеспечения',
      },
      {
        id: 4,
        title: 'ИТОГОВАЯ СУММА:',
        isTotal: true,
      },
    ],
    note: [
      '*6 - Таблица заполняется по каждому гранту, предоставленному в соответствующем году, в случае если в отчетном периоде было движение средств',
      '*7 - Отражаются кассовые расходы, произведенные Получателем гранта, за отчетный период.',
    ],
  },
  {
    id: 2,
    title: 'II. Расходы средств гранта на приобретение оборудования',
    idDescriptionTitle: '*8',
    note: [
      '*8 - Таблица заполняется по каждому гранту, предоставленному в соответствующем году, в случае если в отчетном периоде было движение средств',
      '*9 - Наименование оборудования указывается в соответствии с договором поставки. В случае если в рамках одного договора приобретаются разные группы оборудования (не предполагающегося для совместного использования), то такие группы/позиции указываются в разных строках таблицы',
      '*10 - Указываются реквизиты договоров, актов, товарных накладных, платежных поручений',
    ],
    name: 'reportExpenseStructureEquipment',
    total: 0,
    buttons: [
      {
        id: uuidv4(),
        type: 'actionButton',
        name: 'add-block',
        text: 'Добавить блок',
        default_value: null,
        action: () => addBlock(2),
      },
    ],
  },
  {
    id: 3,
    idDescriptionTitle: '*11',
    title: 'III. Расходы средств гранта на приобретение программного обеспечения',
    name: 'reportExpenseStructureSoftwareSection',
    total: 0,
    note: [
      '*11 - Таблица заполняется по каждому гранту, предоставленному в соответствующем году, в случае если в отчетном периоде было движение средств',
      '*12 - Наименование программного обеспечения указывается в соответствии с договором поставки. В случае если в рамках одного договора приобретаются разные группы программного обеспечения (не предполагающегося для совместного использования), то такие группы/позиции указываются в разных строках таблицы',
      '*13 - Указываются реквизиты договоров, актов, товарных накладных, платежных поручений',
    ],
    buttons: [
      {
        id: uuidv4(),
        type: 'actionButton',
        name: 'add-block',
        text: 'Добавить блок',
        default_value: null,
        action: () => addBlock(3),
      },
    ],
  },
  {
    id: 4,
    title: 'IV. Расходы средств гранта на оплату оказанных услуг, выполненных работ',
    idDescriptionTitle: '*14',
    total: 0,
    name: 'reportExpenseStructureServiceSection',
    buttons: [
      {
        id: uuidv4(),
        type: 'actionButton',
        name: 'add-block',
        text: 'Добавить блок',
        default_value: null,
        action: () => addBlock(4),
      },
    ],
  },
  {
    id: 5,
    buttons: [
      {
        id: uuidv4(),
        type: 'actionButton',
        name: 'add-block',
        text: 'Добавить данные за предыдущий год',
        default_value: null,
        action: () => createReport(),
      },
    ],
    note: [
      '*14 - Таблица заполняется по каждому гранту, предоставленному в соответствующем году, в случае если в отчетном периоде было движение средств',
      '*15 - Наименование услуг, и (или) работ указывается в соответствии с договором. В случае если в рамках одного договора были заказаны разные услуги и (или) работы, то такие позиции указываются в разных строках таблицы',
      '*16 - Указываются реквизиты договоров, актов, платежных поручений',
    ],
  },
])

async function clearStash() {
  try {
    await api.stash().clearStash(hash.value)
  } catch (err) {
    console.log(err)
  }
}

// async function updateDataInStash({ id }) {
//   const findField = formFields.value?.find((el) => el.id === id)
//
//   if (findField) {
//     const params = {
//       field: findField.name,
//       value: findField.default_value,
//       hash: hash.value,
//     }
//
//     await api.stash().saveFieldInStash(params)
//   }
// }

async function getDetailsReportData() {
  isLoading.value = true
  try {
    await api
      .reports()
      .getDetailsReportData(route.params?.id)
      .then((data) => {
        currentReport.value = data

        if (isWinner.value) {
          getSavedData()
        }
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function getSavedData() {
  isLoading.value = true
  try {
    await api
      .stash()
      .getStashData(hash.value)
      .then((data) => {
        console.log(data)
      })
  } catch (err) {
    toast.error(err)
  } finally {
    isLoading.value = false
  }
}

async function createReport() {
  try {
    const params = {
      projectId: projectId.value,
      type: currentReport.value?.reportType,
      year: new Date().getFullYear() - 1,
    }

    await api
      .reports()
      .createReport(params)
      .then((data) => {
        router.push(`/reports/detailed-expense-structure-report-form/${data?.id}`)
      })
  } catch (err) {
    toast.error(err)
  }
}

function updateValue(fieldsForm, data) {
  const { value, id } = data
  fieldsForm.forEach((item) => {
    if (item.id === id) {
      item.default_value = value

      if (item.type === 'dropFile') {
        value?.forEach((el) => {
          uploadFile(item.name, el, item.copy_group)
        })
      }
    }
  })
}

async function downloadFile(id, name) {
  try {
    await api
      .files()
      .downloadFile(id)
      .then((data) => {
        downloadFileWithLinkData(data, name)
      })
  } catch (err) {
    toast.error(err)
  }
}

async function deleteFile(id) {
  try {
    await api
      .files()
      .deleteFile(id)
      .then(() => {
        getFiles()
      })
  } catch (err) {
    toast.error(err)
  }
}

async function uploadFile(name, data, copyGroup) {
  try {
    const form = new FormData()
    form.append('file', data)
    form.append('fileKey', name)
    form.append('entityId', route.params?.id)
    form.append('folder', `${WINNER_FOLDER}/${DETAILED_REPORT_FORM_FOLDER}`)

    if (copyGroup) {
      form.append('groupId', copyGroup)
    }

    await api
      .files()
      .uploadFile(bucket.value, form)
      .then(() => {
        getFiles()
      })
  } catch (err) {
    toast.error(err)
  }
}

async function getFiles() {
  try {
    const params = { entityId: route.params?.id }
    await api
      .files()
      .getFiles(bucket.value, params)
      .then((data) => {
        const allContent = data?.filesInFolders
        if (!allContent && allContent[WINNER_FOLDER]) {
          return
        }
        const filesForms = allContent[WINNER_FOLDER]

        const files = filesForms[DETAILED_REPORT_FORM_FOLDER]
        formFields.value?.forEach((el) => {
          if (!files || el.type !== 'dropFile') {
            return
          }

          if (el.name in files) {
            el.links = files[el.name]?.filter((file) => file.groupId == el?.copy_group)
          } else {
            el.links = []
          }
        })
      })
  } catch (err) {
    console.log(err)
  }
}

function deleteGroup(field) {
  console.log(field)
}

function addBlock(idGroup) {
  const templateFieldsGroup = formFields.value?.filter(
    (el) => el.group_id === idGroup && !el?.copy_group,
  )

  const copyGroup = formFields.value?.filter(
    (el, index) => !index && el.name === templateFieldsGroup[index].name && el.group_id === idGroup,
  )?.length

  const newGroupFields = templateFieldsGroup?.map((el) => {
    return {
      ...el,
      id: uuidv4(),
      copy_group: copyGroup,
      default_value: null,
      links: [],
    }
  })
  formFields.value.splice(formFields.value?.length, 0, ...newGroupFields)
}

function getSubcategoryField(categoryId, subcategoryId) {
  const categoryFields = formFields.value?.filter((el) => el.group_id === categoryId)
  const subcategoryFields = categoryFields?.filter((el) => el.subcategory === subcategoryId)
  return subcategoryFields
}

function getCategoryFields(categoryId) {
  const categoryFields = formFields.value?.filter(
    (el) => el.group_id === categoryId && typeof el?.subcategory !== 'number',
  )
  return categoryFields
}

function updateTotalValue(field, idCategory) {
  console.log(field, idCategory)
}

async function onSubmit() {
  await form.value.validate()
  if (form.value.errors?.length) {
    const errInput = form.value.errors[0]

    focusErrorInput(errInput)
    return
  }

  const data = null //queryDataGeneration()

  try {
    const params = {
      ...data,
      id: idForm.value,
    }
    await api.reports().saveExpenseStructure(route.params?.id, params)
    await clearStash()
    isEdited.value = false
  } catch (err) {
    toast.error('Ошибка при сохранении данных')
  }
}

async function printReportDetailsForm() {
  try {
    const year = formFields.value?.find((el) => el.name === 'year')
    console.log(year, formFields.value)
    if (!year) {
      return
    }

    await api
      .reports()
      .printDetailsStructure(route.params?.id, year.default_value)
      .then((response) => {
        downloadFileData(response, 'Детализированный отчет о структуре расходов')
      })
      .then((data) => {
        console.log(data)
      })
  } catch (err) {
    console.log(err)
  }
}

async function getGeneralReportInfo() {
  isLoading.value = true
  try {
    await api
      .reports()
      .getGeneralReportInfo(route.params?.id)
      .then((data) => {
        formFields.value?.map((el) => {
          if (el.name in data) {
            el.default_value = data[el.name]
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getGeneralReportInfo()
  getDetailsReportData()
  getFiles()
})
</script>
