export const POINTS_STATUSES = {
  NEW: '9ab9e0f3-6408-4773-9785-f58c83fb6a85',
  FILLING: '3ab9194d-6eaf-4384-a667-b68fb2101bae',
  PROCESSING: '5ff9194d-6eaf-4384-a667-b68fb21ae888',
  NEEDS_IMPROVEMENTS: 'e971abb1-7e69-4b26-abf2-d0c105111ff5',
  NO_ACTION_REQUIRED: '1c4b4753-5463-46a8-9524-0b58aeaba48f',
  PROCESSING_REQUIRED: 'd9deb30e-6a64-42b1-a7b2-a5fa1aed79a1',
  TRANSFERRED_TO_MANAGER: '90d6595f-d45d-4bfd-b21d-c7aba84f72cb',
  FINALLY: '0e324ccb-ae49-4286-914d-9b63a4e486de',
  SUSPENDED: '4c8ccebc-bf56-46ff-b76e-efc7760d2f98',
}

export const POINT_TYPE_EXPERT = 'expert'
