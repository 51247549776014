<template>
  <v-row class="overflow-x-auto pa-0 ma-0">
    <the-custom-table :head="head" :data="data" />
  </v-row>
  <v-row v-if="isManager || isMonitor" class="pa-0 ma-0 mt-6">
    <v-btn color="primary" @click="exportAgreementsList">Выгрузить</v-btn>
  </v-row>
</template>
<script setup>
import { v4 as uuidv4 } from 'uuid'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import TheCustomTable from '@/components/TheCustomTable'
import { api } from '@/api/Api'
import { downloadFileData } from '@/helpers'
import moment from 'moment'

const store = useStore()

const props = defineProps({
  projects: {
    type: Array,
    default: () => [],
  },
})

function getContractDate(contracts, field, isDate, isYear) {
  let data = ''
  contracts?.forEach((el) => {
    let date = el[field]

    if (isDate && isYear) {
      date = moment(el[field]).format('YYYY')
    } else if (isDate) {
      date = moment(el[field]).format('DD.MM.YYYY')
    }
    data += `<div>${date}</div>`
  })

  return data
}

const head = ref([
  {
    id: uuidv4(),
    title: 'Центр НТИ',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'Год заключения основного договора',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'Договор №',
  },
  // {
  //   id: uuidv4(),
  //   title: 'Дата договора',
  // },
  {
    id: uuidv4(),
    title: 'Номер доп. соглашения',
  },
  {
    id: uuidv4(),
    title: 'Дата заключения дополнительного соглашения',
  },
  {
    id: uuidv4(),
    title: 'Краткое содержание дополнительного соглашения',
  },
  {
    id: uuidv4(),
    title: 'Сопровождающие сведения',
  },
])

const isManager = computed(() => store.getters['user/isManager'])
const isMonitor = computed(() => store.getters['user/isMonitor'])
const data = computed(() => {
  return props.projects?.map((el) => {
    return {
      id: uuidv4(),
      fullTitle: el.centerNti?.shortTitle,
      contractDate: getContractDate(el.contracts, 'agreementDate', true, true),
      contractNumber: getContractDate(el.contracts, 'number'),
      contractAdditionalNumber: getContractDate(el.contracts, 'additionalAgreementNumber'),
      contractDateAdditional: getContractDate(
        el.contracts,
        'additionalAgreementConclusionDate',
        true,
      ),
      contractShortInformation: getContractDate(el.contracts, 'additionalAgreementSummary'),
      stub: el.id ? `<a href="${getLinkProgram(el.id)}">Подтверждающие документы</a>` : '',
    }
  })
})

function getLinkProgram(id) {
  if (isManager.value) {
    return `/coordination-documents/request-create-support-recipient-program-card-view-for-manager/${id}#files`
  }

  if (isMonitor.value) {
    return `/coordination-documents/request-create-support-recipient-program-card-view-for-monitor/${id}#files`
  }

  return `/coordination-documents/request-create-support-recipient-program-card-view-for-winner/${id}#files`
}

async function exportAgreementsList() {
  try {
    await api
      .projects()
      .exportAgreementsList()
      .then((data) => {
        downloadFileData(data, 'Договоры и ДС')
      })
  } catch (err) {
    console.log(err?.message)
  }
}
</script>
