<template>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <the-container v-else>
    <h3 v-if="report" class="mb-4">
      {{ getPeriodLabel(report) }}
    </h3>
    <div>
      <span>{{ isWinner ? 'Дата формирования отчета: ' : 'Дата отправки отчета: ' }}</span>
      {{ getDateCreatedAtReport() }}
    </div>
    <v-col class="px-0">
      <div class="text-body-2 font-weight-bold py-4 border-bottom-line text-black">
        Название центра НТИ:
        <span class="font-weight-medium">{{ report?.project?.centerNti?.fullTitle }}</span>
      </div>
      <div class="text-body-2 font-weight-bold py-4 border-bottom-line text-black">
        Название программы:
        <span class="font-weight-medium">{{ report?.project?.programName }}</span>
      </div>
    </v-col>
    <v-form
      ref="form"
      v-model="validForm"
      class="px-0 w-100"
      lazy-validation
      @keypress="(e) => (e.keyCode == 13 ? e.preventDefault() : '')"
    >
      <v-col cols="12" class="px-0 my-0">
        <template v-if="canWinnerChangeReport">
          <div v-for="field in fieldsForm" :key="field.id">
            <p v-if="field.title" class="text-body-1 mx-0 my-2">
              {{ field.title }}
            </p>
            <Component
              :is="inputs[getFormInput(field.type)]"
              :key="field?.links"
              v-bind="field"
              @change-value="updateDataInStash"
              @update-value="updateValue"
              @delete-file="(id) => deleteFile(id, field)"
              @download-file="downloadFile"
            />
          </div>
        </template>

        <div class="mb-8">
          <DocumentsList
            :documents="documents"
            :data="report"
            is-button-open-window-type
            @click-action="showDocument"
            @download="downloadFile"
          />
        </div>

        <div v-if="isShowDownloadFilesList" class="mb-8">
          <div class="mb-4 font-weight-bold text-grey">Подтверждающие документы</div>
          <div
            v-for="file in filesList"
            :key="file.key"
            class="text-decoration-underline text-blue-darken-3 cursor-pointer mb-2"
            @click="() => downloadFile(file.id, file?.originalFileName)"
          >
            {{ file?.originalFileName }}
          </div>
        </div>
        <template v-if="canWinnerChangeReport">
          <div v-for="field in fieldsFilesForm" :key="field.id">
            <p v-if="field.title" class="text-body-1 mx-0 my-2">
              {{ field.title }}
            </p>
            <Component
              :is="inputs[getFormInput(field.type)]"
              v-bind="field"
              @change-value="updateDataInStash"
              @update-value="updateValue"
              @delete-file="(id) => deleteFile(id, field)"
              @download-file="downloadFile"
            />
          </div>
        </template>

        <div
          v-if="showManagerComment && report?.managerComment"
          :class="['comment my-6', !isWinner ? 'edited' : 'error']"
        >
          <div>Комментарий Менеджера</div>
          <div class="box-comment">{{ report?.managerComment }}</div>
        </div>
        <div v-if="isShowMonitorComment && report?.monitorComment" class="comment my-6 edited">
          <div>Комментарий Монитора</div>
          <div class="field">{{ report?.monitorComment }}</div>
        </div>
        <template v-if="isManagerHasPermissions">
          <div v-for="field in commentManager" :key="field.id">
            <template
              v-if="
                report.status !== REPORTS_STATUSES.REVIEWED ||
                (field.type !== 'dropFile' && report.status === REPORTS_STATUSES.REVIEWED) ||
                (report.type === REPORTS_TYPES.ANNUAL &&
                  report.status === REPORTS_STATUSES.REVIEWED)
              "
            >
              <p v-if="field.title" class="text-body-1 mx-0 my-0">
                {{ field.title }}
              </p>
              <Component
                :is="inputs[getFormInput(field.type)]"
                v-bind="field"
                @change-value="updateDataInStash"
                @delete-file="(id) => deleteFile(id, field)"
                @download-file="downloadFile"
                @update-value="updateCommentManager"
              />
            </template>
          </div>
        </template>
        <template v-if="isMonitorHasPermissions && report.type !== REPORTS_TYPES.ANNUAL">
          <div v-for="field in commentMonitor" :key="field.id">
            <p v-if="field.title" class="text-body-1 mx-0 my-0">
              {{ field.title }}
            </p>
            <Component
              :is="inputs[getFormInput(field.type)]"
              v-bind="field"
              @change-value="updateDataInStash"
              @delete-file="(id) => deleteFile(id, field)"
              @download-file="downloadFile"
              @update-value="updateCommentMonitor"
            />
          </div>
        </template>
        <template v-if="isMonitorHasPermissions && report.type === REPORTS_TYPES.ANNUAL">
          <div v-for="field in expressConclusionMonitor" :key="field.id">
            <p v-if="field.title" class="text-body-1 mx-0 my-0">
              {{ field.title }}
            </p>
            <Component
              :is="inputs[getFormInput(field.type)]"
              v-bind="field"
              @delete-file="(id) => deleteFile(id, field)"
              @download-file="downloadFile"
              @update-value="updateExpressConclusionMonitor"
            />
          </div>
        </template>
        <template
          v-if="
            report && isManager && report.status === REPORTS_STATUSES.READY_TO_FINALIZE_CONCLUSION
          "
        >
          <div v-for="field in managerFinalConclusion" :key="field.id">
            <p v-if="field.title" class="text-body-1 mx-0 my-0">
              {{ field.title }}
            </p>
            <Component
              :is="inputs[getFormInput(field.type)]"
              v-bind="field"
              @delete-file="(id) => deleteFile(id, field)"
              @download-file="downloadFile"
              @change-value="updateDataInStash"
              @update-value="updateManagerFinalConclusion"
            />
          </div>
        </template>
        <template
          v-if="
            isMonitor &&
            (report?.status === REPORTS_STATUSES.FINALIZED_CONCLUSION ||
              report?.status === REPORTS_STATUSES.REPEAT_FINALIZE_CONCLUSION)
          "
        >
          <div v-for="field in finalConclusionMonitor" :key="field.id">
            <p v-if="field.title" class="text-body-1 mx-0 my-0">
              {{ field.title }}
            </p>
            <Component
              :is="inputs[getFormInput(field.type)]"
              v-bind="field"
              @delete-file="(id) => deleteFile(id, field)"
              @download-file="downloadFile"
              @update-value="updateMonitorFinalConclusion"
            />
          </div>
        </template>
        <v-col v-if="isEditForm" class="mt-4 px-0">
          <div class="text-body-2 text-grey">* - поля обязательные для заполнения</div>
          <div class="text-body-2 text-grey mt-2">Введенные данные сохраняются автоматически</div>
        </v-col>
        <v-row class="mt-12 mx-0 align-center">
          <v-btn
            v-if="canWinnerChangeReport"
            :disabled="isDisabledSubmitReportButton || reportAlreadySent"
            color="primary"
            class="mr-2"
            @click="onSubmit"
          >
            Отправить отчет
          </v-btn>
          <v-btn
            v-if="canManagerSendReportForAccept"
            color="primary"
            class="mr-2"
            :disabled="reportAlreadySent"
            @click="acceptReport"
          >
            Принять отчет
          </v-btn>
          <v-btn
            v-if="canManagerSendReportForRevision"
            color="primary"
            :disabled="isDisableRejectButton || reportAlreadySent"
            class="mr-2"
            @click="rejectReport"
          >
            На доработку
          </v-btn>
          <v-btn
            v-if="canManagerSendReportForReview"
            :disabled="disableToMonitorButton || reportAlreadySent"
            color="primary"
            class="mr-2"
            variant="outlined"
            @click="sendReportToMonitorForApproval"
          >
            Передать Монитору
          </v-btn>
          <v-btn
            v-if="canMonitorSendForApprove"
            color="primary"
            class="mr-2 monitor-button"
            :disabled="isDisableMonitorButton || reportAlreadySent"
            @click="sendReportToManagerForReview"
          >
            Отправить
          </v-btn>
          <v-btn
            v-if="canMonitorSendForApproveExpress"
            color="primary"
            class="mr-2 monitor-button"
            :disabled="isDisableMonitorExpressButton || reportAlreadySent"
            @click="sendExpressConclusion"
          >
            Экспресс-заключение сформировано
          </v-btn>
          <template
            v-if="
              isManager && report && report.status === REPORTS_STATUSES.READY_TO_FINALIZE_CONCLUSION
            "
          >
            <v-btn
              color="primary"
              class="mr-2 manager-button"
              :disabled="isDisableSendToMonitorForFinalizeConclusionButton || reportAlreadySent"
              @click="sendToMonitorForFinalizeConclusion"
            >
              На итоговое заключение
            </v-btn>
            <v-btn
              color="primary"
              class="mr-2 manager-button"
              variant="outlined"
              :disabled="reportAlreadySent"
              @click="rejectReport"
            >
              На доработку
            </v-btn>
          </template>
          <template
            v-if="
              isManager &&
              report &&
              report.status === REPORTS_STATUSES.REVIEWED &&
              report.type === REPORTS_TYPES.ANNUAL
            "
          >
            <v-btn
              color="primary"
              class="mr-2 manager-button"
              variant="outlined"
              :disabled="!managerCommentForRejectMonitor || reportAlreadySent"
              @click="sendToMonitorForRepeatFinalizeConclusion"
            >
              Вернуть монитору
            </v-btn>
          </template>
          <v-btn
            v-if="
              isMonitor &&
              (report?.status === REPORTS_STATUSES.FINALIZED_CONCLUSION ||
                report?.status === REPORTS_STATUSES.REPEAT_FINALIZE_CONCLUSION)
            "
            color="primary"
            class="mr-2 manager-button"
            variant="outlined"
            :disabled="disableButtonFinalMonitorButton || reportAlreadySent"
            @click="sendReportToManagerForReview"
          >
            Итоговое заключение сформировано
          </v-btn>
          <v-btn color="primary" variant="text" @click="openReportsList"> Закрыть </v-btn>
        </v-row>
      </v-col>
    </v-form>

    <template v-if="report?.project?.centerNti?.id">
      <RegisterNTIEmployees
        ref="registerNTIEmployees"
        :center-nti-id="report?.project?.centerNti?.id"
      />
      <RegisterPaymentOrders ref="registerPaymentOrders" />
      <AccountingRegistries ref="accountingRegistries" :report-id="report?.id" />
    </template>

    <TheDialog
      ref="confirmDialog"
      description="Подтвердите отправку данных"
      :dialog="confirmDialog"
      @confirm="sendReport"
    />
  </the-container>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'
import { useStore } from 'vuex'
import moment from 'moment'

import { api } from '@/api/Api'

import {
  inputs,
  getFormInput,
  downloadFileWithLinkData,
  getReportPeriod,
  clearStash,
} from '@/helpers/index'

import { REPORTS_STATUSES, REPORTS_TYPES } from '@/constants'
import { REPORT_FILES } from '@/constants/buckets'
import {
  ACCOUNTING_REGISTRIES,
  ACHIEVEMENT_REPORT,
  EXPENSE_REPORT,
  REGISTER_PAYMENT_ORDERS,
  WINNER_FOLDER,
} from '@/constants/store'
import { useToast } from '@/composables/useToast'

import TheContainer from '@/components/TheContainer'
import TheDialog from '@/components/TheDialog'
import DocumentsList from '@/components/Common/DocumentsList'
import RegisterNTIEmployees from '@/views/Reports/RegisterNTIEmployees'
import RegisterPaymentOrders from '@/views/Reports/RegisterPaymentOrders'
import AccountingRegistries from '@/views/Reports/AccountingRegistries'
import { REPORT_FORM } from '@/constants/stash'

const registerNTIEmployees = ref(null)
const registerPaymentOrders = ref(null)
const accountingRegistries = ref(null)
const confirmDialog = ref(null)
const form = ref(null)
const validForm = ref(true)

const route = useRoute()
const router = useRouter()
const { toast } = useToast()
const store = useStore()

const managerCommentForRejectMonitor = computed(
  () => commentManager.value?.find((el) => el.name === 'managerComment')?.default_value,
)
const disableButtonFinalMonitorButton = computed(() =>
  finalConclusionMonitor.value?.find((el) => !el.links?.length),
)
const isEditForm = computed(() => {
  return (
    (isWinner.value &&
      (report.value?.status === REPORTS_STATUSES.DRAFT ||
        report.value?.status === REPORTS_STATUSES.REJECTED)) ||
    (isManager.value &&
      (report.value?.status === REPORTS_STATUSES.NEW ||
        report.value?.status === REPORTS_STATUSES.REVIEWED ||
        report.value?.status === REPORTS_STATUSES.READY_TO_FINALIZE_CONCLUSION)) ||
    (isMonitor.value &&
      (report.value?.status === REPORTS_STATUSES.SEND_FOR_APPROVE ||
        report.value?.status === REPORTS_STATUSES.FINALIZED_CONCLUSION))
  )
})
const isDisabledSubmitReportButton = computed(() =>
  fieldsFilesForm.value?.find((el) => !el.default_value || !el.links?.length),
)

const showManagerComment = computed(
  () =>
    (isManager.value && report.value?.status === REPORTS_STATUSES.REJECTED) ||
    (isWinner.value && report.value?.status === REPORTS_STATUSES.REJECTED) ||
    ((isManager.value || isMonitor) &&
      report.value?.status === REPORTS_STATUSES.REPEAT_FINALIZE_CONCLUSION) ||
    ((isManager.value || isMonitor.value) &&
      report.value?.status === REPORTS_STATUSES.SEND_FOR_APPROVE),
)

const disableToMonitorButton = computed(() =>
  commentManager.value?.find((el) => el.type === 'dropFile' && !el.links?.length),
)

const isDisableRejectButton = computed(() =>
  commentManager.value?.find((el) => el.type === 'textarea' && !el.default_value),
)
const fieldFormHash = computed(() => `${REPORT_FORM}-${route.params?.id}`)
const projectId = computed(() => store.getters['project/projectId'])

const isWinner = computed(() => store.getters['user/isWinner'])
const isManager = computed(() => store.getters['user/isManager'])
const isMonitor = computed(() => store.getters['user/isMonitor'])

const isShowMonitorComment = computed(
  () =>
    !isWinner.value &&
    report.value?.status === REPORTS_STATUSES.REVIEWED &&
    report.value?.type !== REPORTS_TYPES.ANNUAL,
)
const isManagerHasPermissions = computed(
  () =>
    isManager.value &&
    (report.value?.status === REPORTS_STATUSES.IMPROVED ||
      report.value?.status === REPORTS_STATUSES.REVIEWED ||
      report.value?.status === REPORTS_STATUSES.NEW),
)
const isMonitorHasPermissions = computed(
  () =>
    store.getters['user/isMonitor'] && report.value?.status === REPORTS_STATUSES.SEND_FOR_APPROVE,
)

const canWinnerChangeReport = computed(
  () =>
    isWinner.value &&
    (report.value?.status === REPORTS_STATUSES.DRAFT ||
      report.value?.status === REPORTS_STATUSES.REJECTED),
)
const canManagerSendReportForAccept = computed(
  () => isManager.value && report.value?.status === REPORTS_STATUSES.REVIEWED,
)
const canManagerSendReportForRevision = computed(
  () =>
    isManager.value &&
    (report.value?.status === REPORTS_STATUSES.IMPROVED ||
      report.value?.status === REPORTS_STATUSES.NEW ||
      report.value?.status === REPORTS_STATUSES.REVIEWED),
)
const canManagerSendReportForReview = computed(
  () =>
    isManager.value &&
    (report.value?.status === REPORTS_STATUSES.NEW ||
      report.value?.status === REPORTS_STATUSES.IMPROVED),
)
const isDisableMonitorButton = computed(() =>
  commentMonitor.value?.find((el) => !el.default_value && !el.links?.length),
)
const isDisableMonitorExpressButton = computed(() =>
  expressConclusionMonitor.value?.find((el) => !el.default_value && !el.links?.length),
)
const isDisableSendToMonitorForFinalizeConclusionButton = computed(() =>
  managerFinalConclusion.value?.find((el) => el.type === 'dropFile' && !el.links?.length),
)
const reportAlreadySent = ref(false)
const canMonitorSendForApprove = computed(
  () =>
    isMonitor.value &&
    report.value?.status === REPORTS_STATUSES.SEND_FOR_APPROVE &&
    report.value?.type !== REPORTS_TYPES.ANNUAL,
)

const canMonitorSendForApproveExpress = computed(
  () =>
    isMonitor.value &&
    report.value?.status === REPORTS_STATUSES.SEND_FOR_APPROVE &&
    report.value?.type === REPORTS_TYPES.ANNUAL,
)
const isShowDownloadFilesList = computed(
  () => !canWinnerChangeReport.value && filesList.value.length,
)
const bucket = computed(() => REPORT_FILES)

const isLoading = ref(false)
const report = ref(null)
const filesList = ref([])
const fieldsForm = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'supporting-documents',
    title: 'Форма для загрузки подтверждающих документов',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
])
const fieldsFilesForm = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'files-confirm-money-orders',
    title: 'Добавьте подтверждающие документы для учетного реестра «Платежные поручения»*',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'files-confirm-education',
    title: 'Добавьте подтверждающие документы для учетного реестра «1 - Образование»*',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'files-confirm-licenses',
    title: 'Добавьте подтверждающие документы для учетного реестра «2 - Лицензии»*',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'files-confirm-income',
    title: 'Добавьте подтверждающие документы для учетного реестра «3 - Доходы»*',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'files-confirm-rid',
    title: 'Добавьте подтверждающие документы для учетного реестра «Р4 - РИД»*',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'files-confirm-co-financing',
    title: 'Добавьте подтверждающие документы для учетного реестра «Софинансирование»*',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
])
const commentManager = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'managerComment',
    title: 'Комментарий менеджера*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [['required']],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'manager-docs',
    title: 'Форма для прикрепления документов*',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
])
const commentMonitor = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'monitorComment',
    title: 'Комментарий Монитора*',
    options: [],
    default_value: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'monitor-docs',
    title: 'Добавить заключение Монитора*',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
])
const managerFinalConclusion = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'managerComment',
    title: 'Комментарий Менеджера',
    options: [],
    default_value: '',
    multiple: false,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'final-conclusion-manager',
    title: 'Форма для прикрепления документов',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
])
const expressConclusionMonitor = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'express-conclusion-monitor',
    title: 'Добавить экспресс-заключение Монитора*',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
])
const finalConclusionMonitor = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'final-conclusion-monitor',
    title: 'Итоговое заключение Монитора*',
    options: [],
    default_value: null,
    descr: '',
    note: '',
    multiple: true,
    rules: [],
    sort: 1,
  },
])
const documents = ref([
  {
    id: uuidv4(),
    title: 'Реестр платежных поручений',
    link: '/',
    ref: registerPaymentOrders,
    hasDownload: true,
    name: REGISTER_PAYMENT_ORDERS,
    downloadId: null,
  },
  {
    id: uuidv4(),
    title: 'Учетные реестры.xls',
    link: '/',
    ref: accountingRegistries,
    hasDownload: true,
    name: ACCOUNTING_REGISTRIES,
    downloadId: null,
  },
  {
    id: uuidv4(),
    title: 'Реестр сотрудников',
    link: '/nti-information?tab=4&edit=true',
    ref: registerNTIEmployees,
    hasCenterNtiUrl: true,
  },
  {
    id: uuidv4(),
    title: 'Отчет о расходах, источником финансового обеспечения которых являются средства гранта',
    link: `/reports/expense-report-form/`,
    isNeedIdReport: true,
  },
  {
    id: uuidv4(),
    downloadId: null,
    name: EXPENSE_REPORT,
    hasDownload: true,
    title:
      'ЭЛЕКТРОННЫЙ БЮДЖЕТ. Отчет о расходах, источником финансового обеспечения которых являются средства гранта',
  },
  {
    id: uuidv4(),
    title: 'Отчет о достижении значений результатов предоставления Гранта',
    link: `/reports/result-value-achievement-report-form/`,
    isNeedIdReport: true,
  },
  {
    id: uuidv4(),
    hasDownload: true,
    name: ACHIEVEMENT_REPORT,
    downloadId: null,
    title: 'ЭЛЕКТРОННЫЙ БЮДЖЕТ. Отчет о достижении значений результатов предоставления Гранта',
  },
  {
    id: uuidv4(),
    title:
      'Детализированный отчет о структуре расходов, источником финансового обеспечения которых являются средства гранта.pdf',
    link: `/reports/detailed-expense-structure-report-form/`,
    signature: 'expenseStructureSignature',
    isNeedIdReport: true,
  },
])
const annualDocumentsList = ref([
  {
    id: uuidv4(),
    title: 'Отчет о ходе реализации Программы Центра НТИ',
    link: `/reports/program-progress-report-form/`,
    isNeedIdReport: true,
    signature: 'implementationProgramSignature',
  },
  {
    id: uuidv4(),
    title: 'Отчет о расходах на реализацию Программы Центра НТИ из средств софинансирования',
    link: `/reports/program-expenditure-report-form/`,
    isNeedIdReport: true,
    signature: 'coFinanceStructureSignature',
  },
])

function setIdReportInDocumentsLink(id) {
  documents.value.forEach((el) => {
    if (el?.isNeedIdReport) {
      el.link = el.link + id
    }
  })
}

function getPeriodLabel(report) {
  if (report?.type === REPORTS_TYPES.ANNUAL) {
    return `Годовой отчет ${report?.year}`
  }

  return `Квартальный отчет ${getReportPeriod(report?.type)} ${report?.year}`
}

function updateCommentManager({ value, id }) {
  commentManager.value?.forEach((el) => {
    if (el.id === id) {
      if (el.type === 'dropFile') {
        uploadFile(el.name, value[0])
        return
      }
      el.default_value = value
    }
  })
}

function updateCommentMonitor({ value, id }) {
  commentMonitor.value?.forEach((el) => {
    if (el.id === id) {
      if (el.type === 'dropFile') {
        uploadFile(el.name, value[0])
        return
      }
      el.default_value = value
    }
  })
}

function updateExpressConclusionMonitor({ value, id }) {
  expressConclusionMonitor.value?.forEach((el) => {
    if (el.id === id) {
      if (el.type === 'dropFile') {
        uploadFile(el.name, value[0])
        return
      }
      el.default_value = value
    }
  })
}

function updateManagerFinalConclusion({ value, id }) {
  managerFinalConclusion.value?.forEach((el) => {
    if (el.id === id) {
      if (el.type === 'dropFile') {
        uploadFile(el.name, value[0])
        return
      }
      el.default_value = value
    }
  })
}

function updateMonitorFinalConclusion({ value, id }) {
  finalConclusionMonitor.value?.forEach((el) => {
    if (el.id === id) {
      if (el.type === 'dropFile') {
        uploadFile(el.name, value[0])
        return
      }
      el.default_value = value
    }
  })
}

function getDateCreatedAtReport() {
  return moment(report.value?.createdAt).format('DD.MM.YYYY')
}

async function sendToMonitorForRepeatFinalizeConclusion() {
  try {
    await api
      .reports()
      .sendToMonitorForRepeatFinalizeConclusion(
        route.params?.id,
        managerCommentForRejectMonitor.value,
      )
      .then(() => {
        getReportData()
        clearStash(fieldFormHash.value)
      })
  } catch (err) {
    reportAlreadySent.value = true
    toast.error('Ошибка при отправке экспресс-заключения. Попробуйте обновить страницу')
  }
}

async function sendExpressConclusion() {
  try {
    await api
      .reports()
      .sendExpressConclusion(route.params?.id)
      .then(() => {
        getReportData()
      })
  } catch (err) {
    reportAlreadySent.value = true
    toast.error('Ошибка при отправке. Попробуйте обновить страницу')
  }
}

async function sendToMonitorForFinalizeConclusion() {
  try {
    await api
      .reports()
      .sendToMonitorForFinalizeConclusion(route.params?.id)
      .then(() => {
        getReportData()
        clearStash(fieldFormHash.value)
      })
  } catch (err) {
    reportAlreadySent.value = true
    toast.error('Ошибка при отправке. Попробуйте обновить страницу')
  }
}

async function deleteFile(id, field) {
  try {
    await api
      .files()
      .deleteFile(id)
      .then(() => {
        getFiles()
        const index = field.links?.findIndex((el) => el.id === id)
        field.links?.splice(index, 1)
      })
  } catch (err) {
    toast.error(err)
  }
}

async function getSavedData() {
  isLoading.value = true
  try {
    await api
      .stash()
      .getStashData(fieldFormHash.value)
      .then((data) => {
        commentManager.value?.forEach((el) => {
          if (el.name in data) {
            el.default_value = data[el.name]
          }
        })

        managerFinalConclusion.value?.forEach((el) => {
          if (el.name in data) {
            el.default_value = data[el.name]
          }
        })

        commentMonitor.value?.forEach((el) => {
          if (el.name in data) {
            el.default_value = data[el.name]
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function updateData(filed, value) {
  const params = {
    field: filed.name,
    value: value,
    hash: fieldFormHash.value,
  }

  await api.stash().saveFieldInStash(params)
}

function updateDataInStash({ value, id }) {
  const findField = commentManager.value?.find((el) => el.id === id)
  const findMonitorField = commentMonitor.value?.find((el) => el.id === id)
  const findManagerFinalConclusion = managerFinalConclusion.value?.find((el) => el.id === id)

  if (findField) {
    updateData(findField, value)
  }

  if (findMonitorField) {
    updateData(findMonitorField, value)
  }

  if (findManagerFinalConclusion) {
    updateData(findManagerFinalConclusion, value)
  }
}

async function downloadFile(id, name) {
  try {
    if (!id) {
      return
    }

    await api
      .files()
      .downloadFile(id)
      .then((data) => {
        downloadFileWithLinkData(data, name)
      })
  } catch (err) {
    toast.error(err)
  }
}

async function uploadFile(name, data) {
  try {
    const form = new FormData()
    form.append('file', data)
    form.append('fileKey', name)
    form.append('entityId', route.params?.id)
    form.append('folder', WINNER_FOLDER)
    await api
      .files()
      .uploadFile(bucket.value, form)
      .then(() => {
        toast.success('Файл успешно загружен')
        getFiles()
      })
  } catch (err) {
    toast.error(err)
  }
}

async function getFiles() {
  fieldsForm.value?.forEach((el) => {
    el.links = []
  })
  try {
    const params = { entityId: route.params?.id }
    await api
      .files()
      .getFiles(bucket.value, params)
      .then((data) => {
        if (!data?.filesInFolders && !(WINNER_FOLDER in data?.filesInFolders)) {
          return
        }
        filesList.value = []

        const files = data?.filesInFolders[WINNER_FOLDER]
        Object.values(files)?.forEach((el) => {
          if (!el) {
            return
          }
          Object.values(el)?.forEach((file) => {
            if (file?.isConfirmed) {
              filesList.value.push(file)
            }
          })
        })

        fieldsForm.value?.forEach((el) => {
          if (!files) {
            return
          }

          el.links = el.name in files ? files[el.name]?.filter((el) => !el.isConfirmed) : []
        })

        expressConclusionMonitor.value?.forEach((el) => {
          if (!files) {
            return
          }

          el.links = el.name in files ? files[el.name]?.filter((el) => !el.isConfirmed) : []
        })

        finalConclusionMonitor.value?.forEach((el) => {
          if (!files) {
            return
          }

          el.links = el.name in files ? files[el.name]?.filter((el) => !el.isConfirmed) : []
        })

        managerFinalConclusion.value?.forEach((el) => {
          if (!files) {
            return
          }

          el.links = el.name in files ? files[el.name]?.filter((el) => !el.isConfirmed) : []
        })

        fieldsFilesForm.value?.forEach((el) => {
          if (!files) {
            return
          }

          el.links = el.name in files ? files[el.name]?.filter((el) => !el.isConfirmed) : []

          // if (!canWinnerChangeReport.value) {
          //   filesList.value = [...filesList.value, ...el.links?.filter((el) => !!el.isConfirmed)]
          // }
        })

        documents.value?.forEach((el) => {
          if (!files) {
            return
          }

          if (el?.name && el?.name in files) {
            el.downloadId = files[el.name][0]?.id
          }
        })

        commentManager.value?.forEach((el) => {
          if (!files) {
            return
          }

          el.links = el.name in files ? files[el.name]?.filter((el) => !el.isConfirmed) : []
        })

        commentMonitor.value.forEach((el) => {
          if (!files) {
            return
          }

          el.links = el.name in files ? files[el.name]?.filter((el) => !el.isConfirmed) : []
        })
      })
  } catch (err) {
    console.log(err)
  }
}

async function acceptReport() {
  isLoading.value = true
  try {
    await api
      .reports()
      .acceptReport(route.params?.id)
      .then(() => {
        getReportData()
        clearStash(fieldFormHash.value)
      })
  } catch (err) {
    reportAlreadySent.value = true
    toast.error('Ошибка при отправке отчета. Попробуйте обновить страницу')
  } finally {
    isLoading.value = false
  }
}

async function sendReportToMonitorForApproval() {
  isLoading.value = true
  try {
    const comment =
      commentManager.value?.find((el) => el.name === 'managerComment')?.default_value || ''
    await api
      .reports()
      .sendReportToMonitorForApproval(route.params?.id, comment)
      .then(() => {
        getReportData()
        clearStash(fieldFormHash.value)
      })
  } catch (err) {
    toast.error('Ошибка при отправке отчета. Попробуйте обновить страницу')
    reportAlreadySent.value = true
  } finally {
    isLoading.value = false
  }
}

async function rejectReport() {
  isLoading.value = true
  try {
    const comment =
      report.value?.status === REPORTS_STATUSES.READY_TO_FINALIZE_CONCLUSION
        ? managerFinalConclusion.value?.find((el) => el.name === 'managerComment')
        : commentManager.value?.find((el) => el.name === 'managerComment')
    await api
      .reports()
      .sendReportForRevision(route.params?.id, comment?.default_value)
      .then(() => {
        getReportData()
        clearStash(fieldFormHash.value)
      })
  } catch (err) {
    toast.error('Ошибка при отправке отчета. Попробуйте обновить страницу')
    reportAlreadySent.value = true
  } finally {
    isLoading.value = false
  }
}

async function sendReportToManagerForReview() {
  isLoading.value = true
  try {
    const comment =
      report.value?.type === REPORTS_TYPES.ANNUAL
        ? ''
        : commentMonitor.value?.find((el) => el.name === 'monitorComment')?.default_value

    await api
      .reports()
      .sendReportToManagerForReview(route.params?.id, comment)
      .then(() => {
        getReportData()
        clearStash(fieldFormHash.value)
      })
  } catch (err) {
    reportAlreadySent.value = true
    toast.error('Ошибка при отправке отчета. Попробуйте обновить страницу')
  } finally {
    isLoading.value = false
  }
}

function openReportsList() {
  router.push('/reports')
}

function updateValue({ value, id }) {
  fieldsForm.value.forEach((item) => {
    if (item.id === id) {
      item.default_value = value
      item.isEdit = true

      if (item.type === 'dropFile') {
        value.forEach((el) => {
          uploadFile(item.name, el)
        })
      }
    }
  })

  fieldsFilesForm.value?.forEach((item) => {
    if (item.id === id) {
      item.default_value = value
      item.isEdit = true

      if (item.type === 'dropFile') {
        value?.forEach((file) => {
          uploadFile(item.name, file)
        })
      }
    }
  })
}

async function getReportData() {
  isLoading.value = true
  reportAlreadySent.value = false
  try {
    await api
      .reports()
      .getReportData(+route.params.id)
      .then((data) => {
        report.value = data
        if (data?.status !== REPORTS_STATUSES.ACCEPTED) {
          getSavedData()
        }

        if (data?.type === REPORTS_TYPES.ANNUAL) {
          documents.value = documents.value.concat(annualDocumentsList.value)
        }
        getFiles()
        setIdReportInDocumentsLink(+route.params.id)
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function onSubmit() {
  if (confirmDialog.value) {
    confirmDialog.value.toggleDialog()
  } else {
    sendReport()
  }
}

function sendReport() {
  if (report.value?.status === REPORTS_STATUSES.DRAFT) {
    sendToManagerReport()
  } else {
    updateReport()
  }
}

function showDocument(val) {
  if (val.ref) {
    return val.ref.openDocument(val)
  }

  if (val.downloadId) {
    return downloadFile(val.downloadId, val.title)
  }

  if (val.hasCenterNtiUrl && val.link) {
    return router.push(val.link + `&centerNtiId=${report.value?.centerNtiId}`)
  }

  if (val.link) {
    return router.push(val.link)
  }
}

async function updateReport() {
  try {
    const files = fieldsFilesForm.value?.filter((el) => el.type === 'dropFile' && el?.links?.length)
    const fileKeys = files?.map((el) => el.name)

    const params = {
      projectId: projectId.value,
      type: report.value?.type,
      year: +report.value?.year,
      fileKeys,
    }

    await api
      .reports()
      .updateReport(route.params?.id, params)
      .then(() => {
        confirmDialog.value.toggleDialog()

        getReportData()
      })
  } catch (err) {
    reportAlreadySent.value = true
    toast.error('Ошибка при обновлении отчета. Попробуйте обновить страницу')
  }
}

async function sendToManagerReport() {
  try {
    await api
      .reports()
      .sendToManagerNewReport(route.params?.id)
      .then(() => {
        confirmDialog.value.toggleDialog()

        getReportData()
      })
  } catch (err) {
    reportAlreadySent.value = true
    toast.error('Ошибка при обновлении отчета. Попробуйте обновить страницу')
  }
}

onMounted(() => {
  getReportData()
})
</script>
