<template>
  <the-container>
    <h3 class="mb-6">Отчет о ходе реализации Программы центра НТИ в 2022 году</h3>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <template v-else>
      <template v-if="!isEdited">
        <report-form-preview
          :form-fields="formFields"
          :form-groups="formGroups"
          @print-pdf="printImplementationProgram"
          @download-file="downloadFile"
        />
      </template>
      <v-form v-else ref="form" :key="implementationProgram" lazy-validation>
        <v-col
          v-for="category in formGroups"
          :key="`category-${category.id}-${formFields?.length}`"
          class="pa-0"
        >
          <p v-if="category.title" class="text-subtitle-1 mx-0 my-2 mb-4 font-weight-bold">
            {{ category.title }} {{ category.idDescriptionTitle }}
          </p>
          <template v-if="category?.subcategories?.length">
            <div v-for="subcategory in category?.subcategories" :key="subcategory.id" class="my-4">
              <div v-if="subcategory.title" class="text-body-1 font-weight-bold mb-2">
                {{ subcategory.title }}
              </div>
              <div
                v-for="(fields, index) in getFilterArrayByField(
                  getSubcategoryList(category.id, subcategory.id),
                  'copy_group',
                )"
                :key="`copy-fields-${index}`"
              >
                <div v-for="field in fields" :key="field.id">
                  <div v-if="field.title" class="text-body-1 mx-0">
                    {{ field.title }}{{ isEdited && field?.type !== 'dropFile' ? '*' : '' }}
                  </div>
                  <div :class="[field.disabled ? 'disabled' : '']">
                    <Component
                      :is="inputs[getFormInput(field.type)]"
                      v-bind="field"
                      @update-value="(data) => updateValue(formFields, data)"
                      @change-value="updateFieldValue(field, category.id)"
                      @delete-file="deleteFile"
                      @download-file="downloadFile"
                    />
                  </div>
                  <v-row class="justify-end pa-0 ma-0">
                    <button
                      v-if="
                        field.copy_group &&
                        getIsLastElementInGroup(
                          field.id,
                          category.id,
                          field.copy_group,
                          subcategory.id,
                        )
                      "
                      class="ml-2"
                      type="button"
                      @click="() => deleteFieldsGroup(field.group_id, field.copy_group)"
                    >
                      <v-icon color="red" size="xs"> mdi-delete-outline </v-icon>
                      <span class="text-body-2 title text-red">Удалить блок</span>
                    </button>
                  </v-row>
                </div>
              </div>
              <div v-if="subcategory.buttons?.length" class="mb-4">
                <div v-for="field in subcategory.buttons" :key="`field-${field.id}`">
                  <div :class="[field.disabled ? 'disabled' : '']">
                    <Component :is="inputs[getFormInput(field.type)]" v-bind="field" />
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div v-if="getCategoryFields(category.id)?.length" class="mb-4">
            <div v-for="field in getCategoryFields(category.id)" :key="`field-${field.id}`">
              <p v-if="field.title" class="text-body-1 mx-0 my-0">
                {{ field.title }}{{ isEdited && field?.type !== 'dropFile' ? '*' : '' }}
              </p>
              <div :class="[field.disabled ? 'disabled' : '']">
                <Component
                  :is="inputs[getFormInput(field.type)]"
                  v-bind="field"
                  @update-value="(data) => updateValue(formFields, data)"
                  @change-value="updateFieldValue(field, category.id)"
                  @delete-file="deleteFile"
                  @download-file="downloadFile"
                />
              </div>
              <v-row class="justify-end pa-0 ma-0">
                <button
                  v-if="
                    field.copy_group &&
                    getIsLastElementInGroup(field.id, category.id, field.copy_group)
                  "
                  class="ml-2"
                  type="button"
                  @click="() => deleteFieldsGroup(field.group_id, field.copy_group)"
                >
                  <v-icon color="red" size="xs"> mdi-delete-outline </v-icon>
                  <span class="text-body-2 title text-red">Удалить блок</span>
                </button>
              </v-row>
            </div>
          </div>
          <div v-if="category.buttons?.length" class="mb-4">
            <div v-for="field in category.buttons" :key="`field-${field.id}`">
              <div :class="[field.disabled ? 'disabled' : '']">
                <Component
                  :is="inputs[getFormInput(field.type)]"
                  v-bind="field"
                  @update-value="(data) => updateValue(formFields, data)"
                  @change-value="updateFieldValue(field, category.id)"
                />
              </div>
            </div>
          </div>
          <div v-if="typeof category.total === 'number'" class="font-weight-bold text-subtitle-1">
            <div :key="getCategoryTotalAmountKey(category.id)" class="mb-2">Итого:</div>
            <div>{{ category.total }} руб.</div>
          </div>

          <div v-if="category.note" class="mb-4">
            <div
              v-for="(note, index) in category.note"
              :key="`note-${index}`"
              class="text-grey text-caption mb-1"
            >
              {{ note }}
            </div>
          </div>
        </v-col>
        <v-row class="px-0 align-center mx-0 mt-6">
          <v-btn
            depressed
            color="primary"
            variant="elevated"
            class="mr-2"
            @click="printImplementationProgram"
          >
            Печать
          </v-btn>
          <v-btn depressed color="primary" variant="outlined" @click="onSubmit"> Сохранить </v-btn>
          <v-btn
            class="ml-2 text-decoration-underline"
            color="primary"
            variant="text"
            @click="goBack"
          >
            Закрыть
          </v-btn>
        </v-row>
      </v-form>
    </template>
  </the-container>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'

import { api } from '@/api/Api'

import {
  inputs,
  getFormInput,
  focusErrorInput,
  downloadFileWithLinkData,
  getFilterArrayByField,
  downloadFilePdfData,
} from '@/helpers/index'
import { PROGRAM_PROGRESS_FORM_FOLDER, WINNER_FOLDER } from '@/constants/store'
import { REPORTS_STATUSES } from '@/constants'
import { FORM_PROGRAM_PROGRESS } from '@/constants/forms'
import { REPORT_FILES } from '@/constants/buckets'

import TheContainer from '@/components/TheContainer'
import { useToast } from '@/composables/useToast'
import ReportFormPreview from '@/components/Reports/ReportFormPreview'
import { PROGRAM_PROGRESS_REPORT_FORM } from '@/constants/cachesIds'
import store from '@/store'
import moment from 'moment'

const { toast } = useToast()
const route = useRoute()
const router = useRouter()
const isEdited = ref(false)
const isLoading = ref(false)

const validForm = ref(true)
const form = ref(null)
const implementationProgram = ref(null)
const hash = ref(`${PROGRAM_PROGRESS_REPORT_FORM}-${route.params?.id || ''}`)
const softwareAcquisitionCosts = ref(0)

const formFields = ref([...FORM_PROGRAM_PROGRESS])
const formGroups = ref([
  {
    id: 0,
    title: 'Общие сведения',
  },
  {
    id: 1,
    title: 'I. Достижение ключевых контрольных точек в 2022 году',
    name: 'implementationControlPoints',
    buttons: [
      {
        id: uuidv4(),
        type: 'actionButton',
        name: 'add-block',
        text: 'Добавить блок',
        default_value: null,
        action: () => addBlock(1),
      },
    ],
  },
  {
    id: 2,
    title: 'II. Выполнение мероприятий Программы центра НТИ в 2022 году',
    name: 'activities',
    note: [
      '*25 Указывается номер мероприятия/проекта/работы в соответствии с планом мероприятий действующей редакции Программы. В случае отсутствия представленной работы\n' +
        'в Программе указывается следующий по порядку вложенный номер относящийся к мероприятию/проекту',
      '*26 Указывается наименование выполненного мероприятия/проекта/работы в соответствии с планом мероприятий действующей редакции Программы. В случае отсутствия\n' +
        'представленной работы в Программе, представляется пояснительная записка с обоснованием необходимости и значимости результатов выполнения данной работы для\n' +
        'реализации Программы',
      '*27 Указывается «год предоставления гранта», если расходы были произведены из средств гранта, либо «год предоставления гранта/софин.», в случае если расходы на работу были понесены, в том числе из внебюджетных источников (за исключением ФОТ), либо «софин.» в случае, если расходы произведены из внебюджетных\n' +
        'источников, либо «иное» для всех остальных случаев',
    ],
    subcategories: [
      {
        id: 1,
        name: 'organizationEvents',
        title: 'Организационные мероприятия',
        buttons: [
          {
            id: uuidv4(),
            type: 'actionButton',
            name: 'add-block',
            text: 'Добавить блок',
            default_value: null,
            action: () => addBlock(2, 1),
          },
        ],
      },
      {
        id: 2,
        name: 'implementationScientificProjects',
        title:
          'Реализация ключевых комплексных научно-технических и опытно-конструкторских проектов',
        buttons: [
          {
            id: uuidv4(),
            type: 'actionButton',
            name: 'add-block',
            text: 'Добавить блок',
            default_value: null,
            action: () => addBlock(2, 2),
          },
        ],
      },
      {
        id: 3,
        name: 'securityRightsManagement',
        title: 'Обеспечение правовой охраны, управления правами и защиты РИД',
        buttons: [
          {
            id: uuidv4(),
            type: 'actionButton',
            name: 'add-block',
            text: 'Добавить блок',
            default_value: null,
            action: () => addBlock(2, 3),
          },
        ],
      },
      {
        id: 4,
        name: 'implementationEducationalPrograms',
        title:
          'Разработка и реализация основных образовательных программ высшего образования, программ дополнительного образования, дисциплин (модулей), направленных на формирование компетенций',
        buttons: [
          {
            id: uuidv4(),
            type: 'actionButton',
            name: 'add-block',
            text: 'Добавить блок',
            default_value: null,
            action: () => addBlock(2, 4),
          },
        ],
      },
      {
        id: 5,
        name: 'developingPartnerships',
        title: 'Развитие партнерских отношений',
        hasTotalAmount: true,
        buttons: [
          {
            id: uuidv4(),
            type: 'actionButton',
            name: 'add-block',
            text: 'Добавить блок',
            default_value: null,
            action: () => addBlock(2, 5),
          },
        ],
      },
      {
        id: 6,
        title: 'Иное',
        name: 'other',
        hasTotalAmount: true,
        buttons: [
          {
            id: uuidv4(),
            type: 'actionButton',
            name: 'add-block',
            text: 'Добавить блок',
            default_value: null,
            action: () => addBlock(2, 6),
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: 'III. Достижение целевых значений индикаторов *28',
    name: 'targetIndicators',
    note: ['*28 Заполняется в случае, если индикаторы предусмотрены Программой Центра НТИ'],
    buttons: [
      {
        id: uuidv4(),
        type: 'actionButton',
        name: 'add-block',
        text: 'Добавить блок',
        default_value: null,
        action: () => addBlock(3),
      },
    ],
  },
  {
    id: 5,
    note: ['* - поля обязательные для заполнения', 'Введенные данные сохраняются автоматически'],
  },
])

const bucket = computed(() => REPORT_FILES)
const isWinner = computed(() => store.getters['user/isWinner'])

function goBack() {
  return router.go(-1)
}

function groupFields(fields, key) {
  return fields?.reduce((acc, obj) => {
    const property = obj[key] || 0
    acc[property] = acc[property] || []
    acc[property].push(obj)
    return acc
  }, {})
}

function getIsLastElementInGroup(id, categoryId, copyGroupId, subcategoryId) {
  const categoryFields = formFields.value?.filter((el) => el.group_id === categoryId)
  const subcategoryFieldsFilter = subcategoryId
    ? categoryFields?.filter((el) => el.subcategory === subcategoryId)
    : categoryFields
  const copyFields = subcategoryFieldsFilter?.filter((el) => el.copy_group === copyGroupId)
  const element = copyFields?.findIndex((el) => el.id === id)
  return element === copyFields.length - 1
}

function deleteFieldsGroup(categoryId, copyGroup) {
  const categoryFields = formFields.value?.filter((el) => el.group_id === categoryId)
  const copyFields = categoryFields?.filter((el) => el.copy_group === copyGroup)
  const findIndexFirstElement = formFields.value?.findIndex((el) => copyFields[0].id === el.id)
  if (findIndexFirstElement !== -1 && copyFields.length) {
    formFields.value?.splice(findIndexFirstElement, copyFields.length)
    updateFieldValue(categoryFields[0], categoryId)
  }
}

async function printImplementationProgram() {
  try {
    await api
      .reports()
      .printImplementationProgram(route.params?.id)
      .then((response) => {
        downloadFilePdfData(response, 'Детализированный отчет о структуре расходов')
      })
  } catch (err) {
    console.log(err)
  }
}

function getGroupValue(groupId, data) {
  if (groupId === 1) {
    return data?.map((el) => {
      return {
        controlPoint: el.controlPoint,
        plannedExecutionTime: el.plannedExecutionTime,
        executionTime: el.executionTime,
        plannedResult: el.plannedResult,
        result: el.result,
      }
    })
  }

  if (groupId === 2) {
    return data?.map((el) => {
      return {
        equipmentName: el.equipmentName,
        unitPrice: el.unitPrice,
        count: el.count,
        nameOrganization: el.nameOrganization,
        nameDocuments: el.nameDocuments,
      }
    })
  }

  if (groupId === 3) {
    return data?.map((el) => {
      return {
        indicatorName: el.indicatorName,
        unitName: el.unitName,
        plannedResult: el.plannedResult,
        result: el.result,
      }
    })
  }
}

async function clearStash() {
  try {
    await api.stash().clearStash(hash.value)
  } catch (err) {
    console.log(err)
  }
}

function getCopyGroupData(fields, group) {
  const filterData = groupFields(fields, 'copy_group')

  if (!filterData) {
    return
  }

  const newValue = Object.values(filterData)?.map((el) => {
    let data
    el?.forEach((field) => {
      let value
      if (field.type === 'number') {
        value = +field.default_value
      } else if (field.type === 'date' && field.monthPicker) {
        value = moment(field.default_value).format('YYYY-MM-DD')
      } else {
        value = field.default_value
      }
      data = {
        ...data,
        [field.name]: value,
      }
      return data
    })
    return data
  })

  return getGroupValue(group, newValue)
}

function getDate(date) {
  if (!date) {
    return null
  }

  if (typeof date === 'string') {
    return date
  }
  const year = date.year
  const month = String(date.month)?.length < 2 ? `0${+date.month + 1}` : `${+date.month + 1}`

  const dateString = `${year}-${month}-01`
  if (!moment(dateString).isValid()) {
    return null
  }

  return moment(dateString).format('YYYY-MM-DD')
}

function getObjectValuesGroup(fields, group) {
  const groups = getFilterArrayByField(fields, 'subcategory')
  const currentGroup = formGroups.value?.find((el) => el.id === group)
  if (!currentGroup) {
    return
  }
  let newValue = {}
  currentGroup.subcategories?.forEach((el, index) => {
    const value = getFilterArrayByField(groups[index + 1], 'copy_group')
    const formatData = value
      ? Object.values(value)?.map((fields) => {
          let data = {}
          fields?.forEach((field) => {
            let val = field.default_value
            if (field.type === 'date' && field.monthPicker) {
              return (data[field.name] = getDate(val))
            }
            data[field.name] = val
          })
          return data
        })
      : null

    newValue[el.name] = formatData
  })

  return newValue
}

async function updateDataInStash({ id }) {
  const findField = formFields.value?.find((el) => el.id === id)

  if (findField) {
    const group = findField?.group_id
    const selectGroupName = formGroups.value?.find((el) => el.id === group)?.name
    const fields = formFields.value?.filter((el) => el.group_id === group)

    const fieldsValue =
      group === 2 ? getObjectValuesGroup(fields, group) : getCopyGroupData(fields, group)

    const params = {
      field: selectGroupName,
      value: fieldsValue,
      hash: hash.value,
    }
    await api.stash().saveFieldInStash(params)
  }
}

async function getSavedData() {
  isLoading.value = true
  try {
    await api
      .stash()
      .getStashData(hash.value)
      .then((data) => {
        if (!data) {
          return
        }

        Object.keys(implementationProgram.value)?.forEach((el) => {
          if (el in data) {
            implementationProgram.value[el] = data[el]
          }
        })

        setImplementationData()
      })
  } catch (err) {
    toast.error(err)
  } finally {
    isLoading.value = false
  }
}

function getCategoryTotalAmountKey(categoryId) {
  switch (categoryId) {
    case 2:
      return softwareAcquisitionCosts.value
    default:
      return
  }
}

function updateValue(fieldsForm, data) {
  const { value, id } = data
  fieldsForm?.forEach((item) => {
    if (item.id === id) {
      item.default_value = value
      item.isEdit = true
      if (item.type === 'dropFile') {
        value?.forEach((file) => {
          uploadFile(item.name, file, item.copy_group)
        })
      }
    }
  })
}

async function downloadFile(id, name) {
  try {
    await api
      .files()
      .downloadFile(id)
      .then((data) => {
        downloadFileWithLinkData(data, name)
      })
  } catch (err) {
    toast.error(err)
  }
}

async function deleteFile(id) {
  try {
    await api
      .files()
      .deleteFile(id)
      .then(() => {
        getFiles()
      })
  } catch (err) {
    toast.error(err)
  }
}

async function uploadFile(name, data, copyGroup) {
  try {
    const form = new FormData()
    form.append('file', data)
    form.append('fileKey', name)
    form.append('entityId', route.params?.id)
    form.append('folder', `${WINNER_FOLDER}/${PROGRAM_PROGRESS_FORM_FOLDER}`)
    if (copyGroup) {
      form.append('groupId', copyGroup)
    }

    await api
      .files()
      .uploadFile(bucket.value, form)
      .then(() => {
        getFiles()
      })
  } catch (err) {
    toast.error(err)
  }
}

async function getFiles() {
  formFields.value?.forEach((el) => (el.links = []))
  try {
    const params = { entityId: route.params?.id }
    await api
      .files()
      .getFiles(REPORT_FILES, params)
      .then((data) => {
        const allContent = data?.filesInFolders
        if (!allContent || (allContent && !allContent[WINNER_FOLDER])) {
          return
        }
        const filesForms = allContent[WINNER_FOLDER]

        const files = filesForms[PROGRAM_PROGRESS_FORM_FOLDER]
        formFields.value?.forEach((el) => {
          if (!files || el.type !== 'dropFile') {
            return
          }
          if (el.name in files) {
            el.links = files[el.name]?.filter((file) => file.groupId == el?.copy_group)
          } else {
            el.links = []
          }
        })
      })
  } catch (err) {
    toast.error(err)
  }
}

function addBlock(idGroup, idSubcategory) {
  const templateFieldsGroup = idSubcategory
    ? formFields.value?.filter(
        (el) => el.group_id === idGroup && !el?.copy_group && el?.subcategory === idSubcategory,
      )
    : formFields.value?.filter((el) => el.group_id === idGroup && !el?.copy_group)

  const countGroups = idSubcategory
    ? formFields.value?.filter(
        (el) =>
          el.name === templateFieldsGroup[0].name &&
          el.group_id === idGroup &&
          el?.subcategory === idSubcategory,
      )?.length
    : formFields.value?.filter(
        (el) => el.name === templateFieldsGroup[0].name && el.group_id === idGroup,
      )?.length

  const newGroupFields = templateFieldsGroup?.map((el) => {
    const data = {
      ...el,
    }

    if (idSubcategory) {
      data['subcategory'] = idSubcategory
    }

    return {
      ...data,
      id: uuidv4(),
      copy_group: countGroups,
      default_value: null,
      links: [],
    }
  })
  if (!newGroupFields?.length) {
    return
  }
  formFields.value.splice(formFields.value?.length, 0, ...newGroupFields)
}

function getSubcategoryList(categoryId, subcategoryId) {
  const categoryFields = formFields.value?.filter((el) => el.group_id === categoryId)
  const subcategoryFields = categoryFields?.filter((el) => el.subcategory === subcategoryId)

  return subcategoryFields
}

function getSubcategoryField(categoryId, subcategoryId, index) {
  const categoryFields = formFields.value?.filter((el) => el.group_id === categoryId)
  const subcategoryFields = categoryFields?.filter((el) => el.subcategory === subcategoryId)
  const subcategoryFilterByIndex = !index
    ? subcategoryFields?.filter((el) => !el?.copy_group)
    : subcategoryFields?.filter((el) => el?.copy_group === index)

  return subcategoryFilterByIndex
}

function getCategoryFields(categoryId) {
  const categoryFields = formFields.value?.filter(
    (el) => el.group_id === categoryId && typeof el?.subcategory !== 'number',
  )
  return categoryFields
}

function updateFieldValue(field) {
  updateDataInStash({ id: field.id })
}

function setImplementationData() {
  formGroups.value?.forEach((group) => {
    const selectGroup = implementationProgram.value[group?.name]

    if (!selectGroup) {
      return
    }

    if (implementationProgram.value && group?.name in implementationProgram.value) {
      if (!selectGroup?.length) {
        const subcategories = group?.subcategories
        subcategories?.forEach((subcategory) => {
          if (subcategory.name in selectGroup) {
            const valueSubcategory = selectGroup[subcategory.name]
            valueSubcategory?.forEach((item, index) => {
              if (index && valueSubcategory?.length > 1) {
                addBlock(group.id, subcategory.id)
              }

              const subcategoryFields = getSubcategoryField(group.id, subcategory.id, index)
              subcategoryFields?.forEach((field) => {
                if (field.name in item) {
                  let val = item[field.name]

                  if (
                    field.type === 'date' &&
                    field.monthPicker &&
                    moment(item[field.name]).isValid()
                  ) {
                    val = {
                      month: +moment(item[field.name]).format('MM') - 1,
                      year: +moment(item[field.name]).format('YYYY'),
                    }
                  }

                  field.default_value = val
                }
              })
            })
          }
        })

        return
      }

      selectGroup?.forEach((data, index) => {
        if (!index) {
          const fields = formFields.value?.filter((el) => el.group_id === group.id)
          fields?.forEach((field) => {
            if (field.name in data) {
              if (
                field.type === 'date' &&
                field.monthPicker &&
                moment(data[field.name]).isValid()
              ) {
                const val = {
                  month: +moment(data[field.name]).format('MM') - 1,
                  year: +moment(data[field.name]).format('YYYY'),
                }
                field.default_value = val
              } else {
                field.default_value = data[field.name]
              }
            }
          })
        } else {
          addBlock(group.id)
          const fields = formFields.value?.filter(
            (el) => el.group_id === group.id && el.copy_group === index,
          )
          fields?.forEach((field) => {
            let val = data[field.name]

            if (field.type === 'date' && field.monthPicker && moment(data[field.name]).isValid()) {
              val = {
                month: +moment(data[field.name]).format('MM') - 1,
                year: +moment(data[field.name]).format('YYYY'),
              }

              field.default_value = val
            } else {
              field.default_value = val
            }
          })
        }
      })
    }
  })

  getFiles()
}

async function getImplementationProgram() {
  isLoading.value = true
  try {
    await api
      .reports()
      .getImplementationProgram(route.params?.id)
      .then((data) => {
        implementationProgram.value = data

        formFields.value?.forEach((el) => {
          if (data && el.name in data) {
            el.default_value = data[el.name]
          }
        })

        if (
          isWinner.value &&
          (data?.reportStatus === REPORTS_STATUSES.REJECTED ||
            data?.reportStatus === REPORTS_STATUSES.DRAFT)
        ) {
          isEdited.value = true
          getSavedData()
        } else {
          setImplementationData(data)
        }
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function onSubmit() {
  await form.value.validate()
  if (!validForm.value || form.value.errors?.length) {
    const errInput = form.value.errors[0]
    focusErrorInput(errInput)
    return
  }

  try {
    const params = {
      id: implementationProgram.value?.id,
    }

    formGroups.value?.forEach((el) => {
      const selectGroupName = el?.name
      if (!selectGroupName) {
        return
      }

      const group = el?.id
      const fields = formFields.value?.filter((el) => el.group_id === group)
      const fieldsValue =
        group === 2 ? getObjectValuesGroup(fields, group) : getCopyGroupData(fields, group)
      if (fieldsValue) {
        params[selectGroupName] = fieldsValue
      }
    })

    await api
      .reports()
      .saveReportImplementationProgram(route.params?.id, params)
      .then(() => {
        implementationProgram.value = null
        clearStash().then(() => {
          location.reload()
        })
      })
  } catch (err) {
    console.log(err)
  }
}

async function getGeneralReportInfo() {
  try {
    await api
      .reports()
      .getGeneralReportInfo(route.params?.id)
      .then((data) => {
        formFields.value?.map((el) => {
          if (data && el.name in data) {
            el.default_value = data[el.name]
          }
        })
      })
  } catch (err) {
    console.log(err)
  }
}

function getFormData() {
  getGeneralReportInfo()
  getImplementationProgram()

  if (!isWinner.value) {
    isEdited.value = false
  }
}

onMounted(() => {
  getFormData()
})
</script>
