<template>
  <v-col class="px-0 mx-0">
    <div class="font-weight-bold text-grey mb-4">Название</div>
    <v-col class="px-0 mx-0" md="8" sm="12">
      <v-row class="mx-0 text-grey px-0 row-bottom-bordered">
        <v-col class="flex-1 px-0">Название программы</v-col>
        <v-col class="flex-1 px-0">{{ project?.programName }}</v-col>
      </v-row>
      <v-row class="mx-0 text-grey px-0 row-bottom-bordered">
        <v-col class="flex-1 px-0">Дата и время подачи</v-col>
        <v-col class="flex-1 px-0">{{ getFormatDateTime(ticket?.createdAt) }}</v-col>
      </v-row>
      <v-row class="mx-0 text-grey px-0 row-bottom-bordered">
        <v-col class="flex-1 px-0">Комментарий менеджера</v-col>
        <v-col class="flex-1 px-0">{{ ticket.managerComment }}</v-col>
      </v-row>
      <v-row
        v-if="
          ticket.userComment &&
          (ticket.status === TICKETS_STATUSES.REVIEW ||
            ticket.status === TICKETS_STATUSES.END_CONFIRM ||
            ticket.status === TICKETS_STATUSES.END_IMPROVE)
        "
        class="mx-0 text-grey px-0 row-bottom-bordered"
      >
        <v-col class="flex-1 px-0">Комментарий участника</v-col>
        <v-col class="flex-1 px-0">{{ ticket.userComment }}</v-col>
      </v-row>
    </v-col>
    <documents-list
      v-if="files.length"
      :documents="files"
      is-button-open-window-type
      @click-action="downloadFile"
    />
  </v-col>
</template>
<script setup>
import { api } from '@/api/Api'

import { useToast } from '@/composables/useToast'
import { TICKETS_STATUSES } from '@/constants/tickets'
import { downloadFileWithLinkData, getFormatDateTime } from '@/helpers'

import DocumentsList from '@/components/Common/DocumentsList'

const { toast } = useToast()

defineProps({
  files: {
    type: Array,
    default: () => [],
  },
  ticket: {
    type: Object,
    default: () => {},
  },
  project: {
    type: Object,
    default: () => {},
  },
})

async function downloadFile({ id, name }) {
  try {
    await api
      .files()
      .downloadFile(id)
      .then((data) => {
        downloadFileWithLinkData(data, name)
      })
  } catch (err) {
    toast.error(err)
  }
}
</script>
<style scoped lang="scss">
.row-bottom-bordered {
  border-bottom: 1px solid var(--surface-300);
}
</style>
