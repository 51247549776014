<template>
  <div class="back" @click="onBack()">
    <span class="back__arrow" />
    <p class="back__text">вернуться назад</p>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()

function onBack() {
  router.back()
}
</script>

<style lang="scss" scoped>
.back {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: 5px;

  &:hover {
    & .back__arrow {
      border-color: #000;
    }

    & .back__text {
      color: #000;
    }
  }

  &__arrow {
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    transition: 0.2s;
    border-color: #8a8a8a;
    border-style: solid;
    border-width: 0 0 1px 1px;
  }

  &__text {
    color: #8a8a8a;
    font-size: 14px;
    transition: 0.2s;
    font-weight: 14;
    margin-left: 10px;
    line-height: 20px;
    @media (max-width: 599.95px) {
      font-size: 12px !important;
    }
  }
}
</style>
