<template>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <TheTable v-else :items="reports">
    <Column field="date" header="Дата и время подачи">
      <template #body="{ data: { createdAt } }">
        {{ moment(createdAt).format('DD.MM.YYYY') }}
      </template>
    </Column>
    <Column field="year" header="Год" sortable class="text-break" />
    <Column field="nameCenter" header="Название Центра НТИ">
      <template #body="{ data: { project } }"> {{ project?.centerNti?.fullTitle }} </template>
    </Column>
    <Column field="reporting_period" header="Отчетный период">
      <template #body="{ data: { type } }">
        {{ REPORTS_TYPES_LABEL[type] }}
      </template>
    </Column>
    <Column field="status" header="Статус">
      <template #body="{ data: { status } }">
        {{ REPORT_STATUS_LABELS[status] }}
      </template>
    </Column>
    <Column field="addGroups">
      <template #body="{ data: { id, status } }">
        <RouterLink v-if="status !== REPORTS_STATUSES.DRAFT" :to="`/reports/report-form/${id}`">
          Перейти
        </RouterLink>
      </template>
    </Column>
  </TheTable>
</template>
<script setup>
import { ref } from 'vue'
import moment from 'moment'
import Column from 'primevue/column'

import { REPORTS_TYPES_LABEL, REPORT_STATUS_LABELS, REPORTS_STATUSES } from '@/constants'

import TheTable from '@/components/TheTable'

const isLoading = ref(false)
defineProps({
  reports: {
    type: Array,
    default: () => [],
  },
})
</script>
