<template>
  <v-dialog v-model="dialog" :max-width="maxWidth" persistent>
    <v-card class="w-100 text-center pa-6" min-width="600">
      <v-card-text>
        <h3 v-if="title" class="mb-6">{{ title }}</h3>
        <div>{{ description }}</div>
      </v-card-text>
      <v-card-actions>
        <v-row class="align-center justify-center pt-4">
          <v-btn depressed color="primary" variant="elevated" @click="() => $emit('confirm')">
            {{ confirmButtonText }}
          </v-btn>
          <v-btn color="primary" variant="text" @click="close">
            {{ closeButtonText }}
          </v-btn>
        </v-row>
      </v-card-actions>
      <div class="mt-4">
        <slot />
      </div>
    </v-card>
  </v-dialog>
</template>
<script setup>
import { ref } from 'vue'
import { emit as baseEmit } from '@/composables/form/usePropsForm'

const dialog = ref(false)

const emit = defineEmits(baseEmit)

defineExpose({
  toggleDialog,
  close,
})

function toggleDialog() {
  dialog.value = !dialog.value
}

function close() {
  emit('close')
  dialog.value = false
}
</script>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    confirmButtonText: {
      type: String,
      default: 'Подтвердить',
    },
    closeButtonText: {
      type: String,
      default: 'Отменить',
    },
    description: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 400,
    },
  },
}
</script>
