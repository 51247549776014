import { v4 as uuidv4 } from 'uuid'
import { api } from '@/api/Api'
import cloneDeep from 'lodash/cloneDeep'

export function useForms() {
  function groupById(field, fields) {
    const { id, type } = field
    if (type !== 'group') {
      return
    }
    return fields.filter((item) => item.group_id === id)
  }

  function createNewField(id, fields) {
    const item = fields.find((item) => item.id === id)
    const newItem = { ...item }
    newItem.id = uuidv4()
    newItem.default_value = ''
    newItem.fileData = ''
    newItem.name = newItem.name.replace(/\[\d+\]$/, '[]')

    return newItem
  }

  function pushItemInFields(item, fields) {
    fields.push(item)
  }

  function addNewInput(id, fields) {
    const newItem = createNewField(id, fields)
    pushItemInFields(newItem, fields)
    return newItem.id
  }

  function addNewGroup(id, fields) {
    const newGroup = createNewField(id, fields)

    pushItemInFields(newGroup, fields)
    const field = fields.find((item) => item.id === id)
    const items = groupById(field, fields)
    items.forEach((item) => {
      const newItem = createNewField(item.id, fields)
      newItem.group_id = newGroup.id
      pushItemInFields(newItem, fields)
    })
    return newGroup.id
  }

  function findFieldValue(fields, id) {
    return fields.find((el) => el.id === id)
  }

  async function getFile(val) {
    if (val) {
      const response = await api.upload().getFile(val)
      return URL.createObjectURL(response)
    }

    return
  }

  function getValues(fields, values) {
    const newValue = values?.map((field) => {
      let data = {
        ...field,
        ...findFieldValue(fields, field.field_id),
        id:
          findFieldValue(fields, field.field_id).type === 'group'
            ? findFieldValue(fields, field.field_id).id
            : field.id,
        group_id: field.index ? String(field.index).substr(0, 2) : 0,
        default_value: findFieldValue(fields, field.field_id).type === 'file' ? '' : field.value,
        firstGroupId: findFieldValue(fields, field.field_id).group_id,
      }

      if (findFieldValue(fields, field.field_id).type === 'file') {
        getFile(field.value).then((res) => (data.fileData = res))

        data.fileUrl = field.value
      }

      delete data.value

      return data
    })

    const groupFields = newValue?.filter((el) => el.group_id)
    const groups = newValue?.filter((el) => el.type === 'group')

    const getGroupDescription = (id) => {
      return groups.find((el) => el.id === id)
    }

    sortFieldData(groupFields, 'group_id').forEach((el) => {
      const groupId = el[0].group_id
      const firstGroupId = el[0]?.firstGroupId

      newValue.push({
        ...getGroupDescription(firstGroupId),
        id: groupId,
        index: 0,
        firstGroupId: firstGroupId,
      })
    })

    return newValue?.filter(
      (el) => el.type !== 'group' || (el.type === 'group' && !!el.firstGroupId),
    )
  }

  function sortFieldData(arr, field) {
    const map = arr?.reduce((r, i) => {
      r[i[field]] = r[i[field]] || []
      r[i[field]].push(i)
      return r
    }, {})

    const sortFields = []
    for (const key in map) {
      sortFields.push(map[key])
    }

    return sortFields
  }

  function getValuesByFields(source, fields) {
    const values = Object.entries(source)
    const copy = cloneDeep(fields)

    values.forEach(async ([key, value]) => {
      if (!Array.isArray(value)) {
        if (!value) {
          return
        }

        const field = copy.find((field) => field.name === key)

        if (!field) {
          return
        }

        if (field.type === 'file') {
          const response = await api.upload().getFile(value.value)
          field.fileData = URL.createObjectURL(response)
          field.fileUrl = value.value
          return
        }

        field.default_value = value

        return field
      }

      copy.forEach((field) => {
        if (key === 'rules') {
          if (field.name === key) {
            field.rules = value
            field.default_value = value
          }
          return
        }

        if (field.name === key) {
          if (field.type === 'group') {
            for (let i = 2; i <= value.length; i++) {
              addNewGroup(field.id, copy)
            }

            return
          }

          for (let i = 2; i <= value.length; i++) {
            addNewInput(field.id, copy)
          }
        }
      })

      const field = copy?.find((field) => field.name === key)
      if (field?.type === 'group' || field?.group_id) {
        const groups = copy
          .filter((field) => field.name === key)
          .map((filed) => copy.filter((item) => item.group_id === filed.id))

        groups.forEach((copy, index) => {
          if (!value[index]) {
            return
          }

          copy.forEach((field) => {
            const prop = field.name_children || field.name

            field.default_value = value[index][prop]
            field.name = field.name.replace('[]', `[${value[index].id}]`)
          })
        })
      }

      const inputs = copy.filter((field) => field.name === key)
      for (const input of inputs) {
        const index = inputs.indexOf(input)
        if (input.type === 'file') {
          const response = await api.upload().getFile(value[index].value)
          input.fileData = URL.createObjectURL(response)

          continue
        }

        input.default_value = value[index].value
      }

      return copy
    })

    return copy
  }

  function prepareSource(result) {
    const {
      values,
      forma: { fields },
    } = result
    const source = {}

    values.forEach((value) => {
      const { name } = fields.find((field) => field.id === value.field_id)
      if (name === '') {
        return
      }

      if (source[name]) {
        source[name] = [
          source[name],
          {
            valueId: value.id,
            value: value.value,
            group_id: value?.index ? String(value?.index).substr(0, 2) : 0,
            field_id: value.field_id,
            index: value.index ? value.index : 0,
          },
        ].flat()
        return
      }

      source[name] = {
        valueId: value.id,
        value: value.value,
        group_id: value?.index ? String(value?.index).substr(0, 2) : 0,
        field_id: value.field_id,
        index: value.index ? value.index : 0,
      }
    })

    return source
  }

  return {
    getValues,
    groupById,
    createNewField,
    pushItemInFields,
    addNewInput,
    addNewGroup,
    getValuesByFields,
    prepareSource,
  }
}
