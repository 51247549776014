export const PROGRAM_CHANGE_REQUEST_FILES_MANAGER = 'program-change-request-files-manager'
export const PROGRAM_CHANGE_REQUEST_FILES_WINNER = 'program-change-request-files-winner'

export const MONITOR_FOLDER = 'monitor'
export const MANAGER_FOLDER = 'manager'
export const WINNER_FOLDER = 'winner'
export const USER_FOLDER = 'user'

export const DETAILED_REPORT_FORM_FOLDER = 'detailed-report-form'
export const DETAILED_REPORT_PREV_YEAR_FORM_FOLDER = 'detailed-report-prev-year-form'
export const EXPENSE_REPORT_FORM_FOLDER = 'expense-report'
export const PROGRAM_PROGRESS_FORM_FOLDER = 'program-progress-report'

export const EXPENSE_REPORT = 'expense-report'
export const ACHIEVEMENT_REPORT = 'achievement-report'

export const REGISTER_PAYMENT_ORDERS = 'register-payment-orders'
export const ACCOUNTING_REGISTRIES = 'accounting-registries'
