<template>
  <the-container>
    <h3 class="mb-6">
      Отчет о расходах на реализацию Программы Центра НТИ из средств софинансирования
    </h3>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <template v-else>
      <template v-if="!isEdited">
        <report-form-preview
          :form-fields="formFields"
          :form-groups="formGroups"
          @download-file="downloadFile"
          @print-pdf="printReportDetailsForm"
        />
      </template>
      <v-form v-else ref="form" lazy-validation>
        <v-col
          v-for="category in formGroups"
          :key="`category-${category.id}-${formFields?.length}`"
          class="px-0"
        >
          <p v-if="category.title" class="text-subtitle-1 mx-0 my-2 mb-4 font-weight-bold">
            {{ category.title }} {{ category.idDescriptionTitle }}
          </p>
          <template v-if="category?.subcategories?.length">
            <div v-for="subcategory in category?.subcategories" :key="subcategory.id" class="my-4">
              <div v-if="subcategory.title" class="text-body-1 font-weight-bold mb-2">
                {{ subcategory.title }}
              </div>
              <div
                v-for="field in getSubcategoryField(category.id, subcategory.id)"
                :key="field.id"
              >
                <div v-if="field.title" class="text-body-1 mx-0">
                  {{ field.title }}{{ isEdited && field?.type !== 'dropFile' ? '*' : '' }}
                </div>
                <div :class="[field.disabled ? 'disabled' : '']">
                  <Component
                    :is="inputs[getFormInput(field.type)]"
                    v-bind="field"
                    @update-value="(data) => updateValue(formFields, data)"
                    @change-value="updateFieldValue(field, category.id)"
                    @delete-file="deleteFile"
                    @download-file="downloadFile"
                  />
                </div>
              </div>
            </div>
          </template>

          <div v-if="getCategoryFields(category.id)?.length" class="mb-4">
            <div v-for="field in getCategoryFields(category.id)" :key="`field-${field.id}`">
              <p v-if="field.title" class="text-body-1 mx-0 my-0">
                {{ field.title }}{{ isEdited && field?.type !== 'dropFile' ? '*' : '' }}
              </p>
              <div :class="[field.disabled ? 'disabled' : '']">
                <Component
                  :is="inputs[getFormInput(field.type)]"
                  v-bind="field"
                  @update-value="(data) => updateValue(formFields, data)"
                  @change-value="updateFieldValue(field, category.id)"
                  @delete-file="deleteFile"
                  @download-file="downloadFile"
                />
              </div>
              <v-row class="justify-end pa-0 ma-0">
                <button
                  v-if="
                    field.copy_group &&
                    getIsLastElementInGroup(field.id, category.id, field.copy_group)
                  "
                  class="ml-2"
                  type="button"
                  @click="() => deleteFieldsGroup(field.group_id, field.copy_group)"
                >
                  <v-icon color="red" size="xs"> mdi-delete-outline </v-icon>
                  <span class="text-body-2 title text-red">Удалить блок</span>
                </button>
              </v-row>
            </div>
          </div>
          <div v-if="category.buttons?.length" class="mb-4">
            <div v-for="field in category.buttons" :key="`field-${field.id}`">
              <div :class="[field.disabled ? 'disabled' : '']">
                <Component
                  :is="inputs[getFormInput(field.type)]"
                  v-bind="field"
                  @update-value="(data) => updateValue(formFields, data)"
                  @change-value="updateFieldValue(field, category.id)"
                />
              </div>
            </div>
          </div>
          <div v-if="typeof category.total === 'number'" class="font-weight-bold text-subtitle-1">
            <div :key="getCategoryTotalAmountKey(category.id)" class="mb-2">Итого:</div>
            <div>{{ String(category.total).replace('.', ',') }} руб.</div>
          </div>

          <div v-if="category.note" class="my-4">
            <div
              v-for="(note, index) in category.note"
              :key="`note-${index}`"
              class="text-grey text-caption mb-1"
            >
              {{ note }}
            </div>
          </div>
        </v-col>
        <v-row class="px-0 align-center mx-0 mt-6">
          <v-btn
            depressed
            color="primary"
            variant="elevated"
            class="mr-2"
            @click="printReportDetailsForm"
          >
            Печать
          </v-btn>

          <v-btn
            :disabled="disableSaveButton"
            depressed
            color="primary"
            variant="outlined"
            @click="onSubmit"
          >
            Сохранить
          </v-btn>
          <v-btn
            class="ml-2 text-decoration-underline"
            color="primary"
            variant="text"
            @click="goBack"
          >
            Закрыть
          </v-btn>
        </v-row>
      </v-form>
    </template>
  </the-container>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'

import { api } from '@/api/Api'

import {
  inputs,
  getFormInput,
  focusErrorInput,
  downloadFileWithLinkData,
  downloadFilePdfData,
  round,
} from '@/helpers/index'
import { EXPENSE_REPORT_FORM_FOLDER, WINNER_FOLDER } from '@/constants/store'
import { FORM_PROGRAM_EXPENDITURE_REPORT } from '@/constants/forms'
import { REPORT_FILES } from '@/constants/buckets'

import TheContainer from '@/components/TheContainer'
import { useToast } from '@/composables/useToast'
import ReportFormPreview from '@/components/Reports/ReportFormPreview'
import { PERFORMANCE_EXPENSE_REPORT } from '@/constants/cachesIds'
import store from '@/store'
import { REPORTS_STATUSES } from '@/constants'

const { toast } = useToast()
const route = useRoute()
const router = useRouter()
const isEdited = ref(false)
const isLoading = ref(false)

const validForm = ref(true)
const form = ref(null)
const coFinanceStructure = ref(null)
const hash = ref(`${PERFORMANCE_EXPENSE_REPORT}-${route.params?.id || ''}`)
const softwareAcquisitionCosts = ref(0)
const formFields = ref([...FORM_PROGRAM_EXPENDITURE_REPORT])
const disableSaveButton = computed(() =>
  formFields.value?.find(
    (el) => !String(el.default_value) && el.type !== 'dropFile' && !!el.group_id,
  ),
)
const formGroups = ref([
  {
    id: 0,
    title: 'Общие сведения',
  },
  {
    id: 1,
    title: 'I. Структура расходов средств из внебюджетных источников',
    idDescriptionTitle: '*6',
    name: 'expenseStructure',
    subcategories: [
      {
        id: 0,
        title: '',
      },
      {
        id: 1,
        title: 'Фонд оплаты труда (включая начисления на фонд оплаты труда)',
        hasTotalAmount: true,
      },
      {
        id: 2,
        title: 'Оборудование',
        hasTotalAmount: true,
      },
      {
        id: 3,
        title: 'Программное обеспечение',
        hasTotalAmount: true,
      },
      {
        id: 4,
        title: 'Материалы и комплектующие',
        hasTotalAmount: true,
      },
      {
        id: 5,
        title: 'Оплата услуг сторонних организаций',
        hasTotalAmount: true,
      },
      {
        id: 6,
        title: 'Прочие прямые расходы',
        hasTotalAmount: true,
      },
      {
        id: 7,
        title: 'Накладные расходы',
      },
      {
        id: 8,
        title: 'ИТОГОВАЯ СУММА:',
        hasTotalAmount: true,
        isTotal: true,
      },
    ],
  },
  {
    id: 2,
    title: 'II. Расходы средств из внебюджетных источников на приобретение оборудования',
    name: 'reportCoFinanceExpenseEquipment',
    total: softwareAcquisitionCosts.value,
    note: [
      '*18 Наименование оборудования указывается в соответствии с договором поставки. В случае если в рамках одного договора приобретаются разные группы оборудования\n' +
        '(не предполагающегося для совместного использования), то такие группы/позиции указываются в разных строках таблицы',
      '*19 Указываются реквизиты договоров, актов, товарных накладных, платежных поручений',
    ],
    buttons: [
      {
        id: uuidv4(),
        type: 'actionButton',
        name: 'add-block',
        text: 'Добавить блок',
        default_value: null,
        action: () => addBlock(2),
      },
    ],
  },
  {
    id: 3,
    title: 'III. Расходы средств внебюджетных источников на приобретение программного обеспечения',
    name: 'reportCoFinanceExpenseSoftware',
    total: 0,
    note: [
      '*20 Наименование программного обеспечения указывается в соответствии с договором поставки. В случае если в рамках одного договора приобретаются разные группы программного обеспечения (не предполагающегося для совместного использования), то такие группы/позиции указываются в разных строках таблицы',

      '*21 Указываются реквизиты договоров, актов, товарных накладных, платежных поручений',
    ],
    buttons: [
      {
        id: uuidv4(),
        type: 'actionButton',
        name: 'add-block',
        text: 'Добавить блок',
        default_value: null,
        action: () => addBlock(3),
      },
    ],
  },
  {
    id: 4,
    title:
      'IV.Расходы средств внебюджетных источников на оплату выполненных работ, оказанных услуг',
    name: 'reportCoFinanceExpenseWork',
    buttons: [
      {
        id: uuidv4(),
        type: 'actionButton',
        name: 'add-block',
        text: 'Добавить блок',
        default_value: null,
        action: () => addBlock(4),
      },
    ],
    note: [
      '*22 Наименование услуг, и (или) работ указывается в соответствии с договором. В случае если в рамках одного договора были заказаны разные услуги и (или) работы, то такие позиции указываются в разных строках таблицы',
      '*23 Указываются реквизиты договоров, актов, платежных поручений',
    ],
  },
  {
    id: 5,
    // total: 0,
    note: ['* - поля обязательные для заполнения', 'Введенные данные сохраняются автоматически\n'],
  },
])

const bucket = computed(() => REPORT_FILES)
const isWinner = computed(() => store.getters['user/isWinner'])

function goBack() {
  return router.go(-1)
}

function getIsLastElementInGroup(id, categoryId, copyGroupId) {
  const categoryFields = formFields.value?.filter((el) => el.group_id === categoryId)
  const copyFields = categoryFields?.filter((el) => el.copy_group === copyGroupId)
  const element = copyFields?.findIndex((el) => el.id === id)
  return element === copyFields.length - 1
}

function deleteFieldsGroup(categoryId, copyGroup) {
  const categoryFields = formFields.value?.filter((el) => el.group_id === categoryId)
  const copyFields = categoryFields?.filter((el) => el.copy_group === copyGroup)
  const findIndexFirstElement = formFields.value?.findIndex((el) => copyFields[0].id === el.id)
  if (findIndexFirstElement !== -1 && copyFields.length) {
    formFields.value?.splice(findIndexFirstElement, copyFields.length)
    updateFieldValue(categoryFields[0], categoryId)
  }
}

async function printReportDetailsForm() {
  try {
    await api
      .reports()
      .printCoFinanceStructure(route.params?.id)
      .then((response) => {
        downloadFilePdfData(
          response,
          'Отчет о расходах на реализацию Программы Центра НТИ из средств софинансирования\n',
        )
      })
      .then((data) => {
        console.log(data)
      })
  } catch (err) {
    console.log(err)
  }
}

function groupFields(fields, key) {
  return fields?.reduce((acc, obj) => {
    const property = obj[key] || 0
    acc[property] = acc[property] || []
    acc[property].push(obj)
    return acc
  }, {})
}

function getGroupValue(groupId, data) {
  if (groupId === 2) {
    return data?.map((el) => {
      return {
        equipmentName: el.equipmentName,
        unitPrice: +el.unitPrice,
        count: +el.count,
        nameOrganization: el.nameOrganization,
        nameDocuments: el.nameDocuments,
      }
    })
  }

  if (groupId === 3) {
    return data?.map((el) => {
      return {
        softwareName: el.softwareName,
        unitPrice: +el.unitPrice,
        count: +el.count,
        nameOrganization: el.nameOrganization,
        nameDocuments: el.nameDocuments,
      }
    })
  }

  if (groupId === 4) {
    return data?.map((el) => {
      return {
        workName: el.workName,
        nameOrganization: el.nameOrganization,
        nameDocuments: el.nameDocuments,
        cost: el.cost,
      }
    })
  }
}

async function clearStash() {
  try {
    await api.stash().clearStash(hash.value)
  } catch (err) {
    console.log(err)
  }
}

function getCopyGroupData(fields, group) {
  const filterData = groupFields(fields, 'copy_group')

  if (!filterData) {
    return
  }

  const newValue = Object.values(filterData)?.map((el) => {
    let data
    el?.forEach((field) => {
      data = {
        ...data,
        [field.name]: field.type === 'number' ? +field.default_value : field.default_value,
      }
      return data
    })
    return data
  })

  return getGroupValue(group, newValue)
}

function getDefaultObjectData(fields) {
  let data = {}
  fields?.forEach((el) => {
    if (el.type === 'dropFile') {
      return
    }

    data[el.name] = el.type === 'number' ? +el.default_value : el.default_value
  })
  return data
}

async function updateDataInStash({ id }) {
  const findField = formFields.value?.find((el) => el.id === id)

  if (findField) {
    const group = findField?.group_id
    const selectGroupName = formGroups.value?.find((el) => el.id === group)?.name
    const fields = formFields.value?.filter((el) => el.group_id === group)

    const fieldsValue = group > 1 ? getCopyGroupData(fields, group) : getDefaultObjectData(fields)

    const params = {
      field: selectGroupName,
      value: fieldsValue,
      hash: hash.value,
    }
    calculateAmount()
    await api.stash().saveFieldInStash(params)
  }
}

function calculateAmount() {
  formFields.value?.forEach((el) => {
    const group = formGroups.value?.find((group) => group.id === el.group_id)
    if (el.name === 'amount') {
      if ('subcategories' in group) {
        const fields = formFields.value?.filter(
          (item) => item.group_id === group.id && item?.subcategory === el?.subcategory,
        )
        const fieldsValue = fields?.map((field) =>
          field.name !== 'amount' ? field.default_value : 0,
        )

        const value = fieldsValue?.reduce((acc, obj) => +acc + +obj)
        el.default_value = round(+value)
      }
    }

    if (el?.subcategory === 8) {
      if (el.name === 'totalAmount') {
        const fieldsTotalValues = formFields.value?.filter(
          (item) => item.group_id === group.id && item.name === 'amount',
        )
        const fieldsValues = fieldsTotalValues?.map((el) => +el.default_value)
        const val = fieldsValues?.reduce((acc, obj) => +acc + +obj)
        el.default_value = round(val)
      }

      if (el.name === 'incomeContractsExpenses') {
        const fieldsTotalValues = formFields.value?.filter(
          (item) => item.group_id === group.id && !!item?.isExpensesFromIncomeContracts,
        )
        const fieldsValues = fieldsTotalValues?.map((el) => +el.default_value)
        const val = fieldsValues?.reduce((acc, obj) => +acc + +obj)
        el.default_value = round(val)
      }

      if (el.name === 'otherExpenses') {
        const fieldsTotalValues = formFields.value?.filter(
          (item) => item.group_id === group.id && !!item?.isSpendingFromOtherSources,
        )
        const fieldsValues = fieldsTotalValues?.map((el) => +el.default_value)
        const val = fieldsValues?.reduce((acc, obj) => +acc + +obj)
        el.default_value = round(val)
      }
    }
  })
}

function setData(data) {
  formFields.value?.forEach((el) => {
    if (el.name in data) {
      el.default_value = data[el.name]
    }
  })

  formGroups.value?.forEach((group) => {
    if (group?.name in data) {
      if (!data[group?.name]?.length) {
        const fields = formFields.value?.filter((el) => el.group_id === group.id)

        fields?.forEach((el) => {
          if (el.name in data[group?.name]) {
            el.default_value = +data[group?.name][el.name]
          }
        })

        return
      }

      data[group?.name]?.forEach((data, index) => {
        if (!index) {
          const fields = formFields.value?.filter((el) => el.group_id === group.id)
          fields?.forEach((field) => {
            if (field.name in data) {
              field.default_value = data[field.name]
              updateTotalValue(field, group.id)
            }
          })
        } else {
          const hasGroup = formFields.value?.find(
            (el) => el.group_id === group.id && el.copy_group === index,
          )

          if (!hasGroup) {
            addBlock(group.id)
          }

          const fields = formFields.value?.filter(
            (el) => el.group_id === group.id && el.copy_group === index,
          )
          fields?.forEach((field) => {
            if (field.name in data) {
              field.default_value = data[field.name]
              updateTotalValue(field, group.id)
            }
          })
        }
      })
    }
  })
}

async function getSavedData() {
  isLoading.value = true
  try {
    await api
      .stash()
      .getStashData(hash.value)
      .then((data) => {
        if (!data) {
          return
        }
        setData(data)
        getFiles()
      })
  } catch (err) {
    toast.error(err)
  } finally {
    isLoading.value = false
  }
}

function getCategoryTotalAmountKey(categoryId) {
  switch (categoryId) {
    case 2:
      return softwareAcquisitionCosts.value
    default:
      return
  }
}

function updateValue(fieldsForm, data) {
  const { value, id } = data
  fieldsForm?.forEach((item) => {
    if (item.id === id) {
      item.default_value = value
      item.isEdit = true
      if (item.type === 'dropFile') {
        value?.forEach((el) => {
          uploadFile(item.name, el, item.copy_group)
        })
      }
    }
  })
}

async function downloadFile(id, name) {
  try {
    await api
      .files()
      .downloadFile(id)
      .then((data) => {
        downloadFileWithLinkData(data, name)
      })
  } catch (err) {
    toast.error(err)
  }
}

async function deleteFile(id) {
  try {
    await api
      .files()
      .deleteFile(id)
      .then(() => {
        getFiles()
      })
  } catch (err) {
    toast.error(err)
  }
}

async function uploadFile(name, data, copyGroup) {
  try {
    const form = new FormData()
    form.append('file', data)
    form.append('fileKey', name)
    form.append('entityId', route.params?.id)
    form.append('folder', `${WINNER_FOLDER}/${EXPENSE_REPORT_FORM_FOLDER}`)
    if (copyGroup) {
      form.append('groupId', copyGroup)
    }

    await api
      .files()
      .uploadFile(bucket.value, form)
      .then(() => {
        getFiles()
      })
  } catch (err) {
    toast.error(err)
  }
}

async function getFiles() {
  try {
    const params = { entityId: route.params?.id }
    formFields.value?.forEach((el) => {
      if (el.type === 'dropFile') {
        el.links = []
      }
    })
    await api
      .files()
      .getFiles(REPORT_FILES, params)
      .then((data) => {
        const allContent = data?.filesInFolders
        if (!allContent || (allContent && !allContent[WINNER_FOLDER])) {
          return
        }
        const filesForms = allContent[WINNER_FOLDER]

        const files = filesForms[EXPENSE_REPORT_FORM_FOLDER]
        formFields.value?.forEach((el) => {
          if (!files || el.type !== 'dropFile') {
            return
          }

          if (el.name in files) {
            el.links = files[el.name]?.filter((file) => file.groupId == el?.copy_group)
          } else {
            el.links = []
          }
        })
      })
  } catch (err) {
    toast.error(err)
  }
}

function addBlock(idGroup) {
  const templateFieldsGroup = formFields.value?.filter(
    (el) => el.group_id === idGroup && !el?.copy_group,
  )

  const countGroups = formFields.value?.filter(
    (el) => el.name === templateFieldsGroup[0].name && el.group_id === idGroup,
  )?.length

  const newGroupFields = templateFieldsGroup?.map((el) => {
    return {
      ...el,
      id: uuidv4(),
      copy_group: countGroups,
      default_value: null,
      links: [],
    }
  })
  formFields.value.splice(formFields.value?.length, 0, ...newGroupFields)
}

function getSubcategoryField(categoryId, subcategoryId) {
  const categoryFields = formFields.value?.filter((el) => el.group_id === categoryId)
  const subcategoryFields = categoryFields?.filter((el) => el.subcategory === subcategoryId)
  return subcategoryFields
}

function getCategoryFields(categoryId) {
  const categoryFields = formFields.value?.filter(
    (el) => el.group_id === categoryId && typeof el?.subcategory !== 'number',
  )
  return categoryFields
}

function updateFieldValue(field, idCategory) {
  updateDataInStash({ id: field.id })

  updateTotalValue(field, idCategory)
}

function updateTotalValue(field, idCategory) {
  if (idCategory < 2) {
    return
  }

  if (field.name === 'cost' && idCategory === 4) {
    const fields = formFields.value?.filter(
      (el) => el.group_id === idCategory && el.name === 'cost',
    )
    const group = formGroups.value?.find((el) => el.id === idCategory)
    const newValue = groupFields(fields, 'copy_group')

    let sum = 0
    Object.values(newValue)?.forEach((fields) => {
      const price = fields?.find((el) => el.name === 'cost')?.default_value
      sum += round(+price)
    })
    return (group.total = sum)
  }

  if (field.name !== 'count' && field.name !== 'unitPrice' && field.name !== 'price') {
    return
  }

  const fields = formFields.value?.filter((el) => el.group_id === idCategory)
  const group = formGroups.value?.find((el) => el.id === idCategory)

  if (group) {
    const newValue = groupFields(fields, 'copy_group')

    let sum = 0

    Object.values(newValue)?.forEach((fields) => {
      const count = fields?.find((el) => el.name === 'count')?.default_value || 0
      const pricePart = fields?.find((el) => el.name === 'unitPrice')?.default_value || 0
      const amountField = fields?.find((el) => el.name === 'amount')
      sum += round(+count * +pricePart)
      if (amountField) {
        amountField.default_value = round(count * pricePart)
      }
    })

    return (group.total = +sum)
  }
}

async function getCoFinanceStructure() {
  isLoading.value = true
  try {
    await api
      .reports()
      .getCoFinanceStructure(route.params?.id)
      .then((data) => {
        coFinanceStructure.value = data

        setData(data)
        if (
          isWinner.value &&
          (data?.reportStatus === REPORTS_STATUSES.DRAFT ||
            data?.reportStatus === REPORTS_STATUSES.REJECTED)
        ) {
          isEdited.value = true

          getSavedData().then(() => {
            calculateAmount()
          })
        } else {
          calculateAmount()
        }
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function onSubmit() {
  isLoading.value = true
  await form.value.validate()
  if (!validForm.value) {
    const errInput = form.value.errors[0]
    focusErrorInput(errInput)
    return
  }

  try {
    const params = {
      id: coFinanceStructure.value?.id,
    }

    formGroups.value?.forEach((el) => {
      const selectGroupName = el?.name
      if (!selectGroupName) {
        return
      }

      const group = el?.id
      const fields = formFields.value?.filter((el) => el.group_id === group)
      const fieldsValue = group > 1 ? getCopyGroupData(fields, group) : getDefaultObjectData(fields)
      if (fieldsValue) {
        params[selectGroupName] = fieldsValue
      }
    })

    await api
      .reports()
      .saveCoFinanceStructure(route.params?.id, params)
      .then(() => {
        clearStash()
        getFormData()
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function getGeneralReportInfo() {
  try {
    await api
      .reports()
      .getGeneralReportInfo(route.params?.id)
      .then((data) => {
        formFields.value?.map((el) => {
          if (el.name in data) {
            el.default_value = data[el.name]
          }
        })
      })
  } catch (err) {
    console.log(err)
  }
}

function getFormData() {
  getGeneralReportInfo()
  getCoFinanceStructure()

  if (!isWinner.value) {
    isEdited.value = false
  }
}

onMounted(() => {
  getFormData()
})
</script>
