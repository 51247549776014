<template>
  <div v-if="isVisible" class="d-inline-block">
    <span>
      <v-btn variant="text" icon="mdi-pencil" color="primary" @click="$emit('update-field')" />
    </span>
    <span class="control__item">
      <v-btn variant="text" icon="mdi-delete" color="red" @click="openDialog" />
    </span>
  </div>
  <v-dialog v-model="isShowDeleteDialog">
    <v-card>
      <v-card-title> Удалить поле ? </v-card-title>
      <v-card-actions>
        <v-row :no-gutters="true" justify="center">
          <v-col cols="auto">
            <v-btn @click="deleteField"> Да </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn @click="closeDialog"> Нет </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref } from 'vue'

defineProps({
  isVisible: {
    type: [Boolean, Number],
    default: false,
  },
  fieldId: {
    type: [String, Number],
    default: null,
  },
})

const emit = defineEmits(['delete-filed', 'update-field'])

const isShowDeleteDialog = ref(false)

function openDialog() {
  isShowDeleteDialog.value = true
}
function closeDialog() {
  isShowDeleteDialog.value = false
}

function deleteField() {
  emit('delete-filed')
  closeDialog()
}
</script>
