<template>
  <TheContainer>
    <UsersTable :users="users" />
  </TheContainer>
</template>

<script setup>
import TheContainer from '@/components/TheContainer'
import { computed, onMounted } from 'vue'
import UsersTable from '@/components/Users/UsersTable'
import { useStore } from 'vuex'

const store = useStore()

const users = computed(() => store.getters['user/allUsers'])

onMounted(() => {
  store.dispatch('user/getUsers')
})
</script>
<script>
export default {
  name: 'UsersAndRolesView',
}
</script>
