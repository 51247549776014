<template>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <template v-else>
    <template v-if="templateData">
      <h3 class="text-title">{{ templateData.point?.name }}</h3>
      <div class="mb-6" v-html="templateData.point?.description" />
      <div v-if="isVote && templateData?.files?.length" class="mb-12">
        <div class="font-weight-bold my-6">Скачать пакет документов:</div>
        <div v-for="file in templateData?.files" :key="file.fileId">
          <v-row class="pa-0 ma-0 justify-space-between align-center">
            <v-col class="pa-md-0 pa-0 ma-0" cols="12" md="8" lg="8">
              <v-icon color="grey" class="d-inline">{{ getFileIcon(file?.name) }}</v-icon>
              <span class="ml-2 font-weight-medium text-caption">{{ file?.name }}</span>
            </v-col>
            <v-col cols="12" md="4" lg="4" class="pa-0 ma-0">
              <v-row class="ma-0 pa-0 align-center justify-end w-100">
                <div>
                  <v-btn
                    class="text-caption text-blue-darken-4 ml-2"
                    variant="text"
                    @click="() => downloadFile(file)"
                  >
                    <v-icon color="blue" size="xs"> mdi-download </v-icon>
                    <span class="ml-2 text-body-2 title">Скачать</span>
                  </v-btn>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
    <v-alert
      v-if="!certificates?.length && hasSignField"
      text="Для продолжения работы необходимо установить и настроить КриптоПро"
      type="info"
      variant="tonal"
    />
    <div
      :key="!certificates?.length"
      :style="{ display: !certificates?.length && hasSignField ? 'none' : 'visible' }"
    >
      <div v-for="(field, index) in sortFields" :key="`${field.id}-${index}`">
        <template v-if="!field.hidden">
          <div v-if="field.type !== 'group' && !field.groupCode" class="my-4">
            <v-row class="pa-0 ma-0 align-center mb-4 mt-4">
              <div
                v-if="field.title && !field.index && field.type !== 'agree'"
                class="text-body-1 mx-0 mr-2"
              >
                {{ field.title
                }}<span v-if="field?.required" class="text-red font-weight-bold">*</span>
                <template v-if="!!field.readOnly">
                  <v-icon :id="`ac-${field.id}`">mdiPencilOff</v-icon>
                  <v-tooltip :activator="`ac-${field.id}`" location="top"
                    >Только для чтения</v-tooltip
                  >
                </template>
                <v-progress-circular
                  v-if="field.parseLoad"
                  indeterminate
                  color="primary"
                  :size="16"
                  class="ml-4"
                  :width="2"
                />
              </div>
              <div v-if="field.note">
                <v-icon :id="`tooltip-${field.code1C}`" size="x-small" variant="text" color="grey">
                  mdi-information-outline
                </v-icon>
                <v-tooltip v-if="field.note" :activator="`#tooltip-${field.code1C}`">{{
                  field.note
                }}</v-tooltip>
              </div>
            </v-row>

            <v-row
              :key="getError(field)"
              :class="[
                'pa-0 ma-0 align-start',
                getError(field) ? 'has-error mb-2' : 'default-view mb-2',
              ]"
            >
              <v-col class="pa-0 ma-0 flex-grow-1">
                <v-row class="pa-0 ma-0 align-center w-100">
                  <Component
                    :is="inputs[getFormInput(field.type)]"
                    :key="field.links?.length"
                    :has-error="field.hasError"
                    v-bind="field"
                    :parse-load="field.parseLoad"
                    :class="field.class"
                    :read-only="field.readOnly"
                    :is-url="field.isUrl"
                    :required="field.required"
                    :signable="field.signable"
                    :error-messages="getError(field)"
                    :links="field.signable ? getFilesByAttachCode(field.code1C) : field.links"
                    @update-signed-files="(data) => uploadSignFile(data)"
                    @update-value="(data) => updateValue(data, field)"
                    @change-value="updateCacheValue(field)"
                    @download-example="getTemplateFile"
                    @delete-file="deleteFile"
                    @download-file="downloadFile"
                  />
                </v-row>
                <div v-if="field.managerComment" class="text-red text-caption">
                  {{ field.managerComment }}
                </div>
              </v-col>
              <v-col class="pa-0 ma-0 flex-grow-0 d-flex align-center justify-center">
                <v-btn
                  v-if="field.index"
                  class="ml-2"
                  variant="text"
                  type="button"
                  style="min-height: 56px"
                  @click="() => deleteField(field)"
                >
                  <v-icon color="red" size="xs"> mdi-delete-outline </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <div v-if="field.description" class="text-grey font-italic mt-4">
              <span v-html="field.description" />
            </div>
            <v-btn
              v-if="
                field.multiple &&
                field?.fieldType?.id !== 15 &&
                isLastFieldByCode1C(field.code1C, field.index) &&
                field.type !== 'dropFile'
              "
              color="primary"
              variant="text"
              class="mb-4"
              @click="addNewInput(field)"
            >
              Добавить поле
              <i class="pi-plus pi ml-2" style="font-size: 16px" />
            </v-btn>
          </div>
          <div v-if="field.type === 'group'">
            <v-expansion-panels :model-value="field.expansion" class="mb-6 tasks-tabs">
              <v-expansion-panel expand>
                <v-expansion-panel-title>
                  <div class="text-body-1 ma-0 font-weight-bold mb-1">{{ field.name }}</div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <div class="pa-5">
                    <div
                      v-for="groupField in getFieldsByGroup(field.code1C, field.groupIndex)"
                      :key="field.code1C"
                    >
                      <template v-if="!groupField.hidden">
                        <v-row class="align-center pa-0 ma-0 mb-4 mt-4">
                          <div
                            v-if="
                              groupField.title && !groupField.index && groupField.type !== 'agree'
                            "
                            class="text-body-1 mx-0 mr-2"
                          >
                            {{ groupField.title
                            }}<span v-if="groupField?.required" class="text-red font-weight-bold"
                              >*</span
                            >
                            <template v-if="groupField.readOnly">
                              <v-icon class="ml-2" size="xs" color="grey">mdi-pencil-off</v-icon>
                              <v-tooltip activator="parent" location="top right"
                                >Только для чтения</v-tooltip
                              >
                            </template>
                            <v-progress-circular
                              v-if="groupField.parseLoad"
                              indeterminate
                              color="primary"
                              :size="16"
                              class="ml-4"
                              :width="2"
                            />
                          </div>
                          <div v-if="groupField.note">
                            <v-icon
                              :id="`tooltip-${groupField.code1C}`"
                              size="x-small"
                              variant="text"
                              color="grey"
                            >
                              mdi-information-outline
                            </v-icon>
                            <v-tooltip :activator="`#tooltip-${groupField.code1C}`">{{
                              groupField.note
                            }}</v-tooltip>
                          </div>
                        </v-row>
                        <v-col
                          :key="getError(groupField)"
                          :class="[
                            'pa-0 ma-0',
                            getError(groupField) ? 'has-error mb-2' : 'default-view mb-2',
                          ]"
                        >
                          <v-row class="pa-0 ma-0 align-start">
                            <v-col class="pa-0 ma-0 flex-grow-1">
                              <v-row class="pa-0 ma-0">
                                <Component
                                  :is="inputs[getFormInput(groupField.type)]"
                                  :class="groupField.class"
                                  v-bind="groupField"
                                  :has-error="groupField.hasError"
                                  :is-url="groupField.isUrl"
                                  :parse-load="groupField.parseLoad"
                                  :read-only="groupField.readOnly"
                                  :links="
                                    groupField.signable
                                      ? getFilesByAttachCode(groupField.code1C)
                                      : groupField.links
                                  "
                                  :required="groupField.required"
                                  :error-messages="getError(groupField)"
                                  :signable="groupField.signable"
                                  @update-signed-files="(data) => uploadSignFile(data)"
                                  @update-value="(data) => updateValue(data, groupField)"
                                  @change-value="updateCacheValue(groupField)"
                                  @download-example="getTemplateFile"
                                  @delete-file="deleteFile"
                                  @download-file="downloadFile"
                                />
                              </v-row>
                            </v-col>
                            <v-col class="pa-0 ma-0 flex-grow-0 d-flex align-center justify-center">
                              <v-btn
                                v-if="groupField.index"
                                class="ml-2"
                                variant="text"
                                type="button"
                                style="min-height: 56px"
                                @click="() => deleteField(groupField)"
                              >
                                <v-icon color="red" size="xs"> mdi-delete-outline </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                          <div v-if="groupField.managerComment" class="text-red text-caption mt-2">
                            {{ groupField.managerComment }}
                          </div>
                          <div v-if="groupField.description" class="text-grey font-italic mt-4">
                            <span v-html="groupField.description" />
                          </div>
                        </v-col>
                        <v-btn
                          v-if="
                            groupField.multiple &&
                            groupField?.fieldType?.id !== 15 &&
                            isLastFieldByCode1C(
                              groupField.code1C,
                              groupField.index,
                              field.groupIndex,
                            ) &&
                            groupField.type !== 'dropFile'
                          "
                          color="primary"
                          variant="text"
                          class="mb-4"
                          @click="addNewInput(groupField)"
                        >
                          Добавить поле
                          <i class="pi-plus pi ml-2" style="font-size: 16px" />
                        </v-btn>
                      </template>
                    </div>
                  </div>
                  <v-row v-if="field.groupIndex" class="pa-0 ma-0 justify-end mt-4 px-4 mx-0">
                    <v-btn color="red" variant="text" class="mb-4" @click="deleteGroup(field)">
                      Удалить группу
                      <v-icon color="red" size="xs" class="ml-2"> mdi-delete-outline </v-icon>
                    </v-btn>
                  </v-row>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <div v-if="field.multiple && isLastGroup(field)" class="mb-5 mt-2 px-4">
              <v-btn color="primary" variant="text" class="mb-4" @click="addNewGroup(field)">
                Добавить группу
                <i class="pi-plus pi ml-2" style="font-size: 16px" />
              </v-btn>
            </div>
          </div>
        </template>
      </div>
      <div v-if="pointData?.managerComment" class="text-error mt-4">
        {{ pointData?.managerComment }}
      </div>
      <v-col class="px-0 mt-6">
        <div class="v-messages">
          <span class="text-body-2">* - поля обязательные для заполнения</span>
        </div>
        <div class="v-messages">
          <span class="text-body-2">Введенные данные сохраняются автоматически</span>
        </div>
      </v-col>
      <v-row v-if="templateData?.dateSubmitFrom && !notComeDateFillFrom" class="px-0 mx-0 my-6">
        <v-alert
          :text="`Отправка данных будет доступна ${getFormatDateTime(templateData.dateSubmitFrom)}`"
          type="info"
          variant="tonal"
        />
      </v-row>
      <v-row class="px-0 align-md-center mx-0 mt-6 flex-column flex-md-row align-start">
        <v-btn depressed class="mr-2 mb-2 mb-md-0" color="primary" @click="onSaveNote">
          Сохранить
        </v-btn>
        <v-btn
          v-if="notComeDateFillFrom"
          :disabled="disableSaveButton || isDisableSubmitButton"
          depressed
          color="primary"
          class="mb-2 mb-md-0"
          @click="onSubmit"
        >
          {{ isVote ? 'Сформировать файл голосования' : 'Сформировать для отправки' }}
        </v-btn>
        <v-btn
          class="ml-md-2 text-decoration-underline"
          color="primary"
          variant="text"
          @click="goBack"
        >
          Закрыть
        </v-btn>
        <QuestionnaireDialog
          v-if="parsingValues"
          ref="questionnairesDialog"
          :result="parsingValues"
          :point="pointData"
          :file-index="newParsingData?.fileIndex"
          :title="newParsingData?.title || ''"
          :attachment-code="newParsingData?.id"
          @decline="declineFile"
        />
      </v-row>
    </div>
  </template>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { api } from '@/api/Api'
import { useRoute, useRouter } from 'vue-router'
import {
  downloadFileData,
  downloadFileWithLinkData,
  getFormInput,
  getGroupSort,
  getOptions,
  getSortList,
  inputs,
  getFormatDateTimeForBackend,
  getFileIcon,
  getFormatDateTime,
} from '@/helpers'
import { useToast } from '@/composables/useToast'
import QuestionnaireDialog from '@/views/Tasks/QuestionnaireDialog.vue'
import { QUESTIONNAIRE } from '@/constants/stash'
import moment from 'moment/moment'
import { useStore } from 'vuex'

const route = useRoute()
const router = useRouter()
const { toast } = useToast()
const store = useStore()

const disableSaveButton = computed(() => {
  return (
    !!fields.value?.find(
      (el) =>
        !el.default_value &&
        el.type !== 'dropFile' &&
        el.type !== 'group' &&
        el.required &&
        !el.hidden,
    ) ||
    (errors.value && Object.keys(errors.value)?.length)
  )
})
const newParsingData = computed(() =>
  parsingData.value?.find((el) => el.id === lastParsingId.value),
)
const notComeDateFillFrom = computed(() =>
  templateData.value?.dateSubmitFrom && moment(templateData.value?.dateSubmitFrom).isValid()
    ? moment(new Date()).isAfter(templateData.value?.dateSubmitFrom)
    : true,
)
const currentUserId = computed(() => store.getters['user/currentUserId'])
const certificates = computed(() => store.getters['project/certificates'])
const fieldFormHash = computed(
  () => `${currentUserId.value}-${QUESTIONNAIRE}-${route.params.code1C || route.params.id}`,
)
const sortFields = computed(() => getSortList(fields.value))
const fileId = ref()
const isDisableSubmitButton = ref(false)

const props = defineProps({
  pointData: {
    type: Object,
    default: null,
  },
  isVote: {
    type: Boolean,
    default: false,
  },
})

const parsingValues = ref({})
const isLoading = ref(false)
const lastParsingId = ref()
const questionnairesDialog = ref(null)
const parsingData = ref([])
const files = ref([])
const fields = ref([])
const errors = ref(null)
const templateData = ref()
const groups = ref([])
const defaultFields = ref([
  {
    id: 'name',
    type: 'text',
    default_value: '',
    sort: 0,
    name: 'name',
    hidden: false,
    multiple: false,
    required: true,
    title: 'Название проекта',
    groupCode: null,
  },
  // {
  //   id: 'description',
  //   type: 'textarea',
  //   default_value: '',
  //   sort: 0,
  //   name: 'description',
  //   title: 'Описание проекта',
  //   hidden: false,
  //   multiple: false,
  //   required: true,
  //   groupCode: null,
  // },
])
function deleteGroup(group) {
  const deleteFieldsList = fields.value.filter(
    (field) =>
      (field.groupCode === group.code1C && field.groupIndex === group.groupIndex) ||
      (field.code1C === group.code1C && field.groupIndex === group.groupIndex) ||
      field.id === group.id,
  )
  deleteFieldsList.forEach((el) => {
    deleteField(el)
  })

  const fieldsList = fields.value
    ?.filter((field) => field.id !== group.id && field.groupIndex !== group.groupIndex)
    .filter(
      (el) =>
        (el.groupCode !== group.code1C && el.groupIndex !== group.groupIndex) ||
        (el.groupCode === group.code1C && el.groupIndex !== group.groupIndex),
    )
  fields.value = fieldsList
}

function getError(field) {
  if (
    field.code1C &&
    errors.value &&
    `${field.code1C}-${field.groupIndex}-${field.index}` in errors.value
  ) {
    return errors.value[`${field.code1C}-${field.groupIndex}-${field.index}`]
  }

  return ''
}

function goBack() {
  return router.go(-1)
}

function getFieldsByGroup(code1C, groupIndex) {
  return fields.value?.filter(
    (field) => field.groupCode === code1C && field.groupIndex == groupIndex,
  )
}

function isLastGroup(group) {
  let allFieldsByCode1C = fields.value?.filter((field) => field.code1C === group.code1C)
  const lastElement = allFieldsByCode1C[allFieldsByCode1C.length - 1]
  return lastElement?.groupIndex === group.groupIndex && group.code1C === lastElement?.code1C
}

function isLastFieldByCode1C(code1C, index, groupIndex) {
  let allFieldsByCode1C = fields.value?.filter((field) => field.code1C === code1C)

  if (groupIndex !== undefined) {
    allFieldsByCode1C = fields.value?.filter(
      (field) => field.code1C === code1C && field.groupIndex == groupIndex,
    )
  }

  if (allFieldsByCode1C.length === 1) {
    return true
  }

  return allFieldsByCode1C.length - 1 === index
}

function addNewInput(field, value) {
  const copyField = field
  const newFieldIndex = field.groupIndex
    ? fields.value?.filter(
        (el) => el.code1C === copyField.code1C && el.groupIndex === copyField.groupIndex,
      )
    : fields.value?.filter((el) => el.code1C === copyField.code1C && !el.groupIndex)
  const index = newFieldIndex.length

  const newField = {
    ...copyField,
    id: `${copyField.code1C}.${index}.${field.groupIndex}`,
    groupIndex: field.groupIndex,
    index: index,
    sort: copyField.sort + 1,
    default_value: value ? value : null,
  }

  fields.value = [...fields.value, newField]
}

function addNewGroup(group, groupIndex) {
  const fieldsByGroup = fields.value
    .filter((field) => field.groupCode === group.code1C && !field.groupIndex && !field.index)
    .map((el) => {
      return {
        ...el,
        id: `${el.code1C}.${el.index}.${groupIndex || +group.groupIndex + 1}`,
        groupIndex: groupIndex || +group.groupIndex + 1,
        default_value: null,
      }
    })

  const newGroup = {
    ...group,
    expansion: 0,
    index: (group.index || 0) + 1,
    id: `${group.code1C}.${(group.index || 0) + 1}.${groupIndex || (group.groupIndex || 0) + 1}`,
    groupIndex: groupIndex || (group.groupIndex || 0) + 1,
  }

  fields.value = [...fields.value, newGroup, ...fieldsByGroup]
}

const declineFile = (attachmentCode) => {
  deleteFile(fileId.value, attachmentCode)
}

async function deleteField(field) {
  const { code1C, groupIndex, index, id } = field

  try {
    const params = {
      field: id,
    }
    await api
      .stash()
      .deleteField(fieldFormHash.value, params)
      .then(() => {
        const deleteFieldIndex = fields.value?.findIndex(
          (field) =>
            field.code1C === code1C && field.groupIndex == groupIndex && index === field.index,
        )
        if (deleteFieldIndex === -1) {
          return
        }

        fields.value?.splice(deleteFieldIndex, 1)
      })
  } catch (err) {
    console.log(err)
  }
}

async function formationForm(data) {
  groups.value = data?.point?.pointGroups
  const hasNameField = data?.point?.pointFields.find((el) => el.paramName === 'projectName')
  let fieldsList =
    props.pointData || templateData.value?.preRegistration || hasNameField
      ? []
      : [...defaultFields.value]
  data?.point?.pointFields?.forEach((el) => {
    const list = el?.list?.length ? el?.list : el?.fieldType?.list
    const options = getOptions(list, el?.fieldType?.dataType)
    const rules = [[`${el?.fieldType?.validator}`]]
    fieldsList.push({
      ...el,
      ...el.fieldType,
      type: el?.fieldType?.dataType && el?.fieldType?.id !== 16 ? el?.fieldType?.dataType : 'agree',
      name: el.paramName,
      title: el.name,
      description: el.description,
      // hidden: props.pointData ? false : el.hidden,
      fieldGroup: el.code1C,
      valueIndex: 0,
      isUrl: el?.fieldType?.id === 6,
      isPhone: el?.fieldType?.validator === 'phone',
      value: el?.fieldType?.defaultValue,
      options: options,
      itemTitle: '',
      itemValue: '',
      id: `${el.code1C}.${0}.${0}`,
      rules: rules,
      groupIndex: 0,
      index: 0,
      group_sort: getGroupSort(el.groupCode, groups.value) || 0,
      sort: el.sort,
      required: el.required,
    })
  })
  data?.point?.pointAttachments?.forEach((el) => {
    fieldsList.push({
      ...el,
      ...el.fieldType,
      name: el.paramName,
      title: el.name,
      type: 'dropFile',
      groupIndex: 0,
      id: el.code1C,
      maxSize: el.fileType?.maxSize,
      fileTypes: el.fileType?.extensions,
      fileExampleId: el.fileTypeId,
      parcelable: el.parcelable,
      required: el.required,
      group_sort: getGroupSort(el.groupCode, groups.value) || 0,
      sort: el.sort,
      // hidden: props.pointData ? false : el.hidden,
    })
  })
  data?.point?.pointGroups?.forEach((el) => {
    const fieldsHasError = fieldsList.filter((field) => field.groupCode === el.code1C)

    fieldsList.push({
      ...el,
      groupCode: '',
      groupIndex: 0,
      type: 'group',
      expansion: fieldsHasError ? 0 : 1,
      // hidden: props.pointData ? false : el.hidden,
    })
  })
  fields.value = fieldsList
}

function isJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

function setDefaultValues(data) {
  const savedData = { ...data }

  fields.value?.forEach((field) => {
    if (field.id in savedData) {
      field.default_value =
        field.type === 'checkbox' && isJsonString(savedData[field.id])
          ? JSON.parse(savedData[field.id])
          : savedData[field.id]
      delete savedData[field.id]
    }
  })

  return savedData
}

function setDataFromCache(data) {
  const savedData = setDefaultValues(data)

  if (!Object.keys(savedData).length) {
    return
  }

  Object.keys(savedData)?.forEach((key) => {
    const splitKey = key.split('.')
    if (splitKey.length < 3) {
      return
    }
    const id = splitKey[0]
    const index = +splitKey[1]
    const groupIndex = +splitKey[2]
    const copyField = fields.value.find((el) => `${id}.0.0` === el.id)
    const group = fields.value.find((el) => el.code1C === copyField?.groupCode)
    const hasGroup = !!fields.value?.find(
      (el) => group?.code1C === el?.code1C && el?.groupIndex === groupIndex,
    )

    if (groupIndex && !hasGroup && group) {
      return addNewGroup(group, groupIndex)
    }
    const existsField = fields.value.find((el) => el.id === key)
    if (!existsField) {
      return addNewInput(
        { ...copyField, groupIndex: groupIndex, id: key, index: index },
        savedData[key],
      )
    }
  })
  if (Object.keys(savedData)?.length) {
    setDefaultValues(savedData)
  }
}

async function getSavedData() {
  isLoading.value = true
  try {
    await api
      .stash()
      .getStashData(fieldFormHash.value)
      .then((data) => {
        if (!data) {
          return
        }
        if (props.pointData?.values?.length) {
          getPointDataValues(props.pointData?.values)
          getFilesByPointId(props.pointData?.id)
        }

        setDataFromCache(data)
      })
  } catch (err) {
    toast.error(err)
  } finally {
    isLoading.value = false
  }
}

function openQuestionnairesDialog() {
  if (questionnairesDialog.value) {
    questionnairesDialog.value.dialogToggle()
  }
}

async function parseFile(field, file, index, id) {
  try {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('fileIndex', index)
    fields.value?.forEach((el) => {
      if (el.id === field.id) {
        el.parseLoad = true
      }
    })
    return await api
      .questionnaires()
      .parsingQuestionnaireTemplateFile(field.code1C, formData)
      .then((data) => {
        //uploadSignFile({ file, id: field.code1C, index: index })
        parsingValues.value = data
        parsingData.value.push({
          id: field.code1C,
          data: data,
          index: index,
          fileIndex: index,
          title: field.title,
        })
        lastParsingId.value = field.code1C
        uploadFile(file, id, index)
        openQuestionnairesDialog()
      })
      .catch((err) => {
        fields.value?.forEach((el) => {
          if (el.id === field.id) {
            el.links = []
            el.hasError = true
            el.parseLoad = false
          }
        })
        toast.error(err?.response?.data?.message)
      })
  } catch (err) {
    console.log(err)
  } finally {
    fields.value?.forEach((el) => {
      if (el.id === field.id) {
        el.parseLoad = false
      }
    })
  }
}

async function getTemplateFile(id) {
  if (!id) {
    return
  }

  try {
    await api
      .questionnaires()
      .getTemplateFile(id)
      .then((data) => {
        downloadFileData(data, templateData.value?.name)
      })
  } catch (err) {
    console.log(err)
  }
}

function updateValue(data, field) {
  const { value, id } = data
  const { index, groupIndex } = field
  fields.value?.forEach((item) => {
    if (item.id === id && item.index === index && groupIndex == item.groupIndex) {
      item.default_value = value
      if (item.type === 'dropFile' && item.parcelable) {
        value?.forEach((file, index) => {
          parseFile(item, file, index)
        })
      } else if (item.type === 'dropFile') {
        value?.forEach((file) => {
          uploadSignFile({ file, ...data })
        })
      }
    }
  })
}

async function deleteFile(id, attachCode) {
  await api
    .files()
    .deleteSignedFile(id)
    .then(() => {
      files.value = files.value.filter((file) => file.id !== id)
      fields.value?.forEach((field) => {
        if (field.type === 'dropFile' && field.code1C === attachCode) {
          field.links = field.links?.filter((file) => file.id !== id)
        }
      })
    })
}

async function downloadFile(file) {
  downloadFileWithLinkData(file, file.name)
  // return;
  // try {
  //   await api
  //     .files()
  //     .downloadFile(id)
  //     .then((data) => {
  //       downloadFileWithLinkData(data, name)
  //     })
  // } catch (err) {
  //   toast.error(err)
  // }
}

async function uploadSignFile(data) {
  const { value, id, index } = data
  //const findField = fields.value?.find((el) => el.code1C === id)

  try {
    const currentField = fields.value?.find((el) => el.code1C === id)

    if (!currentField) {
      return
    }

    if (!value) {
      return
    }
    //const fileIndex = +findField?.links?.length + index
    if (currentField.parcelable) {
      await parseFile(currentField, value, index, id)
    } else {
      uploadFile(value, id, index)
    }
  } catch (err) {
    toast.error(err)
  }
}

const uploadFile = async (value, id, index) => {
  const form = new FormData()
  form.append('file', value)
  form.append('attachCode', id)
  form.append('valueIndex', index)

  if (props.pointData?.id) {
    form.append('pointId', props.pointData?.id)
  }

  await api
    .files()
    .uploadSignFile(form)
    .then((file) => {
      fileId.value = file.id
      files.value = [...files.value, file]
      fields.value?.forEach((field) => {
        if (field.type === 'dropFile' && field.code1C === id) {
          field.links = [...field.links, file]
          field.hasError = false
        }
      })
    })
}

async function setTaskData() {
  if (!props.pointData) {
    return
  }
  templateData.value = { ...props.pointData, ...props.pointData?.project }
  props.pointData?.templateAttachments?.forEach((field) => {
    getSignedFiles(field.code1C)
  })
  const data = {
    point: {
      pointGroups: props.pointData?.templateGroups,
      pointFields: props.pointData?.templateFields,
      pointAttachments: props.pointData?.templateAttachments,
    },
  }
  formationForm(data).then(() => {
    getSavedData()
  })
}

async function getQuestionnaireById() {
  try {
    isLoading.value = true
    await api
      .questionnaires()
      .getQuestionnaireById(route.params.code1C)
      .then((data) => {
        templateData.value = data
        data.point.pointAttachments?.forEach((field) => {
          getSignedFiles(field.code1C)
        })
        formationForm(data).then(() => {
          getSavedData()
        })
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message)
          router.push(`/programs/${route.params.code1C}`)
        }
        if (err?.response?.status === 404) {
          router.push('/')
        }
      })
  } finally {
    isLoading.value = false
  }
}

function updateCacheValue(field) {
  updateDataInStash(field)

  const value = getFormatValue(field)
  if (field.code1C && value) {
    checkFieldValidity(field, value)
  }
}

async function getDataByIIN(inn, oldField) {
  await api
    .questionnaires()
    .getDataByIIN(inn)
    .then((data) => {
      fields.value?.forEach((field) => {
        const property = field.paramName ? field.paramName : ''
        if (property in data) {
          field.default_value = data[property]
          if (field?.id !== oldField.id) {
            updateCacheValue(field)
          }
        }
      })
    })
    .catch((err) => {
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message)
      }
    })
}

async function checkFieldValidity(field, value) {
  const { code1C, index, groupIndex } = field
  await api
    .questionnaires()
    .checkFieldValidity(code1C, value)
    .then((data) => {
      if (!data) {
        return
      }

      if (data?.valid) {
        const property = `${code1C}-${groupIndex}-${index}`
        errors.value && property in errors.value ? delete errors.value[property] : null
      }

      if (field?.fieldTypeId === 12) {
        getDataByIIN((field.default_value || '').trim(), field)
      }
    })
    .catch((err) => {
      const error = err.response?.data
      if (error?.message) {
        toast.error(error?.message)
      }
      if (error) {
        errors.value = {
          ...errors.value,
          [`${error.field}-${groupIndex}-${index}`]: error.message,
        }
      }
    })
}

async function updateDataInStash(field) {
  const { default_value, id } = field
  const findField = fields.value?.find((el) => el.id === id)

  if (findField) {
    const value =
      findField.type === 'checkbox' ? JSON.stringify(default_value || '') : default_value
    const params = {
      field: findField.id,
      value: value,
      hash: fieldFormHash.value,
    }

    await api.stash().saveFieldInStash(params)
  }
}

function getFilesByAttachCode(attachCode) {
  return files.value?.filter((file) => file.attachCode === attachCode) || []
}

async function getFilesByPointId(pointId) {
  try {
    await api
      .files()
      .getFileByPointId(pointId)
      .then((saveFiles) => {
        saveFiles?.forEach((file) => {
          const fileField = fields.value?.find(
            (field) =>
              field.code1C === file.attachCode &&
              file.groupIndex === field.groupIndex &&
              file.indexValue === field.indexValue,
          )
          if (fileField) {
            fileField['links'] = [...fileField.links, file]
            files.value = [...files.value, file]
          }
        })
      })
  } catch (err) {
    console.log(err)
  }
}
async function getSignedFiles(attachCode) {
  try {
    await api
      .files()
      .getSignedFiles({
        [`filter[attachCode]`]: attachCode,
      })
      .then((data) => {
        files.value = [...files.value, ...data]

        fields.value?.forEach((field) => {
          if (field.type === 'dropFile') {
            const filesList = getFilesByAttachCode(field.code1C)
            field['links'] = filesList
          }
        })
      })
  } catch (err) {
    toast.error(err)
  }
}

async function clearStash(data) {
  try {
    await api
      .stash()
      .clearStash(fieldFormHash.value)
      .then(() => {
        if (data) {
          if (data?.pointId) {
            router.replace(`/tasks/${data?.pointId}`)
          }
        }
      })
  } catch (err) {
    console.log(err)
  }
}

function getFormatValue(field) {
  if (field.type === 'datetime-local') {
    return field.default_value ? getFormatDateTimeForBackend(field.default_value) : ''
  }

  if (field.type === 'date') {
    return field.default_value ? moment(field.default_value).format('DD.MM.YYYY') : ''
  }

  if (field.type === 'number') {
    return +field.default_value
  }

  return field.default_value || ''
}

function getData() {
  let params = {
    values: {},
  }

  if (!props.pointData) {
    params['project'] = {}
  } else {
    params['pointId'] = +route.params.id
    params['code1C'] = templateData.value?.project?.templateCode
  }

  params['templateCode'] = templateData.value?.projectTypeCode

  fields.value?.forEach((el) => {
    if (!el.default_value) {
      return
    }
    if (!el.code1C && !props.pointData) {
      params['project'] = {
        ...params['project'],
        [el.id]: el.default_value,
      }
    } else if (el.type !== 'dropFile' && el.type !== 'group') {
      if (el.type === 'select' && el.multiple) {
        let arr = []
        if (typeof el?.default_value === 'object' && el?.default_value) {
          arr = el?.default_value
        } else if (el?.default_value) {
          arr = [el?.default_value]
        } else {
          arr = []
        }
        arr?.forEach((value, index) => {
          params['values'] = {
            ...params['values'],
            [el.code1C]: [
              ...(params.values[el.code1C] || []),
              {
                value: value,
                groupIndex: el.groupIndex || null,
                valueIndex: index,
              },
            ],
          }
        })
        return
      }

      if (el.fieldTypeId === 14) {
        if (el.readOnly) {
          params['values'] = {
            ...params['values'],
            [el.code1C]: [
              ...(params.values[el.code1C] || []),
              {
                value: el.default_value,
                groupIndex: el.groupIndex || null,
                valueIndex: el.index,
              },
            ],
          }
          return
        }
        let arr = []
        if (typeof el?.default_value === 'object' && el?.default_value) {
          arr = el?.default_value || []
        } else if (el?.default_value) {
          arr = [el?.default_value]
        }

        arr?.forEach((value, index) => {
          params['values'] = {
            ...params['values'],
            [el.code1C]: [
              ...(params.values[el.code1C] || []),
              {
                value: value,
                groupIndex: el.groupIndex || null,
                valueIndex: index,
              },
            ],
          }
        })
        return
      }
      params['values'] = {
        ...params['values'],
        [el.code1C]: [
          ...(params.values[el.code1C] || []),
          {
            value: getFormatValue(el),
            groupIndex: el.groupIndex || null,
            valueIndex: el.index,
          },
        ],
      }
    }
  })

  if (templateData.value?.preRegistration) {
    params['project'] = {
      name: 'Предварительная регистрация',
      description: '',
    }
  } else {
    const name = fields.value?.find((field) => field.paramName === 'projectName')
    const description = fields.value?.find((field) => field.paramName === 'projectDescription')
    if (name) {
      params['project'] = {
        ...params['project'],
        name: name?.default_value,
      }

      if (description) {
        params['project'] = {
          ...params['project'],
          description: description?.default_value,
        }
      } else {
        params['project'] = {
          name: name?.default_value,
          description: '',
        }
      }
    } else {
      params['project'] = {
        ...params['project'],
        description: '',
        name: fields.value.find((el) => el.name === 'name')?.default_value,
      }
    }
  }

  return params
}

async function onSaveNote() {
  try {
    const params = getData()
    api
      .questionnaires()
      .saveApplicationNote({
        code1C: templateData.value?.code1C,
        ...params,
        pointCode: templateData.value?.point?.code1C || templateData.value?.templatePointCode,
      })
      .then(() => {
        toast.success('Черновик сохранен')
      })
      .catch((err) => {
        const error = err.response.data
        if (error.field && error.message) {
          const errField = fields.value.find((el) => el.code1C === error.field)

          toast.error(error.message + `в поле ${errField.title}`)
        }
      })
  } catch (err) {
    console.log(err)
  }
}
async function onUpdate(data) {
  try {
    await api
      .projects()
      .savePoint(route.params?.id, data)
      .then(() => {
        toast.success(`Проектная точка успешно сохранена`)
        clearStash()
        window.location.reload()
        // getSavedData()
      })
  } catch (err) {
    const error = err.response.data
    if (error.field && error.message) {
      const errField = fields.value?.find((el) => el?.code1C === error?.field)
      const errMessage = errField?.title
        ? `${error.message} в поле ${errField?.title}`
        : error.message
      toast.error(errMessage)
    } else if (error?.message) {
      toast.error(error?.message)
    }
  }
}

async function onSubmit() {
  isDisableSubmitButton.value = true
  try {
    const params = getData()

    if (props.pointData) {
      return onUpdate(params)
    }
    api
      .questionnaires()
      .saveApplication(templateData.value?.code1C, params)
      .then((data) => {
        toast.success(`Проект успешно создан`)
        clearStash(data)
      })
      .catch((err) => {
        const error = err.response.data
        if (error.field && error.message) {
          const errField = fields.value?.find((el) => el?.code1C === error?.field)
          const errMessage = errField?.title
            ? `${error.message} в поле ${errField?.title}`
            : error.message
          toast.error(errMessage)
        } else if (error?.message) {
          toast.error(error?.message)
        }
      })
  } catch (err) {
    toast.error(err?.message)
  } finally {
    setTimeout(() => {
      isDisableSubmitButton.value = false
    }, 2000)
  }
}

const getPointDataValues = (values) => {
  const savedData = {}
  values.forEach((valueField) => {
    if (!valueField.value) {
      return
    }
    const selectField = fields.value?.find((field) => {
      if (field.code1C === valueField.fieldCode) {
        field.managerComment = valueField.managerComment
        return field
      }

      return false
    })

    if (selectField?.dataType === 'select' && selectField.multiple) {
      const valueKey = `${valueField.fieldCode}.${0}.${valueField.groupIndex || 0}`
      if (valueKey in savedData) {
        savedData[valueKey] = [...savedData[valueKey], valueField.value]
      } else {
        savedData[valueKey] = [valueField.value]
      }
    } else {
      savedData[
        `${valueField.fieldCode}.${valueField.valueIndex || 0}.${valueField.groupIndex || 0}`
      ] = valueField.value
    }
  })
  setDataFromCache(savedData)
}

onMounted(() => {
  if (route.params.code1C) {
    return getQuestionnaireById()
  }

  setTaskData()
})
</script>
<style lang="scss">
.has-error {
  .v-input__details {
    display: flex;
  }
}

.default-view {
  .v-input__details {
    display: none;
  }
}
</style>
