<template>
  <v-row v-if="isLoading" class="px-0 justify-center align-center" style="min-height: 400px">
    <v-progress-circular color="primary" :indeterminate="true" />
  </v-row>
  <template v-else>
    <v-row class="overflow-x-auto pa-0 ma-0">
      <the-custom-table
        :head="head"
        :data="data"
        width="1600px"
        :has-delete="isWinner"
        :has-edit="isWinner"
        @edit="(id) => $router.push(`/employees-form/${id}`)"
        @delete="deleteEmployee"
      />
    </v-row>
    <template v-if="isWinner">
      <FormFile
        id="ImportFile"
        v-model="importFile"
        accept=".xls, .xlsx"
        reverse
        style="display: none"
        :rules="[['required'], ['file', { extensions: ['.xlsx', '.xls'] }]]"
        clearable
        single-line
        variant="outlined"
      />
      <v-row class="mt-12 mx-0 align-center">
        <v-btn
          v-if="!!isShowBackButton"
          variant="outlined"
          color="primary"
          class="mr-2"
          @click="goBack"
        >
          Назад
        </v-btn>
        <v-btn class="mr-4" color="primary" variant="outlined" @click="downloadEmployeesTemplate">
          Скачать шаблон / инструкцию
        </v-btn>
        <v-btn color="primary" @click="findFile"> Загрузить </v-btn>
      </v-row>
      <v-row class="mt-12 mx-0 align-center">
        <v-btn color="primary" @click="() => $router.push('/employees-form')">
          Добавить сотрудника
        </v-btn>
      </v-row>
    </template>
    <v-row v-else class="mt-12 mx-0 align-center">
      <v-btn class="mr-4" color="primary" @click="exportConsortiums"> Выгрузить </v-btn>
    </v-row>
  </template>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import { api } from '@/api/Api'

import TheCustomTable from '@/components/TheCustomTable'
import FormFile from '@/components/Form/FormFile'
import { useStore } from 'vuex'
import { downloadFileData } from '@/helpers'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'
import { useToast } from '@/composables/useToast'

const { toast } = useToast()
const store = useStore()

const head = ref([
  {
    id: uuidv4(),
    title: 'Номер Программы',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'Номер Исполнителя',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: 'Центр НТИ',
    hasFilter: true,
  },
  {
    id: uuidv4(),
    title: '№ п/п',
  },
  {
    id: uuidv4(),
    title: 'ФИО',
  },
  {
    id: uuidv4(),
    title: 'Должность в Центре НТИ',
  },
  {
    id: uuidv4(),
    title: 'Дата приема',
  },
  {
    id: uuidv4(),
    title: 'Дата увольнения',
  },
  {
    id: uuidv4(),
    title: 'Отчетный период',
    hasFilter: true,
  },
])

const route = useRoute()
const router = useRouter()

const data = ref([])
const isLoading = ref(false)
const importFile = ref()

const isWinner = computed(() => store.getters['user/isWinner'])
const isShowBackButton = computed(() => route.query?.edit)

function findFile() {
  document.getElementById('inputCustomImportFile').click()
}

async function exportConsortiums() {
  try {
    api
      .information()
      .exportEmployees()
      .then((response) => {
        downloadFileData(response, 'Сотрудники центров НТИ')
      })
  } catch (err) {
    console.log(err)
  }
}

async function downloadEmployeesTemplate() {
  try {
    await api
      .information()
      .downloadEmployeesTemplate()
      .then((response) => {
        downloadFileData(response, 'Шаблон "Сотрудники центров НТИ"')
      })
  } catch (err) {
    console.log(err)
  }
}

async function deleteEmployee(id) {
  try {
    await api
      .information()
      .deleteEmployee(id)
      .then(() => getEmployees())
  } catch (err) {
    console.log(err)
  }
}

async function getEmployees() {
  isLoading.value = true
  try {
    await api
      .information()
      .getEmployees()
      .then((res) => {
        data.value = res?.map((el) => {
          delete el.centerNtiId
          return {
            ...el,
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function importEmployees() {
  try {
    const form = new FormData()
    form.append('file', importFile.value[0])
    await api
      .information()
      .importEmployees(form)
      .then(() => {
        getEmployees()
      })
  } catch (err) {
    toast.error('Ошибка при чтении файла. Попробуйте загрузить другой файл')
    console.log(err)
  }
}

function goBack() {
  return router.go(-1)
}

onMounted(() => {
  getEmployees()
})

watch(importFile, () => {
  importEmployees()
})
</script>
