import { ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'

export const fields = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'protocolKK',
    title: 'Загрузить рекомендации Экспертного совета / Протокол КК*',
    options: [],
    default_value: null,
    descr: '',
    links: [],
    note: '',
    multiple: true,
    rules: [['required'], ['file', { extensions: ['.pdf'] }]],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 1,
    type: 'textarea',
    name: 'managerComment',
    title: 'Комментарий менеджера*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 2,
  },
])
