<template>
  <v-form ref="form" v-model="validForm">
    <section v-if="fieldsForNewForm.length !== 0" class="form__container">
      <v-row>
        <v-col cols="12" md="6">
          <div v-for="field in fieldsForNewForm" :key="field.id">
            <template v-if="field.isVisible">
              <p v-if="field.title" class="text-body-1 ma-0">
                {{ field.title }}
              </p>
              <Component
                :is="inputs[getFormInput(field.type)]"
                v-bind="field"
                @update-value="updateValue"
              />
            </template>
          </div>
          <div class="v-input__details">
            <div class="v-messages">
              <span class="text-body-2">* - поля обязательные для заполнения</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </section>
  </v-form>
</template>

<script setup>
import FormText from '@/components/Form/FormText'
import FormSelect from '@/components/Form/FormSelect'
import FormCombobox from '@/components/Form/FormCombobox'
import FormFile from '@/components/Form/FormFile'
import FormTextarea from '@/components/Form/FormTextarea'
import FormRadio from '@/components/Form/FormRadio'
import FormCheckbox from '@/components/Form/FormCheckbox'
import FormTextRules from '@/components/Form/FormTextRules'
import { ref, onMounted, watch } from 'vue'
import { api } from '@/api/Api'
import { v4 as uuidv4 } from 'uuid'
import { findItem } from '@/utils/common'
import { useRoute } from 'vue-router'

const route = useRoute()
const inputs = {
  FormText,
  FormTextarea,
  FormSelect,
  FormCombobox,
  FormFile,
  FormRadio,
  FormCheckbox,
  FormTextRules,
}
const emit = defineEmits(['update-forms', 'get-forms'])

const validForm = ref(true)
const form = ref(null)
const fieldsForNewForm = ref([
  {
    isVisible: true,
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    name: 'contest_id',
    title: 'Выбрать конкурс*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: null,
    sort: 10,
    itemValue: 'id',
  },
  {
    isVisible: true,
    id: uuidv4(),
    group_id: 0,
    type: 'radio',
    name: 'wayCreated',
    title: 'Способ создания формы',
    options: [
      {
        id: 7,
        name: 'Создать новую',
        value: 'new',
        description: '',
        disabled: 'N',
        sort: 10,
      },
      {
        id: 8,
        name: 'Создать форму на основе ранее созданной',
        value: 'based',
        description: '',
        disabled: 'N',
        sort: 10,
      },
    ],
    default_value: 'new',
    descr: '',
    note: '',
    multiple: 0,
    rules: null,
    sort: 80,
  },
  {
    isVisible: true,
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'title',
    title: 'Заголовок формы*',
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 20,
    findType: 'wayCreated',
    findValue: 'new',
  },
  {
    isVisible: true,
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'description',
    title: 'Описание формы',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [],
    sort: 30,
    findType: 'wayCreated',
    findValue: 'new',
  },
  {
    isVisible: true,
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'email',
    title: 'E-mail для кнопки «Связаться с менеджером»',
    options: [],
    default_value: '',
    descr:
      'Так же используется как отправитель при отправке писем. Позволяет переопределить настройки конкурса.',
    note: '',
    multiple: 0,
    rules: [['email']],
    sort: 40,
    findType: 'wayCreated',
    findValue: 'new',
  },
  {
    isVisible: true,
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'email_sign',
    title: 'Подпись для уведомлений и писем',
    options: [],
    default_value: '',
    descr: 'Позволяет переопределить настройки конкурса.',
    note: '',
    multiple: 0,
    rules: null,
    sort: 50,
    findType: 'wayCreated',
    findValue: 'new',
  },
  {
    isVisible: true,
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'success_message',
    title: 'Текст сообщения об успешной регистрации',
    options: [],
    default_value: '',
    descr:
      'Выводится при успешной отправке анкеты на экране и отправляется по почте пользователю.  Позволяет переопределить настройки конкурса.',
    note: '',
    multiple: 0,
    rules: null,
    sort: 60,
    findType: 'wayCreated',
    findValue: 'new',
  },
  {
    isVisible: false,
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    name: 'donor_id',
    title: 'Выберите форму',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: null,
    sort: 110,
    itemValue: 'id',
    itemTitle: 'title',
    findType: 'wayCreated',
    findValue: 'based',
  },
  {
    isVisible: true,
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    name: 'data_source_id',
    title: 'Выберите форму, для обогащения анкеты',
    options: [
      {
        title: 'Нет формы',
        id: 0,
      },
    ],
    default_value: 0,
    descr: '',
    note: '',
    multiple: 0,
    rules: null,
    sort: 110,
    itemValue: 'id',
    itemTitle: 'title',
    findType: 'wayCreated',
    findValue: 'new',
  },
])
const formItems = ref([])

function getFormInput(type) {
  switch (type) {
    case 'hidden': {
      return ''
    }
    case 'text': {
      return 'FormText'
    }
    case 'textarea': {
      return 'FormTextarea'
    }
    case 'select': {
      return 'FormSelect'
    }
    case 'combobox': {
      return 'FormCombobox'
    }
    case 'combobox_other': {
      return 'FormTextCombobox'
    }
    case 'group': {
      return ''
    }
    case 'checkbox': {
      return 'FormCheckbox'
    }
    case 'radio': {
      return 'FormRadio'
    }
    case 'file': {
      return 'FormFile'
    }
    case 'rules': {
      return 'FormTextRules'
    }
    default: {
      return ''
    }
  }
}

function updateValue({ value, id }) {
  fieldsForNewForm.value.forEach((item) => {
    if (item.id === id) {
      item.default_value = value
    }

    if (item.findType && item.findValue) {
      item.isVisible =
        findItem('name', item.findType, fieldsForNewForm.value)?.default_value === item.findValue
    }
  })
}
function focusErrorInput({ id }) {
  const inputId = '#' + id
  document.querySelector(inputId).focus()
}
async function getForm() {
  await form.value.validate()
  if (!validForm.value) {
    const errInput = form.value.errors[0]
    focusErrorInput(errInput)
    return
  }

  emit('get-forms', fieldsForNewForm.value, validForm.value)
}
async function loadItems() {
  const { items: contestItems } = await api.contest().getContests(1, 50)
  const { items: forms } = await api.form().getForms(1, 1000, { exclude: 'contest,fields' })
  formItems.value = forms

  findItem('name', 'donor_id', fieldsForNewForm.value).options = forms
  findItem('name', 'contest_id', fieldsForNewForm.value).options = contestItems
  if (!route.query?.contest_id) {
    return
  }

  const contest = findItem('id', Number(route.query.contest_id), contestItems)
  if (contest) {
    findItem('name', 'contest_id', fieldsForNewForm.value).default_value = contest.id
  }
}

watch(fieldsForNewForm.value[0], async () => {
  const { default_value: contestId } = findItem('name', 'contest_id', fieldsForNewForm.value)
  if (contestId) {
    const item = findItem('name', 'data_source_id', fieldsForNewForm.value)
    const qwery = {
      contest_id: contestId,
      exclude: 'contest,fields',
    }
    const { items } = await api.form().getForms(1, 1000, qwery)
    const basisForm = [
      {
        title: 'Нет формы',
        id: 0,
      },
    ]
    item.options = [...basisForm, ...items]
  }
})

onMounted(loadItems)

defineExpose({
  getForm,
})
</script>
<script>
export default {
  name: 'FormsNewForm',
}
</script>
