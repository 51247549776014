<template>
  <table :style="`max-width:  ${width};width:  ${width}; width: 100%`" class="table">
    <tr class="th-wrapper pa-0 ma-0">
      <td
        v-for="(item, index) in head"
        :key="`th-${index}`"
        class="ma-0 th-table text-center white-space-nowrap"
      >
        <v-row class="pa-0 ma-0 flex-nowrap align-center justify-center word-break">
          <div class="text-caption font-weight-bold word-break">
            {{ item.title }}
          </div>
          <!--          <v-icon v-if="item.hasFilter" size="xs" color="grey"> mdi-chevron-down </v-icon>-->
        </v-row>
      </td>
      <td v-if="hasEdit" width="50" />
      <td v-if="hasDelete" width="50" />
    </tr>
    <tr v-for="(row, index) in data" :key="`th-${index}`" class="row-table pa-0 ma-0">
      <template v-for="(item, i) in row">
        <td
          v-if="i !== 'id'"
          :key="`th-${i}`"
          :class="[
            'td-table text-caption align-center word-break ',
            textTdLeft ? 'text-left' : 'text-center',
          ]"
        >
          <div style="word-break: normal" v-html="item || '-'" />
        </td>
      </template>
      <td
        v-if="hasEdit"
        style="max-width: 50px"
        width="50"
        class="text-center text-caption white-space-nowrap"
      >
        <v-btn
          size="x-small"
          icon="mdi-pencil"
          color="primary"
          variant="text"
          @click="$emit('edit', row['id'])"
        />
      </td>
      <td
        v-if="hasDelete"
        width="50"
        style="max-width: 50px"
        class="text-center text-caption white-space-nowrap"
      >
        <v-btn
          size="x-small"
          icon="mdi-delete-outline"
          color="red"
          variant="text"
          @click="() => confirmDelete(row['id'])"
        />
      </td>
    </tr>
  </table>
  <v-row
    v-if="!data?.length"
    :style="`min-width: ${width}`"
    class="px-0 mx-0 py-6 text-grey text-caption justify-center row-table my-0"
  >
    Нет данных
  </v-row>
  <v-dialog v-model="dialog" width="400">
    <v-card>
      <v-card-text class="text-center">Вы действительно хотите удалить?</v-card-text>
      <v-card-actions class="d-flex align-center justify-center">
        <v-btn color="red" @click="deleteItem"> Удалить </v-btn>
        <v-btn color="primary" variant="text" @click="closeConfirmDeleteDialog"> Отмена </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup>
import { ref } from 'vue'

const dialog = ref(false)
const selectedElement = ref(false)
const emit = defineEmits('delete')

function confirmDelete(id) {
  dialog.value = true
  selectedElement.value = id
}

function closeConfirmDeleteDialog() {
  dialog.value = false
  selectedElement.value = null
}

function deleteItem() {
  emit('delete', selectedElement.value)
  dialog.value = false
}
</script>
<script>
export default {
  props: {
    head: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    hasEdit: {
      type: Boolean,
      default: false,
    },
    hasDelete: {
      type: Boolean,
      default: false,
    },
    textTdLeft: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="scss">
.table {
  width: 100%;
}
.row-table {
  border: 1px solid var(--border-th-table);
  border-top: 0;

  .td-table {
    //border-right: 1px solid var(--border-th-table);
    word-break: break-word;
    min-width: 100%;
    padding: 10px;

    &:last-child {
      border-right: 0;
    }
  }
}

table {
  border-collapse: collapse;
}
.th-wrapper {
  background: var(--background-thead-table);
  border: 1px solid var(--border-th-table);

  .th-table {
    //border-right: 1px solid var(--border-th-table);
    word-break: break-word;
    border-top: 1px solid var(--border-th-table);
    padding: 10px;
    vertical-align: middle;

    &:first-child {
      border-left: 1px solid var(--border-th-table);
    }

    &:last-child {
      border-right: 0;
      border-right: 1px solid var(--border-th-table);
    }
  }
}
</style>
