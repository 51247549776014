<template>
  <the-container>
    <h3 class="mb-6">
      Детализированный отчет о структуре расходов, источником финансового обеспечения которых
      являются средства гранта
    </h3>
    <v-col
      v-for="(item, index) in value"
      :key="`category${index}`"
      class="text-grey-darken-2 my-6 px-0 mx-0"
      cols="8"
    >
      <v-col v-if="item.title" class="text-body-1 px-0 font-weight-bold mb-6">{{
        item.title
      }}</v-col>
      <v-row
        v-for="(row, indexRow) in item.data"
        :key="`row-${indexRow}`"
        class="px-0 mx-0 border-bottom text-grey-darken-2"
      >
        <v-col cols="6" class="px-0 mx-0">{{ row.title }}</v-col>
        <v-col cols="6" class="px-0 mx-0">{{ row.value }}</v-col>
      </v-row>
      <template v-if="item.docs?.length">
        <v-col class="text-body-1 px-0 font-weight-bold my-6">Прикрепленные документы:</v-col>
        <v-col v-for="(doc, i) in item.docs" :key="`doc-${i}`" class="pa-0 ma-0 text-blue">
          <RouterLink :to="doc.link">
            {{ doc.title }}
          </RouterLink>
        </v-col>
      </template>
    </v-col>

    <v-row class="mx-0 mt-12 px-0 align-center">
      <v-btn depressed color="primary" variant="elevated" class="mr-2"> Печать </v-btn>
      <v-btn class="ml-2 text-decoration-underline" color="primary" variant="text"> Закрыть </v-btn>
      <v-progress-circular indeterminate color="primary" :size="20" class="ml-4" :width="3" />
      <div class="text-body-2 text-grey ml-2">Данные сохраняются</div>
    </v-row>
  </the-container>
</template>
<script setup>
import TheContainer from '@/components/TheContainer'
import { ref } from 'vue'

const value = ref([
  {
    title: 'Общие сведения',
    data: [
      {
        title: 'Полное наименование получателя Гранта',
        value: 'Заполненные данные',
      },
      {
        title: 'ИНН Получателя гранта',
        value: 'Заполненные данные',
      },
      {
        title: 'Наименование Центра НТИ',
        value: 'Заполненные данные',
      },
      {
        title: 'Дата начала реализации Программы Центра НТИ',
        value: 'Заполненные данные',
      },
      {
        title: 'Номер Договора',
        value: 'Заполненные данные',
      },
      {
        title: 'Дата заключения Договора',
        value: 'Заполненные данные',
      },
      {
        title: 'Дата начала отчётного периода',
        value: 'Заполненные данные',
      },
      {
        title: 'Дата окончания отчётного периода',
        value: 'Заполненные данные',
      },
      {
        title: 'Дата предоставления отчета',
        value: 'Заполненные данные',
      },
      {
        title: 'Вид отчета',
        value: 'Заполненные данные',
      },
    ],
  },
  {
    title: 'I. Достижение ключевых контрольных точке в 2022 году',
    data: [
      {
        title: 'Плановый объем расходов, руб.',
        value: 'Заполненные данные',
      },
    ],
  },
  {
    title: 'Фонд оплаты труда (включая начисления на фонд оплаты труда)',
    data: [
      {
        title: 'Расходы из доходных договоров, руб.',
        value: 'Заполненные данные',
      },
      {
        title: 'Расходы из других источников, руб.',
        value: 'Заполненные данные',
      },
      {
        title: 'Всего средств:',
        value: 'Заполненные данные',
      },
    ],
  },
  {
    title: 'Оборудование',
    data: [
      {
        title: 'Расходы из доходных договоров, руб.',
        value: 'Заполненные данные',
      },
      {
        title: 'Расходы из других источников, руб.',
        value: 'Заполненные данные',
      },
      {
        title: 'Всего средств:',
        value: 'Заполненные данные',
      },
    ],
  },
  {
    title: 'Программное обеспечение',
    data: [
      {
        title: 'Расходы из доходных договоров, руб.',
        value: 'Заполненные данные',
      },
      {
        title: 'Расходы из других источников, руб.',
        value: 'Заполненные данные',
      },
      {
        title: 'Всего средств:',
        value: 'Заполненные данные',
      },
    ],
  },
  {
    title: 'Материалы и комплектующие',
    data: [
      {
        title: 'Расходы из доходных договоров, руб.',
        value: 'Заполненные данные',
      },
      {
        title: 'Всего средств:',
        value: 'Заполненные данные',
      },
    ],
  },
  {
    title: 'Оплата услуг сторонних организаций',
    data: [
      {
        title: 'Расходы из доходных договоров, руб.',
        value: 'Заполненные данные',
      },
      {
        title: 'Всего средств:',
        value: 'Заполненные данные',
      },
    ],
  },
  {
    title: 'Прочие прямые расходы',
    data: [
      {
        title: 'Расходы из доходных договоров, руб.',
        value: 'Заполненные данные',
      },
      {
        title: 'Всего средств:',
        value: 'Заполненные данные',
      },
    ],
  },
  {
    title: 'Накладные расходы',
    data: [
      {
        title: 'Расходы из доходных договоров, руб.',
        value: 'Заполненные данные',
      },
      {
        title: 'Всего средств:',
        value: 'Заполненные данные',
      },
    ],
  },
  {
    title: 'Накладные расходы',
    data: [
      {
        title: 'Расходы из доходных договоров, руб.',
        value: 'Заполненные данные',
      },
      {
        title: 'Всего средств:',
        value: 'Заполненные данные',
      },
      {
        title: 'Итого:',
        value: '1 234 567 руб.',
      },
    ],
  },
  {
    title: 'ИТОГОВАЯ СУММА:',
    data: [
      {
        title: 'Плановый объем расходов, руб.',
        value: 'Сумма считается автоматически',
      },
      {
        title: 'Расходы из доходных договоров, руб.',
        value: 'Сумма считается автоматически',
      },
      {
        title: 'Расходы из других источников, руб.',
        value: 'Сумма считается автоматически',
      },
    ],
  },
  {
    title:
      'II. Расходы средств из внебюджетных источников на приобретение оборудования в 2022 году',
    data: [
      {
        title: 'Наименование оборудования',
        value: 'Заполненные данные',
      },
      {
        title: 'Количество',
        value: 'Заполненные данные',
      },
      {
        title: 'Цена за единицу, руб.',
        value: 'Заполненные данные',
      },
      {
        title: 'Стоимость, руб.',
        value: 'Заполненные данные',
      },
      {
        title: 'Наименование организации, понесшей расходы',
        value: 'Заполненные данные',
      },
      {
        title: 'Наименование и реквизиты док-в, подтверждающих расходы',
        value: 'Заполненные данные',
      },
      {
        title: 'Итого:',
        value: '1 234 567 руб.',
      },
    ],
    docs: [
      {
        id: 1,
        title: 'Подтверждающие документы',
        link: 'https://drive.google.com/file/d/1-eLPumtFzPNVm7w__kVmiCaS6q57AywM/view',
      },
    ],
  },
  {
    title:
      'III. Расходы средств внебюджетных источников на приобретение программного обеспечения в 2022 году',
    data: [
      {
        title: 'Наименование оборудования',
        value: 'Заполненные данные',
      },
      {
        title: 'Количество',
        value: 'Заполненные данные',
      },
      {
        title: 'Цена за единицу, руб.',
        value: 'Заполненные данные',
      },
      {
        title: 'Стоимость, руб.',
        value: 'Заполненные данные',
      },
      {
        title: 'Наименование организации, понесшей расходы',
        value: 'Заполненные данные',
      },
      {
        title: 'Наименование и реквизиты док-в, подтверждающих расходы',
        value: 'Заполненные данные',
      },
      {
        title: 'Итого:',
        value: '1 234 567 руб.',
      },
    ],
    docs: [
      {
        id: 1,
        title: 'Подтверждающие документы',
        link: 'https://drive.google.com/file/d/1-eLPumtFzPNVm7w__kVmiCaS6q57AywM/view',
      },
    ],
  },
  {
    title:
      'IV.Расходы средств внебюджетных источников на оплату выполненных работ, оказанных услуг в 2022 году',
    data: [
      {
        title: 'Наименование работ, услуг ',
        value: 'Заполненные данные',
      },
      {
        title: 'Наименование организации, понесшей расходы',
        value: 'Заполненные данные',
      },
      {
        title: 'Наименование и реквизиты док-в, подтверждающих расходы',
        value: 'Заполненные данные',
      },
      {
        title: 'Стоимость, руб.',
        value: 'Заполненные данные',
      },
    ],
    docs: [
      {
        id: 1,
        title: 'Подтверждающие документы',
        link: 'https://drive.google.com/file/d/1-eLPumtFzPNVm7w__kVmiCaS6q57AywM/view',
      },
    ],
  },
  {
    data: [
      {
        title: 'Итого:',
        value: '2 456 789 руб.е',
      },
    ],
  },
])
</script>
