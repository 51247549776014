<template>
  <v-form>
    <v-row>
      <v-col cols="12" md="8">
        <div v-for="field in fieldsForm" :key="field.id">
          <p v-if="field.title" class="text-body-1 ma-0">
            {{ field.title }}
          </p>
          <Component
            :is="inputs[getFormInput(field.type)]"
            v-bind="field"
            @change-value="updateDataInStash"
            @update-value="(data) => updateValue(data, fieldsForm)"
            @delete-file="(id) => $emit('delete-file', id)"
            @download-file="(id, name) => $emit('download-file', id, name)"
          />
        </div>
        <v-col class="px-0 mt-6">
          <div class="v-messages">
            <div class="text-body-2 text-grey">* - поля обязательные для заполнения</div>
            <span class="text-body-2">Введенные данные сохраняются автоматически</span>
          </div>
        </v-col>
      </v-col>
    </v-row>
    <v-row class="align-center px-0 mx-0 mt-4">
      <v-btn
        :disabled="isDisabledConfirmButton"
        color="primary"
        class="mr-2"
        @click="() => sendWPData(2, false)"
      >
        Согласовано полностью
      </v-btn>
      <v-btn
        :disabled="isDisabledConfirmButton"
        color="primary"
        class="mr-2"
        @click="() => sendWPData(1, false)"
      >
        Согласовано частично
      </v-btn>
      <v-btn
        :disabled="isDisabledConfirmButton"
        color="primary"
        class="mr-2"
        @click="() => sendWPData(null, true)"
      >
        Не согласовано
      </v-btn>
      <v-btn
        class="text-decoration-underline"
        color="primary"
        variant="text"
        @click="() => router.push('/coordination-documents')"
      >
        Закрыть
      </v-btn>
    </v-row>
  </v-form>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'

import { api } from '@/api/Api'

import { useToast } from '@/composables/useToast'
import { inputs, getFormInput } from '@/helpers'
import { emit as baseEmit } from '@/composables/form/usePropsForm'
import { PROGRAM_CHANGE_REQUEST_MANAGER } from '@/constants/stash'

const route = useRoute()
const router = useRouter()
const { toast } = useToast()

const emit = defineEmits(baseEmit)
const props = defineProps({
  ticket: {
    type: Object,
    default: () => {},
  },
  files: {
    type: Array,
    default: () => [],
  },
})

const isDisabledConfirmButton = computed(() =>
  fieldsForm.value?.find((el) => !el?.links?.length && !el.default_value),
)
const fieldFormHash = computed(() => `${PROGRAM_CHANGE_REQUEST_MANAGER}-${route.params?.id}`)

const isLoading = ref(false)
const fieldsForm = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'dropFile',
    name: 'conclusionWG',
    title: 'Заключение РГ*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [['required']],
    sort: 1,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'managerRGConfirmComment',
    title: 'Комментарий Менеджера*',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: true,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 1,
  },
])

function updateValue({ value, id }) {
  fieldsForm.value?.forEach((item) => {
    if (item.id === id) {
      item.default_value = value

      if (item.type === 'dropFile') {
        value?.forEach((file) => {
          emit('upload-file', item.name, file)
        })
      }
    }
  })
}

async function updateDataInStash({ value, id }) {
  const findField = fieldsForm.value?.find((el) => el.id === id)
  if (findField) {
    const params = {
      field: findField.name,
      value: value,
      hash: fieldFormHash.value,
    }

    await api.stash().saveFieldInStash(params)
  }
}
async function sendWPData(statusConfirm, isDiscard = false) {
  isLoading.value = true
  try {
    const managerConfirmFiles = fieldsForm.value?.filter((el) => el.type === 'dropFile')
    const fileKeys = managerConfirmFiles?.map((el) => el.name)
    const managerComment = fieldsForm.value?.find((el) => el.name === 'managerRGConfirmComment')
    let data = {
      fileKeys: fileKeys,
      isDiscard: !!isDiscard,
      managerComment: managerComment?.default_value || '',
    }

    if (statusConfirm) {
      data = {
        ...data,
        statusConfirm: statusConfirm,
      }
    }

    await api
      .tickets()
      .editStatusChangeRequest(route.params?.id, data, props.ticket?.status)
      .then(() => {
        emit('get-data')
        emit('get-files')
      })
  } catch (err) {
    toast.error(err?.message)
  } finally {
    isLoading.value = false
  }
}

function setData() {
  fieldsForm.value?.forEach((el) => {
    if (el.type !== 'dropFile') {
      return
    }

    if (props.files && el.name in props.files) {
      el.links = props.files[el.name]?.filter((el) => !el.isConfirmed)
    }
  })
}

async function getSavedData() {
  isLoading.value = true
  try {
    await api
      .stash()
      .getStashData(fieldFormHash.value)
      .then((data) => {
        fieldsForm.value?.forEach((el) => {
          if (el.type === 'dropFile') {
            return
          }
          if (el.name in data) {
            el.default_value = data[el.name]
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  if (props.files) {
    setData()
  }

  getSavedData()
})
</script>
