<template>
  <the-tabs :key="activeTab" :tabs="tabs" :active-tab="activeTab" @tab-click="tabClick" />
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import TheTabs from '@/components/TheTabs'
import QuarterContent from '@/views/Reports/PeriodReportsList/QuarterContent'
import { REPORTS_IDS, REPORTS_TYPES } from '@/constants'
import { emit as baseEmit } from '@/composables/form/usePropsForm'

const emit = defineEmits(baseEmit)

const activeTab = ref(0)

const tabs = ref([
  {
    id: uuidv4(),
    title: 'I Квартал',
    component: QuarterContent,
    quarterId: REPORTS_IDS.FIRST_QUARTER,
    quarterType: REPORTS_TYPES.FIRST_QUARTER,
  },
  {
    id: uuidv4(),
    title: 'II Квартал',
    component: QuarterContent,
    quarterId: REPORTS_IDS.SECOND_QUARTER,
    quarterType: REPORTS_TYPES.SECOND_QUARTER,
  },
  {
    id: uuidv4(),
    title: 'III Квартал',
    component: QuarterContent,
    quarterId: REPORTS_IDS.THIRD_QUARTER,
    quarterType: REPORTS_TYPES.THIRD_QUARTER,
  },
  {
    id: uuidv4(),
    title: 'Годовой отчет',
    component: QuarterContent,
    quarterId: REPORTS_IDS.ANNUAL,
    quarterType: REPORTS_TYPES.ANNUAL,
    isAnnualReport: true,
  },
])

function tabClick(index) {
  localStorage.setItem('tab', index)
  emit('change-report', index)
}

onMounted(() => {
  if (JSON.parse(localStorage.getItem('tab'))) {
    activeTab.value = JSON.parse(localStorage.getItem('tab'))
    emit('change-report', activeTab.value)
  }
})
</script>
